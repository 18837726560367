<div class="actions-container" [class.disabled]="disabled" (click)="expandCollapse($event)" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <ng-content></ng-content>
  <div class="actions-icon" [class.expanded]="expandedState && !disabled"></div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="expandedState"
  [cdkConnectedOverlayPositions]="positions" (overlayOutsideClick)="expandCollapse($event)">
  <div class="dropdown-panel actions-dropdown">
    <div class="dropdown-item" *ngFor="let action of actions" (click)="run(action)" [ngClass]="action.class || []" [class.disabled]="action.disabled">
      {{action.title}}
    </div>
  </div>
</ng-template>