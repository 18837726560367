<nav class="navigation-bar">
  <div class="navigation-sign" (click)="toggleOpen()">
    <div class="menu-container" #menu>
      <div class="menu-header"></div>
      <div class="menu-contents">
        <perfect-scrollbar>
          <div class="menu-section" *ngIf="(activeUser | async)?.isAdmin || (activeUser | async)?.isTechnician" [class.expanded]="expanded[0]">
            <div class="section-title" (click)="toggle(0)">
              <div class="title-text">Models Management</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item align-container small-spacing" [routerLink]="['administration', 'device-models']"
                  routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" (isActiveChange)="expand(0)"
                  (mouseenter)="showBadge()" (mouseleave)="hideBadge()">
                <span>Device models</span>
                <span class="badge" *ngIf="badgesInfo?.incompleteModels"
                    [ngbPopover]="badgesInfo?.incompleteModels ? incompleteModels : null"
                    triggers="manual" #badge="ngbPopover" container="body" popoverClass="warning-tip">
                  {{badgesInfo.incompleteModels}}
                </span>
              </a>
              <a class="menu-item" [routerLink]="['administration', 'device-configs']" *ngIf="(activeUser | async)?.isAdmin"
                routerLinkActive="active" (isActiveChange)="expand(0)"><span>Device JSON-configs</span></a>
              <a class="menu-item" [routerLink]="['administration', 'model-configs']" *ngIf="(activeUser | async)?.isAdmin"
                routerLinkActive="active" (isActiveChange)="expand(0)"><span>Model JSON-configs</span></a>
              <a class="menu-item" [routerLink]="['administration', 'modifications']" *ngIf="(activeUser | async)?.isAdmin"
                routerLinkActive="active" (isActiveChange)="expand(0)"><span>Modifications</span></a>
              <a class="menu-item" [routerLink]="['administration', 'instructions']" *ngIf="(activeUser | async)?.isAdmin"
                routerLinkActive="active" (isActiveChange)="expand(0)"><span>Device instructions</span></a>
            </div>
          </div>
          <div class="menu-section" *ngIf="(activeUser | async)?.isAdmin" [class.expanded]="expanded[1]">
            <div class="section-title" (click)="toggle(1)">
              <div class="title-text">Settings</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" [routerLink]="['administration', 'device-types']"
                routerLinkActive="active" (isActiveChange)="expand(1)"><span>Device types</span></a>
            </div>
          </div>
          <div class="menu-section" [class.expanded]="expanded[2]"
            *ngIf="(activeUser | async)?.isAssembler || (activeUser | async)?.isInstallator || (activeUser | async)?.isAdmin">
            <div class="section-title" (click)="toggle(2)">
              <div class="title-text">Utilities</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="utilities/binding-tool"
                routerLinkActive="active" (isActiveChange)="expand(2)"
                *ngIf="(activeUser | async)?.isInstallator || (activeUser | async)?.isAdmin">
                <span>Wi-Fi module firmware</span>
              </a>
              <a class="menu-item" routerLink="utilities/bindings"
                routerLinkActive="active"  (isActiveChange)="expand(2)"
                *ngIf="(activeUser | async)?.isAssembler || (activeUser | async)?.isAdmin">
                <span>Wi-Fi module and device bind</span>
              </a>
            </div>
          </div>
          <div class="menu-section" *ngIf="(activeUser | async)?.isAdmin || (activeUser | async)?.isTechnician" [class.expanded]="expanded[3]">
            <div class="section-title" (click)="toggle(3)">
              <div class="title-text">Provisioning</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" [routerLink]="['provisioning', 'wifi-modules']"
                routerLinkActive="active" (isActiveChange)="expand(3)"><span>Wi-Fi modules &amp; devices</span></a>
              <a class="menu-item" [routerLink]="['provisioning', 'firmware']" *ngIf="(activeUser | async)?.isAdmin"
                routerLinkActive="active" (isActiveChange)="expand(3)" [routerLinkActiveOptions]="{exact: false}">
                <span>Firmware management</span></a>
            </div>
          </div>
          <div class="menu-section"  [class.expanded]="expanded[4]"
            *ngIf="(activeUser | async)?.isAdmin || (activeUser | async)?.isContentManager">
            <div class="section-title" (click)="toggle(4)">
              <div class="title-text">Templates</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="mobile-backend/templates"
                routerLinkActive="active" (isActiveChange)="expand(4)"><span>Pages Templates</span></a>
              <a class="menu-item" routerLink="mobile-backend/dictionaries"
                routerLinkActive="active" (isActiveChange)="expand(4)"><span>Dictionaries</span></a>
              <a class="menu-item" routerLink="mobile-backend/catalogue"
                routerLinkActive="active" (isActiveChange)="expand(4)"><span>Catalogue</span></a>
            </div>
          </div>
          <div class="menu-section"  [class.expanded]="expanded[5]"
            *ngIf="((activeUser | async)?.isAdmin && !(activeUser | async)?.isReadOnlyAdmin)|| (activeUser | async)?.isManager">
            <div class="section-title" (click)="toggle(5)">
              <div class="title-text">Reports</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="reports/devices"
                routerLinkActive="active" (isActiveChange)="expand(5)"><span>Devices Report</span></a>
              <a class="menu-item" routerLink="reports/firmware"
                routerLinkActive="active" (isActiveChange)="expand(5)"><span>Firmware Report</span></a>
            </div>
          </div>
          <div class="menu-section" *ngIf="(activeUser | async)?.isAdmin"  [class.expanded]="expanded[6]">
            <div class="section-title" (click)="toggle(6)">
              <div class="title-text">Haier TV</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="tv-apps/apps-versions"
                routerLinkActive="active" (isActiveChange)="expand(6)"><span>Mobile TV Apps Management</span></a>
            </div>
          </div>
          <div class="menu-section" *ngIf="(activeUser | async)?.isAdmin"  [class.expanded]="expanded[7]">
            <div class="section-title" (click)="toggle(7)">
              <div class="title-text">Administration</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="utilities/export-import"
                routerLinkActive="active" (isActiveChange)="expand(7)"><span>Export/Import</span></a>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="utilities/user-actions"
                routerLinkActive="active" (isActiveChange)="expand(7)"><span>User Actions</span></a>
            </div>
          </div>
          <div class="menu-section" *ngIf="(activeUser | async)?.isAdmin && !environment.production"  [class.expanded]="expanded[8]">
            <div class="section-title" (click)="toggle(8)">
              <div class="title-text">Testing</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="testing/firmware"
                routerLinkActive="active" (isActiveChange)="expand(8)"><span>Firmwares</span></a>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="testing/records"
                routerLinkActive="active" (isActiveChange)="expand(8)"><span>Records</span></a>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="testing/virtual-devices"
                routerLinkActive="active" (isActiveChange)="expand(8)"><span>Virtual Smart Devices</span></a>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="testing/tests"
                routerLinkActive="active" (isActiveChange)="expand(8)"><span>Tests</span></a>
            </div>
          </div>
          <div class="menu-section"  [class.expanded]="expanded[9]"
            *ngIf="((activeUser | async)?.isAdmin && !(activeUser | async)?.isReadOnlyAdmin)|| (activeUser | async)?.isManager">
            <div class="section-title" (click)="toggle(9)">
              <div class="title-text">Test Report Management</div>
              <div class="expand"></div>
            </div>
            <div class="section-content">
              <a class="menu-item" routerLink="reports/test-reports" [routerLinkActiveOptions]="{exact: true}"
                routerLinkActive="active" (isActiveChange)="expand(9)"><span>Test Reports</span></a>
              <a class="menu-item" routerLink="reports/test-reports/statistics"
                routerLinkActive="active" (isActiveChange)="expand(9)"><span>Statistics</span></a>
              <a class="menu-item" routerLink="reports/models-groups"
                routerLinkActive="active" (isActiveChange)="expand(9)"><span>Models Groups</span></a>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</nav>
<ng-template #incompleteModels>
  <div class="tip-content incomplete-models">
    <div class="tip-title">Need to work on:</div>
    <div class="tip-body">
      <ul class="incomplete-info">
        <li>Uncompleted models &mdash; {{badgesInfo?.incompleteModels}}</li>
      </ul>
    </div>
  </div>
</ng-template>
