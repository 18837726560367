<div class="calendar-container input-container" [class.highlighted]="expanded || focused" [class.clearable]="clearable">
  <input type="text" #text autocomplete="off" class="calendar-input" matInput [matDatepicker]="datePicker"
    [textMask]="CALENDAR_MASK" [disabled]="disabled" (focus)="setFocused(true)" (blur)="setFocused(false)"
    (click)="datePicker.open()" (dateChange)="handleChange($event)" [min]="minDate" [max]="maxDate" [value]="value">
  <mat-datepicker #datePicker [calendarHeaderComponent]="CalendarPanel"
    (opened)="setExpanded(true)" (closed)="setExpanded(false)">
  </mat-datepicker>
  <div class="controls">
    <div class="button button-clear" *ngIf="clearable && value" (click)="clear()"></div>
    <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
  </div>
</div>