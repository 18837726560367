<div class="tests-view inner-view">
  <div class="view-header">
    <h1 class="view-header align-container small-spacing">
      <span>Tests</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section search-block">
    <div class="align-container space-between">
      <div class="search-field">
        <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search ID, model or firmware version"></app-search-bar>
      </div>
      <button class="button primary" (click)="startTest()">Start Test</button>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ID" (sort)="setSorting($event)">
                Id
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_MAC" (sort)="setSorting($event)">
                Device MAC
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_MODEL" (sort)="setSorting($event)">
                Device Model
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.FW_VERSION" (sort)="setSorting($event)">
                Fw Version
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STARTED" (sort)="setSorting($event)">
                Started
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ENDED" (sort)="setSorting($event)">
                Ended
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header> 
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of tests?.content">
            <td>{{row.id || '&mdash;'}}</td>
            <td class="upper-case">{{row.deviceMac || '&mdash;'}}</td>
            <td>{{row.deviceModel || '&mdash;'}}</td>
            <td>{{row.fwVersion || '&mdash;'}}</td>
            <td>{{row.started ? (row.started | date:DATE_TIME) : '&mdash;'}}</td>
            <td>{{row.ended ? (row.ended | date:DATE_TIME) : '&mdash;'}}</td>
            <td class="by-content">
              <div class="no-status" *ngIf="!row.status">&mdash;</div>
              <div class="testing-status" [ngClass]="'status-' + row.status" *ngIf="row.status">{{row.status | capitalize}}</div>
            </td>
            <td class="button-column"><a class="button button-view" [routerLink]="['/testing', 'test', row.id]"></a>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!tests || !tests?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="tests && tests.totalPages > 1">
      <div class="text-info">Showing {{tests.startElement}} to {{tests.endElement}} of {{tests.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="tests.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
<div><b>Tests</b> – section for running firmware tests and viewing test results.</div>
<div class="top-space">In this section the user is able to:</div>
<ol class="top-space">
  <li>Start test</li>
  <li>View test result</li>
  <li>Find a specific test in the list by search box</li>
</ol>
<div class="top-space">For running a new test user should:</div>
<ol class="top-space">
  <li>Tap “<u>Start test</u>” button</li>
  <li>Select needed device for which he wants to run the test – select by MAC address</li>
  <li>Select needed device model</li>
  <li>Tap “<u>Run</u>” button</li>
</ol>
<div class="top-space">If test was successfully run - new test page would be displayed to the user with the process of testing.</div>
<div class="top-space">Use&nbsp;&nbsp;<button class="button-view"></button>&nbsp;&nbsp;button to view detailed information about the test.</div>
</ng-template>