<div class="templates-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Pages Templates</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section search-block align-container">
    <div class="filter-query">
      <app-search-bar (search)="search($event)" [searching]="loading" placeholder="Search template name"></app-search-bar>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.NAME" (sort)="setSorting($event)">
                Template Name
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.EDITED_AT" (sort)="setSorting($event)">
                Edited
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.EDITED_BY" (sort)="setSorting($event)">
                Editor
              </app-sortable-header>
            </th>
            <th class="actions">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of templates?.content">
            <td>{{row.name || '&mdash;'}}</td>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td>{{row.editedBy || '&mdash;'}}</td>
            <td class="button-column">
              <a class="button button-view" [routerLink]="['/mobile-backend', 'template', row.id]"></a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!templates || !templates?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="templates && templates.totalPages > 1">
      <div class="text-info">Showing {{templates.startElement}} to {{templates.endElement}} of {{templates.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="templates.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div>Here you can see all templates being used in EVO IoT app.</div>
  <div class="top-space">You can find specific template in the list and by search box.</div>
  <div class="top-space">
    You can edit data in a specific template  (texts, links, texts for alarms) but not delete or add new ones.
  </div>
</ng-template>