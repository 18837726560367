import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TestReportStatistics } from '@models/report';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { ReportService } from '@services/report.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-statistics-view',
  templateUrl: './statistics-view.component.html',
  styleUrls: ['./statistics-view.component.scss']
})
export class StatisticsViewComponent implements OnInit {

  public statistics: TestReportStatistics;
  public loading = false;

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private dialogService: DialogService, private notificationService: NotificationService,
    private reportService: ReportService) { }

  public ngOnInit(): void {
    this.loading = true;
    this.reportService.loadTestReportStatistics().pipe(finalize(() => this.loading = false))
        .subscribe((stats: TestReportStatistics) => {
      (stats?.deviceTypeStatisticList || []).sort((a, b) => a.name.localeCompare(b.name));
      this.statistics = stats;
    }, (err: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire actual statistics data');
    });
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Statistics',
      text: this.information
    }});
  }

}
