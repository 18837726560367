import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplatesService } from '@services/templates.service';
import { DialogService } from '@services/dialog.service';
import { CatalogueItem, CatalogueGroup } from '@models/templates';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { TreeNode, ViewTreeNode, TreeNodeType } from '@models/tree-node';
import { Subject, finalize } from 'rxjs';
import { Utils } from '@services/utils';

@Component({
  selector: 'app-image-catalogue-view',
  templateUrl: './image-catalogue-view.component.html',
  styleUrls: ['./image-catalogue-view.component.scss']
})
export class ImageCatalogueViewComponent implements OnInit, OnDestroy {

  public catalogueTree: ViewTreeNode<CatalogueGroup, CatalogueItem>;
  public catalogue: CatalogueGroup;
  public selectedNode: ViewTreeNode<CatalogueGroup, CatalogueItem>;
  public loading = false;
  public TreeNodeType = TreeNodeType;

  private activatedNode: TreeNode;
  private query: string = '';
  private destroyed = new Subject<void>();

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute,
      private templatesService: TemplatesService, private dialogService: DialogService) {
    this.activatedNode = (this.router.getCurrentNavigation().extras.state as any)?.active;
  }

  public ngOnInit(): void {
    this.loading = true;
    this.templatesService.getImagesCatalogue()
        .pipe(finalize(() => this.loading = false)).subscribe((catalogue: CatalogueGroup) => {
      this.catalogue = catalogue;
      this.catalogueTree = this.buildViewTree(catalogue);
      Utils.updateTreeCounters(this.catalogueTree);
      this.updateInformation(this.catalogueTree);
      this.selectedNode = this.activatedNode && Utils.findNode(this.catalogueTree, this.activatedNode);
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { width: '800px', data: {
      title: 'How to work with Catalogue',
      text: this.information
    }});
  }

  public filterItems(query: string): void {
    this.query = query;
    Utils.filterTree(this.catalogueTree, query);
    if (!this.selectedNode?.visible) {
      this.selectedNode = null;
    }
    this.catalogueTree.visible = true; // assert shown even if not match
    Utils.updateTreeCounters(this.catalogueTree);
    this.updateInformation(this.catalogueTree);
  }

  public cancelEvent(event: MouseEvent): void {
    event.stopPropagation();
  }

  public updateNodeText(source: CatalogueItem): void {
    if (this.selectedNode) {
      Utils.updateNode(this.selectedNode, source, this.query);
    }
  }

  private buildViewTree(source: CatalogueGroup | CatalogueItem): ViewTreeNode<CatalogueGroup, CatalogueItem> {
    const root = source as CatalogueGroup;
    const terminal = !root.nodes && !root.leafs;
    const children = terminal ? null : ([] as Array<CatalogueGroup | CatalogueItem>)
      .concat(root.nodes || []).concat(root.leafs || []).map(x => this.buildViewTree(x));
    return {
      id: source.id,
      name: source.name,
      type: source.type,
      description: source.description,
      terminal,
      children,
      original: source
    } as ViewTreeNode<CatalogueGroup, CatalogueItem>;
  }

  private updateInformation(node: ViewTreeNode<CatalogueGroup, CatalogueItem>): void {
    node.additional = node.terminal ? '' : '' + node.itemsCount;
    (node.children || []).forEach(x => this.updateInformation(x));
  }

}
