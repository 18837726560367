<div class="dialog-content device-model-edit">
  <div class="dialog-header">
    <span class="title">Assign Group to Model</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="information-block">
      Models groups are found. <br/>The models in it are grouped by the same epp (IC) as this model. <br/>
    </div>
    <div class="groups-list">
      <div class="dropdown-label">Select the group to which you want to add the model.</div>
      <app-dropdown [items]="modelGroups" placeholder="Select Model Group" [(ngModel)]="modelGroup">
      </app-dropdown>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="assignModelGroup()"
      [disabled]="!modelGroup">Add Model</button>
  </div>
</div>
