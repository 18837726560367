<div class="module-spaces-users-view sub-view">
  <div class="info" *ngIf="details">
    <perfect-scrollbar>
      <div class="scroll-content">
        <div class="panel-header spaces">Spaces</div>
        <table class="results-table stripes-table static-borders">
          <thead>
            <tr>
              <th class="wide-column">Id</th> 
              <th>Name</th>
              <th class="narrow-column">Type</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let space of details.spaces">
              <td>{{space.id}}</td>
              <td>{{space.name}}</td>
              <td class="capitalize">{{space.type}}</td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!details.spaces?.length" [compact]="true" [bordered]="true"></app-no-data>
        <div class="panel-header">Users</div>
        <table class="results-table stripes-table static-borders">
          <thead>
            <tr>
              <th>Id</th> 
              <th class="narrow-column">Owner</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of details.users">
              <td>{{user.id}}</td>
              <td class="table-check" [class.checked]="user.owner"></td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!details.users?.length" [compact]="true" [bordered]="true"></app-no-data>
      </div>
    </perfect-scrollbar>
  </div>
</div>