<div class="multiline-edit input-container" [class.clearable]="clearable">
  <perfect-scrollbar>
    <textarea #textarea class="text-input"
      [attr.maxlength]="maxlength" 
      [attr.placeholder]="placeholder" 
      [disabled]="disabled" 
      [readOnly]="readOnly"
      [class.invalid]="invalid" 
      [class.focused]="focused" 
      [class.narrow-input]="narrow"
      (blur)="setFocused(false)" 
      (focus)="setFocused(true)"
      [(ngModel)]="value"
      (ngModelChange)="commitValue()"
      autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
    </textarea>
  </perfect-scrollbar>
  <div class="controls">
    <div class="button button-clear" *ngIf="value" (click)="clear()"></div>
  </div>
</div>