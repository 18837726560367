import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DeviceTypesViewComponent } from './pages/device-types-view/device-types-view.component';
import { DeviceModelsViewComponent } from './pages/device-models-view/device-models-view.component';
import { DeviceModelViewComponent } from './pages/device-model-view/device-model-view.component';
import { JsonConfigViewComponent } from './pages/json-config-view/json-config-view.component';
import { ModelConfigViewComponent } from './pages/model-config-view/model-config-view.component';
import { ConfigModificationsViewComponent } from './pages/config-modifications-view/config-modifications-view.component';
import { InstructionsViewComponent } from './pages/instructions-view/instructions-view.component';
import { HttpParams } from '@constants/http-params';
import { UserRoles } from '@models/user-info';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'device-types'
  },
  {
    path: 'device-types',
    component: DeviceTypesViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  },
  {
    path: 'device-models',
    pathMatch: 'full',
    component: DeviceModelsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.TECHNICIAN]}
  },
  {
    path: 'device-models/:' + HttpParams.MODEL_ID,
    component: DeviceModelViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.TECHNICIAN]}
  },
  {
    path: 'device-configs',
    component: JsonConfigViewComponent,
  },
  {
    path: 'model-configs',
    component: ModelConfigViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  },
  {
    path: 'modifications',
    component: ConfigModificationsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  },
  {
    path: 'instructions',
    component: InstructionsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministrationRoutingModule {}
