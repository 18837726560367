import { Component, OnInit, Input } from '@angular/core';
import { WizardStep } from '@models/actions';

@Component({
  selector: 'app-wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.scss']
})
export class WizardStepsComponent implements OnInit {

  @Input()
  public steps: Array<WizardStep> = [];

  constructor() { }

  public ngOnInit(): void {
  }

}
