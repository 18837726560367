import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceModelGroupStatus, DeviceModelsGroupDetails } from '@models/device-models';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { Utils } from '@services/utils';
import { Breadcrumbs } from '@models/breadcrumbs';
import { HttpParams } from '@constants/http-params';
import { ConfirmDialogIcon } from '@models/dialogs';
import { DATE_TIME } from '@constants/dates';
import { Subject, takeUntil, finalize } from 'rxjs';

@Component({
  selector: 'app-models-group-view',
  templateUrl: './models-group-view.component.html',
  styleUrls: ['./models-group-view.component.scss']
})
export class ModelsGroupViewComponent implements OnInit, OnDestroy {

  public modelsGroupId: number;
  public modelsGroup: DeviceModelsGroupDetails;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Models Groups', link: ['/reports', 'models-groups']}];
  public DeviceModelGroupStatus = DeviceModelGroupStatus;

  @ViewChild('information') information: TemplateRef<any>;
  private destroyed = new Subject<void>();

  constructor(private deviceModelsService: DeviceModelsService, private dialogService: DialogService,
      private notificationService: NotificationService, private utils: Utils, private router: Router) {}

  public ngOnInit(): void {
    this.modelsGroupId = Number(this.utils.lookupParam(HttpParams.MODELS_GROUP_ID));
    if (this.modelsGroupId !== null) {
      this.loading = true;
      this.deviceModelsService.getDeviceModelsGroup(this.modelsGroupId).pipe(finalize(() => this.loading = false))
          .subscribe((modelsGroup: DeviceModelsGroupDetails) => {
        this.modelsGroup = modelsGroup;
        this.breadcrumbs.push({title: modelsGroup.name});
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Models Group Card',
      text: this.information
    }});
  }

  public editModelsGroup(): void {
    this.router.navigate(['/reports', 'models-group', this.modelsGroupId, 'edit'], {state: {path: this.router.url}});
  }

  public deleteModelsGroup(): void {
    if (this.modelsGroup) {
      if (this.modelsGroup.deletable) {
        this.dialogService.showModal(ConfirmationDialogComponent, {
          width: '500px', data: {
            title: 'Delete Models Group',
            text: `You are about to delete Models Group ${this.modelsGroup.name}.
                 <br/>Are you sure you want to delete this Models Group?`,
            icon: ConfirmDialogIcon.WARNING,
            agreeButtonText: 'Yes, delete',
            cancelButtonText: 'Close'
          }
        }).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
          if (result) {
            this.deviceModelsService.deleteDeviceModelsGroup(this.modelsGroup).subscribe(() => {
              this.notificationService.success(`Models Group ${this.modelsGroup.name} successfully deleted`);
              this.router.navigate(['/reports', 'models-groups']);
            }, (error: HttpErrorResponse) => {
              this.notificationService.error('Failed to Delete Models Group');
            });
          }
        });
      } else {
        this.dialogService.showModal(InformationDialogComponent, {width: '360px', data: {
          title: 'Can not delete group',
          text: 'It is not possible to delete this model group, because there are already linked system elements for it'
        }});
      }
    }
  }

}
