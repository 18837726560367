import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceModuleMoveToStandDialogData } from '../../models/dialog-data';
import { DeviceModulesService } from '@services/device-modules.service';
import { NotificationService } from '@services/notification.service';
import { WiFiModule } from '@models/device-modules';
import { DropdownItem } from '@models/dropdown';
import { Subject, finalize, takeUntil } from 'rxjs';

@Component({
  selector: 'app-device-module-change-stand-dialog',
  templateUrl: './device-module-change-stand-dialog.component.html',
  styleUrls: ['./device-module-change-stand-dialog.component.scss']
})
export class DeviceModuleChangeStandDialogComponent implements OnInit {

  public systemStands: Array<DropdownItem>;
  public newStand: DropdownItem;
  public currentStand: DropdownItem;

  public progressing = false;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceModuleMoveToStandDialogData,
    private dialogRef: MatDialogRef<DeviceModuleChangeStandDialogComponent>,
    private deviceModulesService: DeviceModulesService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.systemStands = (this.data.stands?.availableStand || []).map(x => ({value: x.id, title: x.name}));
    const currentStand = this.data.stands?.currentStand;
    this.currentStand = {value: currentStand?.id, title: currentStand?.name};
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public proceed(): void {
    const wifiModule = this.data.deviceModule;
    this.progressing = true;
    this.deviceModulesService.moveModuleToAnotherStand(wifiModule.macAddress, this.newStand.value)
        .pipe(takeUntil(this.destroyed), finalize(() => this.progressing = false))
        .subscribe((result: WiFiModule) => {
      this.notificationService.success(`MAC address is successfully moved to another stand`);
      result.online = false; // assert no longer current stand, i.e. non-online
      this.dialogRef.close(result);
    }, (error) => {
      this.notificationService.error(`Failed to move module ${wifiModule.macAddress} to another stand`);
      this.dialogRef.close(null);
    });
  }

}
