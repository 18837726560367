<div class="dialog-content switch-stand">
  <div class="dialog-header">
    <span class="title">Move to another stand</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <table class="module-status">
      <tr>
        <td class="field-label">MAC address</td>
        <td class="field-value">{{data.deviceModule.macAddress}}</td>
      </tr>
      <tr>
        <td class="field-label">Current status</td>
        <td class="field-value">{{data.deviceModule.online ? 'Online' : 'Offline'}}</td>
      </tr>
    </table>
    <div class="field-container">
      <div class="field-name">New stand</div>
      <div class="field">
         <app-dropdown [items]="systemStands" [(ngModel)]="newStand" placeholder="Select"></app-dropdown>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()" [disabled]="!newStand || newStand?.value === currentStand?.value || progressing">Move</button>
  </div>
</div> 