import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { MatSnackBar, MatSnackBarRef,
  MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition  } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Notification, NotificationType } from '@models/notification';
import { NOTIFICATION_DELAY, NOTIFICATION_PAUSE } from '@constants/config';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {

  private notificationSubscription: Subscription;
  private snackConfig = {
    horizontalPosition: 'end' as MatSnackBarHorizontalPosition,
    verticalPosition: 'top' as MatSnackBarVerticalPosition,
    duration: NOTIFICATION_DELAY
  };

  @ViewChild('template')
  private templateRef: TemplateRef<any>;
  private displaying = false;
  private queue: Array<Notification> = [];

  public type: NotificationType;
  public message: string;
  public NotificationType = NotificationType;
  public instance: MatSnackBarRef<any>;

  constructor(private notificationService: NotificationService,
    private snackBar: MatSnackBar, private changeDetector: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.notificationSubscription = this.notificationService.subscribe((notification: Notification) => {
      this.queue.push(notification);
      this.scheduleShowNext(false);
    });
  }

  public ngOnDestroy(): void {
    this.notificationSubscription.unsubscribe();
  }

  public close(): void {
    if (this.instance) {
      this.instance.dismiss();
      this.scheduleShowNext();
    }
  }

  private scheduleShowNext(delay = true): void {
    if (delay) {
      setTimeout(() => this.attemptShowNow(), NOTIFICATION_PAUSE);
    } else {
      this.attemptShowNow();
    }
  }

  private attemptShowNow(): void {
    if (this.displaying) {
      this.scheduleShowNext();
    } else if (this.queue.length) {
      this.show(this.queue.shift());
    }
  }

  private show(notification: Notification): void {
    this.displaying = true;
    this.message = notification.message;
    this.type = notification.type;
    this.changeDetector.detectChanges();
    this.instance = this.snackBar.openFromTemplate(this.templateRef, this.snackConfig);
    this.instance.afterDismissed().subscribe(() => {
      this.displaying = false;
      this.scheduleShowNext();
    });
  }

}
