import { Component, OnInit } from '@angular/core';
import { TvAppsService } from '@services/tv-apps.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { Utils } from '@services/utils';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { CoverageDialogComponent } from '@components/coverage-dialog/coverage-dialog.component';   
import { TvAppVersion, TvAppVersionView, TvAppVersionStatus } from '@models/tv-app';
import { Sorting, SortOrder } from '@models/sorting';
import { DropdownItem } from '@models/dropdown';
import { DATE } from '@constants/dates';
import { APP_VERSIONS_COVERAGES } from '@constants/coverage';
import { ConfirmDialogIcon } from '@models/dialogs';
import { Observable, Subject } from 'rxjs';
import { takeUntil, finalize, shareReplay } from 'rxjs/operators'; 

enum SortColumn {
  VERSION = 'versionName',
  APP_NAME = 'application.name',
  STATUS = 'status',
  ENABLED = 'enabled',
  COVERAGE = 'coverage',
  UPDATED = 'updatedAt'
} 

@Component({
  selector: 'app-tv-apps-versions-view',
  templateUrl: './tv-apps-versions-view.component.html',
  styleUrls: ['./tv-apps-versions-view.component.scss']
})
export class TvAppsVersionsViewComponent implements OnInit {

  public versions: Array<TvAppVersionView>;
  public versionsProcessed: Array<TvAppVersionView>;
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public progressing = false;
  public TvAppVersionStatus = TvAppVersionStatus;
  public DATE_FORMAT = DATE;
  public sorting: Sorting = {column: SortColumn.UPDATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  private ready: Observable<any>;
  private destroyed: Subject<void> = new Subject<void>(); 

  constructor(private tvAppsService: TvAppsService, private dialogService: DialogService,
      private notificationService: NotificationService) { }

  public ngOnInit(): void {
    this.loadData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }  

  public search(query: string): void {
    this.query = query;
    this.processData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.processData();
  }

  public changeCoverage(tvAppVersion: TvAppVersion): void {
    if (tvAppVersion.coverage < 100) {
      this.dialogService.showModal(CoverageDialogComponent, { data: {
        title: 'Change Coverage',
        text: 'Coverage<br/>',
        agreeButtonText: 'Confirm',
        coverages: APP_VERSIONS_COVERAGES
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((coverage: DropdownItem) => {
        if (coverage) {
          this.progressing = true;
          this.tvAppsService.publishTvAppVersion(tvAppVersion, coverage.value)
              .pipe(finalize(() => this.progressing = false)).subscribe(() => {
            this.notificationService.success('Coverage successfull updated');
            this.setActions(tvAppVersion);
          }, (error) => {
            this.notificationService.error('Failed to update App version coverage');
          });
        }
      });
    }
  }

  public publish(tvAppVersion: TvAppVersionView): void {
    const published = this.versions.filter(x => 
      x.application?.id === tvAppVersion?.application?.id && x.status === TvAppVersionStatus.PUBLISHED);
    const newerPublished = published.filter(x => x.versionCode > tvAppVersion.versionCode);
    if (newerPublished.length) {
      this.dialogService.showModal(ConfirmationDialogComponent, { data: {
        title: 'Imposible to Publish App',
        text: `Publishing of <b>${tvAppVersion.application?.name} V.${tvAppVersion.versionName}.</b> is not possible <br/>
               because more up to date version of this app (<b>V. ${newerPublished[0].versionName}</b>) is already<br/>published.`,
        icon: ConfirmDialogIcon.WARNING,
        cancelButton: false
      }});
    } else {
      this.dialogService.showModal(CoverageDialogComponent, { data: {
        title: 'Publish App',
        text: `You are about to publish <b>${tvAppVersion.application?.name} V.${tvAppVersion.versionName}.</b><br/>
               Please set the coverage and confirm you want to puplish new version.<br/><div class="coverage-title">Coverage</div>`,
        additionalText: published.length ? `<br/>
               Be aware that after publishing new version the coverage of published <br/>
               version V.<b>${published[0].versionName}</b> will be set to 100%<br/>` : '',
        agreeButtonText: 'Confirm',
        icon: ConfirmDialogIcon.WARNING,
        coverages: APP_VERSIONS_COVERAGES
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((coverage: DropdownItem) => {
        if (coverage) {
          this.progressing = true;
          this.tvAppsService.publishTvAppVersion(tvAppVersion, coverage.value)
              .pipe(finalize(() => this.progressing = false)).subscribe(() => {
            if (published.length) {
              published.forEach(x => x.coverage = 100);
            }
            this.notificationService.success('App Version successfully published');
            this.setActions(tvAppVersion);
            this.processData();
          }, (error) => {
            this.notificationService.error('Failed to publish App Version');
          });
        }
      });
    }
  }

  public downloadApk(tvAppVersion: TvAppVersion): void {
    this.tvAppsService.downloadTvAppVersionApk(tvAppVersion);
  }

  public unpublish(tvAppVersion: TvAppVersion): void {
    const anotherPublished = this.versions.find(x => x !== tvAppVersion && x?.application.id === tvAppVersion?.application?.id &&
      x.status === TvAppVersionStatus.PUBLISHED);
    let text = `You are about to unpublish <b>${tvAppVersion.application?.name} V.${tvAppVersion.versionName}.</b><br/>`;
    if (!anotherPublished) {
      text += `Please be aware that this is the only published verion of this application <br/>
               type and after unpublishing you will not have any published version.<br/><br/>`;
    } else {
      text += `<br/>`;
    }
    text += `Please confirm you want to unpuplish this version.`;
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Unpublish App',
      text,
      agreeButtonText: 'Confirm',
      icon: ConfirmDialogIcon.WARNING
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.progressing = true;
        this.tvAppsService.unpublishTvAppVersion(tvAppVersion)
            .pipe(finalize(() => this.progressing = false)).subscribe(() => {
          this.notificationService.success('App verison was successfully unpublished');
          this.setActions(tvAppVersion);
          this.processData();
        }, (error) => {
          this.notificationService.error('Failed to unpublish App Version');
        });
      }
    });
  }

  public setVersionEnabled(tvAppVersion: TvAppVersion, enabled: boolean): void {
    this.progressing = true;
    this.tvAppsService.setVersionEnabled(tvAppVersion, enabled)
        .pipe(finalize(() => this.progressing = false)).subscribe(() => {
      this.notificationService.success('App verison successfully ' + (enabled ? 'enabled' : 'disabled'));
      this.setActions(tvAppVersion);
      this.processData();
    }, (error) => {
      this.notificationService.error('Failed to ' + (enabled ? 'enable' : 'disable') + ' App Version');
    });
  }

  private loadData(): void {
    this.loading = true;
    this.ready = this.tvAppsService.getTvAppsVersions().pipe(shareReplay(1), finalize(() => this.loading = false));
    this.ready.subscribe((versions: Array<TvAppVersion>) => {
      this.versions = versions || [];
      this.versions.forEach(tvAppVersion => this.setActions(tvAppVersion));
      this.processData();
    });
  }

  private processData(): void {
    this.ready.subscribe(() => {
      this.versionsProcessed = this.versions.filter((x) => {
        return Utils.find(x, [SortColumn.VERSION, SortColumn.APP_NAME], this.query);
      });
      this.versionsProcessed.sort((a, b) => {
        return Utils.compare(a, b, this.sorting);
      });
    });
  }

  private setActions(tvAppVersion: TvAppVersionView): TvAppVersionView {
    if (tvAppVersion.status === TvAppVersionStatus.PUBLISHED) {
      tvAppVersion.actions = [{
        title: 'Download apk',
        action: () => this.downloadApk(tvAppVersion)
      }, {
        title: 'Unpublish',
        action: () => this.unpublish(tvAppVersion)
      }, {
        title: 'Change Coverage',
        action: () => this.changeCoverage(tvAppVersion),
        disabled: tvAppVersion.coverage === 100
      }];
    } else {
      tvAppVersion.actions = [{
        title: 'Publish',
        action: () => this.publish(tvAppVersion)
      }];
    }
    tvAppVersion.actions = tvAppVersion.actions.concat({
      title: tvAppVersion.enabled ? 'Disable' : 'Enable',
      action: () => this.setVersionEnabled(tvAppVersion, !tvAppVersion.enabled)
    });
    return tvAppVersion;
  }

} 