<div class="tree-area">
  <table class="node-container" *ngIf="json">
    <ng-container *ngIf="heading" [ngTemplateOutlet]="heading">
    </ng-container>
    <ng-container [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{node: json}">
    </ng-container>
  </table>
</div>
<ng-template #nodeTemplate let-node="node">
  <ng-container *ngFor="let row of node.children">
    <tr class="node-row">
      <th class="node-name" [style.padding-left]="node.level * 8 + 'px'">{{row.key}}</th>
      <td class="node-value">
        <ng-container *ngIf="row.type === JsonNodeType.OBJECT">
          &nbsp;
        </ng-container>
        <ng-container *ngIf="row.type === JsonNodeType.ARRAY">
          <span *ngIf="!row.value || !row.value.length">&mdash;</span>
          <span *ngIf="row.value?.length" class="comma-separated separate-lines" >
            <span class="item" *ngFor="let item of row.value">{{item}}</span>
          </span>
        </ng-container>
        <ng-container *ngIf="row.type === JsonNodeType.DATE">
          {{row.value | date:DATE_TIME}}
        </ng-container>
        <ng-container *ngIf="row.type === JsonNodeType.NUMERIC">
          {{row.value | number}}
        </ng-container>
        <ng-container *ngIf="row.type === JsonNodeType.PLAIN">
          {{row.value || '&mdash;'}}
        </ng-container>
      </td>
    </tr>
    <ng-container *ngIf="row.type === JsonNodeType.OBJECT">
      <ng-container [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{node: row.value}">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>