import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentGuard implements CanActivate, CanActivateChild {
  
  constructor(private router: Router) {
  }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const result = this.check(next);
    if (!result) {
      this.router.navigate(['/access-denied']);
    }
    return result;
  }

  public canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(next, state);
  }

  private check(next: ActivatedRouteSnapshot): boolean {
    const prodOnly = next.data['prodOnly'] as boolean;
    const devOnly = next.data['devOnly'] as boolean;
    return (prodOnly === undefined && devOnly === undefined)
      || prodOnly && environment.production || devOnly && !environment.production;
  }

}