<div class="device-models-view inner-view">
  <div class="view-header align-container space-between">
    <h1 class="align-container small-spacing">
      <span>Device models</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
    <div class="header-controls" *ngIf="!environment.production && (activeUser | async)?.isAdmin">
      <button class="primary button-add" (click)="createDeviceModel()">Add New Model</button>
    </div>
  </div>
  <div class="view-section search-block align-container">
    <div class="device-type">
      <app-dropdown [items]="deviceTypesItems" [(ngModel)]="deviceType" (ngModelChange)="updateData()"
          placeholder="Select device type"></app-dropdown>
    </div>
    <div class="filter-query">
      <app-search-bar (search)="search($event)" [searching]="loading"
          placeholder="Search model, board ID, or mat. number"></app-search-bar>
    </div>
    <div class="filter-tags">
      <app-dropdown [items]="tagsItems" [multi]="true" [(ngModel)]="tags" (ngModelChange)="updateData()" [clearable]="true"
          placeholder="Select tags"></app-dropdown>
    </div>
  </div>
  <div class="view-section results-block">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.MODEL" (sort)="setSorting($event)">
                Model
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_TYPE" (sort)="setSorting($event)">
                Device Type
              </app-sortable-header>
            </th>
            <th>
              Sap
            </th>
            <th class="centered">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                Updated
              </app-sortable-header>
            </th>
            <th class="narrow-column">Epp</th>
            <th class="narrow-column">Log</th>
            <th class="narrow-column">Instr</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of deviceModels?.content">
            <td>{{row.customerModel || '&mdash;'}}</td>
            <td>{{row.deviceTypeName || '&mdash;'}}</td>
            <td>{{row.materialNumber || '&mdash;'}}</td>
            <td class="centered">{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td class="centered">
              <div class="status-flag" [class.checked]="row.deviceConfig" [class.missing]="!row.deviceConfig"></div>
            </td>
            <td class="centered">
              <div class="status-flag" [class.checked]="row.modelConfig" [class.missing]="!row.modelConfig"></div>
            </td>
            <td class="centered">
              <div class="status-flag" [class.checked]="row.instruction" [class.missing]="!row.instruction"></div>
            </td>
            <td class="button-column">
              <a class="button button-view" [routerLink]="['/administration', 'device-models', row.id]"></a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!deviceModels || !deviceModels?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="deviceModels && deviceModels.totalPages > 1">
      <div class="text-info">Showing {{deviceModels.startElement}} to {{deviceModels.endElement}} of {{deviceModels.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="deviceModels.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Device models/list</b> – section for information management needed to prepare the device model launch. Here you can see if all required information is provided for the launch of the particular device model – it is indicated by the status of the model: completed - everything is ready, uncompleted - something is missing. When hovering over the status of the model, the system will display a message with brief information about what kind of information is missed. You can see the details in the model card. The list of models is formed by the IoT platform by loading information about the models and their involvement in a particular type from the external Haier data sources.</div>
  <div class="top-space"><span class="max-attention">ATTENTION: </span><b>the absence of any model in the list may be caused by the absence of the required device type according to this model - in this case, you need to check the list of device types registered in the IoT platform by going to the “Device types” section.</b></div>
  <div class="top-space"><span class="max-attention">ATTENTION: </span><b>the model status is updated every few minutes. If you have performed any action that affects the status, it is considered normal if you did not immediately see a change in status.</b></div>
  <div class="top-space">1.1 There is a possibility to filter and search by certain values <b>in the list of device models.</b></div>
  <ul class="info-list top-space">
    <li><b>MODEL</b> – model name. The list of models is loaded into the platform by itself by loading information about the models and their involvement in a particular type from the external Haier data sources.</li>
    <li><b>TAGS</b> – tags that can be assigned to each model to further identification of the model. You can add a tag by editing the model card.</li>
    <li><b>DEVICE TYPE</b> – the type of device this model is according to. The type is determined by the system when the models’ data is loaded into the platform.</li>
    <li><b>STATUS</b> – the status of the model, the indicator of readiness for launch.</li>
    <li><b>UPDATED</b> – the date of the last change of any information on this model, which is presented here.</li>
    <li><b>Button</b> - <button class="button button-view"></button> opening the model card to view detailed information about the model.</li>
  </ul>
  <div class="top-space">1.2 <b>Device models / model card</b> - all the information necessary to launch the model, the status of its presence, and completeness status:</div>
  <div class="top-space">1.2.1 MODEL - the name of the model. The list of available models is loaded automatically by the platform by loading information about the models and their involvement in a particular type from the external Haier data sources.</div>
  <div class="top-space">1.2.2 DEVICE TYPE - the type of device the model is according to. The type is determined by the platform internally on model data load.</div>
  <div class="top-space">1.2.3 BOARD ID - this is a unique identifier of the motherboard that is installed in the device of this model.<br/><span class="max-attention">ATTENTION:</span><b> this value is taken from the Model JSON Config.</b></div>
  <div class="top-space">1.2.4 MATERIAL NUMBER - the production code of the device type for this model.</div>
  <div class="top-space">1.2.5 TAGS - tags that can be set for each model for the further identification of the model or features it has. Here you can assign a tag (not more than 20) to a model, create a new tag, use an existing one, or copy tags from another model (when you select a model for copying, the system will show the presence of tags for the model in the form of their number). Tags are options - you don't have to add them to the model if it is not necessary.</div>
  <div class="top-space">1.2.6 Device JSON config - information about the presence of a JSON configuration for the devices that is suitable to this model, designed to be loaded into the device itself (to communicate with the device's motherboard). Only valid configurations (in the "active" status) are taken into account. Usually, one JSON configuration could be suitable for plenty of device models.<br/><span class="max-attention">ATTENTION:</span><b> the presence of a suitable Device JSON config is determined by the value of the BOARD ID. Since this value is taken from the Model JSON config, the platform is only able to determine if there is a Device JSON config if there is a Model JSON config for this model. So, if there is no Model JSON config, the platform will not be able to find a suitable Device JSON config.</b></div>
  <div class="top-space">1.2.7 Model JSON config - information about the presence of a JSON configuration for a device model, intended for the operation of a mobile application with a given device model and describing features available for this model. It could be suitable for only one specific model.</div>
  <div class="top-space">1.2.8 FIRMWARE - information about the availability of the firmware suitable for this model. There may be more than one eligible firmware, depending on the different kinds of firmware - only the most recent and published firmware is considered eligible. It is necessary to put a final mark confirming the actual presence of the firmware required for this model - the presence of a mark affects the readiness status of the model (STATUS in the list of models and on the model card).</div>
  <div class="top-space">1.2.9 Instruction - information about the availability of instructions provided for this model used while the enrollment process of the device to the IoT platform.</div>
  <div class="top-space">1.3 When considering the availability of any information on the model, you can go to the appropriate section related to this type of information, by using the link to go to the corresponding section. For example: under the information about the availability of Instruction, you can see the link to the “Device instructions” section - when you switch to this section, the system by default will apply a filter according to the model from the model card you switched from.</div>
  <div class="top-space"><b>Additional functionality</b></div>
  <ol class="info-list top-space">
    <li>You can add a new model by yourself (by hand), only into test stands - it is not available for the “prod-stand”:
      <ol type="a">
        <li>You can see the button “Add New model +”- to add a new model “by hand”</li>
        <li>You have to fill in the gaps:
          <ol type="i">
            <li>Model Name – you should know it (you have to add it)</li>
            <li>SAP-code – you should know itc.You have to choose Device Type and Product Line for this device
          </ol>
        </li>
      </ol>
    </li>
    <li>It is possible to add a new model without SAP-code</li>
  </ol>
</ng-template>