<div class="dates-range">
  <div class="range-type" *ngIf="typeSelector">
    <app-dropdown [items]="rangeItems" [(ngModel)]="rangeItem"
      (ngModelChange)="onRangeTypeSelected($event)" [disabled]="disabled">
    </app-dropdown>
  </div>
  <div class="range-selection" *ngIf="rangeType !== DatesRangeTypes.ALL_TIME">
    <div class="start-date">
      <app-datepicker *ngIf="rangeType !== DatesRangeTypes.YEAR && rangeType !== DatesRangeTypes.MONTH || !typeSelector"
        [(ngModel)]="range.from" (ngModelChange)="onStartDateSelected($event)" [disabled]="disabled"
        [minDate]="lowerBound?.from" [maxDate]="lowerBound?.to" [clearable]="clearable">
      </app-datepicker>
      <app-month-select *ngIf="rangeType === DatesRangeTypes.MONTH && typeSelector" [(ngModel)]="range.from" [minDate]="minDate" [maxDate]="maxDate"
         (ngModelChange)="onStartDateSelected($event)" [disabled]="disabled">
      </app-month-select>
      <app-year-select *ngIf="rangeType === DatesRangeTypes.YEAR && typeSelector" [(ngModel)]="range.from" [minDate]="minDate" [maxDate]="maxDate"
         (ngModelChange)="onStartDateSelected($event)" [disabled]="disabled">
      </app-year-select>
    </div>
    <div class="separator" *ngIf="rangeType === DatesRangeTypes.WEEK || rangeType === DatesRangeTypes.CUSTOM || !typeSelector">&mdash;</div>
    <div class="end-date" *ngIf="rangeType === DatesRangeTypes.WEEK || rangeType === DatesRangeTypes.CUSTOM || !typeSelector">
      <app-datepicker [(ngModel)]="range.to" (ngModelChange)="onEndDateSelected($event)"
        [disabled]="disabled || rangeType === DatesRangeTypes.WEEK"
        [minDate]="upperBound?.from" [maxDate]="upperBound?.to" [clearable]="clearable">
      </app-datepicker>
    </div>
  </div>
</div>
