import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TvAppsVersionsViewComponent } from './pages/tv-apps-versions-view/tv-apps-versions-view.component';
import { UserRoles } from '@models/user-info';

const routes: Routes = [
  {
    path: 'apps-versions',
    component: TvAppsVersionsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TvAppsRoutingModule {}
