<div class="dialog-content device-module-edit">
  <div class="dialog-header">
    <span class="title">Change serial number or model</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="mac-address">
      MAC address <b>{{data.deviceModule.macAddress}}</b>
    </div>
    <div class="field-container">
      <div class="field-name">Current serial number</div>
      <div class="field">
        <input type="text" class="input upper-case" disabled="true" [(ngModel)]="data.deviceModule.serialNumber"/>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">New serial number</div>
      <div class="field">
        <div class="field-layout align-container mid-spacing">
          <input type="text" class="input upper-case" maxlength="20" [(ngModel)]="newSerialNumber" (ngModelChange)="validate()"/>
          <div class="align-container controls-container">
            <button class="button button-arrow-fill" #tip="ngbPopover" cdkOverlayOrigin #trigger="cdkOverlayOrigin"
              [disabled]="!newSerialNumber || invalidSerial" [disablePopover]="!newSerialNumber || invalidSerial || badgeShown"
              ngbPopover="Fill model according to s/n" triggers="mouseenter:mouseleave"
              placement="top" openDelay="500" container="body" (click)="lookupModelsBySn()">
                <div class="dummy" ngbPopover="No models found, please select model manually"
                  triggers="manual" #badge="ngbPopover" placement="top" container="body" popoverClass="warning-tip"></div>
            </button>
          </div>
        </div>
        <div class="validation">
          <span *ngIf="invalidSerial">Field must contain 11-20 latin symbols and/or numbers</span>
        </div>
      </div>
    </div>
    <div class="field-container smaller-padding">
      <div class="field-name">Current model</div>
      <div class="field">
        <input type="text" class="input" disabled="true" [(ngModel)]="data.deviceModule.deviceModel"/>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">New model</div>
      <div class="field">
        <app-lookup placeholder="" [provider]="modelsProvider" [clearable]="true" [(ngModel)]="newModel" (ngModelChange)="validate()"></app-lookup>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()" [disabled]="!allowedToProceed">Upload</button>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="!!lookupModels"
    [cdkConnectedOverlayPositions]="positions" (overlayOutsideClick)="closeLookup()">
  <div class="dropdown-panel actions-dropdown">
    <div class="dropdown-item" *ngFor="let model of lookupModels" (click)="selectLookupModel(model)">
      {{model.name}}
    </div>
  </div>
</ng-template>
