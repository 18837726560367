import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FirmwareService } from '@services/firmware.service';
import { UtilitiesService } from '@services/utilities.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { Utils } from '@services/utils';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { CoverageDialogComponent } from '@components/coverage-dialog/coverage-dialog.component';
import {Firmware, FirmwareState, FirmwareTestReportAction} from '@models/firmware';
import { Breadcrumbs } from '@models/breadcrumbs';
import { DropdownItem } from '@models/dropdown';
import { HttpParams } from '@constants/http-params';
import { DATE } from '@constants/dates';
import { FIRMWARE_COVERAGES } from '@constants/coverage';
import { Subject } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import {ButtonsDialogComponent} from "@components/buttons-dialog/buttons-dialog.component";

@Component({
  selector: 'app-firmware-view',
  templateUrl: './firmware-view.component.html',
  styleUrls: ['./firmware-view.component.scss']
})
export class FirmwareViewComponent implements OnInit {

  public firmwareId: number = null;
  public firmware: Firmware = null;
  public loading = false;
  public downloading = false;
  public DATE = DATE;
  public coverages = FIRMWARE_COVERAGES;
  public coverage: DropdownItem;
  public backupCoverage: DropdownItem;
  public FirmwareState = FirmwareState;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Firmware Management', link: ['/provisioning', 'firmware']}];
  private destroyed: Subject<void> = new Subject<void>();

  constructor(private firmwareService: FirmwareService, private utilityService: UtilitiesService,
    private dialogService: DialogService, private notificationService: NotificationService, private utils: Utils, private cd: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.firmwareId = Number(this.utils.lookupParam(HttpParams.FIRMWARE_ID));
    if (this.firmwareId !== null) {
      this.loading = true;
      this.firmwareService.getFirmwareDetails(this.firmwareId)
          .pipe(finalize(() => this.loading = false)).subscribe((firmware: Firmware) => {
        this.firmware = firmware;
        this.breadcrumbs.push({title: 'Version: ' + firmware.version});
        this.coverage = this.coverages.find(x => x.value === firmware.coverage);
        this.backupCoverage = this.coverage;
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setCoverage(newCoverage: DropdownItem): void {
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Confirm Changes',
      text: 'Please confirm that you want to change coverage?'
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: DropdownItem) => {
      if (result) {
        if (newCoverage.value === 100) {
          this.firmwareService.checkAssociatedTestReports(this.firmware.id).pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
            if (result) {
              this.warnConnectedTestReportExistAndProceed(true, newCoverage);
            } else {
              this.proceedPublish(true, newCoverage);
            }
          });
        } else {
          this.proceedPublish(true, newCoverage);
        }
      } else {
        this.coverage = this.backupCoverage;
      }
    });
  }

  public publish(): void {
    this.dialogService.showModal(CoverageDialogComponent, { data: {
      title: 'Confirm Publishing',
      text: 'To confirm that you want to publish this version please select coverage',
      coverages: FIRMWARE_COVERAGES
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((coverage: DropdownItem) => {
      if (coverage) {
        if (coverage.value === 100) {
          this.firmwareService.checkAssociatedTestReports(this.firmware.id).pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
            if (result) {
              this.warnConnectedTestReportExistAndProceed(false, coverage)
            } else {
              this.proceedPublish(false, coverage);
            }
          });
        } else {
          this.proceedPublish(false, coverage);
        }
      }
    });
  }

  public warnConnectedTestReportExistAndProceed(setCoverage: boolean, coverage: DropdownItem): void {
    this.dialogService.showModal(ButtonsDialogComponent, { width: '500px', data: {
        title: 'Connected test report exists',
        text: `Publishing new firmware may affect existing test reports: test reports for the previous firmware version will be deactivated
               (switch to Inactive status), which will reduce the percentage of test coverage of the system. To avoid this you can change
               the firmware version in existing test reports and they will remain in Active status.`,
        buttons: [{text: 'Deactivate test reports', code: FirmwareTestReportAction.DEACTIVATE_TEST_REPORTS},
          {text: 'Change firmware version', code: FirmwareTestReportAction.CHANGE_TEST_REPORTS_FIRMWARE_VERSION}]
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: FirmwareTestReportAction) => {
      if (result) {
        this.proceedPublish(setCoverage, coverage, result);
      } else {
        this.coverage = this.backupCoverage;
      }
    });
  }

  public proceedPublish(setCoverage: boolean, coverage: DropdownItem, action?: FirmwareTestReportAction): void {
    this.firmwareService.publish(this.firmware.id, coverage.value, action).subscribe(() => {
      this.notificationService.success(setCoverage ? 'Coverage successfully updated' : 'Firmware successfully published');
      this.firmware.state = FirmwareState.PUBLISHED;
      this.firmware.coverage = coverage.value;
      this.coverage = coverage;
      this.backupCoverage = this.coverage;
    }, (error) => {
      this.coverage = this.backupCoverage;
      this.notificationService.error(setCoverage ? 'Failed to update firmware coverage' : 'Firmware publishing failed');
    });
  }

  public downloadFirmware(): void {
    this.downloading = true;
    this.utilityService.downloadFirmwareByUrl(this.firmware.firmwareURL).pipe(finalize(() => this.downloading = false)).subscribe(
      () => {},
      (error: HttpErrorResponse) => {
        this.notificationService.error('Firmware Download Failed');
    });
  }

  public unpublish(): void {
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Confirm Unpublishing',
      text: 'Confirm you want unpublish this version'
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.firmwareService.checkTestReportExists(this.firmware.id).pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
          if (result) {
            this.dialogService.showModal(ConfirmationDialogComponent, {
              width: '500px', data: {
                title: 'Connected test report exists',
                text: `If the firmware is unpublished, the active reports related to this firmware will change to Inactive status. Unpublish the firmware anyway?`,
                agreeButtonText: 'Unpublish',
              }
            }).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
              if (result) {
                this.proceedUnpublish();
              }
            });
          } else {
            this.proceedUnpublish();
          }
        });
      }
    });
  }

  public proceedUnpublish(): void {
    this.firmwareService.unpublish(this.firmware.id).subscribe(() => {
      this.notificationService.success('Firmware successfully unpublished');
      this.firmware.state = FirmwareState.UNPUBLISHED;
      this.firmware.coverage = null;
      this.coverage = null;
    }, (error) => {
      this.notificationService.error('Firmware unpublishing failed');
    });
  }

}
