import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceModulesService } from '@services/device-modules.service';
import { NotificationService } from '@services/notification.service';
import { WiFiModule, WiFiModuleDetailed, WiFiModuleCommand, WiFiModuleCommandType } from '@models/device-modules';
import { DeviceModuleDialogData, WiFiModuleCommandDetails } from '../../models/dialog-data';
import { DATE, DATE_TIME } from '@constants/dates';

@Component({
  selector: 'app-device-module-dialog',
  templateUrl: './device-module-dialog.component.html',
  styleUrls: ['./device-module-dialog.component.scss']
})
export class DeviceModuleDialogComponent implements OnInit {

  public deviceModule: WiFiModule;
  public details: WiFiModuleDetailed;
  public loading = false;
  public DATE = DATE;
  public DATE_TIME = DATE_TIME;
  public commandDetailsMode = false;
  public command: WiFiModuleCommand;
  public commandDetails: string;
  public CommandType = WiFiModuleCommandType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceModuleDialogData,
    private dialogRef: MatDialogRef<DeviceModuleDialogComponent>,
    private deviceModulesService: DeviceModulesService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.deviceModule = this.data.deviceModule;
    this.loading = true;
    this.deviceModulesService.getDeviceWiFiModuleDetailed(this.deviceModule.id).subscribe((details: WiFiModuleDetailed) => {
      this.loading = false;
      this.details = details;
    }, (error: HttpErrorResponse) => {
      this.loading = false;
      this.notificationService.error('Failed to acquire WiFi Module details');
      this.dialogRef.close();
    });
  }

  public viewCommandDetails(event: WiFiModuleCommandDetails): void {
    this.command = event.command;
    this.commandDetails = event.details;
    this.commandDetailsMode = true;
  }

  public returnBack(): void {
    this.commandDetailsMode = false;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public checkDialogSize(tabIndex: number): void {
    this.dialogRef.updateSize('800px', tabIndex > 0 ? '80vh' : null);
  }

}
