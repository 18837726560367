<div class="tree-view" (click)="selectNode(null)">
  <div class="header-panel align-container space-between">
    <button class="button link-button bold-link collapse-all" *ngIf="showCollapseAll" (click)="collapseAll()">Collapse All</button>
    <ng-content></ng-content>
  </div>
  <div class="tree-container" #treeContainer>
    <div class="root-container" *ngFor="let root of roots">
      <ng-container  [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{node: root}">
      </ng-container>
    </div>
  </div>
</div>
<ng-template #nodeTemplate let-node="node">
  <div class="node" [class.terminal]="node.terminal" *ngIf="node.visible !== false">
    <div class="node-row align-container space-between" [class.selected]="node === selectedNode" (click)="selectNode(node)" appStopPropagation>
      <div class="left-side align-container small-spacing">
        <div class="node-icon expand-collapse" *ngIf="!node.terminal" (click)="expandCollapse(node)"
          [class.expanded]="!node.collapsed" [class.disabled]="!node.childrenCount"></div>
        <div class="node-icon node-type-icon" *ngIf="node.type" [ngClass]="'node-type-' + node.type"></div>
        <div class="node-name label" *ngIf="!node.nameHighlighted">{{node.name}}</div>
        <div class="node-name label name-highlighted" [innerHTML]="node.nameHighlighted" *ngIf="node.nameHighlighted"></div>
      </div>
      <div class="right-side align-container small-spacing">
        <div class="node-additional" *ngIf="node.additional">{{node.additional}}</div>
        <div class="node-icon node-warning" *ngIf="node.warning"></div>
      </div>
    </div>
    <div class="children-container" [class.collapsed]="node.collapsed"
      [ngStyle]="{'max-height.px': (node.rowsCount - 1) * 50}" *ngIf="!node.terminal && node.childrenCount">
      <ng-container *ngFor="let child of node.children">
        <div class="child-container" *ngIf="child.visible !== false">
          <ng-container [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{node: child}">
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>