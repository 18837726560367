import { Component, OnInit, Input, forwardRef, ViewChild, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { CONFIG_PATCH_SCHEMA, ConfigPatchOperations, ConfigPatchOperationType } from '@constants/patches';
import { PatchOperation } from '@models/config-modifications';
import { validate } from 'jsonschema';
import { DropdownTreeComponent } from '@components/dropdown-tree/dropdown-tree.component';
import { MultilineEditComponent } from '@components/multiline-edit/multiline-edit.component';

@Component({
  selector: 'app-config-patch-constructor',
  templateUrl: './config-patch-constructor.component.html',
  styleUrls: ['./config-patch-constructor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ConfigPatchConstructorComponent),
    multi: true
  }]
})
export class ConfigPatchConstructorComponent implements OnInit, ControlValueAccessor {

  @Input()
  public configTree: Object;

  @Input()
  public disabled = false;

  @Input()
  public invalid = true;

  @ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;
  @ViewChildren('path') private pathElements: Array<DropdownTreeComponent>;
  @ViewChildren('from') private fromElements: Array<DropdownTreeComponent>;
  @ViewChildren('value') private valueElements: Array<MultilineEditComponent>;

  public allOperations = ConfigPatchOperations;
  public operations: Array<PatchOperation> = [];
  public activeRow: PatchOperation;
  public upAllowed = true;
  public downAllowed = true;
  public OpType = ConfigPatchOperationType;

  private onTouchedCallback: () => void;
  protected commit(value: string) {}

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnInit(): void {
  }

  public writeValue(json: string): void {
    this.invalid = false;
    this.operations = [];
    if (json) {
      try {
        const value = JSON.parse(json);
        const validationResults = validate(value, CONFIG_PATCH_SCHEMA);
        if (validationResults.errors?.length) {
          this.invalid = true;
        } else {
          for (let operation of value) {
            this.operations.push(Object.assign({}, operation));
          }
        }
      } catch(e) {
        this.invalid = true;
      }
    }
    if (!this.invalid && !this.operations.length) {
      this.clear();
    }
    this.updateView();
  }

  public update(): void {
    const value = this.operations.filter(x => !!x.op).map(x => {
      switch(x.op) {
        case ConfigPatchOperationType.REMOVE:
        case ConfigPatchOperationType.REPLACE_STEP: {
          return {op: x.op, path: x.path};
        }
        case ConfigPatchOperationType.MOVE:
        case ConfigPatchOperationType.COPY: {
          return {op: x.op, path: x.path, from: x.from};
        }
        default: {
          return {op: x.op, path: x.path, value: x.value};
        }
      }
    });
    this.commit(JSON.stringify(value));
  }

  public updateView(): void {
    setTimeout(() => {
      (Array.from(this.pathElements) as Array<DropdownTreeComponent | MultilineEditComponent>)
          .concat(Array.from(this.fromElements)).concat(Array.from(this.valueElements)).forEach((el) => {
        el.updateHeight();
      });
    });
  }

  public addRow(): void {
    const newOperation: PatchOperation = {op: null};
    this.operations.push(newOperation);
    this.activeRow = newOperation;
    this.scrollbar.directiveRef.scrollToBottom();
    this.updateUpDown();
    this.update();
  }

  public setActiveRow(row: PatchOperation): void {
    this.activeRow = row;
    this.updateUpDown();
    this.changeDetectorRef.detectChanges();
  }

  public updateUpDown(): void {
    this.upAllowed = this.operations[0] !== this.activeRow;
    this.downAllowed = this.operations[this.operations.length - 1] !== this.activeRow;
  }

  public moveRow(shift: number): void {
    const index = this.operations.findIndex(x => x === this.activeRow);
    const tmp = this.operations[index];
    this.operations[index] = this.operations[index + shift];
    this.operations[index + shift] = tmp;
    this.updateUpDown();
    this.update();
  }

  public removeRow(): void {
    this.operations = this.operations.filter(x => x !== this.activeRow);
    this.activeRow = null;
    this.updateUpDown();
    this.update();
  }

  public removeEmpty(): void {
    this.operations = this.operations.filter(x => !!x.op);
    this.update();
  }

  public clear(): void {
    this.operations = [{op: null}];
  }

  public clearError(): void {
    this.invalid = false;
    this.clear();
    this.update();
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

}
