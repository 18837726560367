import {
  Directive, 
  ElementRef, 
  Output, 
  EventEmitter, 
  HostListener
} from '@angular/core';

@Directive({
    selector: '[appClickOutside]'
})
export class ClickOutsideDirective {
  @Output() clickOutside = new EventEmitter<Event>();
    
  private elementRef: ElementRef;
  
  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
  }

  @HostListener('document:click', ['$event'])
  onClick(e: Event): void {
    const clickedInside = this.elementRef.nativeElement.contains(e.target);
    if (!clickedInside) {
      this.clickOutside.emit(e);
    }
  }
}
