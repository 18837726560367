import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Action } from '@models/actions';
import { POSITION_BELOW } from '@constants/overlay-position';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

  @Input()
  public actions: Array<Action>;

  @Input()
  public disabled = false;

  @Output()
  public expanded = new EventEmitter<boolean>;

  @Output()
  public collapsed = new EventEmitter<boolean>;

  public expandedState = false;
  public positions = POSITION_BELOW;

  constructor() { }

  public ngOnInit(): void {
  }

  public expandCollapse(event: MouseEvent): void {
    this.expandedState = !this.expandedState;
    event.stopPropagation();
    (this.expandedState ? this.expanded : this.collapsed).emit(true);
  }

  public run(action: Action): void {
    if (!this.disabled && !action.disabled) {
      this.expandedState = false;
      action.action();
      this.collapsed.emit(true);
    }
  }

}
