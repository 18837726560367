import { Component, OnDestroy, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Utils } from '@services/utils';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-calendar-panel',
  templateUrl: './calendar-panel.component.html',
  styleUrls: ['./calendar-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarPanelComponent<D> implements OnDestroy {

  private destroyed = new Subject<void>();

  constructor(private calendar: MatCalendar<D>, private dateAdapter: DateAdapter<D>,
      @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats, cdr: ChangeDetectorRef) {
    calendar.stateChanges.pipe(takeUntil(this.destroyed)).subscribe(() => cdr.markForCheck());
  }

  public ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public get monthLabel() {
    return Utils.capitalize(this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.monthLabel));
  }

  public get yearLabel() {
    return this.dateAdapter
      .format(this.calendar.activeDate, this.dateFormats.display.dateA11yLabel);
  }

  public previousClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  public nextClicked(mode: 'month' | 'year') {
    this.calendar.activeDate =
      mode === 'month'
        ? this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1)
        : this.dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }

}
