import { Action } from '@models/actions'; 

export enum TvAppVersionStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export interface TvAppVersion {
  id: number;
  versionName: string;
  versionCode: number;
  fileName: string;
  fullPath: string;
  presignedUrl: string;
  enabled: boolean;
  coverage: number;
  status: TvAppVersionStatus;
  urlGenerationDate: Date;
  updatedAt: Date;
  applicationName: string;
  application: {
    id: number;
    name: string;
  }
}

export interface TvAppVersionView extends TvAppVersion {
  actions?: Array<Action>; 
}