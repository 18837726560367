<div class="module-info-view">
  <div class="info" *ngIf="details">
    <div class="field-name label">Wi-Fi Module</div>
    <div class="field-value">{{details.macAddress || '&mdash;'}}</div>
    <div class="spacer"></div>
    <div class="field-name label">Firmware Version</div>
    <div class="field-value">{{details.firmwareId || '&mdash;'}}</div>

    <div class="field-name label">Serial Number</div>
    <div class="field-value">{{details.serialNumber || '&mdash;'}}</div>
    <div class="spacer"></div>
    <div class="field-name label">Firmware Kind</div>
    <div class="field-value capitalize">{{details.firmwareType || '&mdash;'}}</div>

    <div class="field-name label">Device Type</div>
    <div class="field-value capitalize">{{details.deviceType || '&mdash;'}}</div>
    <div class="spacer"></div>
    <div class="field-name label">Binary Firmware</div>
    <div class="field-value capitalize">{{details.binaryFirmware ? 'yes' : 'no'}}</div>

    <div class="field-name label">Device Model</div>
    <div class="field-value">
      <a class="button link-button" (click)="navigateModelDetails()"
        *ngIf="details?.deviceModel && details?.deviceModelId">{{details.deviceModel}}</a>
      <span *ngIf="!details?.deviceModel || !details?.deviceModelId">{{details?.deviceModel || '&mdash;'}}</span>
    </div>
    <div class="spacer"></div>
    <div class="field-name label">Activation Date</div>
    <div class="field-value">{{details.activatedAt ? (details.activatedAt | date:DATE_FORMAT) : '&mdash;'}}</div>

    <div class="field-name label">Device Name</div>
    <div class="field-value">{{details.deviceName || '&mdash;'}}</div>
    <div class="spacer"></div>
    <div class="field-name label">Last Data Date</div>
    <div class="field-value">{{details.lastSeenAt ? (details.lastSeenAt | date:DATE_FORMAT) : '&mdash;'}}</div>

    <div class="field-name label">Current Status</div>
    <div class="field-value capitalize">{{details.networkStatus || '&mdash;'}}</div>
    <div class="spacer"></div>
    <div class="field-name label">JSON-config version</div>
    <div class="field-value">{{details.configId || '&mdash;'}}</div>

    <div class="field-name label"></div>
    <div class="field-value"></div>
    <div class="spacer"></div>
    <div class="field-name label">Motherboard Version</div>
    <div class="field-value">{{details.mbVersion || '&mdash;'}}</div>
  </div>
</div>