import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceModuleDialogData } from '../../models/dialog-data';
import { DeviceModulesService } from '@services/device-modules.service';
import { NotificationService } from '@services/notification.service';
import { WiFiModule } from '@models/device-modules';
import { Subject, of, concatMap, finalize, takeUntil } from 'rxjs';

const ACCESS_TOKEN_REGEXP = /^[a-zA-Z0-9\!#\$%&\(\)\*\+\-\.\/\:<=>\?@\[\\\]\^_`\{\|\}~]{1,20}$/;

@Component({
  selector: 'app-device-module-access-token-dialog',
  templateUrl: './device-module-access-token-dialog.component.html',
  styleUrls: ['./device-module-access-token-dialog.component.scss']
})
export class DeviceModuleAccessTokenDialogComponent implements OnInit, OnDestroy {

  public newAccessToken: string = '';
  public progressing = false;
  public changed = false;
  public errors: any = {empty: true};

  private currentAccessToken: string;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceModuleDialogData,
    private dialogRef: MatDialogRef<DeviceModuleAccessTokenDialogComponent>,
    private deviceModulesService: DeviceModulesService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public updateChanges(): void {
    this.changed = true;
    const sameToken = this.newAccessToken === this.currentAccessToken;
    const invalidToken = !ACCESS_TOKEN_REGEXP.test(this.newAccessToken);
    const errors = {...(sameToken ? {sameToken} : null), ...(invalidToken ? {invalidToken} : null)};
    this.errors = Object.keys(errors).length === 0 ? null : errors;
  }

  public resetErrors(): void {
    this.errors = null;
  }

  public proceed(): void {
    const wifiModule = this.data.deviceModule;
    this.progressing = true;
    this.deviceModulesService.checkModuleAccessToken(wifiModule.macAddress, this.newAccessToken).pipe(concatMap((result: boolean) => {
      return result ? this.deviceModulesService.updateModuleAccessToken(wifiModule.macAddress, this.newAccessToken) : of(null);
    }), takeUntil(this.destroyed), finalize(() => this.progressing = false)).subscribe((result: WiFiModule) => {
      if (result) {
        this.notificationService.success(`Access token is successfully changed`);
        this.dialogRef.close(result);
      } else {
        this.currentAccessToken = this.newAccessToken;
        this.updateChanges();
      }
    }, (err: HttpErrorResponse) => {
      this.notificationService.error(`Failed to update access token for module ${wifiModule.macAddress}`);
    });
  }
}
