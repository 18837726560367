<div class="status-history-view sub-view">
  <div class="controls-panel view-header align-container" [class.space-between]="justify">
    <div class="label-base">Period</div>
    <div class="range-select">
      <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData(true)" [typeSelector]="false">
      </app-dates-range>
    </div>
    <div class="query-selector">
      <app-search-bar (search)="search($event)" [searching]="loading" placeholder="Search name">
      </app-search-bar>
    </div>
  </div>
  <div class="view-section">
    <div class="contents scroll-container">
      <perfect-scrollbar>
        <table class="results-table stripes-table">
          <thead>
            <tr>
              <th>
                Ts
              </th>
              <th>
                Name
              </th>
              <th>
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of statusHistory?.content">
              <td>{{row.ts ? (row.ts | date:DATE_TIME) : '&mdash;'}}</td>
              <td>{{row.name || '&mdash;'}}</td>
              <td>{{row.value || '&mdash;'}}</td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!statusHistory || !statusHistory?.content.length" [bordered]="true"></app-no-data>
      </perfect-scrollbar>
    </div>
  </div>
  <div class="pagination-block attached" *ngIf="statusHistory && statusHistory.totalPages > 1">
    <div class="text-info">Showing {{statusHistory.startElement}} to {{statusHistory.endElement}} of {{statusHistory.totalElements}} items</div>
    <app-pagination [activePage]="activePage" [totalPages]="statusHistory.totalPages" (pageChanged)="setActivePage($event)">
    </app-pagination>
  </div>
</div>