<div class="dialog-content">
  <div class="dialog-header">
    <div class="title-container">
      <div class="icon" *ngIf="data.icon" [ngClass]="data.icon"></div>
      <div class="title">{{data.title}}</div>
    </div>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <perfect-scrollbar #scrollbar *ngIf="!$any(data).disableScroll && !$any(data).json">
      <div class="scroll-area">
        <ng-container [ngTemplateOutlet]="content">
        </ng-container>
      </div>
    </perfect-scrollbar>
    <ng-container [ngTemplateOutlet]="content" *ngIf="$any(data).disableScroll || $any(data).json">
    </ng-container>
  </div>
  <div class="dialog-footer">
    <button class="primary" (click)="proceed()" *ngIf="data.agreeButton !== false">{{data.agreeButtonText || 'Close'}}</button>
  </div>
</div>
<ng-template #content>
  <ng-container *ngIf="data.text">
    <div class="info" [innerHTML]="data.text" *ngIf="isPlainHtml"></div>
    <div class="info" *ngIf="!isPlainHtml">
      <ng-container [ngTemplateOutlet]="data.text"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="$any(data).json">
    <div class="json-container">
      <app-json-edit [ngModel]="data.json" [disabled]="true"></app-json-edit>
    </div>
  </ng-container>
</ng-template>
