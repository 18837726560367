<div class="dialog-content device-config">
  <div class="dialog-header">
    <span class="title">Upload JSON-config</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="field-container file-container">
      <ng-container *ngIf="!fileItem && !loading">
        <a class="button link-button" (click)="selectFile()">
          <div class="link-icon add-item"></div><span>Select JSON or text file</span>
        </a>
        <input type="file" #file [hidden]="true" class="hidden" (change)="fileSelected($event)" accept=".json, .txt"/>
      </ng-container>
      <div class="loader centered" *ngIf="loading"></div>
      <div class="selected-file-container align-container" *ngIf="fileItem && !loading">
        <div class="selected-file">
          <div class="file-icon"></div>
          <div class="file-name">{{fileItem.name}}</div>
        </div>
        <div class="button remove-button" (click)="removeFile()"></div>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">Device type</div>
      <div class="field">
        <app-dropdown [items]="deviceTypesItems" [(ngModel)]="deviceType" placeholder="Select Device Type"></app-dropdown>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">Epp Config Version</div>
      <div class="field">
        <app-dropdown [items]="eppVersions" [(ngModel)]="eppVersion" placeholder="Select Epp Version"></app-dropdown>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">Board ID</div>
      <div class="field">
        <input type="text" [(ngModel)]="boardId" placeholder="Enter board ID" maxlength="64"/>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()"
      [disabled]="!fileItem || !boardId || !eppVersion || !deviceType?.value || loading">Upload</button>
  </div>
</div> 