import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ModificationEditDialogComponent } from '../../components/modification-edit-dialog/modification-edit-dialog.component';
import { ModificationViewDialogComponent } from '../../components/modification-view-dialog/modification-view-dialog.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { DeviceModelsService } from '@services/device-models.service';
import { ConfigModificationsService } from '@services/config-modifications.service';
import { DeviceTypesAndModels, DeviceTypeSimple } from '@models/device-models';
import { ModificationPatch, ModificationPatchView, ModificationPatchDetails,
  ModificationStatus } from '@models/config-modifications';
import { DropdownItem } from '@models/dropdown';
import { Paged } from '@models/pageable';
import { SortOrder, Sorting } from '@models/sorting';
import { ConfirmDialogIcon } from '@models/dialogs';
import { DATE_TIME } from '@constants/dates';
import { Subject, Observable, takeUntil, finalize, shareReplay } from 'rxjs';

enum SortColumn {
  NAME = 'name',
  STATUS = 'status',
  UPDATED = 'updatedAt',
  AUTHOR = 'author'
}

@Component({
  selector: 'app-config-modifications-view',
  templateUrl: './config-modifications-view.component.html',
  styleUrls: ['./config-modifications-view.component.scss']
})
export class ConfigModificationsViewComponent implements OnInit, OnDestroy {

  public modifications: Paged<ModificationPatchView>;
  public query: string = '';
  public statusItems: Array<DropdownItem> = [];
  public status: DropdownItem = null;
  public activePage = 1;
  public loading = false;
  public progressing = false;
  public sorting: Sorting = {column: SortColumn.UPDATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public DATE_FORMAT = DATE_TIME;
  public ModificationStatus = ModificationStatus;

  @ViewChild('information') information: TemplateRef<any>;

  private typesAndModels: DeviceTypesAndModels;
  private destroyed = new Subject<void>();
  private dialogDataReady: Observable<any>;
  private tipsTimeouts = new Map<number, number>();

  constructor(private modelsService: DeviceModelsService, private configService: ConfigModificationsService,
    private dialogService: DialogService, private notificationService: NotificationService) { }

  public ngOnInit(): void {
    this.prepareView();
    this.updateData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Modifications',
      text: this.information
    }});
  }

  public showPopover(popover: NgbPopover, row: ModificationPatchView): void {
    if (this.tipsTimeouts.has(row.id)) {
      clearTimeout(this.tipsTimeouts.get(row.id));
    } else {
      popover.open({row, popover});
    }
  }

  public hidePopover(popover: NgbPopover, row: ModificationPatchView): void {
    this.tipsTimeouts.set(row.id, setTimeout(() => {
      popover.close();
      this.tipsTimeouts.delete(row.id);
    }, 300));
  }

  public updateData(resetPage = false): void {
    this.loading = true;
    this.configService.getConfigModifications(this.query, this.status?.value, this.sorting, resetPage ? 1 : this.activePage)
        .pipe(finalize(() => this.loading = false)).subscribe((modifications: Paged<ModificationPatch>) => {
      this.modifications = modifications;
      this.modifications.content.forEach((modification: ModificationPatch) => this.setActions(modification));
      this.activePage = resetPage ? 1 : this.activePage;
    });
  }

  public createEditModificationPatch(modification?: ModificationPatch): void {
    this.dialogDataReady.subscribe(() => {
      this.progressing = true;
      this.dialogService.showModal(ModificationEditDialogComponent,
        { height: '720px', width: '800px', data: {typesAndModels: this.typesAndModels, modification}})
          .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: ModificationPatchDetails) => {
        this.progressing = false;
        if (result) {
          this.updateData();
        }
      });
    });
  }

  public viewModificationPatchDetails(modification: ModificationPatch): void {
    this.dialogDataReady.subscribe(() => {
      this.progressing = true;
      this.dialogService.showModal(ModificationViewDialogComponent,
        { height: '720px', width: '800px', data: {modification}})
          .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
        this.progressing = false;
      });
    });
  }

  public archiveModificationPatch(modification: ModificationPatchView): void {
    this.dialogDataReady.subscribe(() => {
      this.dialogService.showModal(ConfirmationDialogComponent, { width: '550px', data: {
        title: 'Archive Modification',
        text: `You are about to archive modification <b>(${modification.name || '&mdash;'})</b>
          which is active for ${(modification.models || []).length} model configs.
          As a result the modification will be deactivated and selected configs will not work as they do now.<br/>
          Please confirm if you want to continue.`,
        icon: ConfirmDialogIcon.WARNING,
        agreeButtonText: 'Confirm'
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result) => {
        if (result) {
          this.configService.archiveConfigPatch(modification.id)
              .pipe(finalize(() => this.progressing = false)).subscribe(() => {
            this.updateData();
            this.notificationService.success('Modification successfully archived');
          }, (error: HttpErrorResponse) => {
            this.notificationService.error('Something went wrong. Please try again');
            console.error(error);
          });
        }
      });
    });
  }

  private setActions(modificationPatch: ModificationPatchView): void {
    modificationPatch.actions = [{
      title: 'View',
      action: () => this.viewModificationPatchDetails(modificationPatch)
    }, {
      title: 'Edit',
      action: () => this.createEditModificationPatch(modificationPatch)
    }];
    if (modificationPatch.status !== ModificationStatus.ARCHIVED) {
      modificationPatch.actions.push({
        title: 'Archive',
        action: () => this.archiveModificationPatch(modificationPatch)
      });
    }
  }

  private prepareView(): void {
    this.dialogDataReady = this.modelsService.getDeviceTypesAndModels().pipe(shareReplay(1));
    this.dialogDataReady.subscribe((deviceTypesAndModels: DeviceTypesAndModels) => {
      this.typesAndModels = deviceTypesAndModels;
    });
    this.statusItems = [{value: null, title: 'All Statuses'},
      {value: ModificationStatus.ACTIVE, title: 'Active'},
      {value: ModificationStatus.DRAFT, title: 'Draft'},
      {value: ModificationStatus.ARCHIVED, title: 'Archived'}];
    this.status = this.statusItems[0];
  }

}
