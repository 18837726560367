import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Testing, VirtualDeviceSimplified } from '@models/testing';
import { TestingService } from '@services/testing.service';
import { NotificationService } from '@services/notification.service';
import { DropdownItem } from '@models/dropdown';
import { forkJoin, finalize, Subject } from 'rxjs';
import { CONFLICT, PRECONDITION_FAILED } from '@constants/http-params';

@Component({
  selector: 'app-run-testing-dialog',
  templateUrl: './run-testing-dialog.component.html',
  styleUrls: ['./run-testing-dialog.component.scss']
})
export class RunTestingDialogComponent implements OnInit, OnDestroy {

  public availableDevices: Array<DropdownItem<VirtualDeviceSimplified>>;
  public device: DropdownItem<VirtualDeviceSimplified>;
  public availableModels: Array<DropdownItem>;
  public model: DropdownItem;
  public loading = false;

  private destroyed = new Subject<void>();

  constructor(private dialogRef: MatDialogRef<RunTestingDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef, private router: Router,
    private testingService: TestingService, private notificationService: NotificationService, ) { }

  public ngOnInit(): void {
    this.loading = true;
    forkJoin({
      devices: this.testingService.getTestingDevices(),
      models: this.testingService.getTestingModels()
    }).pipe(finalize(() => this.loading = false)).subscribe(({devices, models}) => {
      this.availableDevices = (devices || []).map(x => ({value: x.deviceMac, title: x.deviceMac, original: x}));
      this.availableModels = (models || []).map(x => ({value: x.modelId, title: x.customerModel}));
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public runTesting(): void {
    if (this.device && this.model) {
      this.loading = true;
      const virtualDevice = this.device.original;
      this.testingService.runTesting(virtualDevice.deviceMac, virtualDevice.playerMac, this.model.value)
          .pipe(finalize(() => this.loading = false)).subscribe((testing: Testing) => {
        this.router.navigate(['testing', 'test', testing.id]);
        this.close();
        this.notificationService.success('Testing started');
      }, (error: HttpErrorResponse) => {
        if (error.status === CONFLICT) {
          virtualDevice.busy = true;
          this.awaitsDeviceReady(virtualDevice);
        } else if (error.status === PRECONDITION_FAILED) {
          virtualDevice.offline = true;
          this.awaitsDeviceReady(virtualDevice);
        } else {
          this.notificationService.error(`Failed to Start Tesing on device ${virtualDevice.deviceMac}`);
        }
      });
    }
  }

  private awaitsDeviceReady(device: VirtualDeviceSimplified): void {
    this.testingService.awaitsDeviceReady(device.deviceMac, this.destroyed).subscribe(() => {
      device.offline = device.busy = false;
      this.changeDetectorRef.detectChanges();
    });
  }

}
