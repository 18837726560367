import { NgModule } from '@angular/core';

import { AdministrationRoutingModule } from './administration-routing.module';
import { CommonModule } from '../common/common.module';

import { DeviceTypesViewComponent } from './pages/device-types-view/device-types-view.component';
import { DeviceModelsViewComponent } from './pages/device-models-view/device-models-view.component';
import { DeviceModelViewComponent } from './pages/device-model-view/device-model-view.component';
import { JsonConfigViewComponent } from './pages/json-config-view/json-config-view.component';
import { ModelConfigViewComponent } from './pages/model-config-view/model-config-view.component';
import { InstructionsViewComponent } from './pages/instructions-view/instructions-view.component';
import { ConfigModificationsViewComponent } from './pages/config-modifications-view/config-modifications-view.component';

import { DeviceTypeEditDialogComponent } from './components/device-type-edit-dialog/device-type-edit-dialog.component';
import { DeviceInstructionEditDialogComponent }
  from './components/device-instruction-edit-dialog/device-instruction-edit-dialog.component';
import { DeviceInstructionConflictDialogComponent }
  from './components/device-instruction-conflict-dialog/device-instruction-conflict-dialog.component';
import { DeviceConfigDialogComponent } from './components/device-config-dialog/device-config-dialog.component';
import { ModelConfigEditDialogComponent } from './components/model-config-edit-dialog/model-config-edit-dialog.component';
import { ModelAssignGroupDialogComponent } from './components/model-assign-group-dialog/model-assign-group-dialog.component';
import { TypesModelsSelectionComponent } from './components/types-models-selection/types-models-selection.component';
import { ModelEditDialogComponent } from './components/model-edit-dialog/model-edit-dialog.component';
import { ModificationEditDialogComponent } from './components/modification-edit-dialog/modification-edit-dialog.component';
import { ModificationViewDialogComponent } from './components/modification-view-dialog/modification-view-dialog.component';
import { ModelsSelectionBoxComponent } from './components/models-selection-box/models-selection-box.component';
import { ConfigPatchConstructorComponent } from './components/config-patch-constructor/config-patch-constructor.component';
import { ConfigPatchResultsComponent } from './components/config-patch-results/config-patch-results.component';

@NgModule({
  imports: [
    CommonModule,
    AdministrationRoutingModule
  ],
  declarations: [
    DeviceTypesViewComponent,
    DeviceModelsViewComponent,
    DeviceModelViewComponent,
    JsonConfigViewComponent,
    ModelConfigViewComponent,
    InstructionsViewComponent,
    ConfigModificationsViewComponent,
    DeviceTypeEditDialogComponent,
    DeviceInstructionEditDialogComponent,
    DeviceInstructionConflictDialogComponent,
    DeviceConfigDialogComponent,
    TypesModelsSelectionComponent,
    ModelEditDialogComponent,
    ModelConfigEditDialogComponent,
    ModelAssignGroupDialogComponent,
    ModificationEditDialogComponent,
    ModificationViewDialogComponent,
    ModelsSelectionBoxComponent,
    ConfigPatchConstructorComponent,
    ConfigPatchResultsComponent,
  ]
})
export class AdministrationModule { }
