import { Component, OnInit, OnDestroy, OnChanges, Input, Output, EventEmitter,
  SimpleChanges, forwardRef, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MultilineEditComponent } from '../multiline-edit/multiline-edit.component';
import { POSITION_BELOW } from '@constants/overlay-position';
import { ViewTreeNode } from '@models/tree-node';
import { BehaviorSubject, takeUntil, debounceTime, skip } from 'rxjs';
import { BLUR_DELAY, LOOKUP_DELAY } from '@constants/config';
import { Utils } from '@services/utils';

@Component({
  selector: 'app-dropdown-tree',
  templateUrl: './dropdown-tree.component.html',
  styleUrls: ['./dropdown-tree.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownTreeComponent),
    multi: true
  }]

})
export class DropdownTreeComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {

  @Input()
  public dropdownTree: Object;

  @Input()
  public hideRoot = true;

  @Input()
  public editable = true;

  @Input()
  public consistent = false;

  @Input()
  public clearable = true;

  @Input()
  public disabled = false;

  @Input()
  public invalid = false;

  @Input()
  public multiline = false;

  @Input()
  public narrow = false;

  @Input()
  public placeholder = 'Type value';

  @Output()
  public changed = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MultilineEditComponent) multilineEdit: MultilineEditComponent;
  @ViewChild('trigger') trigger: ElementRef;
  @ViewChild('treeContainer', { static: false }) treeContainer: ElementRef;

  public expanded = false;
  public focused = false;
  public justFocused = false;
  public justSelected = false;
  public backup: string = '';
  public value: string = '';
  public tree: ViewTreeNode;
  public selectedNode: ViewTreeNode;
  public sizeBox: ClientRect;
  public PositionBelow = POSITION_BELOW;

  private blurTimeout: number;
  private destroyed = new BehaviorSubject<boolean>(false); 
  private scheduledSearch = new BehaviorSubject<string>('');
  private onTouchedCallback: () => void;
  protected commit(value: string) {}

  constructor(private changeDetection: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.scheduledSearch.pipe(debounceTime(LOOKUP_DELAY), skip(1)).subscribe(() => this.search());
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete(); 
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes as any).dropdownTree) {
      this.tree = this.dropdownTree && Utils.jsonTreeToViewTree(this.dropdownTree);
      if (this.tree) {
        this.tree.collapsed = false;
        Utils.updateTreeCounters(this.tree);
      }
    }
  }

  public toggle(event: MouseEvent): void {
    if (this.justFocused) {
      this.returnFocus(event);
    } else {
      this.setExpanded(!this.expanded);
      this.justFocused = false;
    }
  }

  public setExpanded(expanded: boolean): void {
    this.expanded = this.disabled || !this.tree ? false : expanded;
    this.sizeBox = this.trigger.nativeElement.getBoundingClientRect();
  }

  public scheduleSearch(): void {
    if (!this.justSelected) {
      this.scheduledSearch.next(this.value);
    }
    this.justSelected = false;
  }

  public search(): void {
    const normalizedPath = Utils.normalizeTreePath(this.value);
    const treePath = Utils.findTreePath(this.tree, normalizedPath, false);
    this.setExpanded(!!treePath?.length);
    if (treePath?.length) {
      this.collapse(this.tree);
      treePath.forEach((x: ViewTreeNode) => x.collapsed = false);
      Utils.updateTreeCounters(this.tree);
      this.selectedNode = treePath[treePath.length - 1];
      this.scrollToSelected();
    }
  }

  public clear(event: MouseEvent): void {
    this.value = this.backup = '';
    this.commit(null);
    this.expanded = false;
  }

  public writeValue(value: string) {
    if (this.destroyed.value) {
      return;
    }
    this.value = this.backup = value || '';
    this.expanded = false;
    this.selectByValue();
  }

  public selectNode(node: ViewTreeNode): void {
    this.selectedNode = node;
    this.setExpanded(false);
    this.justSelected = true;
    const path = Utils.findNodePath(this.tree, node);
    const value = (path.concat(node)).map(x => x.name).join('/');
    this.value = this.backup = value;
    this.commit(this.value);
    this.returnFocus();
  }

  public setFocused(focused: boolean): void {
    this.focused = focused;
    if (this.focused) {
      this.setExpanded(true);
      this.backup = this.value;
      this.justFocused = true;
      setTimeout(() => this.justFocused = false, BLUR_DELAY);
    } else {
      this.cancelBlur();
      this.blurTimeout = setTimeout(() => {
        this.setExpanded(false);
        if (!this.consistent || this.selectByValue()) {
          this.commit(this.value);
        } else {
          this.value = this.backup;
        }
      }, BLUR_DELAY);
    }
  }

  public expandCollapse(node: ViewTreeNode, event: MouseEvent): void {
    if (!node.terminal && node.children?.length) {
      node.collapsed = !node.collapsed;
      Utils.updateTreeCounters(this.tree);
    }
    this.returnFocus(event);
    event.stopPropagation();
  }

  public collapse(root: ViewTreeNode): void {
    root.collapsed = true;
    (root.children || []).forEach(x => this.collapse(x));
    this.tree.collapsed = false;
    Utils.updateTreeCounters(this.tree);
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public updateHeight(): void {
    this.multilineEdit?.updateHeight();
  }

  private returnFocus(event?: MouseEvent): void {
    event && event.stopPropagation();
    this.cancelBlur();
    if (this.multiline && this.input?.nativeElement) {
      this.input.nativeElement.focus();
    } else if (!this.multiline && this.multilineEdit) {
      this.multilineEdit.returnFocus();
    }
  }

  private cancelBlur(): void {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
  }

  private selectByValue(): ViewTreeNode | null {
    const normalizedPath = Utils.normalizeTreePath(this.value);
    const treePath = Utils.findTreePath(this.tree, normalizedPath);
    return treePath ? (this.selectedNode = treePath[treePath.length - 1]) : null;
  }

  private scrollToSelected(): void {
    setTimeout(() => {
      if (this.treeContainer?.nativeElement && this.selectedNode) {
        const selectedElement = this.treeContainer.nativeElement.querySelector('.node-row.selected');
        if (selectedElement) {
          selectedElement.scrollIntoView({ block: 'center' });
        }
      }
    }, 200);
  }

}
