import { Component, OnInit, OnChanges, SimpleChanges, Input, Output,
  ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Utils } from '@services/utils';
import { ViewTreeNode } from '@models/tree-node';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})
export class TreeViewComponent implements OnInit, OnChanges {

  @Input()
  public root: ViewTreeNode | Array<ViewTreeNode>;

  @Input()
  public selectedNode: ViewTreeNode = null;

  @Input()
  public showCollapseAll = true;

  @Output()
  public nodeSelected = new EventEmitter<ViewTreeNode>();

  @ViewChild('treeContainer', { static: true }) treeContainer: ElementRef;

  public roots: Array<ViewTreeNode> = [];
  public preventScrolling = false;

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.roots = this.root ? (Array.isArray(this.root) ? this.root : [this.root]) : [];
    if (this.roots && changes['selectedNode'] && this.selectedNode) {
      const pathes = this.roots.map(x => Utils.findNodePath(x, this.selectedNode)).filter(x => !!x);
      if (pathes.length) {
        pathes[0].forEach(node => node.collapsed = false);
        this.roots.forEach(x => Utils.updateTreeCounters(x));
      }
      if (this.preventScrolling) {
        this.preventScrolling = false;
      } else {
        this.scrollToSelected();
      }
    }
  }

  public expandCollapse(node: ViewTreeNode): void {
    if (!node.terminal && node.children?.length) {
      node.collapsed = !node.collapsed;
    }
    this.roots.forEach(x => Utils.updateTreeCounters(x));
  }

  public collapseAll(node?: ViewTreeNode): void {
    if (node) {
      if (!node.terminal) {
        node.collapsed = true;
        (node.children || []).forEach(x => this.collapseAll(x));
      }
      Utils.updateTreeCounters(node);
    } else {
      this.roots.forEach(x => this.collapseAll(x));
    }
  }

  public selectNode(node: ViewTreeNode): void {
    this.selectedNode = node;
    this.preventScrolling = true;
    this.nodeSelected.emit(node);
  }

  public scrollToSelected(): void {
    setTimeout(() => {
      if (this.treeContainer?.nativeElement && this.selectedNode) {
        const selectedElement = this.treeContainer.nativeElement.querySelector('.node-row.selected');
        if (selectedElement) {
          selectedElement.scrollIntoView({ block: 'center' });
        }
      }
    }, 200);
  }
}
