import * as moment from 'moment';

export interface DatesRange {
  from: Date;
  to: Date;
}

export enum DatesRangeTypes {
  YEAR = 'year',
  MONTH = 'month',
  DAY = 'day',
  DAY_BEFORE = 'yesterday',
  WEEK = 'week',
  ALL_TIME = 'all_time',
  CUSTOM = 'custom'
}

export interface DatesRangeType {
  type: DatesRangeTypes;
  label: string;
  complete?: (date: Date) => Date;
  defaultRange: () => DatesRange;
  recognize: (range: DatesRange) => boolean;
}

export function limitDate(date: Date, limits: DatesRange): Date {
  return date < limits?.from ? limits.from : (date > limits?.to ? limits.to : date);
}

export function minDate(dates: Array<Date>): Date {
  return (dates || []).filter(x => !!x && x instanceof Date && isFinite(x.getTime()))
    .sort((x, y) => x.getTime() - y.getTime())[0];
}

export function maxDate(dates: Array<Date>): Date {
  return (dates || []).filter(x => !!x && x instanceof Date && isFinite(x.getTime()))
    .sort((x, y) => x.getTime() - y.getTime()).reverse()[0];
}

export function fixRange(range: DatesRange): DatesRange {
  const startDate = range?.from ? moment(range.from).startOf('day').toDate() : null;
  const endDate = range?.to ? moment(range.to).endOf('day').toDate() : null;
  return {from: startDate, to: endDate} as DatesRange;
}

export const DATES_RANGE_TYPES: { [key in DatesRangeTypes]: DatesRangeType } = {
  [DatesRangeTypes.YEAR]: {
    type: DatesRangeTypes.YEAR,
    label: 'Year',
    complete: (date: Date): Date => {
      return moment(date).endOf('year').toDate();
    },
    defaultRange: () => {
      return {from: moment().startOf('year').toDate(), to: moment().endOf('year').toDate()};
    },
    recognize: (range: DatesRange) => {
      return range && range.from && range.to && moment(range.from).isSame(range.to, 'year')
        && moment(range.from).startOf('year').isSame(range.from, 'day')
        && moment(range.to).endOf('year').isSame(range.to, 'day');
    }
  },
  [DatesRangeTypes.MONTH]: {
    type: DatesRangeTypes.MONTH,
    label: 'Month',
    complete: (date: Date): Date => {
      return moment(date).endOf('month').toDate();
    },
    defaultRange: () => {
      return {from: moment().startOf('month').toDate(), to: moment().endOf('month').toDate()};
    },
    recognize: (range: DatesRange) => {
      return range && range.from && range.to && moment(range.from).isSame(range.to, 'month')
        && moment(range.from).startOf('month').isSame(range.from, 'day')
        && moment(range.to).endOf('month').isSame(range.to, 'day');
    }
  },
  [DatesRangeTypes.DAY]: {
    type: DatesRangeTypes.DAY,
    label: 'Day',
    complete: (date: Date): Date => {
      return moment(date).endOf('day').toDate();
    },
    defaultRange: () => {
      return {from: moment().startOf('day').toDate(), to: moment().endOf('day').toDate()};
    },
    recognize: (range: DatesRange) => {
      return range && range.from && range.to && moment(range.from).isSame(range.to, 'day')
        && moment().startOf('day').isSame(range.from, 'day')
        && moment().endOf('day').isSame(range.to, 'day');
    }
  },
  [DatesRangeTypes.DAY_BEFORE]: {
    type: DatesRangeTypes.DAY_BEFORE,
    label: 'Day',
    complete: (date: Date): Date => {
      return moment(date).endOf('day').toDate();
    },
    defaultRange: () => {
      return {from: moment().add(-1, 'day').startOf('day').toDate(), to: moment().add(-1, 'day').endOf('day').toDate()};
    },
    recognize: (range: DatesRange) => {
      return range && range.from && range.to && moment(range.from).isSame(range.to, 'day')
        && moment().add(-1, 'day').startOf('day').isSame(range.from, 'day')
        && moment().add(-1, 'day').endOf('day').isSame(range.to, 'day');
    }
  },
  [DatesRangeTypes.WEEK]: {
    type: DatesRangeTypes.WEEK,
    label: 'Week',
    complete: (date: Date): Date => {
      return moment(date).add(1, 'week').toDate();
    },
    defaultRange: () => {
      return {from: moment().add(-6, 'day').startOf('day').toDate(), to: moment().endOf('day').toDate()};
    },
    recognize: (range: DatesRange) => {
      return range && range.from && range.to && moment(range.to).diff(range.from, 'days') === 7;
    }
  },
  [DatesRangeTypes.ALL_TIME]: {
    type: DatesRangeTypes.ALL_TIME,
    label: 'All Time',
    defaultRange: () => {
      return {from: moment({year: 1900}).toDate(), to: moment({year: 2500}).toDate()};
    },
    recognize: (range: DatesRange) => {
      return range && moment({year: 1900}).isSame(range.from, 'day') && moment({year: 2500}).isSame(range.to, 'day');
    }
  },
  [DatesRangeTypes.CUSTOM]: {
    type: DatesRangeTypes.CUSTOM,
    label: 'Custom',
    defaultRange: () => {
      return DATES_RANGE_TYPES[DatesRangeTypes.YEAR].defaultRange();
    },
    recognize: (range: DatesRange) => {
      return true;
    }
  }
};

export const DATES_RANGE_TYPES_STD = {...DATES_RANGE_TYPES};
export const DATES_RANGE_TYPES_YSTER = {...DATES_RANGE_TYPES};
delete DATES_RANGE_TYPES_STD[DatesRangeTypes.DAY_BEFORE];
delete DATES_RANGE_TYPES_YSTER[DatesRangeTypes.DAY];

