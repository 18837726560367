import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { WiFiModuleDetailed, WiFiModuleCommand,
  WiFiModuleCommandEntryDetails, WiFiModuleCommandType } from '@models/device-modules';
import { WiFiModuleCommandDetails } from '../../models/dialog-data';
import { DeviceModulesService } from '@services/device-modules.service';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DatesRange, DATES_RANGE_TYPES, DatesRangeTypes } from '@models/dates';
import { DATE_TIME } from '@constants/dates';
import { finalize } from 'rxjs';

enum SortColumn {
  NAME = 'name',
  TYPE = 'type',
  TIME = 'time',
  AUTHOR = 'author',
  TRACE = 'trace'
}

@Component({
  selector: 'app-device-module-commands-list',
  templateUrl: './device-module-commands-list.component.html',
  styleUrls: ['./device-module-commands-list.component.scss']
})
export class DeviceModuleCommandsListComponent implements OnInit, OnChanges {

  @Input()
  public details: WiFiModuleDetailed;

  @Input()
  public justify = false;

  @Output()
  public viewDetails = new EventEmitter<WiFiModuleCommandDetails>();

  public range: DatesRange = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
  public query: string = '';

  public commands: Paged<WiFiModuleCommand>;

  public activePage = 1;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.TIME, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public CommandType = WiFiModuleCommandType;

  constructor(private deviceModulesService: DeviceModulesService) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.range = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public viewCommandEntry(command: WiFiModuleCommand): void {
    this.deviceModulesService.getDeviceWiFiModuleCommandDetails(command)
        .subscribe((details: WiFiModuleCommandEntryDetails) => {
      this.viewDetails.emit({command, details: details.value || ''} as WiFiModuleCommandDetails);
    });
  }

  public updateData(resetPage = false): void {
    if (this.details) {
      this.loading = true;
      this.deviceModulesService.getDeviceWiFiModuleCommands(
        this.details.macAddress, this.range, this.query, this.sorting, resetPage ? 1 : this.activePage)
      .pipe(finalize(() => this.loading = false))
      .subscribe((commands: Paged<WiFiModuleCommand>) => {
        this.commands = commands;
        this.activePage = resetPage ? 1 : this.activePage;
      });
    }
  }

}
