import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { BaseDialogData, JsonDialogData } from '@models/dialogs';
import { isString } from 'lodash';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent implements OnInit, OnDestroy {

  @ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;

  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BaseDialogData | JsonDialogData,
    private dialogRef: MatDialogRef<BaseDialogData>
  ) { }

  public isPlainHtml = true;

  public ngOnInit(): void {
    this.isPlainHtml = !this.data.text || isString(this.data.text);
    this.dialogRef.afterOpened().pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.scrollbar?.directiveRef && this.scrollbar.directiveRef.update();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public proceed(): void {
    this.dialogRef.close(true);
  }
}
