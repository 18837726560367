<div class="commands-view sub-view">
  <div class="controls-panel view-header align-container" [class.space-between]="justify">
    <div class="label-base">Period</div>
    <div class="range-select">
      <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData(true)" [typeSelector]="false">
      </app-dates-range>
    </div>
    <div class="query-field">
      <app-search-bar (search)="search($event)" [searching]="loading" placeholder="Search trace">
      </app-search-bar>
    </div>
  </div>
  <div class="view-section">
    <div class="contents scroll-container">
      <perfect-scrollbar>
        <table class="results-table stripes-table">
          <thead>
            <tr>
              <th>
                Type
              </th>
              <th>
                Name
              </th>
              <th>
                Trace
              </th>
              <th>
                Time
              </th>
              <th>
                Author
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of commands?.content">
              <td class="capitalize">{{row.type || '&mdash;'}}</td>
              <td class="break-word">{{row.type === CommandType.REQUEST ? (row.name || '&mdash;') : (row.errNo || 'Ok')}}</td>
              <td>{{row.trace || '&mdash;'}}</td>
              <td class="nowrap">{{row.ts ? (row.ts | date:DATE_TIME) : '&mdash;'}}</td>
              <td class="break-word">{{row.author || '&mdash;'}}</td>
              <td>
                <div class="view-details" (click)="viewCommandEntry(row)"></div>
              </td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!commands || !commands?.content.length" [bordered]="true"></app-no-data>
      </perfect-scrollbar>
      <div class="pagination-block attached" *ngIf="commands && commands.totalPages > 1">
        <div class="text-info">Showing {{commands.startElement}} to {{commands.endElement}} of {{commands.totalElements}} items</div>
        <app-pagination [activePage]="activePage" [totalPages]="commands.totalPages" (pageChanged)="setActivePage($event)">
        </app-pagination>
      </div>
    </div>
  </div>
</div>