import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpParams } from '@constants/http-params';
import { UserRoles } from '@models/user-info';

import { DictionariesViewComponent } from './pages/dictionaries-view/dictionaries-view.component';
import { ImageCatalogueViewComponent } from './pages/image-catalogue-view/image-catalogue-view.component';
import { TemplatesViewComponent } from './pages/templates-view/templates-view.component';
import { PageTemplateViewComponent } from './pages/template-view/template-view.component';
import { ComponentUsagesViewComponent } from './pages/component-usages-view/component-usages-view.component';

const routes: Routes = [
  {
    path: 'dictionaries',
    component: DictionariesViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.CONTENT_MANAGER]}
  },
  {
    path: 'catalogue',
    component: ImageCatalogueViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.CONTENT_MANAGER]}
  },
  {
    path: 'templates',
    component: TemplatesViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.CONTENT_MANAGER]}
  },
  {
    path: `template/:${HttpParams.TEMPLATE_ID}`,
    component: PageTemplateViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.CONTENT_MANAGER]}
  },
  {
    path: `component/:${HttpParams.COMPONENT_TYPE}/:${HttpParams.COMPONENT_ID}/usages`,
    component: ComponentUsagesViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.CONTENT_MANAGER]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MobileBackendRoutingModule {}
