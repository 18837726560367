import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { WiFiModuleDetailed } from '@models/device-modules';
import { DATE } from '@constants/dates';

@Component({
  selector: 'app-device-module-info-view',
  templateUrl: './device-module-info-view.component.html',
  styleUrls: ['./device-module-info-view.component.scss']
})
export class DeviceModuleInfoViewComponent {

  @Input()
  public details: WiFiModuleDetailed;

  @Output()
  public navigated = new EventEmitter<boolean>;

  public DATE_FORMAT = DATE;

  constructor(private router: Router) {}

  public navigateModelDetails(): void {
    if (this.details?.deviceModelId) {
      this.router.navigate(['administration', 'device-models', this.details.deviceModelId]);
      this.navigated.emit(true);
    }
  }

}
