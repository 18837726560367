<div class="page-controls">
  <div class="first-page nav-button" *ngIf="tailButtons" [class.disabled]="currentPage === 1" (click)="setPage(1)">
    <a class="page-ref">First</a>
  </div>
  <div class="prev-page nav-button" *ngIf="prevNextButtons" [class.disabled]="currentPage === 1" (click)="setPage(currentPage - 1)">
    <a class="page-ref">Prev</a>
  </div>
  <ng-container *ngIf="numericButtons">
    <div *ngFor="let page of numericControlStructure" class="nav-button page-item" [class.current-page]="page === currentPage" [class.separator]="page === -1" (click)="setPage(page)">
      <span *ngIf="page === -1" class="page-ref">...</span>
      <span *ngIf="page === currentPage" class="page-ref">{{page}}</span>
      <a *ngIf="page !== -1 && page !== currentPage" class="page-ref">{{page}}</a>
    </div>
  </ng-container>
  <div class="next-page nav-button" *ngIf="prevNextButtons" [class.disabled]="currentPage === totalPages" (click)="setPage(currentPage + 1)">
    <a class="page-ref">Next</a>
  </div>
  <div class="last-page nav-button" *ngIf="tailButtons" [class.disabled]="currentPage === totalPages" (click)="setPage(totalPages)">
    <a class="page-ref">Last</a>
  </div>
</div>
