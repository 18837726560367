<div class="wizard-steps">
  <div class="steps-container">
    <ng-container *ngFor="let step of steps">
      <div class="step" 
        [class.active]="step.active" [class.visited]="step.visited"
        [class.invalid]="step.invalid" [class.optional]="step.optional">
        <div class="step-icon"></div>
        <div class="step-title">{{step.title}}</div>
      </div>
      <div class="separator"></div>
    </ng-container>
  </div>
</div>