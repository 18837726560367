import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceModelsService } from '@services/device-models.service';
import { NotificationService } from '@services/notification.service';
import { DeviceModelUniquinessResponse } from '@models/device-models';
import { ModelEditDialogData } from '../../models/dialog-data';
import { DeviceType } from '@models/device-models';
import { DropdownItem } from '@models/dropdown';
import { Subject, takeUntil, debounceTime, finalize } from 'rxjs';
import { SPECIAL_CHARS } from '@constants/main';

export const CHECK_TIMEOUT = 500;
export const MODEL_NAME_REGEXP = new RegExp('^[a-zA-Z0-9\\s' + SPECIAL_CHARS + ']{1,50}$');
export const SAP_REGEXP = new RegExp('^[a-zA-Z0-9]{9}RU$');

@Component({
  selector: 'app-model-edit-dialog',
  templateUrl: './model-edit-dialog.component.html',
  styleUrls: ['./model-edit-dialog.component.scss']
})
export class ModelEditDialogComponent implements OnInit, OnDestroy {

  public deviceTypes: Array<DropdownItem>;
  public deviceType: DropdownItem;
  public productLines: Array<DropdownItem>;
  public productLine: DropdownItem;

  public isNew = true;
  public progressing = false;
  public modelName: string = '';
  public sapCode: string = '';
  public errors: any = null;

  private destroyed = new Subject<void>();
  private checkPipe = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModelEditDialogData,
    private dialogRef: MatDialogRef<ModelEditDialogComponent>,
    private deviceModelsService: DeviceModelsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.isNew = this.data.isNew;
    this.modelName = this.data.model ? this.data.model.customerModel || '' : '';
    this.sapCode = this.data.model ? this.data.model.materialNumber || '' : '';
    this.deviceTypes = this.data.deviceTypes.map(x => ({value: x.id, title: x.name, original: x}));
    this.deviceType = this.data.model ? this.deviceTypes.find(x => x.value === this.data.model.deviceTypeId)
      : this.deviceTypes.length && this.deviceTypes[0];
    this.updateProductLines(false);
    this.checkPipe.pipe(debounceTime(CHECK_TIMEOUT), takeUntil(this.destroyed)).subscribe(() => {
      this.check();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public check(): void {
    this.errors = null;
    if (!this.modelName || !this.modelName.trim() || !MODEL_NAME_REGEXP.test(this.modelName)) {
      this.errors = {name: {invalid: true}};
    }
    if (this.sapCode && !SAP_REGEXP.test(this.sapCode)) {
      this.errors = {...this.errors, sap: {invalid: true}};
    }
    if (!this.errors) {
      this.progressing = true;
      this.deviceModelsService.checkModelNameAndSapUniqueness(this.deviceType?.value, this.modelName, this.sapCode)
          .pipe(finalize(() => this.progressing = false)).subscribe((uniquenessResponse: DeviceModelUniquinessResponse) => {
        if (!uniquenessResponse.customerModel) {
          this.errors = {name: {nonunique: true}};
        }
        if (!uniquenessResponse.materialNumber) {
          this.errors = {...this.errors, sap: {nonunique: true}};
        }
      });
    }
  }

  public updateProductLines(doCheck = true): void {
    const currentDeviceType = this.deviceType?.original as DeviceType;
    if (currentDeviceType) {
      this.productLines = currentDeviceType.productLines.map(x => ({value: x.id, title: x.name}));
      this.productLine = this.data.model ? this.productLines.find(x => x.value === this.data.model.productLineId)
        : this.productLines.length && this.productLines[0];
    } else {
      this.productLines = [];
      this.productLine = null;
    }
    doCheck && this.check();
  }

  public pipeCheck(): void {
    this.checkPipe.next();
  }

  public proceed(): void {
    if (!this.progressing) {
      this.progressing = true;
      if (this.isNew) {
        this.deviceModelsService.createDeviceModel(this.modelName, this.sapCode, this.deviceType?.value, this.productLine?.value)
            .subscribe((result: void) => {
          this.notificationService.success(`Model ${this.modelName} successfully created`);
          this.dialogRef.close(true);
        }, (error) => {
          this.notificationService.error(`Failed to create model ${this.modelName}`);
          this.dialogRef.close(false);
        });
      }
    }
  }

}
