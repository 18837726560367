import { Component, Input } from '@angular/core';
import { WiFiModuleDetailed } from '@models/device-modules';

@Component({
  selector: 'app-device-module-spaces-view',
  templateUrl: './device-module-spaces-view.component.html',
  styleUrls: ['./device-module-spaces-view.component.scss']
})
export class DeviceModuleSpacesViewComponent {

  @Input()
  public details: WiFiModuleDetailed;

}
