import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { UserInfoService } from '@services/user-info.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ModelEditDialogComponent } from '../../components/model-edit-dialog/model-edit-dialog.component';
import { DeviceModel, DeviceModelView, DeviceType } from '@models/device-models';
import { ModelEditDialogData } from '../../models/dialog-data';
import { DropdownItem } from '@models/dropdown';
import { Tag } from '@models/informational';
import { Paged } from '@models/pageable';
import { UserInfo } from '@models/user-info';
import { Sorting, SortOrder } from '@models/sorting';
import { DATE } from '@constants/dates';
import { Observable, Subject, forkJoin, finalize, takeUntil, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';

enum SortColumn {
  MODEL = 'customerModel',
  DEVICE_TYPE = 'deviceTypeName',
  UPDATED = 'updatedAt'
}
 
@Component({
  selector: 'app-device-models-view',
  templateUrl: './device-models-view.component.html',
  styleUrls: ['./device-models-view.component.scss']
})
export class DeviceModelsViewComponent implements OnInit, OnDestroy {

  public deviceModels: Paged<DeviceModelView>;
  public deviceTypesItems: Array<DropdownItem> = [];
  public deviceType: DropdownItem;
  public tagsItems: Array<DropdownItem> = [];
  public tags: Array<DropdownItem> = [];
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public progressing = false;
  public DATE_FORMAT = DATE;
  public sorting: Sorting = {column: SortColumn.UPDATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public activeUser: Observable<UserInfo>;
  public environment = environment;

  @ViewChild('information') information: TemplateRef<any>;

  private deviceTypes: Array<DeviceType>;
  private ready: Observable<any>;
  private destroyed = new Subject<void>();

  constructor(private modelsService: DeviceModelsService, private dialogService: DialogService,
    private userInfoService: UserInfoService) { }

  public ngOnInit(): void {
    this.activeUser = this.userInfoService.getActiveUser();
    this.fillDeviceTypesAndTags();
    this.updateData(false);
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData(false);
  }

  public search(query: string): void {
    this.query = query;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Device models',
      text: this.information
    }});
  }

  public createDeviceModel(): void {
    this.progressing = true;
    this.ready.subscribe(() => {
      const data = {isNew: true, deviceTypes: this.deviceTypes} as ModelEditDialogData;
      this.dialogService.showModal(ModelEditDialogComponent, { width: '500px', data})
          .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: DeviceModel) => {
        this.progressing = false;
        if (result) {
          this.updateData();
        }
      });
    });
  }


  public updateData(resetPage = true): void {
    this.loading = true;
    this.modelsService.getDeviceModels(this.query,
      this.deviceType?.value, this.tags.map(x => x.value), resetPage ? 1 : this.activePage, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe((models: Paged<DeviceModel>) => {
      this.deviceModels = models as Paged<DeviceModelView>;
      const selectedTags = new Set(this.tags.map(x => x.value));
      this.deviceModels.content.forEach(x => (x.tags || []).forEach(y => y.selected = selectedTags.has(y.id)));
      this.activePage = resetPage ? 1 : this.activePage;
    }, (error: any) => {
      console.error(error);
    });
  }

  private fillDeviceTypesAndTags(): void {
    this.loading = true;
    this.ready = forkJoin({
      deviceTypes: this.modelsService.getDeviceTypes(),
      tags: this.modelsService.getModelsTags()
    }).pipe(
      takeUntil(this.destroyed),
      shareReplay(1),
      finalize(() => this.loading = false)
    );
    this.ready.subscribe(({deviceTypes, productLines, tags}) => {
      this.deviceTypesItems = [{value: null, title: 'All Device types'}]
        .concat(deviceTypes.map((x: DeviceType) => ({value: x.id, title: x.name})));
      this.deviceType = this.deviceTypesItems[0];
      this.deviceTypes = deviceTypes || [];
      this.tagsItems = tags.map((x: Tag) => ({value: x.id, title: '#' + x.name}));
    });
  }

}

