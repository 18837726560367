<div class="configs-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Device JSON-configs</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section filtering-panel align-container">
    <div class="filtering-block align-container">
      <div class="search-model">
        <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search by name, version or board ID"></app-search-bar>
      </div>
      <div class="device-type">
        <app-dropdown [items]="deviceTypesItems" [(ngModel)]="deviceType" (ngModelChange)="updateData(true)"
          placeholder="Select device type"></app-dropdown>
      </div>
      <div class="active-only">
        <app-checkbox [(ngModel)]="activeOnly" (ngModelChange)="updateData(true)">Active only</app-checkbox>
      </div>
    </div>
    <div class="buttons">
      <button class="primary button-upload" (click)="createDeviceConfig()">Upload Config</button>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.NAME" (sort)="setSorting($event)">
                Name
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.VERSION" (sort)="setSorting($event)">
                Version
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_TYPE" (sort)="setSorting($event)">
                Device Type
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.BOARD_ID" (sort)="setSorting($event)">
                Board ID
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.EPP_VERSION" (sort)="setSorting($event)">
                Epp
              </app-sortable-header>
            </th>
            <th class="centered">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ACTIVE" (sort)="setSorting($event)">
                Status
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.CREATED" (sort)="setSorting($event)">
                Created
              </app-sortable-header>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of configs?.content">
            <td class="break-all">{{row.fileName || '&mdash;'}}</td>
            <td>{{row.id || '&mdash;'}}</td>
            <td>{{row.deviceType || '&mdash;'}}</td>
            <td class="break-all">{{row.boardId || '&mdash;'}}</td>
            <td>{{row.eppVersion || '&mdash;'}}</td>
            <td>
              <div class="activity-status" [ngClass]="{active: row.active, inactive: !row.active}">
                {{row.active ? 'Active' : 'Inactive'}}
              </div>
            </td>
            <td>{{row.createdAt ? (row.createdAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td><button class="button button-download" (click)="downloadDeviceConfig(row)"></button></td> 
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!configs || !configs?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="configs && configs.totalPages > 1">
      <div class="text-info">Showing {{configs.startElement}} to {{configs.endElement}} of {{configs.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="configs.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Device JSON-configs</b> – section for managing JSON configurations intended for loading into devices of a certain type. In this section, you can see both the current (currently active) configurations, and the outdated ones - for each configuration there is a mark indicating its status.</div>
  <div class="top-space">In this section you can:</div>
  <ol>
    <li>Upload configuration</li>
    <li>Download available in the IoT platform</li>
  </ol>
  <div class="top-space">
    The main window of the section contains a list of json-files with information about:
    <ol>
      <li>NAME – a name of a file</li>
      <li>VERSION – an internal version of a file (in the Platform DB)</li>
      <li>DEVICE TYPE – for which device type</li>
      <li>BOARD ID – an ID of a motherboard of a device, which this file is available for</li>
      <li>EPP – epp-version of the device (1.0 or 2.*)</li>
      <li>STATUS – whether a file is active for the Platform or not</li>
      <li>CREATED – date of downloading a file</li>
    </ol>
  </div>
</ng-template>