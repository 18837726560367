<div class="dialog-content new-record-dialog">
  <div class="dialog-header">
    <div class="title-container">
      <div class="title">New Record</div>
    </div>
    <div class="close-icon" (click)="close()"></div>
  </div>
  <div class="dialog-body">
    <div class="field-container recorder-search">
      <div class="field-name">Select needed recorder</div>
      <div class="search-line align-container justify-content">
        <div class="search-field">
          <input type="text" class="input upper-case" placeholder="Search by MAC address"
            [disabled]="searching" [(ngModel)]="macAddress" (keydown.enter)="findDevice(macAddress)">
        </div>
        <div class="button-container">
          <button class="primary" (click)="findDevice(macAddress)" [disabled]="searching">Search</button>
        </div>
      </div>
    </div>
    <div class="recorder-info data-table" *ngIf="!searching && isOk(recorder)">
      <div class="info-line">
        <div class="field-name">Device Model</div>
        <div class="field-value">{{recorder.deviceModel || '&mdash;'}}</div>
      </div>
      <div class="info-line">
        <div class="field-name">Device Type</div>
        <div class="field-value">{{recorder.deviceType || '&mdash;'}}</div>
      </div>
      <div class="info-line">
        <div class="field-name">Firmware Version</div>
        <div class="field-value">{{recorder.fwVersion || '&mdash;'}}</div>
      </div>
    </div>
    <div class="recorder-info recorder-message" *ngIf="!searching">
      <span class="error-message" *ngIf="invalidMac">Text you entered doesn't correspond to valid MAC address format</span>
      <span class="error-message" *ngIf="missing">Virtual device with specified MAC address not found</span>
      <span class="error-message" *ngIf="failed">Failed to find device information</span>
      <ng-container *ngIf="recorder && !isOk(recorder)">
        <span class="error-message" *ngIf="!recorder.online">Device not online</span>
        <span class="error-message" *ngIf="recorder.online && !recorder.configExists">
          No model-configuration file found for {{recorder.deviceMac}}</span>
        <span class="error-message" *ngIf="recorder.online && recorder.configExists && !recorder.autotestExists">
          Autotests for model not found</span>
        <span class="error-message" *ngIf="recorder.online && recorder.configExists && recorder.autotestExists && !recorder.isBindToTestUser">
          This device {{recorder.deviceMac}} connected not to the test user.</span>
      </ng-container>
      <ng-container *ngIf="recorder && isOk(recorder) && (recorder.upgradeId || recorder.testingId)">
        <span class="info-message" *ngIf="recorder.upgradeId">Installing Firmware</span>
        <span class="info-message" *ngIf="!recorder.upgradeId && recorder.testingId">Device Busy</span>
      </ng-container>
    </div>
    <ng-container *ngIf="!searching && !invalidMac && !missing && !failed && isOk(recorder)">
      <div class="installing-progress" *ngIf="progressing || recorder.upgradeId || recorder.testingId">
        <div class="loader centered"></div>
      </div>
      <ng-container *ngIf="!(progressing || recorder.upgradeId || recorder.testingId)">
        <div class="recorder-info recorder-ready-container" *ngIf="firmwareInstalled">
          <span class="status-green">SUCCESS:&nbsp;</span>
          <span class="recorder-message">The firmware v.{{recorder.fwVersion || '&mdash;'}} is sucessfully installed</span>
        </div>
        <div class="recorder-info recorder-ready-container" *ngIf="recorder.upgradeFailed && !firmwareInstalled">
          <span class="status-red">FAILURE:&nbsp;</span>
          <span class="recorder-message">failed to install firmware v.{{attemptedFwVersion || '&mdash;'}} on device {{recorder.deviceMac}}</span>
        </div>
        <div class="recorder-info recorder-ready-container" *ngIf="recorder.recorderInstalled && !firmwareInstalled && !recorder.upgradeFailed">
          <span class="recorder-message" *ngIf="recorder.recorderInstalled">Latest firmware v.{{recorder.fwVersion || '&mdash;'}} already installed</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="dialog-footer">
    <button class="secondary" (click)="close()">Close</button>
    <div class="action-buttons">
      <button class="primary" (click)="updateFirmware(true)" *ngIf="!recorder || !recorder.recorderInstalled"
        [disabled]="searching || progressing || !isOk(recorder)">Update Firmware</button>
      <button class="primary" (click)="updateFirmware(false)" *ngIf="recorder?.recorderInstalled"
        [disabled]="searching || progressing || recorder?.upgradeId || recorder?.testingId || !isOk(recorder)">Roll Back</button>
      <button class="primary" (click)="runRecording()" *ngIf="recorder?.recorderInstalled"
        [disabled]="searching || progressing || recorder?.upgradeId || recorder?.testingId || !isOk(recorder)">Run</button>
    </div>
  </div>
</div>
