import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceTypeDialogData } from '../../models/dialog-data';
import { DeviceType, ProductLine } from '@models/device-models';
import { DeviceModelsService } from '@services/device-models.service';
import { NotificationService } from '@services/notification.service';
import { DropdownItem } from '@models/dropdown';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-device-type-edit-dialog',
  templateUrl: './device-type-edit-dialog.component.html',
  styleUrls: ['./device-type-edit-dialog.component.scss']
})
export class DeviceTypeEditDialogComponent implements OnInit, OnDestroy {

  public isNew = true;
  public initialDeviceType: DeviceType = null;
  public name: string;
  public knownNames: Array<string> = [];
  public productLines: Array<ProductLine>;
  public productLinesItems: Array<DropdownItem<ProductLine>>;
  public productLinesSelected: Array<DropdownItem<ProductLine>>;
  public errors: {name?: boolean, productLines?: boolean} = null;
  public progressing = false;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceTypeDialogData,
    private dialogRef: MatDialogRef<DeviceTypeEditDialogComponent>,
    private deviceModelsService: DeviceModelsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.isNew = this.data.isNew;
    this.initialDeviceType = this.data.deviceType || {} as DeviceType;
    this.knownNames = this.data.knownNames;
    this.name = this.initialDeviceType.name || '';
    this.productLines = this.data.productLines;
    this.productLinesItems = this.productLines.map(x => ({value: x.id, title: x.name, original: x}));
    this.productLinesSelected = (this.initialDeviceType.productLines || [])
      .map(x => this.productLinesItems.find(y => y.value === x.id));
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public resetErrors(): void {
    this.errors = null;
  }

  public proceed(): void {
    if (!this.name || this.knownNames.includes(this.name)) {
      this.errors = {name: true};
    }
    if (!this.productLinesSelected.length) {
      this.errors = Object.assign(this.errors || {}, {productLines: true});
    }
    if (!this.errors) {
      const deviceType = {...this.initialDeviceType,
        ...{name: this.name, productLines: this.productLinesSelected.map(x => x.original)}} as DeviceType;
      this.progressing = true;
      const request = this.isNew ? this.deviceModelsService.createDeviceType(deviceType) :
        this.deviceModelsService.updateDeviceType(deviceType);
      request.pipe(finalize(() => this.progressing = false)).subscribe((deviceType: DeviceType) => {
        this.notificationService.success('Device Type successfully ' + (this.isNew ? 'created' : 'updated'));
        this.dialogRef.close(deviceType);
      }, (error: HttpErrorResponse) => {
        this.notificationService.error((this.isNew ? 'Creating' : 'Updating') + ' Device Type failed');
      });
    }
  }

}
