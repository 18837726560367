<div class="binding-tool-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Wi-Fi module firmware</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section">
    <div class="view-layout">
      <div class="panel no-padding firmware-utilities">
        <div class="panel-header">
          Firmware Utilities Download
        </div>
        <div class="panel-section information">
          <div class="section-content no-border">
            Download mass production script with access tokens and selected firmware<br/>
            Also contains QA utility with selected firmware version to control
          </div>
        </div>
        <div class="panel-section">
          <div class="form-container">
            <div class="field-label">
              Models&nbsp;<span class="red">*</span>
            </div>
            <div class="field-container">
              <app-lookup placeholder="Search model" [provider]="modelsProvider" [(ngModel)]="models"
                [clearable]="true" [magnifyingGlass]="true" [multi]="true" [unique]="true" [disabled]="true"></app-lookup>
            </div>
            <div class="field-label">
              Tokens requested&nbsp;<span class="red">*</span>
            </div>
            <div class="field-container">
              <input class="tokens-requested" type="text" mask="separator.0" separatorLimit="10000" thousandSeparator="" [(ngModel)]="tokensRequested" disabled/>
            </div>
            <div class="field-label">
              Firmware version&nbsp;<span class="red">*</span>
            </div>
            <div class="field-container">
              <app-dropdown placeholder="Select firmware version" [items]="firmwares" [(ngModel)]="firmware"
                [disabled]="true">
              </app-dropdown>
            </div>
            <div class="download-actions">
              <button class="primary" [disabled]="!firmware || !models?.length || !tokensRequested || tokensRequested === '0'"
                (click)="downloadArchive()" [disabled]="true">Download</button>
            </div>
          </div>
        </div>
      </div>
      <div class="panel no-padding upload-panel">
        <div class="panel-header">
          Results Upload
        </div>
        <div class="panel-section information">
          <div class="section-content no-border">
            Upload the resulting Mac address file back to the Wi-Fi module firmware application.
          </div>
        </div>
        <div class="panel-section no-separator">
          <table class="utilities-list">
            <tr class="utility-row" *ngIf="(activeUser | async)?.isAdmin">
              <td class="label">Burn Results</td>
              <td class="controls">
                <app-upload-button text="Upload" (changed)="uploadMacAddresses($event)" extensions=".csv" [disabled]="downloading || uploading">
                </app-upload-button>
              </td>
            </tr>
            <tr class="utility-row">
              <td class="label">QA Results</td>
              <td class="controls">
                <app-upload-button text="Upload" (changed)="uploadQaResults($event)" extensions=".csv" [disabled]="downloading || uploading">
                </app-upload-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-dialog">
    <ngx-loading [show]="uploading || downloading" [config]="{fullScreenBackdrop: true}" [template]="loadingTemplate"></ngx-loading>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="loading-banner">
    <div class="loader centered"></div>
    <div class="text">{{uploading ? 'Uploading ' : 'Preparing '}}data<br/>Please wait...</div>
  </div>
</ng-template>
<ng-template #information>
<div><b>Wi-Fi module firmware</b> - Section for providing the process of installing the firmware on Wi-Fi modules for smart devices with obtaining firmware results (modules’ MAC addresses); ensuring the process of checking the results of the firmware and receiving the results of the verification.</div>
<div class="top-space">Here you can download all the files you need in one archive file:</div>
<ol class="info-list top-space">
  <li>A firmware in “one image“ format - this firmware cannot be in the "not published" status (see section Firmware Management) and has to be 100% published.</li>
  <li>A script for the mass installation of firmware on Wi-Fi modules (massprod) - with the same firmware version</li>
  <li>A QA-utility for testing flashed Wi-Fi modules (for their performance). It is highly recommended to download the QA utility along with the utility and/or script for installing the firmware on Wi-Fi - the utility checks the firmware version against the information available from the QA utility (which was relevant for the utility and the firmware script at the time of downloading all three).</li>
</ol>
<div class="top-space">Here you can upload:</div>
<ol class="info-list top-space">
  <li>Results of Wi-Fi module firmware (produced both using the utility and in the “massprod” version) into the Platform database (to upload MAC addresses). Available only for an Administrator.</li>
  <li>Files with the results of testing the firmware installed on the modules into the Platform database (to upload results of the QA utility).</li>
</ol>
</ng-template>