import { NgModule } from '@angular/core';
import { CommonModule } from '../common/common.module';
import { TvAppsRoutingModule } from './tv-apps-routing.module';
import { TvAppsVersionsViewComponent } from './pages/tv-apps-versions-view/tv-apps-versions-view.component';

@NgModule({
  declarations: [
    TvAppsVersionsViewComponent
  ],
  imports: [
    CommonModule,
    TvAppsRoutingModule
  ]
})
export class TvAppsModule { }
