<div class="export-import-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Export/Import</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section">
    <div class="view-layout">
      <div class="panel no-padding" [formGroup]="form">
        <div class="panel-header">
          Select content for export/import
        </div>
        <div class="panel-section text-section">
          <div class="form-row">
            <app-checkbox formControlName="records">Dictionaries</app-checkbox>
          </div>
          <div class="form-row">
            <app-checkbox formControlName="images">Catalogues</app-checkbox>
          </div>
        </div>
        <div class="panel-section no-separator panel-controls">
          <button class="button primary" (click)="runDownload()" [disabled]="!allowed">Download</button>
          <div class="upload-button">
          <app-upload-button text="Upload" extensions=".zip" [disabled]="!allowed || environment.production"
              [ngbPopover]="environment.production ? disableReason : null"
              triggers="mouseenter:mouseleave" placement="bottom top" popoverClass="warning-tip"
              (changed)="runUpload($event)">
            </app-upload-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-dialog">
    <ngx-loading [show]="uploading || downloading" [config]="{fullScreenBackdrop: true}" [template]="loadingTemplate"></ngx-loading>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="loading-banner">
    <div class="loader centered"></div>
    <div class="text">{{uploading ? 'Uploading ' : 'Preparing '}}data<br/>Please wait...</div>
  </div>
</ng-template>
<ng-template #disableReason>
  <div class="tip-content">
    Data upload is disabled for ‘{{environment.environmentName}}’
  </div>
</ng-template>
<ng-template #information>
<div><b>Export/import</b> - the section where you can export or import specific data. You can see the kinds of data available for the export/import- you can choose which kind you need to export/import.</div>
<div class="top-space">Button “Download“ - for the export. After the console will generate the data file choose a directory to save the data file.</div>
<div class="top-space">Button “Upload“ - for the import.</div>
<div class="top-space"><span class="max-attention">ATTENTION:</span> Import is not available for the real product platform (“product stand”). For uploading data, you should have a data file exported (saved) before (look at the description above about the button "Download)".</div>
</ng-template>