import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { TestReportDetails, TestReportStatus } from '@models/report';
import { ReportService } from '@services/report.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { Utils } from '@services/utils';
import { Breadcrumbs } from '@models/breadcrumbs';
import { HttpParams } from '@constants/http-params';
import { DATE_TIME } from '@constants/dates';
import { Subject, finalize } from 'rxjs';

@Component({
  selector: 'app-test-report-view',
  templateUrl: './test-report-view.component.html',
  styleUrls: ['./test-report-view.component.scss']
})
export class TestReportViewComponent implements OnInit, OnDestroy {

  public testReportId: number;
  public testReport: TestReportDetails;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Test Reports', link: ['/reports', 'test-reports']}];
  public TestReportStatus = TestReportStatus;

  @ViewChild('information') information: TemplateRef<any>;
  private destroyed = new Subject<void>();

  constructor(private reportService: ReportService, private dialogService: DialogService,
      private notificationService: NotificationService, private utils: Utils, private router: Router) {}

  public ngOnInit(): void {
    this.testReportId = Number(this.utils.lookupParam(HttpParams.TEST_REPORT_ID));
    if (this.testReportId !== null) {
      this.loading = true;
      this.reportService.getTestReportById(this.testReportId).pipe(finalize(() => this.loading = false))
          .subscribe((testReport: TestReportDetails) => {
        this.testReport = testReport;
        this.breadcrumbs.push({title: testReport.fileName});
        if (!testReport.allModelsHasLog) {
          setTimeout(() => {
            this.dialogService.showModal(InformationDialogComponent, { maxWidth: '500px', data: {
                title: 'Logical JSON not set',
                text: 'Within this test report, models were found for which no logical JSON was defined. Please specify a value in the LOGIC JSON field for all models'
              }});
          }, 2000);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Test Report Card',
      text: this.information
    }});
  }

  public editTestReport(): void {
    this.router.navigate(['/reports', 'test-report', this.testReportId, 'edit'], {state: {path: this.router.url}});
  }

  public backToReports(): void {
    this.router.navigate(['/reports', 'test-reports']);
  }

  public downloadTestReport(): void {
    this.reportService.downloadTestReport(this.testReportId, this.testReport.fileName).subscribe();
  }

  public deleteTestReport(): void {
    if (this.testReport) {
      this.reportService.deleteTestReportProcedure(this.testReport).subscribe(() => {
      }, () => {
        this.notificationService.error(`Failed to delete Test Report ${this.testReport.fileName}`);
      });
    }
  }

}

