import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { WiFiModuleDetailed, WiFiModuleSession } from '@models/device-modules';
import { DeviceModulesService } from '@services/device-modules.service';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DatesRange, DATES_RANGE_TYPES, DatesRangeTypes } from '@models/dates';
import { DATE_TIME } from '@constants/dates';
import { finalize } from 'rxjs';

enum SortColumn {
  ON_TIME = 'onTime',
  OFF_TIME = 'offTime'
}

@Component({
  selector: 'app-device-module-sessions-list',
  templateUrl: './device-module-sessions-list.component.html',
  styleUrls: ['./device-module-sessions-list.component.scss']
})
export class DeviceModuleSessionsListComponent implements OnInit, OnChanges {

  @Input()
  public details: WiFiModuleDetailed;

  public range: DatesRange = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
  public sessions: Paged<WiFiModuleSession>;

  public activePage = 1;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.ON_TIME, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  constructor(private deviceModulesService: DeviceModulesService) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.range = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public updateData(resetPage = false): void {
    if (this.details) {
      this.loading = true;
      this.deviceModulesService.getDeviceWiFiModuleSessions(this.details.id,
        this.range, this.sorting, resetPage ? 1 : this.activePage)
      .pipe(finalize(() => this.loading = false))
      .subscribe((sessions: Paged<WiFiModuleSession>) => {
        this.sessions = sessions;
        this.activePage = resetPage ? 1 : this.activePage;
      });
    }
  }

}
