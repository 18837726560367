export enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video'
}

export interface MediaDescriptor {
  id: string;
  url: string;
  type?: MediaType;
}

export interface Tag {
  id: number;
  name: string;
}

export interface Attribute {
  name: string;
  value: string;
}

export interface TagView extends Tag {
  selected: boolean;
}

export interface BriefInfo {
  id: number;
  updatedAt?: Date;
}

export interface BadgesInfo {
  incompleteModels: number;
}

export interface SystemStand {
  id: number;
  name: string;
}

export interface SystemStandsInfo {
  currentStand: SystemStand;
  availableStand: Array<SystemStand>;
}