import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@constants/http-params';
import { Location } from '@angular/common';
import { TemplatesService } from '@services/templates.service';
import { DialogService } from '@services/dialog.service';
import { TemplatesItem, TemplateGroup, TemplatesItemGroup } from '@models/templates';
import { Breadcrumbs } from '@models/breadcrumbs';
import { ViewTreeNode, TreeNodeType } from '@models/tree-node';
import { Subject, forkJoin, finalize } from 'rxjs';
import { Utils } from '@services/utils';
import { DATE } from '@constants/dates';

@Component({
  selector: 'app-component-usages-view',
  templateUrl: './component-usages-view.component.html',
  styleUrls: ['./component-usages-view.component.scss']
})
export class ComponentUsagesViewComponent implements OnInit, OnDestroy {

  public componentUsages: Array<ViewTreeNode<TemplatesItemGroup, TemplatesItem>>;
  public componentInfo: TemplatesItem;
  public selectedNode: ViewTreeNode<TemplatesItemGroup, TemplatesItem>;
  public loading = false;
  public TreeNodeType = TreeNodeType;
  public breadcrumbs: Array<Breadcrumbs> = [];

  private returnData: any;

  private destroyed = new Subject<void>();

  constructor(private location: Location, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute,
      private templatesService: TemplatesService, private dialogService: DialogService, private utils: Utils) {
    this.returnData = this.router.getCurrentNavigation().extras.state;
  }

  public ngOnInit(): void {
    this.loading = true;
    let componentType = this.utils.lookupParam(HttpParams.COMPONENT_TYPE) as TreeNodeType;
    componentType = componentType && componentType.toUpperCase() as TreeNodeType;
    const componentId = Number(this.utils.lookupParam(HttpParams.COMPONENT_ID));
    if (componentType && componentId) {
      forkJoin({
        componentBase: this.templatesService.getComponentInfo(componentType, componentId),
        componentUsages: this.templatesService.getComponentUsages(componentType, componentId)
      }).pipe(finalize(() => this.loading = false)).subscribe(({componentBase, componentUsages}) => {
        this.breadcrumbs.push({title: Utils.capitalize(componentType)});
        this.componentInfo = componentBase;
        this.clearUsagesTree(componentUsages, componentBase);
        this.componentUsages = componentUsages.map((x: TemplateGroup) => {
          const result = this.buildViewTree(x);
          result.additional = this.formatInfo(x);
          return result;
        });
        this.componentUsages.forEach(x => Utils.updateTreeCounters(x));
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public navigateBack(): void {
    if (this.returnData) {
      this.router.navigate(['/' + this.returnData.path], {state: this.returnData.data});
    } else {
      this.location.back();
    }
  }

  public cancelEvent(event: MouseEvent): void {
    event.stopPropagation();
  }

  public updateNodeText(source: TemplatesItem): void {
    if (this.selectedNode) {
      Utils.updateNode(this.selectedNode, source);
    }
  }

  private clearUsagesTree(source: Array<TemplatesItemGroup>, restrict: TemplatesItem): void {
    source.forEach(x => {
      x.leafs = x.leafs.filter(y => y.id === restrict.id).map(y => restrict);
      this.clearUsagesTree(x.nodes || [], restrict);
    });
  }

  private buildViewTree(source: TemplatesItemGroup | TemplatesItem): ViewTreeNode<TemplatesItemGroup, TemplatesItem> {
    const root = source as TemplatesItemGroup;
    const terminal = !root.nodes && !root.leafs;
    const children = terminal ? null : ([] as Array<TemplatesItemGroup | TemplatesItem>)
      .concat(root.nodes || []).concat(root.leafs || []).map(x => this.buildViewTree(x));
    return {
      id: source.id,
      name: source.name,
      type: source.type,
      description: source.description,
      terminal,
      children,
      original: source
    } as ViewTreeNode<TemplatesItemGroup, TemplatesItem>;
  }

  private formatInfo(source: TemplateGroup): string {
    if (source.updatedBy) {
      return source.updatedBy + ' ' + (source.updatedAt ? this.datePipe.transform(source.updatedAt, DATE) : '');
    } else if (source.createdBy) {
      return source.createdBy + ' ' + (source.createdAt ? this.datePipe.transform(source.createdAt, DATE) : '');
    } else {
      return source.createdAt || source.updatedAt ? this.datePipe.transform(source.updatedAt || source.createdAt, DATE) : '';
    }
  }

}
