import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AuditService } from '@services/audit.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { UserActionDetailsDialogComponent }
  from '../../components/user-action-details-dialog/user-action-details-dialog.component';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DropdownItem } from '@models/dropdown';
import { DatesRange, DatesRangeTypes, DATES_RANGE_TYPES } from '@models/dates';
import { DATE_TIME } from '@constants/dates';
import { Subject, Observable, forkJoin, takeUntil, finalize, shareReplay } from 'rxjs';
import { UserAction, UserActionType, UserActionTargetType } from '@models/user-actions';

enum SortColumn {
  DATE_TIME = 'dateTime',
  AUTHOR = 'author',
  ACTION = 'action',
  TARGET_TYPE = 'targetType',
  TARGET = 'target'
}

@Component({
  selector: 'app-user-actions-view',
  templateUrl: './user-actions-view.component.html',
  styleUrls: ['./user-actions-view.component.scss']
})
export class UserActionsViewComponent implements OnInit {

  public userActions: Paged<UserAction>;
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public actionTypes: Array<DropdownItem>;
  public availableActionTypes: Array<DropdownItem>;
  public actionType: DropdownItem;
  public targetTypes: Array<DropdownItem>;
  public targetType: DropdownItem;
  public range: DatesRange = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
  public DATE_TIME_FORMAT = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.DATE_TIME, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  private destroyed = new Subject<void>();
  private ready: Observable<any>;

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private auditService: AuditService,
    private dialogService: DialogService, private notificationService: NotificationService) { }

  public ngOnInit(): void {
    this.fillActionsAndTargetTypes();
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData(false);
  }

  public search(query: string): void {
    this.query = query;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with User Actions',
      text: this.information
    }});
  }

  public showDetails(userAction: UserAction): void {
    this.dialogService.showModal(UserActionDetailsDialogComponent, { width: '600px', maxHeight: '80vh', data: {
      action: userAction
    }});
  }

  public filterActionTypes(): void {
    if (this.targetType?.value) {
      this.availableActionTypes = this.actionTypes.filter(x => !x.value || x.original.targetType === this.targetType.value);
      this.actionType = this.availableActionTypes.find(x => x.value === this.actionType?.value) ? this.actionType
        : this.availableActionTypes.find(x => !x.value);
    } else {
      this.availableActionTypes = this.actionTypes;
    }
    this.updateData();
  }

  public updateData(resetPage = true): void {
    this.loading = true;
    this.ready.subscribe(() => {
      this.auditService.getAuditUserActions(this.query, this.targetType?.value, this.actionType?.value,
            this.range, resetPage ? 1 : this.activePage, this.sorting)
          .pipe(finalize(() => this.loading = false))
          .subscribe((userActions: Paged<UserAction>) => {
        this.userActions = userActions;
        this.activePage = resetPage ? 1 : this.activePage;
      });
    });
  }

  public exportXLS(): void {
    this.loading = true;
    this.auditService.exportUserActions(this.query, this.targetType?.value, this.actionType?.value,
            this.range, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe(() => {}, (err: HttpErrorResponse) => {
      this.notificationService.error('Failed to export data');
    });
  }

  private fillActionsAndTargetTypes(): void {
    this.loading = true;
    this.ready = forkJoin({
      actionTypes: this.auditService.getAuditActionTypes(),
      targetTypes: this.auditService.getAuditTargetTypes()
    }).pipe(
      takeUntil(this.destroyed),
      shareReplay(1),
      finalize(() => this.loading = false)
    );
    this.ready.subscribe(({actionTypes, targetTypes}) => {
      this.actionTypes = [{value: null, title: 'All actions'} as DropdownItem]
        .concat((actionTypes || []).map((x: UserActionType) => ({value: x.id, title: x.name, original: x})));
      this.availableActionTypes = this.actionTypes;
      this.targetTypes = [{value: null, title: 'All target types'} as DropdownItem]
        .concat((targetTypes || []).map((x: UserActionTargetType) => ({value: x.id, title: x.name})));
    });
  }

}
