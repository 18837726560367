<div class="component-usages-view">
  <div class="layout-panel">
    <div class="left-side-panel inner-view">
      <div class="view-header with-breadcrumbs">
       <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
       <div class="view-layout align-container space-between">
         <div class="view-title">{{componentInfo?.name || '&nbsp;'}}</div>
          <a class="button primary" (click)="navigateBack()">Close</a>
        </div>
      </div>
      <div class="view-section scroll-container" *ngIf="!loading">
        <perfect-scrollbar>
          <div class="scroll-area">
            <app-tree-view [root]="componentUsages" (nodeSelected)="selectedNode = $event">
              <div class="tree-info">
                <span class="tree-label"><b>Used </b>{{componentInfo?.usedCount || 0}} times</span>
              </div>
            </app-tree-view>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="right-side-panel" (click)="cancelEvent($event)">
      <app-text-preview *ngIf="selectedNode?.type === TreeNodeType.TEXT" [hideUsagesRef]="true"
        [text]="selectedNode.original" [editable]="false" (updated)="updateNodeText($event)">
      </app-text-preview>
      <app-image-preview *ngIf="selectedNode?.type === TreeNodeType.IMAGE" [hideUsagesRef]="true"
        [image]="selectedNode.original" [editable]="false" (updated)="updateNodeText($event)">
      </app-image-preview>
      <app-directory-preview *ngIf="selectedNode && !selectedNode.type"
        [directory]="selectedNode.original" [editable]="false">
      </app-directory-preview>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
