<div class="bindings-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Wi-Fi module and device bind</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section">
    <div class="view-layout">
      <div class="panel no-padding">
        <div class="panel-header">
          Download Utility
        </div>
        <div class="panel-section text-section">
          Download and save the utility to an external device.
        </div>
        <div class="panel-section no-separator panel-controls">
          <a class="button primary" (click)="downloadUtility()">Download utility</a>
        </div>
        <div class="panel-section text-section">
          If you also need to check the relevance of MAC addresses, then download the List of MAC Addresses 
        </div>
        <div class="panel-section no-separator panel-controls">
          <a class="button secondary" (click)="downloadMacAddresses()">Download MAC Addresses </a>
        </div>
      </div>
      <div class="panel no-padding">
        <div class="panel-header">
          Upload bindings
        </div>
        <div class="panel-section text-section">
          To upload data on the bindings of Wi-Fi modules to devices into the platform in manual mode, the user uses the button “upload bindings“. Available only for Utility results.
        </div>
        <div class="panel-section no-separator panel-controls">
          <app-upload-button text="Upload bindings" (changed)="uploadMacAddresses($event)" extensions=".csv"></app-upload-button>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-dialog">
    <ngx-loading [show]="uploading || downloading" [config]="{fullScreenBackdrop: true}" [template]="loadingTemplate"></ngx-loading>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="loading-banner">
    <div class="loader centered"></div>
    <div class="text">{{uploading ? 'Uploading ' : 'Preparing '}}data<br/>Please wait...</div>
  </div>
</ng-template>
<ng-template #information>
<div><b>Wi-Fi module and device bind</b> - Section for providing the process of obtaining information about devices on which firmware Wi-Fi modules have been installed.</div>
<div class="top-space">Here you can:</div>
<ol class="info-list top-space">
  <li>Download a utility designed to collect data on the installation of a specific Wi-Fi module on a specific consumer device (a pair of data: the MAC address of the module + the serial number of the device on which this module was installed)</li>
  <li>Download the list of MAC addresses (available in the Platform database), if you want the utility to control Wi-Fi modules for whether they are their modules (is there some information about them in the Platform database)</li>
  <li>Upload information about data pairs to the Platform database. ATTENTION: it is allowed that information about data pairs can be generated not only using the above utility but, in any case, in a certain format that the Console can support for loading data into the Platform's database.</li>
</ol>
</ng-template>