import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { TestingService } from '@services/testing.service';
import { NotificationService } from '@services/notification.service';
import { DialogService } from '@services/dialog.service';
import { TestingVirtualDevice, DeviceFirmwareUpdateStatus, TestingVirtualDeviceStatus } from '@models/testing';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { DeviceUploadFirmwareDialogComponent }
  from '../../components/device-upload-firmware-dialog/device-upload-firmware-dialog.component';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { Subject, finalize, takeUntil } from 'rxjs';

enum SortColumn {
  DEVICE_MAC = 'deviceMac',
  DEVICE_TYPE = 'deviceType',
  DEVICE_MODEL = 'deviceModel',
  DEVICE_FW_VERSION = 'deviceFwVersion',
  DEVICE_STATUS = 'deviceOnline',
  PLAYER_MAC = 'playerMac',
  PLAYER_FW_VERSION = 'playerFwVersion',
  PLAYER_STATUS = 'playerOnline'
}

@Component({
  selector: 'app-virtual-devices-view',
  templateUrl: './virtual-devices-view.component.html',
  styleUrls: ['./virtual-devices-view.component.scss']
})
export class VirtualDevicesViewComponent implements OnInit, OnDestroy {

  public smartDevices: Paged<TestingVirtualDevice>;
  public activePage = 1;
  public loading = false;
  public sorting: Sorting = {column: SortColumn.DEVICE_MAC, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  private destroyed = new Subject<void>();

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private testingService: TestingService,
    private dialogService: DialogService, private notificationService: NotificationService) { }

  public ngOnInit(): void {
    this.updateData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
    this.testingService.cancelVirtualSmartDeviceMonitoring();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '600px', data: {
      title: 'Virtual Smart Devices',
      text: this.information
    }});
  }

  public uploadFirmware(virtualDevice: TestingVirtualDevice): void {
    this.dialogService.showModal(DeviceUploadFirmwareDialogComponent, { width: '500px', data: {virtualDevice}});
  }

  private updateData(): void {
    this.loading = true;
    this.testingService.getVirtualSmartDevices(this.activePage, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe((smartDevices: Paged<TestingVirtualDevice>) => {
      this.smartDevices = smartDevices;
      this.refreshTestingDevicesTracking();
    });
  }

  private refreshTestingDevicesTracking(): void {
    const trackedDevices = (this.smartDevices.content || []).filter(x => x.trackId);
    this.testingService.setVirtualSmartDevicesMonitoringOnTrack(trackedDevices)
        .pipe(takeUntil(this.destroyed)).subscribe((statusUpdate: TestingVirtualDeviceStatus) => {
      const trackedDevice = (this.smartDevices?.content || []).find(x => x.deviceMac === statusUpdate.deviceMac);
      trackedDevice && delete trackedDevice.trackId;
      if (statusUpdate.status === DeviceFirmwareUpdateStatus.COMPLETED) {
        trackedDevice.deviceFwVersion = statusUpdate.fwVersion;
        this.notificationService.success(`The firmware v. ${statusUpdate.fwVersion || 'unknown'} is sucessfully installed. 
          Device ${statusUpdate.deviceMac} is ready for the testing start`);
      } else if (statusUpdate.status === DeviceFirmwareUpdateStatus.ERROR) {
        this.notificationService.error(`Something went wrong. Failed to install firmware 
          ${statusUpdate.fwVersion || 'unknown'} on device ${statusUpdate.deviceMac}. Please try again`);
      }
    });
  }

}
