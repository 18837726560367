<div class="dialog-content model-config-edit">
  <div class="dialog-header">
    <span class="title">{{isCloneMode ? 'Clone' : 'Edit'}}</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="field-container">
      <div class="field-name">Current model</div>
      <div class="field">
        <input type="text" class="input" disabled="true" [(ngModel)]="model.customerModel"/>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">New model</div>
      <div class="field">
        <app-lookup placeholder="" [provider]="modelsProvider" [clearable]="true"
          [(ngModel)]="newModel" (ngModelChange)="updateChanges()" [disabled]="!!newBoardId"></app-lookup>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">Current Board ID</div>
      <div class="field">
        <input type="text" class="input upper-case" disabled="true" [(ngModel)]="model.boardId"/>
      </div>
    </div>
    <div class="field-container">
      <div class="field-name">New Board ID</div>
      <div class="field">
        <input type="text" class="input upper-case" maxlength="64" [(ngModel)]="newBoardId"
          (ngModelChange)="updateChanges()" [disabled]="!!newModel"/>
        <div class="validation" *ngIf="errors">
          <div *ngIf="errors.invalidBoard">Field must contain 64 latin symbols and/or numbers</div>
          <div *ngIf="errors.sameBoard && !errors.invalidBoard">Same Board ID entered</div>
        </div>
      </div>
    </div>
    
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()" [disabled]="loading || !changes || errors">{{isCloneMode ? 'Clone' : 'Save'}}</button>
  </div>
</div> 