import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { LOOKUP_DELAY, BLUR_DELAY } from '@constants/config';

const SYMBOLS_NOTIFICATION_DELAY = LOOKUP_DELAY * 2;

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SearchBarComponent),
    multi: true
  }]
})
export class SearchBarComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  public searching = false;

  @Input()
  public placeholder = 'Search';

  @Input()
  public mask: {mask: Array<string | any>} = null;

  @Input()
  public clearable = true;

  @Input()
  public searchOnInput = true;

  @Input()
  public minQueryLength = 0;

  @Input()
  public minQueryMessage = 'At least %minSymbols% symbols expected to filter data';

  @Input()
  public emptyAllowed = true;

  @Input()
  public disabled = false;

  @Input()
  public upperCase = false;

  @Input()
  public transform: (x: string) => string;

  @Output()
  public search = new EventEmitter<string>();

  @ViewChild('minQueryTip') minQueryLengthTip: NgbPopover;

  public query = '';
  public focused = false;
  public lastCommitted = '';

  private scheduledSearch = new Subject<string>();
  private destroyed = new Subject<boolean>();
  private rollbackValue: number;

  private onTouchedCallback: () => void;
  protected commit(value: string) {}

  constructor() { }

  public ngOnInit(): void {
    this.minQueryMessage = this.minQueryMessage.replace('%minSymbols%', '' + this.minQueryLength);
    const generalPipe = this.scheduledSearch.pipe(takeUntil(this.destroyed));
    generalPipe.pipe(distinctUntilChanged(), debounceTime(LOOKUP_DELAY)).subscribe((query: string) => {
      this.searchNow();
    });
    generalPipe.pipe(debounceTime(SYMBOLS_NOTIFICATION_DELAY)).subscribe((query: string) => {
      if (!this.isValidSearchQuery(query)) {
        this.minQueryLengthTip.open();
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  } 

  public scheduleSearch(): void {
    if (this.searchOnInput) {
      this.scheduledSearch.next(this.query);
    }
  }

  public searchNow(): void {
    this.minQueryLengthTip.close();
    if (this.rollbackValue) {
      clearTimeout(this.rollbackValue);
    }
    if (this.isValidSearchQuery(this.query)) {
      if (this.transform) {
        this.query = this.transform(this.query);
      }
      this.search.emit(this.query);
      this.commit(this.query);
      this.lastCommitted = this.query;
    } else {
      this.minQueryLengthTip.open();
    }
  }

  public clear(): void {
    this.query = '';
    this.searchNow();
  }

  public setFocused(focused: boolean): void {
    this.focused = focused;
    this.rollbackValue = focused ? null : setTimeout(() => {
      if (!focused && !this.isValidSearchQuery(this.query)) {
        this.query = this.lastCommitted;
      }
    }, BLUR_DELAY);
    this.minQueryLengthTip.close();
  }

  public writeValue(value: string) {
    this.query = this.lastCommitted = value;
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private isValidSearchQuery(query: string): boolean {
    return !this.minQueryLength || (query || '').length >= this.minQueryLength || !query && this.emptyAllowed;
  }

}
