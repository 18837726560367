<div class="virtual-devices-view inner-view">
  <div class="view-header">
    <h1 class="view-header align-container small-spacing">
      <span>Virtual Smart Devices</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_MAC" (sort)="setSorting($event)">
                Device Mac
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_TYPE" (sort)="setSorting($event)">
                Device Type
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_MODEL" (sort)="setSorting($event)">
                Device Model
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_FW_VERSION" (sort)="setSorting($event)">
                Device Fw Version
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_STATUS" (sort)="setSorting($event)">
                Device Status
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.PLAYER_MAC" (sort)="setSorting($event)">
                Player Mac
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.PLAYER_FW_VERSION" (sort)="setSorting($event)">
                Player Fw Version
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.PLAYER_STATUS" (sort)="setSorting($event)">
                Player Status
              </app-sortable-header> 
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of smartDevices?.content">
            <td class="upper-case">{{row.deviceMac || '&mdash;'}}</td>
            <td>{{row.deviceType || '&mdash;'}}</td>
            <td>{{row.deviceModel || '&mdash;'}}</td>
            <td>{{row.deviceFwVersion || '&mdash;'}}</td>
            <td class="by-content">
              <div class="activity-status" [class.active]="row.deviceOnline === true" [class.inactive]="row.deviceOnline === false">
                {{row.deviceOnline ? 'Online' : 'Offline'}}
              </div>
            </td>
            <td class="upper-case">{{row.playerMac || '&mdash;'}}</td>
            <td>{{row.playerFwVersion || '&mdash;'}}</td>
            <td class="by-content">
              <div class="activity-status" [class.active]="row.playerOnline === true" [class.inactive]="row.playerOnline === false">
                {{row.playerOnline ? 'Online' : 'Offline'}}
              </div>
            </td>
            <td class="by-content"><button class="button primary" (click)="uploadFirmware(row)" [disabled]="row.trackId || !row.deviceOnline">Upload Firmware</button>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!smartDevices || !smartDevices?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="smartDevices && smartDevices.totalPages > 1">
      <div class="text-info">Showing {{smartDevices.startElement}} to {{smartDevices.endElement}} of {{smartDevices.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="smartDevices.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Virtual Smart Devices</b> – section where user can upload a <b>firmware</b> that will be used for testing a <b>Virtual Smart Device</b>.</div>
  <div class="top-space"><b>Uploading firmware</b> is available by tapping an appropriate button “<u>Upload Firmware</u>” and selecting a needed one from the list.</div>
</ng-template>