<div class="records-view inner-view">
  <div class="view-header">
    <h1 class="view-header align-container small-spacing">
      <span>Records</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section search-block">
    <div class="align-container space-between">
      <div class="search-field">
        <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search model"></app-search-bar>
      </div>
      <button class="button-add primary" (click)="addRecord()">Add Record</button>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ID" (sort)="setSorting($event)">
                Id
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_MODEL" (sort)="setSorting($event)">
                Device Model
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.FW_VERSION" (sort)="setSorting($event)">
                Fw Version
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.IDENT_CODE" (sort)="setSorting($event)">
                Ident Code
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STARTED" (sort)="setSorting($event)">
                Started
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ENDED" (sort)="setSorting($event)">
                Ended
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header> 
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of records?.content">
            <td>{{row.id || '&mdash;'}}</td>
            <td>{{row.deviceModel || '&mdash;'}}</td>
            <td>{{row.fwVersion || '&mdash;'}}</td>
            <td class="ident-code">{{row.identificationCode || '&mdash;'}}</td>
            <td>{{row.started ? (row.started | date:DATE_TIME) : '&mdash;'}}</td>
            <td>{{row.ended ? (row.ended | date:DATE_TIME) : '&mdash;'}}</td>
            <td class="by-content">
              <div class="no-status" *ngIf="!row.status">&mdash;</div>
              <div class="testing-status" [ngClass]="'status-' + row.status" *ngIf="row.status">{{row.status | capitalize}}</div>
            </td>
            <td class="button-column"><a class="button button-view" [routerLink]="['/testing', 'record', row.id]"></a>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!records || !records?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="records && records.totalPages > 1">
      <div class="text-info">Showing {{records.startElement}} to {{records.endElement}} of {{records.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="records.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Records</b> – section for managing records of the device behavior – device responses to the commands.</div>
  <div class="top-space">In this section the user is able to:</div>
  <ol class="top-space">
    <li>Add a new record for the device</li>
    <li>Find a specific record in the list by search box</li>
  </ol>
  <div class="top-space">Use button&nbsp;&nbsp;<button class="button-view"></button>&nbsp;&nbsp;to view detailed information about record.</div>
  <div class="top-space">For adding a new record user should:</div>
  <ol class="top-space">
    <li>Tap “<u>Add Record</u>” button</li>
    <li>Choose a needed device for which he wants to create a record – searching by MAC address</li>
    <li>Tap “<u>Update Firmware</u>” button</li>
    <li>Tap “<u>Run</u>” button</li>
  </ol>
  <div class="top-space">If a record was successfully added - a new record page would be displayed to the user with the process of recording. In record page user is able to install the latest firmware using an appropriate button “Install Latest Firmware” – only after the process of recording is completed.</div>
</ng-template>