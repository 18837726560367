import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { DeviceConfigsService } from '@services/device-configs.service';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { Utils } from '@services/utils';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ModelConfigEditDialogComponent } from '../../components/model-config-edit-dialog/model-config-edit-dialog.component';
import { ModificationEditDialogComponent } from '../../components/modification-edit-dialog/modification-edit-dialog.component';
import { ModelConfig, ModelConfigView, CreateModelConfigResponse } from '@models/device-configs';
import { DeviceTypeSimple, DeviceTypesAndModels } from '@models/device-models';
import { DropdownItem } from '@models/dropdown';
import { ModelConfigEditDialogData, ModelConfigurationEditDialogData } from '../../models/dialog-data';
import { Paged } from '@models/pageable';
import { SortOrder, Sorting } from '@models/sorting';
import { ConfirmDialogIcon } from '@models/dialogs';
import { DATE } from '@constants/dates';
import { HttpErrorResponse } from '@angular/common/http';
import { CONFLICT, EXPECTATION_FAILED, UNSUPPORTED, NOT_FOUND, HttpParams } from '@constants/http-params';
import { Observable, Subject, takeUntil, finalize, shareReplay } from 'rxjs';

enum SortColumn {
  NAME = 'fileName',
  DEVICE_TYPE = 'deviceType',
  MODEL = 'model',
  ACTIVE = 'active',
  CREATED = 'createdAt'
}

@Component({
  selector: 'app-model-config-view',
  templateUrl: './model-config-view.component.html',
  styleUrls: ['./model-config-view.component.scss']
})
export class ModelConfigViewComponent implements OnInit, OnDestroy {

  public configs: Paged<ModelConfigView>;
  public query: string = '';
  public activeOnly = true;
  public deviceTypesItems: Array<DropdownItem> = [];
  public deviceType: DropdownItem = null;
  public typesAndModels: DeviceTypesAndModels;
  public activePage = 1;
  public loading = false;
  public progressing = false;
  public sorting: Sorting = {column: SortColumn.CREATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public DATE_FORMAT = DATE;

  @ViewChild('information') information: TemplateRef<any>;
  @ViewChild('file') file: ElementRef;

  private deviceTypes: Array<DeviceTypeSimple>;
  private destroyed = new Subject<void>();
  private dialogDataReady: Observable<any>;
  private transferFiles: Array<File>;
  private transferFileIndex = 0;
  private transferCount = 0;

  constructor(private configsService: DeviceConfigsService, private modelsService: DeviceModelsService,
    private dialogService: DialogService, private notificationService: NotificationService, private utils: Utils) { }

  public ngOnInit(): void {
    this.query = this.utils.lookupParam(HttpParams.QUERY) || '';
    this.fillDeviceTypes();
    this.updateData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Model JSON-configs',
      text: this.information
    }});
  }

  public updateData(resetPage = false): void {
    this.loading = true;
    this.configsService.getModelConfigs(this.query, this.deviceType?.value,
      this.activeOnly, this.sorting, resetPage ? 1 : this.activePage)
        .pipe(finalize(() => this.loading = false)).subscribe((configs: Paged<ModelConfig>) => {
      this.configs = configs;
      this.configs.content.forEach((modelConfig: ModelConfig) => this.setActions(modelConfig));
      this.activePage = resetPage ? 1 : this.activePage;
    });
  }

  // @Deprecated
  public editModelConfig(modelConfig: ModelConfig, withClone: boolean): void {
    const data = {modelId: modelConfig.modelId, configId: modelConfig.id, cloneMode: withClone} as ModelConfigEditDialogData;
    this.dialogService.showModal(ModelConfigEditDialogComponent, { width: '500px', data})
        .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: ModelConfig) => {
      if (result) {
        if (withClone) {
          this.updateData();
        } else {
          Object.assign(modelConfig, result);
        }
      }
    });
  }

  // @Deprecated
  public deleteModelConfig(modelConfig: ModelConfig): void {
    this.progressing = true;
    this.configsService.deleteModelConfig(modelConfig.id, false).pipe(finalize(() => this.progressing = false)).subscribe(() => {
      this.notificationService.success('Model Config successfull deleted');
      this.updateData();
    }, (error: HttpErrorResponse) => {
      if (error.status === CONFLICT) {
        this.dialogService.showModal(ConfirmationDialogComponent, { width: '500px', data: {
          title: 'Delete JSON-config',
          text: `You are about to delete active JSON-config file which is probably used to ensure some smart devices work.
                 <br/>Are you sure you want to delete this JSON-config?`,
          icon: ConfirmDialogIcon.WARNING,
          agreeButtonText: 'Yes, delete',
          cancelButtonText: 'Close'
        }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result) => {
          if (result) {
            this.configsService.deleteModelConfig(modelConfig.id, true).pipe(finalize(() => this.progressing = false)).subscribe(() => {
              this.notificationService.success('Model Config successfull deleted');
              this.updateData();
            }, (error: HttpErrorResponse) => {
              this.notificationService.error('Failed to Delete Model Config');
              console.error(error);
            });
          }
        });
      } else {
        this.notificationService.error('Failed to Delete Model Config');
        console.error(error);
      }
    });
  }

  public createModification(modelConfig: ModelConfig): void {
    const data = {model: modelConfig.model, typesAndModels: this.typesAndModels} as ModelConfigurationEditDialogData;
    this.dialogService.showModal(ModificationEditDialogComponent, { width: '800px', data})
        .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.updateData();
      }
    });
  }

  public selectModelFile(): void {
    this.file.nativeElement.click();
  }

  public fileSelected(event: Event): void {
    this.progressing = true;
    // @ts-ignore
    this.transferFiles = event.target.files;
    if (!!this.transferFiles?.length) {
      this.transferFileIndex = this.transferCount = 0;
      this.transferNextFile(false, false);
    }
  }

  private fillDeviceTypes(): void {
    this.dialogDataReady = this.modelsService.getDeviceTypesAndModels().pipe(shareReplay(1));
    this.dialogDataReady.subscribe((typesAndModels: DeviceTypesAndModels) => {
      this.deviceTypes = typesAndModels.types;
      this.typesAndModels = typesAndModels;
      this.deviceTypesItems = [{title: 'All Device Types', value: null}]
        .concat(this.deviceTypes.map((x: DeviceTypeSimple) => ({value: x.id, title: x.name})));
      this.deviceType = this.deviceTypesItems[0];
    });
  }

  private downloadModelConfig(modelConfig: ModelConfig): void {
    this.utils.downloadFile(modelConfig.presignedUrl, true);
  }

  private transferNextFile(prevSuccess = true, doShift = true): void {
    this.transferCount += (prevSuccess ? 1 : 0);
    this.transferFileIndex += (doShift ? 1 : 0);
    if (this.transferFileIndex === this.transferFiles.length) {
      this.progressing = false;
      if (this.transferCount > 0) {
        if (this.transferCount === this.transferFiles.length) {
          this.notificationService.success('Configs are successfully uploaded.');
        } else {
          this.notificationService.success('Configs are partially successfully uploaded.');
        }
      }
      this.file.nativeElement.value = '';
      this.updateData();
      return;
    }
    const files = this.transferFiles;
    const index = this.transferFileIndex;
    this.configsService.createModelConfig(files[index], false).subscribe((response: CreateModelConfigResponse) => {
      if (!response || response.configSaved) {
        this.transferNextFile(!!response);
      } else {
        const models = (response.models || []).map(x => (x.deviceType || '-') + ' / ' + (x.model || '-')).join(', ');
        this.dialogService.showModal(ConfirmationDialogComponent, { maxWidth: '700px', data: {
          title: 'Config already exists',
          text: `You are going to upload <b>${files[index].name}</b>.
            JSON-config files with the same names are already exist and used for the next
            devices models: <b>${models}</b>.<br/><br/>
            If you click “Continue“ the system will have one more JSON-config file with the same name
            used for different Device Models.`,
          icon: ConfirmDialogIcon.WARNING,
          agreeButtonText: 'Continue'
        }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result) => {
          if (result) {
            this.configsService.createModelConfig(files[index], true).subscribe((response: CreateModelConfigResponse) => {
              if (response.configSaved) {
                this.transferNextFile();
              } else {
                this.dialogService.showModal(InformationDialogComponent, { data: {
                  title: 'Invalid Config file',
                  text: `File ${files[index].name} is broken or has wrong format, unable to detect device model to bind configuration to`,
                  icon: ConfirmDialogIcon.WARNING,
                  agreeButtonText: 'Continue'
                }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe(() => this.transferNextFile(false));
              }
            }, (error: HttpErrorResponse) => {
              this.showError(files[index], error);
            });
          } else {
            this.transferNextFile(false);
          }
        });
      }
    }, (error: HttpErrorResponse) => {
      this.showError(files[index], error);
    });
  }

  private showError(file: File, error?: HttpErrorResponse): void {
    let text;
    const ending = `Please, check file or contact the platform administrator.`;
    const baseMessage = `Uploading config file ${file.name} failed, try again later.`;
    if (error?.status === NOT_FOUND) {
      text = error.error?.message || `${baseMessage} ${ending}`;
    } else if (error?.status === UNSUPPORTED) {
      text = `${baseMessage} Sorry, you can add a file only one of these file formats: JSON, TXT. ${ending}`;
    } else if (error?.status === EXPECTATION_FAILED) {
      text = error.error?.message || `${baseMessage} Sorry, your JSON file is broken and has invalid structure. ${ending}`;
    } else {
      text = `${baseMessage} ${ending}`;
    }
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '500px', data: {
      title: 'Error in config processing',
      text: (text || '').replace(/\n/g, '<br>'),
      icon: ConfirmDialogIcon.WARNING,
      agreeButtonText: 'Continue'
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe(() => this.transferNextFile(false));
  }

  private setActions(modelConfig: ModelConfigView): ModelConfigView {
    modelConfig.actions = [{
      title: 'Download',
      action: () => this.downloadModelConfig(modelConfig)
    }];
    if (modelConfig.active) {
      modelConfig.actions.push({
        title: 'Create modification',
        action: () => this.createModification(modelConfig)
      });
    }
    return modelConfig;
  }


}
