<header class="header">
  <div class="user-info-container">
    <div class="user-icon" *ngIf="activeUser?.imgUrl" [ngStyle]="{'background-image': 'url(' + async?.imgUrl + ')'}"></div>
    <div class="user-icon default-user-icon" *ngIf="!activeUser?.imgUrl"></div>
    <div class="user-name" *ngIf="activeUser?.firstName || activeUser?.lastName">
      <div class="user-first-name">{{activeUser.firstName}}</div>
      <div class="user-last-name">{{activeUser.lastName}}</div>
    </div>
    <div class="user-name" *ngIf="activeUser && !(activeUser.firstName || activeUser.lastName)">
      <div class="user-login-name">{{activeUser.userName}}</div>
    </div>
    <div class="logout-button" (click)="logout()"></div>
  </div>
</header>