import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { JsonNodeGroup, JsonNodeType } from '@models/tree-node';
import { DATE_TIME } from '@constants/dates';

@Component({
  selector: 'app-static-tree',
  templateUrl: './static-tree.component.html',
  styleUrls: ['./static-tree.component.scss']
})
export class StaticTreeComponent implements OnInit {

  @Input()
  public json: JsonNodeGroup;

  @Input()
  public heading: TemplateRef<any>;

  public JsonNodeType = JsonNodeType;
  public DATE_TIME = DATE_TIME;

  constructor() { }

  public ngOnInit(): void {
  }

}
