import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DeviceModuleEditDialogData } from '../../models/dialog-data';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceModelSimple } from '@models/device-models';
import { WiFiModule } from '@models/device-modules';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { DeviceModulesService } from '@services/device-modules.service';
import { DeviceModelsService } from '@services/device-models.service';
import { NotificationService } from '@services/notification.service';
import { Subject, Observable, of, takeUntil, finalize } from 'rxjs';
import { POSITION_BELOW } from '@constants/overlay-position';

const SERIAL_REGEXP = /^[a-zA-Z0-9]{11,20}$/;

@Component({
  selector: 'app-device-module-edit-dialog',
  templateUrl: './device-module-edit-dialog.component.html',
  styleUrls: ['./device-module-edit-dialog.component.scss']
})
export class DeviceModuleEditDialogComponent implements OnInit, OnDestroy {

  public modelsProvider: LookupProvider;
  public newModel: DropdownItem = null;
  public lookupModels: Array<DeviceModelSimple>;
  public lookupShown = false;
  public badgeShown = false;
  public newSerialNumber: string = '';
  public invalidSerial = false;
  public allowedToProceed = false;
  public positions = POSITION_BELOW;

  @ViewChild('badge') badgeTip: NgbPopover;
  @ViewChild('tip') helpTip: NgbPopover;

  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceModuleEditDialogData,
    private dialogRef: MatDialogRef<DeviceModuleEditDialogComponent>,
    private deviceModulesService: DeviceModulesService,
    private deviceModelsService: DeviceModelsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    const models = this.data.models.models
      .filter(x => x.id !== this.data.deviceModule.deviceModelId)
      .map((x: DeviceModelSimple) => ({value: x.id, title: x.name} as DropdownItem));
    this.modelsProvider = {
      search: (query: string): Observable<Array<DropdownItem>> => {
        return of(models.filter(x => x.title.toUpperCase().includes(query.toUpperCase())));
      }
    };
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public validate(): void {
    this.invalidSerial = this.newSerialNumber && !SERIAL_REGEXP.test(this.newSerialNumber);
    const sameSerial = this.newSerialNumber === this.data.deviceModule.serialNumber;
    const sameModel = this.newModel?.value === this.data.deviceModule.deviceModelId;
    this.allowedToProceed = !!this.newModel && !(sameSerial && sameModel) &&
      (!this.newSerialNumber && !!this.data.deviceModule.serialNumber || !this.invalidSerial);
  }

  public lookupModelsBySn(): void {
    this.lookupModels = null;
    this.deviceModelsService.lookupModelsBySn(this.newSerialNumber).subscribe((models: Array<DeviceModelSimple>) => {
      if (models && models.length) {
        if (models.length === 1) {
          this.newModel = {value: models[0].id, title: models[0].name};
        } else {
          this.lookupModels = models;
        }
      } else {
        this.showNothingFound();
      }
      this.validate();
    }, (err: HttpErrorResponse) => {
      this.showNothingFound();
    });
  }

  public selectLookupModel(model: DeviceModelSimple): void {
    this.newModel = {value: model.id, title: model.name};
    this.lookupModels = null;
    this.validate();
  }

  public closeLookup(): void {
    this.lookupModels = null;
  }

  public showNothingFound(): void {
    this.helpTip && this.helpTip.close();
    setTimeout(() => {
      this.badgeTip && this.badgeTip.open();
      this.badgeShown = true;
      setTimeout(() => {
        this.badgeTip && this.badgeTip.close();
        this.badgeShown = false;
        const element = (this.helpTip as any)?._elementRef?.nativeElement;
        if (element && element.parentElement.querySelector(':hover') === element) {
          setTimeout(() => {
            this.helpTip && this.helpTip.open();
          }, 300);
        }
      }, 3000);
    }, 300);
  }

  public proceed(): void {
    const wifiModule = this.data.deviceModule;
    this.deviceModulesService.updateDeviceWiFiModule(wifiModule.macAddress, this.newModel?.value,
        this.newSerialNumber || wifiModule.serialNumber).subscribe((result: WiFiModule) => {
      this.notificationService.success(`Module ${wifiModule.macAddress} successfully updated`);
      this.dialogRef.close(result);
    }, (error) => {
      this.notificationService.error(`Failed to update module ${wifiModule.macAddress}`);
      this.dialogRef.close(null);
    });
  }
}
