export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
  NONE = 'none'
}

export interface Sorting {
  column: string;
  order: SortOrder;
}

export function switchSort(column: string, current?: Sorting): Sorting {
  if (current && current.column === column) {
    if (current.order === SortOrder.ASC) {
      return {column, order: SortOrder.DESC};
    } else if (current.order === SortOrder.DESC) {
      return {column, order: SortOrder.NONE};
    }
  }
  return {column, order: SortOrder.ASC};
}

export function toggleSort(column: string, current?: Sorting): Sorting {
  if (current && current.column === column) {
    if (current.order === SortOrder.ASC) {
      return {column, order: SortOrder.DESC};
    }
  }
  return {column, order: SortOrder.ASC};
}