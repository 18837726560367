import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '@services/notification.service';
import { DeviceModulesService } from '@services/device-modules.service';
import { DeviceModuleDialogPermissionDialogData } from '../../models/dialog-data';
import { WiFiModuleDiagnosticPermissionRequest } from '@models/device-modules';
import { DropdownItem } from '@models/dropdown';
import { MAC_ADDRESS_FORMAT, MAC_ADDRESS_TEXT_MASK } from '@constants/main';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-diagnostic-permission-dialog',
  templateUrl: './diagnostic-permission-dialog.component.html',
  styleUrls: ['./diagnostic-permission-dialog.component.scss']
})
export class DiagnosticPermissionDialogComponent implements OnInit, OnDestroy {

  public macAddress = '';
  public macAddressLocked = false;
  public macAddressChanged = false;
  public macAddressIncomplete = false;
  public ownersLoading = false;
  public owner: DropdownItem;
  public owners: Array<DropdownItem>;
  public duration: DropdownItem;
  public durations: Array<DropdownItem> = new Array(24).fill(1).map((x, i) => ({title: (i + 1) + 'h', value: i + 1}));
  public askAllOwners = false;
  public macAddressMask = {mask: MAC_ADDRESS_TEXT_MASK, guide: false, showMask: false};

  private destroyed: Subject<void> = new Subject<void>();

  constructor(@Inject(MAT_DIALOG_DATA) private dialogData: DeviceModuleDialogPermissionDialogData,
    private dialogRef: MatDialogRef<DiagnosticPermissionDialogComponent>,
    private notificationService: NotificationService, private deviceModulesService: DeviceModulesService) { }

  public ngOnInit(): void {
    if (this.dialogData.macAddress) {
      this.macAddress = this.dialogData.macAddress;
      this.macAddressLocked = true;
      this.updateOwners();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public onMacAddressChanged(): void {
    this.macAddressChanged = true;
    this.updateOwners();
  }

  public updateOwners(): void {
    this.macAddressIncomplete = !MAC_ADDRESS_FORMAT.test(this.macAddress);
    if (!this.macAddressIncomplete) {
      this.deviceModulesService.getDeviceOwners(this.macAddress.toLowerCase()).subscribe((result: Array<string>) => {
        this.owners = (result || []).map(x => ({value: x, title: x}));
        this.owner = this.owners.find(x => x.value === this.owner);
        this.macAddressChanged = false;
      }, (err: HttpErrorResponse) => this.resetOwners());
    } else {
      this.resetOwners();
    }
  }

  public resetOwners(): void {
    this.owners = [];
    this.resetOwner();
  }

  public resetOwner(): void {
    this.owner = null;
  }

  public createPermission(): void {
    const permissionRequest: WiFiModuleDiagnosticPermissionRequest = {mac: this.macAddress.toLowerCase(),
      ownerId: this.owner?.value || null, duration: this.duration.value, showAll: this.askAllOwners};
    this.deviceModulesService.createDiagnosticRequest(permissionRequest).pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.notificationService.success('Permission request was successfully added');
      this.dialogRef.close(true);
    }, (err: HttpErrorResponse) => {
      this.notificationService.error('Something went wrong. Please try again');
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

}
