import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserInfoService } from '@services/user-info.service';
import { UserInfo } from '@models/user-info';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  public activeUser: UserInfo;
  private subscription: Subscription;

  constructor(private userService: UserInfoService) { }

  public ngOnInit(): void {
    this.subscription = this.userService.getActiveUser().subscribe((userInfo: UserInfo) => {
      this.activeUser = userInfo;
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public logout(): void {
    this.userService.logout();
  }

}
