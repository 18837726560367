export enum RequestQuestions {
  WITHOUT_QUESTION = 'WITHOUT_QUESTION',
  DEACTIVATE_ANOTHER_TEST_REPORT = 'DEACTIVATE_ANOTHER_TEST_REPORT',
  ANOTHER_TEST_REPORT_EXISTS_DEACTIVATE_ANOTHER_TEST_REPORT = 'ANOTHER_TEST_REPORT_EXISTS_DEACTIVATE_ANOTHER_TEST_REPORT',
  LAST_ACTIVE_REPORT_NOT_FOUND_DELETE_REPORT_ANYWAY = 'LAST_ACTIVE_REPORT_NOT_FOUND_DELETE_REPORT_ANYWAY',
  JSON_LIST_NOT_EQUALS_DELETE_REPORT_A_AND_ACTIVATE_B = 'JSON_LIST_NOT_EQUALS_DELETE_REPORT_A_AND_ACTIVATE_B',
  JSON_LIST_NOT_EQUALS_DEACTIVATE_REPORT_A_AND_ACTIVATE_B = 'JSON_LIST_NOT_EQUALS_DEACTIVATE_REPORT_A_AND_ACTIVATE_B',
  MOVE_LATEST_REPORT_TO_ACTIVE = 'MOVE_LATEST_REPORT_TO_ACTIVE',
  SAVE_IN_TARGET_GROUP_OR_CREATE_NEW = 'SAVE_IN_TARGET_GROUP_OR_CREATE_NEW',
}

export enum RequestAnswers {
  YES = 'YES',
  NO = 'NO',
  DELETE_REPORT_A = 'DELETE_REPORT_A',
  DEACTIVATE_REPORT_A = 'DEACTIVATE_REPORT_A',
  DEACTIVATE_REPORT_A_AND_ACTIVATE_B = 'DEACTIVATE_REPORT_A_AND_ACTIVATE_B',
  DELETE_REPORT_A_AND_ACTIVATE_B = 'DELETE_REPORT_A_AND_ACTIVATE_B',
  CREATE_NEW = 'CREATE_NEW',
  UPDATE_CURRENT = 'UPDATE_CURRENT'
}

export enum RequestMessages {
  TARGET_TEST_REPORT_ACTIVATED_ANOTHER_DEACTIVATED = 'TARGET_TEST_REPORT_ACTIVATED_ANOTHER_DEACTIVATED',
  REPORT_WAS_DEACTIVATED = 'REPORT_WAS_DEACTIVATED',
  LAST_ACTIVE_REPORT_NOT_FOUND_REPORT_A_DEACTIVATED = 'LAST_ACTIVE_REPORT_NOT_FOUND_REPORT_A_DEACTIVATED',
  REPORT_A_WAS_DEACTIVATED_REPORT_B_WAS_ACTIVATED = 'REPORT_A_WAS_DEACTIVATED_REPORT_B_WAS_ACTIVATED',
  REPORT_WAS_DEACTIVATED_REQUIRED_UPLOAD_NEW_REPORT = 'REPORT_WAS_DEACTIVATED_REQUIRED_UPLOAD_NEW_REPORT',
  REPORT_WAS_DEACTIVATED_LAST_ACTIVE_REPORT_ACTIVATED = 'REPORT_WAS_DEACTIVATED_LAST_ACTIVE_REPORT_ACTIVATED',
  NEW_MODEL_GROUP_CREATED = 'NEW_MODEL_GROUP_CREATED',
  MODEL_GROUP_UPDATED = 'MODEL_GROUP_UPDATED',
  REPORT_WAS_DELETED = 'REPORT_WAS_DELETED',
  REPORT_A_WAS_DELETED_REPORT_B_WAS_ACTIVATED = 'REPORT_A_WAS_DELETED_REPORT_B_WAS_ACTIVATED',
  REPORT_WAS_DELETED_REQUIRED_UPLOAD_NEW_REPORT = 'REPORT_WAS_DELETED_REQUIRED_UPLOAD_NEW_REPORT',
  REPORT_WAS_DELETED_LAST_ACTIVE_REPORT_ACTIVATED = 'REPORT_WAS_DELETED_LAST_ACTIVE_REPORT_ACTIVATED',
  TARGET_TEST_REPORT_ACTIVATED = 'TARGET_TEST_REPORT_ACTIVATED'
}

export interface RequestQuestion {
  question: RequestQuestions;
  params: string[];
}

export interface RequestMessage {
  resultMessage: RequestMessages;
  params: string[];
}

export interface RequestAnswer {
  question: RequestQuestions,
  answer: RequestAnswers
}

export interface RequestResult {
  cancel: boolean;
  success: boolean;
  details?: string;
}

