import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModelConfigEditDialogData } from '../../models/dialog-data';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { DeviceModelsService } from '@services/device-models.service';
import { DeviceConfigsService } from '@services/device-configs.service';
import { NotificationService } from '@services/notification.service';
import { DeviceModel } from '@models/device-models';
import { Subject, Observable, of, takeUntil, finalize } from 'rxjs';

export const BOARD_ID_REGEXP = /^[a-zA-Z0-9]{1,64}$/;

@Component({
  selector: 'app-model-config-edit-dialog',
  templateUrl: './model-config-edit-dialog.component.html',
  styleUrls: ['./model-config-edit-dialog.component.scss']
})
export class ModelConfigEditDialogComponent implements OnInit, OnDestroy {

  public isCloneMode = false;
  public modelsProvider: LookupProvider;
  public model: DeviceModel;
  public newModel: DropdownItem = null;
  public newBoardId: string = '';
  public changes = false;
  public loading = false;
  public errors: any = null;

  private destroyed = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModelConfigEditDialogData,
    private dialogRef: MatDialogRef<ModelConfigEditDialogComponent>,
    private deviceModelsService: DeviceModelsService,
    private configsService: DeviceConfigsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.isCloneMode = this.data.cloneMode;
    this.loading = true;
    this.modelsProvider = {search: (query: string): Observable<Array<DropdownItem>> => {
      return this.loading ? of([])
        : this.configsService.lookupConfigAcceptableModels(this.model.id, query);
    }};
    this.deviceModelsService.getDeviceModelById(this.data.modelId)
        .pipe(takeUntil(this.destroyed), finalize(() => this.loading = false))
        .subscribe((model: DeviceModel) => {
      this.model = model;
    });      
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public updateChanges(): void {
    if (this.newBoardId) {
      this.newModel = null;
    } else if (this.newModel) {
      this.newBoardId = '';
    }
    const sameBoard = this.newBoardId === this.model.boardId;
    const invalidBoard = !BOARD_ID_REGEXP.test(this.newBoardId);
    this.changes = !!this.newModel || (this.newBoardId && !invalidBoard);
    const errors = {...(sameBoard ? {sameBoard} : null), ...(invalidBoard ? {invalidBoard} : null)};
    this.errors = !this.newBoardId || Object.keys(errors).length === 0 ? null : errors;
  }

  public resetErrors(): void {
    this.errors = null;
  }

  public proceed(): void {
    this.configsService.updateModelConfig(this.isCloneMode, this.data.configId, this.newModel?.value, this.newBoardId)
        .pipe(takeUntil(this.destroyed), finalize(() => this.loading = false))
        .subscribe((configUpdateResult: any) => {
      const action = this.isCloneMode ? 'cloned' : 'updated';
      this.notificationService.success(`Model config successfully ${action}`);
      this.dialogRef.close(true);
    }, (error) => {
      const action = this.isCloneMode ? 'clone' : 'update';
      this.notificationService.error(`Failed to ${action} model config`);
      this.dialogRef.close(false);
    });
  }

}
