import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { ModelModificationView } from '@models/config-modifications';

@Component({
  selector: 'app-config-patch-results',
  templateUrl: './config-patch-results.component.html',
  styleUrls: ['./config-patch-results.component.scss']
})
export class ConfigPatchResultsComponent implements OnChanges {

  @Input()
  public results: Array<ModelModificationView>;

  @Input()
  public validationPhase = true;

  @Input()
  public removingAllowed = true;

  @Output()
  public modelRemoved = new EventEmitter<ModelModificationView>();

  @Output()
  public detailsRequested = new EventEmitter<ModelModificationView>();

  public valid = false;
  public validCount = 0;
  public invalidCount = 0;
  public activeModel: ModelModificationView;

  public ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  public update(): void {
    this.valid = (this.results || []).every(model => model.ok);
    this.validCount = (this.results || []).filter(model => model.ok).length;
    this.invalidCount = (this.results || []).length - this.validCount;
  }

  public remove(line: ModelModificationView): void {
    if (this.removingAllowed) {
      this.modelRemoved.emit(line);
    }
  }

  public viewDetails(line: ModelModificationView): void {
    this.detailsRequested.emit(line);
  }

}
