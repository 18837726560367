<div class="dialog-content">
  <div class="dialog-header">
    <div class="title-container">
      <div class="title">{{data.title}}</div>
    </div>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="info" [innerHTML]="data.text"></div>
  </div>
  <div class="dialog-footer align-container space-between">
    <div class="left-buttons align-container mid-spacing">
      <button *ngFor="let button of leftSideButtons" [class]="button.class || ['primary']" (click)="emit(button)">{{button.text}}</button>
    </div>
    <div class="right-buttons align-container mid-spacing">
      <button *ngFor="let button of rightSideButtons" [class]="button.class || ['primary']" (click)="emit(button)">{{button.text}}</button>
    </div>
  </div>
</div>
