import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MovetoDialogData } from '@models/dialogs';
import { ViewTreeNode } from '@models/tree-node';

@Component({
  selector: 'app-moveto-dialog',
  templateUrl: './moveto-dialog.component.html',
  styleUrls: ['./moveto-dialog.component.scss']
})
export class MovetoDialogComponent implements OnInit {

  public preparedTree: ViewTreeNode<any, any>;
  public selectedNode: ViewTreeNode<any, any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: MovetoDialogData,
    private dialogRef: MatDialogRef<MovetoDialogComponent>
  ) {}

  public ngOnInit(): void {
    /*this.preparedTree = Utils.excludeViewTreeNode(Utils.copyTree(this.data.root, true, true), this.data.source);
    this.selectedNode = Utils.findParent(this.data.root, this.data.source);*/
  }

  public selectNode(node: ViewTreeNode<any, any>): void {
    this.selectedNode = node;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public proceed(): void {
    this.dialogRef.close(this.selectedNode);
  }

}
