import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpParams } from '@constants/http-params';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplatesService } from '@services/templates.service';
import { DialogService } from '@services/dialog.service';
import { TemplatesItem, TemplatesItemGroup, TemplateGroup } from '@models/templates';
import { Breadcrumbs } from '@models/breadcrumbs';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { TreeNode, ViewTreeNode, TreeNodeType } from '@models/tree-node';
import { Subject, finalize } from 'rxjs';
import { Utils } from '@services/utils';
import { DATE } from '@constants/dates';

@Component({
  selector: 'app-template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class PageTemplateViewComponent implements OnInit, OnDestroy {

  public templateTree: ViewTreeNode<TemplatesItemGroup, TemplatesItem>;
  public template: TemplateGroup;
  public selectedNode: ViewTreeNode<TemplatesItemGroup, TemplatesItem>;
  public loading = false;
  public TreeNodeType = TreeNodeType;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Pages Templates', link: ['/mobile-backend', 'templates']}];

  private activatedNode: TreeNode;
  private query: string = '';
  private destroyed = new Subject<void>();

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute, private utils: Utils,
      private templatesService: TemplatesService, private dialogService: DialogService, private datePipe: DatePipe) {
    this.activatedNode = (this.router.getCurrentNavigation().extras.state as any)?.active;
  }

  public ngOnInit(): void {
    this.loading = true;
    const templateId = Number(this.utils.lookupParam(HttpParams.TEMPLATE_ID));
    if (templateId !== null) {
      this.templatesService.getPageTemplate(templateId)
          .pipe(finalize(() => this.loading = false)).subscribe((template: TemplateGroup) => {
        this.breadcrumbs.push({title: template.name});
        this.template = template;
        this.templateTree = this.buildViewTree(template);
        Utils.updateTreeCounters(this.templateTree);
        this.templateTree.additional = this.formatInfo(template);
        this.selectedNode = this.activatedNode && Utils.findNode(this.templateTree, this.activatedNode);
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Template View',
      text: this.information
    }});
  }

  public filterItems(query: string): void {
    this.query = query;
    Utils.filterTree(this.templateTree, query);
    if (!this.selectedNode?.visible) {
      this.selectedNode = null;
    }
    this.templateTree.visible = true; // assert shown even if not match
    Utils.updateTreeCounters(this.templateTree);
  }

  public cancelEvent(event: MouseEvent): void {
    event.stopPropagation();
  }

  public updateNodeText(source: TemplatesItem): void {
    if (this.selectedNode) {
      Utils.updateNode(this.selectedNode, source, this.query);
      this.templateTree.additional = this.formatInfo(source);
    }
  }

  private buildViewTree(source: TemplatesItemGroup | TemplatesItem): ViewTreeNode<TemplatesItemGroup, TemplatesItem> {
    const root = source as TemplatesItemGroup;
    const terminal = !root.nodes && !root.leafs;
    const children = terminal ? null : ([] as Array<TemplatesItemGroup | TemplatesItem>)
      .concat(root.nodes || []).concat(root.leafs || []).map(x => this.buildViewTree(x));
    return {
      id: source.id,
      name: source.name,
      type: source.type,
      description: source.description,
      terminal,
      children,
      original: source
    } as ViewTreeNode<TemplatesItemGroup, TemplatesItem>;
  }

  private formatInfo(source: TemplateGroup | TemplatesItem): string {
    if (source.updatedBy) {
      return source.updatedBy + ' ' + (source.updatedAt ? this.datePipe.transform(source.updatedAt, DATE) : '');
    } else if (source.createdBy) {
      return source.createdBy + ' ' + (source.createdAt ? this.datePipe.transform(source.createdAt, DATE) : '');
    } else {
      return source.createdAt || source.updatedAt ? this.datePipe.transform(source.updatedAt || source.createdAt, DATE) : '';
    }
  }

}
