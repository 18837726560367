import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { UtilitiesService } from '@services/utilities.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { ConfirmDialogIcon } from '@models/dialogs';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, takeUntil, finalize } from 'rxjs';
import { NOT_FOUND } from '@constants/http-params';

@Component({
  selector: 'app-bindings-view',
  templateUrl: './bindings-view.component.html',
  styleUrls: ['./bindings-view.component.scss']
})
export class BindingsViewComponent implements OnInit, OnDestroy {

  constructor(private utilitiesService: UtilitiesService,
    private notificationService: NotificationService, private dialogService: DialogService) { }

  public uploading = false;
  public downloading = false;

  @ViewChild('information') information: TemplateRef<any>;

  private destroyed = new Subject<void>();

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  } 

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Wi-Fi module and device bind',
      text: this.information
    }});
  }

  public downloadUtility(): void {
    this.downloading = true;
    this.utilitiesService.downloadBindingUtility().pipe(finalize(() => this.downloading = false)).subscribe(
      () => {},
      (err: HttpErrorResponse) => {
        if (err.status === NOT_FOUND) {
          this.notificationService.error(`Attention! 
            There is no any published univesal firmware in Platform.
            At least one previously published version of this software should be available to proceed.`);
        } else {
          this.notificationService.error(err.message);
        }
      });
  }

  public downloadMacAddresses(): void {
    this.downloading = true;
    this.utilitiesService.downloadBindingMacAddressesWhiteList().pipe(finalize(() => this.downloading = false)).subscribe(() => {});
  }

  public uploadMacAddresses(file: File): void {
    this.uploading = true;
    this.utilitiesService.uploadMacAddressesBindings(file).subscribe((parseErrorLog: string) => {
      this.finishUploading(parseErrorLog);
    }, (error) => {
      this.finishUploading(null, error);
    });      
  }

  private finishUploading(parseErrorLog: string, error?: HttpErrorResponse): void {
    this.uploading = false;
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: parseErrorLog || error ? 'Error' : 'Uploaded',
      text: error ? 'Error uploading file' : (parseErrorLog ?
        `Some of MAC addresses were not found in the platform and could not be used to save the binding to device in the platform.
        For more details please check Activity log.` : 'Bindings successfully uploaded.'),
      agreeButton: !!parseErrorLog,
      agreeButtonText: 'Open Activity log',
      cancelButtonText: 'Close',
      icon: parseErrorLog || error ? ConfirmDialogIcon.ERROR : null
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: any) => { 
      if (parseErrorLog && result) {
        this.utilitiesService.downloadErrorLog(parseErrorLog);
      }
    });
  }

} 