<div class="patch-results">
  <div class="results-header align-container space-between">
    <div class="caption">
      <ng-container *ngIf="validationPhase">
        <span class="text" *ngIf="valid">All configs match the patch</span>
        <span class="text" *ngIf="!valid">Some configs don’t match the patch<span *ngIf="removingAllowed">, please remove them of fix the patch</span></span>
      </ng-container>
      <ng-container *ngIf="!validationPhase">
        <span class="text">Patch is applied to {{validCount}} configs</span>
      </ng-container>
    </div>
    <div class="counters">
      <div class="success-counter">
        <span class="text" *ngIf="validationPhase">Matches&nbsp;</span>
        <span class="text" *ngIf="!validationPhase">Applied&nbsp;</span>
        <span class="label">{{validCount || 0}}&nbsp;&nbsp;</span>
      </div>
      <div class="error-counter">
        <span class="text">Errors —&nbsp;</span>
        <span class="label">{{invalidCount || 0}}</span>
      </div>
    </div>
  </div>
  <div class="models-details">
    <perfect-scrollbar>
      <div class="scroll-area">
        <div class="model-row" *ngFor="let model of results"
            [class.active]="model === activeModel" (mousedown)="activeModel = model">
          <div class="model-line">
            <div class="model-name">
              {{model.deviceType?.name || '&mdash;'}} / {{model.model?.name || '&mdash;'}}
            </div>
            <div class="status">
              <div class="status-icon" [class.success]="model.ok" [class.error]="!model.ok"></div>
              <div class="status-text" [class.success]="model.ok" [class.error]="!model.ok">
                <ng-container *ngIf="validationPhase">
                  <span class="text" *ngIf="model.ok">Patch matches configuration</span>
                  <span class="text" *ngIf="!model.ok">{{model.error || 'Not matches'}}</span>
                </ng-container>
                <ng-container *ngIf="!validationPhase">
                  <span class="text" *ngIf="model.ok">Successfully applied</span>
                  <span class="text" *ngIf="!model.ok">{{model.error || 'Not applied'}}</span>
                </ng-container>
              </div>
              <div class="expand-collapse-error" [class.expanded]="model.expanded"
                  *ngIf="!model.ok" (click)="model.expanded = !model.expanded"></div>
            </div>
            <div class="actions-icons">
              <div class="button button-remove-line" *ngIf="removingAllowed && !model.ok" (click)="remove(model)"></div>
              <div class="button button-view-details" *ngIf="!validationPhase && model.ok" (click)="viewDetails(model)"></div>
            </div>
          </div>
          <div class="details-line" [class.expanded]="model.expanded" *ngIf="!model.ok">
            {{model.details || 'No Details Available'}}
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</div>