import { Base } from '@models/base';
import { Action } from '@models/actions';
import { DeviceModelSimple, DeviceTypeSimple } from '@models/device-models';
import { ConfigPatchOperationType } from '@constants/patches';

export enum ModificationStatus {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED'
}

export interface ModificationPatchModel extends Base {
  invalid?: boolean;
}

export interface ModificationPatch {
  id: number | null;
  name: string;
  modelsCount?: number;
  invalidCount?: number;
  status: ModificationStatus;
  updatedAt: Date;
  author?: string;
  type: Base;
  models: Array<ModificationPatchModel>;
}

export interface ModificationPatchView extends ModificationPatch {
  actions?: Array<Action>;
}

export interface ModificationPatchModelExt extends ModificationPatchModel {
  error?: string;
  details?: string;
}

export interface ModificationPatchDetails extends ModificationPatch {
  patch: string;
  models: Array<ModificationPatchModelExt>;
}

export interface ModelModificationInfo {
  model: DeviceModelSimple;
  hasModification: boolean;
  configId: number;
}

export interface ModelModificationView {
  model: DeviceModelSimple;
  deviceType: Base;
  ok: boolean;
  error?: string;
  details?: string;
  expanded?: boolean;
}

// no properties rename, must match validation chema
export interface PatchOperation {
  op: ConfigPatchOperationType;
  path?: string;
  value?: string;
  from?: string;
}


