<div class="sortable-header" (click)="emit()"
    [class.align-right]="align === Align.RIGHT"
    [class.align-center]="align === Align.CENTER">
  <div class="column-name">
    <ng-content></ng-content>
  </div>
  <div class="sort-marker" [class.visible]="sorting?.column === column">
    <div class="sort-arrow"
      [class.asc]="sorting?.order === SortOrder.ASC"
      [class.desc]="sorting?.order === SortOrder.DESC"
      [class.none]="sorting?.order === SortOrder.NONE">
    </div>
  </div>
</div>