import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { TreeNode, ViewTreeNode, JsonNode, JsonNodeGroup, JsonNodeType } from '@models/tree-node';
import { TemplatesItem } from '@models/templates';
import { Observable, Subject, map, mergeMap, catchError, throwError, from, empty } from 'rxjs';
import * as lodash from 'lodash';
import { NO_AUTH_HEADER } from '@constants/http-params';
import { PAGE_SIZE } from '@constants/main';
import { UserInfoService } from './user-info.service';

const PLAUSIBLE_DATE = /^\d+\-\d+\-\d+[\dTZ\.\-+\:]+$/;
const DOWNLOAD_BLOB = {observe: 'response' as 'body', responseType: 'blob' as 'json'};
const DOWNLOAD_TEXT = {observe: 'response' as 'body', responseType: 'text' as 'json'};

export function provided(x: any): boolean {
  return x !== null && x !== undefined;
}

@Injectable({
  providedIn: 'root'
})
export class Utils {

  constructor(private http: HttpClient, private router: Router, private userInfoService: UserInfoService) {} 

  public lookupParam(param: string): string {
    const lookup = (snapshot: ActivatedRouteSnapshot): string => {
      const ownValue = snapshot.params[param] || snapshot.queryParams[param];
      const children = snapshot.children;
      const childValues = children.map(x => lookup(x));
      return [ownValue].concat(childValues).find((x: string) => x !== undefined);
    };
    return lookup(this.router.routerState.snapshot.root);
  }

  public downloadFile(fileUrl: string, ignoreReadonly = false, fileName?: string): void {
    if (ignoreReadonly || this.userInfoService.checkReadonlyPermission()) {
      const link = document.createElement('a');
      link.href = fileUrl;
      if (fileName) {
        link.setAttribute('download', fileName);
      }
      link.click();
    }
  }

  public getFile(fileUrl: string): Observable<string> {
    return this.http.get<string>(fileUrl, DOWNLOAD_TEXT);
  }

  // download and open file with security token and progress meter, returns file name or error message
  public downloadFileWithAuth(fileUrl: string, params?: {[key: string]: string | boolean | number},
      postData?: any, noAuth = false): Observable<string> {
    if (!this.userInfoService.checkReadonlyPermission()) {
      return empty();
    }
    let headers = new HttpHeaders();
    if (noAuth) {
      headers = headers.set(NO_AUTH_HEADER, NO_AUTH_HEADER);
    }
    const requestData = Object.assign({}, DOWNLOAD_BLOB, {headers, params});
    const request = postData ? this.http.post(fileUrl, postData, requestData) : this.http.get(fileUrl, requestData);
    return request.pipe(
      map((response: any) => {
        const fileName = this.extractFileNameFromResponse(response as HttpResponse<any>);
        this.downloadFile(URL.createObjectURL(response.body), true, fileName);
        return fileName;
      }),
      catchError((err: HttpErrorResponse) => {
        const text = err.error.text && lodash.isFunction(err.error.text) ? err.error.text() : ''; 
        const errorText = text || err.error.text || err.error.statusText;
        return from(errorText).pipe(mergeMap((errContents: any) => {
          try {
            const errorInfo = JSON.parse(errContents);
            Object.assign(err as any, errorInfo);
          } finally {
            return throwError(() => err);
          }
        }));
      })
    );
  }

  public extractFileNameFromResponse(response: HttpResponse<any>): string {
    const contentDisposition = response.headers.get('content-disposition');
    const requestPath = new URL(response.url).pathname.split('/');
    let fileName = requestPath[requestPath.length - 1];
    if (contentDisposition) {
      const pairs = contentDisposition.split(';').map((x: string) => x.trim());
      const fileNamePair = pairs.find((x: string) => x.startsWith('filename='));
      fileName = fileNamePair ? fileNamePair.split('=')[1] : 'UnknownFile';
      if (fileName.startsWith('"')) {
        fileName = fileName.substring(1);
      }
      if (fileName.endsWith('"')) {
        fileName = fileName.substring(0, fileName.length - 1);
      }
    }
    return fileName;
  }

  public static extendPaged<T = any>(paged: Paged<T>): Paged<T> {
    paged.page = paged.pageable.pageNumber;
    paged.pageSize = paged.pageable.pageSize;
    paged.startElement = paged.page * paged.pageSize + 1;
    paged.endElement = Math.min((paged.page + 1) * paged.pageSize, paged.totalElements);
    return paged;
  }

  public static createPage<T = any>(contents: Array<T>, page: number, pageSize = PAGE_SIZE): Paged<T> {
    const data = (contents || []).slice((page - 1) * pageSize, page * pageSize);
    return Utils.extendPaged<T>({
      content: data,
      empty: !!contents.length,
      pageable: {pageNumber: page - 1, pageSize},
      totalPages: Math.ceil((contents?.length || 0) / pageSize),
      totalElements: contents?.length || 0,
    } as Paged<T>);
  }

  public static copyDeep<T>(x: any): T {
    return lodash.cloneDeep(x) as T;
  }

  public static capitalize(x: string): string {
    return x?.length ? x[0].toUpperCase() + x.substring(1).toLowerCase() : x;
  }

  public static unpackCamelCase(text: string): string {
    const result = text.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.substring(1);
  }

  public static unpackSnakeCase(text: string): string {
    return text.split(/[\-_]/g).map(x => Utils.capitalize(x)).join(' ');
  }

  public static errors(obj: {[key: string]: any}): Object {
    const keys = Object.keys(obj).filter(key => !!obj[key]).map(key => [key, obj[key]]);
    return keys.length ? Object.fromEntries(keys) : null;
  }

  public static compare(a: any, b: any, sorting: Sorting, ignoreCase = true): number {
    let valA = lodash.get(a, sorting.column, undefined);
    let valB = lodash.get(b, sorting.column, undefined);
    valA = lodash.isString(valA) && ignoreCase ? valA.toUpperCase() : valA;
    valB = lodash.isString(valB) && ignoreCase ? valB.toUpperCase() : valB;
    const result = valA < valB ? -1 : (valA > valB ? 1 : 0);
    return sorting.order === SortOrder.DESC ? -result : result;
  }

  public static find(a: any, field: string | Array<string>, query: string): boolean {
    const check = (value: string) => {
      return ('' + value).toUpperCase().includes((query || '').toUpperCase());
    };
    if (field && field.length) {
      return (field as string[]).some(x => check(lodash.get(a, x, '')));
    } else {
      return check(lodash.get(a, field, '') as string);
    }
  }

  public static filterTree(root: ViewTreeNode, query: string, force = false): boolean {
    const match = (root.name || '').toUpperCase().includes((query || '').toUpperCase());
    (root.children || []).forEach(x => Utils.filterTree(x, query, match || force));
    const visible = match || force || (root.children || []).some(x => x.visible);
    root.nameHighlighted = match ? Utils.highlightSubstring(root.name, query) : null;
    root.collapsed = !visible;
    return root.visible = visible;
  }

  public static buildJsonTree(json: { [key: string]: any }, unpackKeys = true, level = 0): JsonNodeGroup {
    const keys = Object.keys(json);
    const result = {children: [], level} as JsonNodeGroup;
    for (let key of keys) {
      let value = json[key];
      if (lodash.isString(value) && PLAUSIBLE_DATE.test(value)) {
        const date = new Date(value);
        value = isFinite(date.getTime()) ? date : value;
      }
      const isComplex = lodash.isObject(value) && !lodash.isDate(value);
      const isArray = Array.isArray(value) && (!value.length || !value.some(x => lodash.isObject(x)));
      const nodeType = isComplex ? (isArray ? JsonNodeType.ARRAY : JsonNodeType.OBJECT) : lodash.isDate(value) ?
        JsonNodeType.DATE : lodash.isNumber(value) ? JsonNodeType.NUMERIC : JsonNodeType.PLAIN;
      const keyName = unpackKeys ? Utils.unpackCamelCase(key) : key;
      result.children.push({key: keyName, type: nodeType,
        value: isComplex && !isArray ? Utils.buildJsonTree(value, unpackKeys, level + 1) : value});
    }
    return result;
  }

  public static jsonTreeToViewTree(json: any, key: string = null, currentPath = ''): ViewTreeNode {
    const children = !lodash.isObject(json) ? null
      : Object.keys(json).map(x => Utils.jsonTreeToViewTree((json as any)[x], x, currentPath + '/' + x))
    return {
      id: null,
      name: key,
      description: currentPath,
      terminal: !children || !children.length,
      children,
      collapsed: true,
      original: json
    };
  }

  public static updateNode(node: ViewTreeNode, source: TemplatesItem, query?: string) {
    node.name = source.name;
    node.description = source.description;
    const match = query ? (node.name || '').toUpperCase().includes((query || '').toUpperCase()) : false;
    node.nameHighlighted = match ? Utils.highlightSubstring(node.name, query) : null;
  }

  public static findNodePath(root: ViewTreeNode, node: ViewTreeNode, current: Array<ViewTreeNode> = []): Array<ViewTreeNode> | null {
    if (!root?.children) {
      return null;
    }
    if (root.children.includes(node)) {
      return current.concat(root);
    } else {
      return root.children.reduce((acc, x) => acc || Utils.findNodePath(x, node, current.concat(root)), null as Array<ViewTreeNode>);
    }
  }

  public static findTreePath(root: ViewTreeNode, path: string[], exact = true): Array<ViewTreeNode> | null {
    let node = root;
    const match = (node: ViewTreeNode, value: string, last: boolean): boolean => {
      return last ? (node.name || '').toLowerCase().includes((value || '').toLowerCase()) : node.name === value;
    };
    const result = path.reduce((acc, current, index) => {
      node = node && (node.children || []).find(x => match(x, current, index === path.length - 1));
      return node ? acc.concat(node) : acc;
    }, []);
    const succeed = exact ? result.length === path.length : !!result.length;
    return succeed ? result : null;
  }

  public static normalizeTreePath(path: string): string[] {
    return path.replace(/[\[\]]/g, '/').replace(/\/+/g, '/').replace(/^\/*/g, '').replace(/\/*$/g, '').split('/').filter(x => !!x);
  }

  public static findNode(root: ViewTreeNode, node: TreeNode): ViewTreeNode {
    const check = root.original.id === node.id && root.original.type === node.type;
    return check ? root : (root.children || []).reduce((acc, x) => acc || Utils.findNode(x, node), null);
  }

  public static updateTreeCounters(root: ViewTreeNode): void {
    Utils.updateTreeRowCounters(root);
    Utils.updateTreeItemCounters(root);
  }

  public static updateTreeRowCounters(root: ViewTreeNode): number {
    const selfCount = root.visible === false ? 0 : 1;
    if (root.terminal || root.collapsed) {
      return root.rowsCount = selfCount;
    } else {
      return root.rowsCount = (root.children || []).reduce(
        (acc, x) => acc + Utils.updateTreeRowCounters(x), 0) + selfCount;
    }
  }

  public static updateTreeItemCounters(root: ViewTreeNode): number {
    const selfCount = root.visible === false ? 0 : 1;
    if (root.terminal) {
      return root.itemsCount = selfCount;
    } else {
      root.childrenCount = (root.children || []).filter(x => x.visible !== false).length;
      return root.itemsCount = (root.children || []).reduce(
        (acc, x) => acc + Utils.updateTreeItemCounters(x), 0);
    }
  }

  public static highlightSubstring(html: string, highlightQuery: string): string {
    const TEMPLATE = '<span class="highlighted">${query}</span>';
    if (!highlightQuery) {
      return html;
    }
    let line = html;
    const pattern = new RegExp('(' + highlightQuery + ')', 'gi');
    const matches = [];
    let match;
    do {
      match = pattern.exec(line);
      if (match) {
        matches.push(match);
      }
    } while (match);
    matches.sort((matchA, matchB) => {
      return matchB.index - matchA.index;
    });
    for (const foundMatch of matches) {
      line =
        line.substring(0, foundMatch.index) + TEMPLATE.replace(/\$\{query\}/, foundMatch[0]) +
        line.substring(foundMatch.index + highlightQuery.length);
    }
    return line;
  }

}
