<div class="diagnostic-permissions-view inner-view">
  <div class="view-header with-breadcrumbs">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1 class="align-container small-spacing">
      <span>Permission Request&nbsp;&nbsp;&nbsp;<span class="highlighted">{{permissionRequestId}}</span></span>
    </h1>
  </div>
  <div class="view-section scroll-container" *ngIf="request">
    <perfect-scrollbar>
      <div class="view-layout scroll-area">
        <div class="left-side">
          <div class="panel request-info">
            <div class="panel-header">
              <div class="panel-name">Main Info</div>
            </div>
            <div class="panel-section">
              <table class="grayed-titles">
                <tr>
                  <th>Request ID</th>
                  <td>{{request.id || '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Owner ID</th>
                  <td>{{request.ownerId || '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>MAC address</th>
                  <td>{{request.mac || '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Duration</th>
                  <td>{{request.duration ? request.duration + 'h' : '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Created</th>
                  <td>{{request.ts ? (request.ts | date:DATE_TIME) : '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td class="capitalize">{{request.status || '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Status updated</th>
                  <td>{{request.statusUpdated ? (request.statusUpdated | date:DATE_TIME) : '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Author</th>
                  <td>{{request.author || '&mdash;'}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="panel status-history">
            <div class="panel-header">History</div>
            <div class="panel-section history-section">
              <ng-container [ngTemplateOutlet]="history" [ngTemplateOutletContext]="{history: request.history}"></ng-container>
            </div>
            <div class="panel-section buttons" *ngIf="!request.completed">
              <button class="primary" (click)="completeRequest()">Complete request</button>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <ngx-loading [show]="loading">
  </ngx-loading>
</div>
<ng-template #history let-history="history">
  <div class="history-container">
    <ng-container *ngFor="let status of history">
      <ng-container [ngTemplateOutlet]="statusRow" [ngTemplateOutletContext]="{status}"></ng-container>
    </ng-container>
  </div>
</ng-template>
<ng-template #statusRow let-status="status">
  <div class="status-container" [class.history-expanded]="status.expanded" [class.history-collapsed]="!status.expanded">
    <ng-container [ngTemplateOutlet]="history" [ngTemplateOutletContext]="{history: status.details}" *ngIf="status.details?.length">
    </ng-container>
    <div class="status-row">
      <div class="status-icon" [ngClass]="'status-' + status.status"></div>
      <div class="status-name capitalize">{{status.text || status.status}}</div>
      <div class="status-date">{{status.ts ? (status.ts | date:DATE_TIME) : '&mdash;'}}</div>
      <div class="expand-button" *ngIf="status.details?.length" (click)="status.expanded = !status.expanded"
        [class.expanded]="status.expanded" [class.collapsed]="!status.expanded"></div>
    </div>
  </div>
</ng-template>
