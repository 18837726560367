<div class="patch-constructor">
  <div class="controls">
    <button class="link-button" (click)="addRow()" [disabled]="invalid">
      <div class="link-icon add-item"></div><span class="text">Add Operation</span>
    </button>
    <button class="button-up" [disabled]="!activeRow || !upAllowed" (click)="moveRow(-1)"></button>
    <button class="button-down" [disabled]="!activeRow || !downAllowed" (click)="moveRow(1)"></button>
    <button class="button-remove-line-active" [disabled]="!activeRow" (click)="removeRow()"></button>
  </div>
  <div class="operations-container">
    <div class="invalid-stub" *ngIf="invalid">
      <div class="invalid-block validation">
        <div class="button validation-bubble"></div>
        <div class="main-message">JSON code is invalid and could not be repaired automatically, please use Editor</div>
        <div class="clear-message" (click)="clearError()">
          <div class="action-icon"></div>
          <div class="text">Clear All</div>
        </div>
      </div>
    </div>
    <perfect-scrollbar #scrollbar *ngIf="!invalid">
      <div class="scroll-area" appClickOutside (clickOutside)="setActiveRow(null)">
        <ng-container *ngFor="let row of operations; index as index">
          <div class="operation-row" [ngClass]="'operation-' + row.op"
               [class.selected]="row === activeRow" (mousedown)="setActiveRow(row)">
            <div class="row-number label">{{index + 1}}</div>
            <div class="operation">
              <app-dropdown [items]="allOperations" [(ngModel)]="row.op" (ngModelChange)="update()"
                [narrow]="true" [bindValue]="true" placeholder="operation"></app-dropdown>
            </div>
            <div class="operation-path" *ngIf="row.op">
              <app-dropdown-tree #path [(ngModel)]="row.path" (ngModelChange)="update()" [multiline]="true"
                [narrow]="true" [dropdownTree]="configTree" placeholder="type path"></app-dropdown-tree>
            </div>
            <div class="operation-from"
                *ngIf="row.op === OpType.MOVE || row.op === OpType.COPY">
              <app-dropdown-tree #from [(ngModel)]="row.from" (ngModelChange)="update()" [multiline]="true"
                [narrow]="true" [dropdownTree]="configTree" placeholder="type source"></app-dropdown-tree>
            </div>
            <div class="operation-value"
                *ngIf="row.op === OpType.ADD || row.op === OpType.TEST || row.op === OpType.REPLACE">
              <app-multiline-edit #value [(ngModel)]="row.value" (ngModelChange)="update()"
                  [narrow]="true" placeholder="type value" [clearable]="true">
              </app-multiline-edit>
            </div>
            <div class="operation-tip" *ngIf="!row.op">
              Select patch operation to specify additional parameters
            </div>
          </div>
        </ng-container>
      </div>
    </perfect-scrollbar>
  </div>
</div>