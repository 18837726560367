import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { WiFiModuleDetailed, WiFiModuleDiagnosticPermission } from '@models/device-modules';
import { DiagnosticPermissionDialogComponent } from '../diagnostic-permission-dialog/diagnostic-permission-dialog.component';
import { DeviceModuleDialogPermissionDialogData } from '../../models/dialog-data';
import { DeviceModulesService } from '@services/device-modules.service';
import { DialogService } from '@services/dialog.service';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DatesRange, DATES_RANGE_TYPES, DatesRangeTypes } from '@models/dates';
import { DATE_TIME } from '@constants/dates';
import { Subject, finalize, takeUntil } from 'rxjs';

enum SortColumn {
  REQUEST_ID = 'id',
  OWNER_ID = 'ownerId',
  AUTHOR = 'author',
  CREATED = 'ts',
  DURATION = 'duration',
  STATUS = 'status'
}

@Component({
  selector: 'app-device-module-permissions-list',
  templateUrl: './device-module-permissions-list.component.html',
  styleUrls: ['./device-module-permissions-list.component.scss']
})
export class DeviceModulePermissionsListComponent implements OnInit, OnDestroy {

  @Input()
  public details: WiFiModuleDetailed;

  public range: DatesRange = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
  public query: string = '';
  public permissions: Paged<WiFiModuleDiagnosticPermission>;

  public activePage = 1;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.REQUEST_ID, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  private destroyed = new Subject<void>();

  constructor(private deviceModulesService: DeviceModulesService, private dialogService: DialogService) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.range = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public updateData(resetPage = false): void {
    if (this.details) {
      this.loading = true;
      this.deviceModulesService.getDeviceWiFiModuleDiagnosticPermissions(
        this.details.macAddress, this.range, this.query, this.sorting, resetPage ? 1 : this.activePage)
      .pipe(finalize(() => this.loading = false))
      .subscribe((permissions: Paged<WiFiModuleDiagnosticPermission>) => {
        this.permissions = permissions;
        this.activePage = resetPage ? 1 : this.activePage;
      });
    }
  }

  public createPermission(): void {
    this.dialogService.showModal(DiagnosticPermissionDialogComponent, { maxWidth: '500px', data: {
      macAddress: this.details.macAddress
    } as DeviceModuleDialogPermissionDialogData})
        .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.updateData();
      }
    });

  }

}
