<div class="search-bar input-container" [class.focused]="focused" [class.clearable]="clearable">
  <div class="dummy" [ngbPopover]="minQueryMessage"
      triggers="manual" #minQueryTip="ngbPopover" placement="top" container="body"></div>
  <input type="text" class="search-input" [class.upper-case]="upperCase" *ngIf="!mask"
    [(ngModel)]="query" (ngModelChange)="scheduleSearch()" [disabled]="disabled"
    [attr.placeholder]="placeholder" (focus)="setFocused(true)" (blur)="setFocused(false)" (keyup.enter)="searchNow()"/>
  <input type="text" class="search-input" [class.upper-case]="upperCase" *ngIf="mask"
    [(ngModel)]="query" (ngModelChange)="scheduleSearch()" [disabled]="disabled" [textMask]="mask"
    [attr.placeholder]="placeholder" (focus)="setFocused(true)" (blur)="setFocused(false)" (keyup.enter)="searchNow()"/>
  <div class="controls">
    <div class="button button-clear" *ngIf="clearable && query" (click)="clear()"></div>
    <div class="search-icon" (click)="searchNow()"></div>
  </div>
</div>