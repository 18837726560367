<div class="dialog-content device-instruction-edit">
  <div class="dialog-header">
    <span class="title">{{(isNew ? 'New' : 'Edit') + ' Instruction'}}</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body" [@.disabled]="true">
    <div class="wizard-steps">
      <app-wizard-steps [steps]="wizardSteps"></app-wizard-steps>
    </div>
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTab = $event">
      <mat-tab label="Instruction">
        <div class="instruction-view">
          <div class="instruction-info">
            <div class="instruction-title">How to fill instruction</div>
            <div class="instruction-text">
              <ul class="instruction-list">
                <li>Fill obligatory sections <b>Info, Text, Types &amp; Models.</b></li> 
                <li>Image is optional.</li>
                <li>Untill all necessary sections are filled your instruction will be saved as a draft.</li>
              </ul>
            </div>
          </div>
          <div class="block instruction-name">
            <div class="field-label">Instruction name</div>
            <div class="field">
              <input type="text" [(ngModel)]="instruction.name" class="input name-input" maxlength="50"
                [class.invalid]="!instruction.name && highlightErrors" (input)="resetHighlight()"/>
            </div>
            <div class="validation" *ngIf="highlightErrors">
              Please fill Instruction name
            </div>
          </div>
          <div class="block instruction-type">
            <div class="field-label">Instruction type</div>
            <div class="field">
              <app-dropdown [items]="instructionTypesItems" [(ngModel)]="instructionType" [disabled]="true"
                [invalid]="!instructionType && highlightErrors"></app-dropdown>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Content">
        <div class="steps-view">
          <div class="steps-container scroll-container">
            <perfect-scrollbar #scrollbar>
              <div class="steps-list">
                <div class="step-block" *ngFor="let step of instruction.instructions; let index = index"
                    [class.deletable]="instruction.instructions.length > 1">
                  <div class="step-header align-container space-between">
                    <h2 class="step-name panel-header">Step {{index + 1}}</h2>
                    <div class="step-remove" (click)="removeInstructionStep(index)">Delete step</div>
                  </div>
                  <div class="step-content align-container">
                    <textarea class="step-text" [(ngModel)]="step.description" maxlength="500"></textarea>
                    <div class="step-file">
                      <app-image-upload [(ngModel)]="step.file"></app-image-upload>
                    </div>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="step-controls">
            <a class="button link-button" (click)="addInstructionStep()"><div class="link-icon add-item"></div><span>Add New Step</span></a> 
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Types & Models">
        <div class="types-models">
          <div class="controls-panel">
            <div class="device-types">
              <app-dropdown [items]="deviceTypesItems" [(ngModel)]="deviceType" placeholder="Select device type" [clearable]="true"></app-dropdown>
            </div>
            <div class="models-search">
              <app-search-bar placeholder="Search model" (search)="query = $event" [clearable]="true"></app-search-bar>
            </div>
            <div class="product-lines">
              <app-dropdown [items]="productLines" [(ngModel)]="productLine" placeholder="Select Product Line" [clearable]="true"></app-dropdown>
            </div>
            <div class="tags-search">
              <app-lookup placeholder="Search tag" [provider]="modelsTagsProvider" [(ngModel)]="modelsTag" [clearable]="true" [magnifyingGlass]="true"></app-lookup>
            </div>
          </div>
          <div class="models-selector">
            <app-types-models-selection
              [typesAndModels]="typesAndModels"
              [filterType]="deviceType?.value"
              [filterQuery]="query"
              [filterProductLine]="productLine?.value"
              [filterTag]="modelsTag?.value"
              [(ngModel)]="selectedTypesAndModels">
            </app-types-models-selection>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="dialog-footer controls align-container space-between">
    <button class="secondary" mat-dialog-close [disabled]="progressing">Cancel</button>
    <div class="main-buttons align-container mid-spacing">
      <button class="secondary button-back" (click)="previousTab()" [disabled]="progressing || selectedTab === 0">Prev</button>
      <button class="primary button-forward" [class.button-forward]="selectedTab < 3" (click)="nextTab()" [disabled]="progressing">
        {{selectedTab === 2 ? (isDraft() ? 'Save draft' : 'Save') : 'Next'}}
      </button>
  </div>
</div> 
