<div class="device-model-view inner-view">
  <div class="view-header with-breadcrumbs">
    <div class="align-container space-between header-layout">
      <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
      <button class="primary button-delete delete-gray" (click)="deleteModel()"
        [disabled]="deviceModel?.deviceConfig || deviceModel?.modelConfig" cdkOverlayOrigin
        [disablePopover]="!deviceModel?.deviceConfig && !deviceModel?.modelConfig"
        ngbPopover="Model with device or model config can not be deleted" triggers="mouseenter:mouseleave"
        placement="top-right" openDelay="500" container="body">Delete</button>
    </div>
    <h1 class="align-container small-spacing">
      <span>{{deviceModel ? deviceModel.deviceTypeName + ' / ' + deviceModel.customerModel : '&nbsp;'}}</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section scroll-container" *ngIf="deviceModel">
    <perfect-scrollbar>
      <div class="view-layout scroll-area">
        <div class="left-side">
          <div class="panel model-info">
            <div class="panel-header">
              <div class="panel-name">Model Info</div>
            </div>
            <div class="panel-section">
              <table class="grayed-titles">
                <tr>
                  <th>Model</th>
                  <td>{{deviceModel.customerModel || '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Device Type</th>
                  <td>{{deviceModel.deviceTypeName || '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>Board ID</th>
                  <td class="break-word">{{deviceModel.boardId || '&mdash;'}}</td>
                </tr>
                <tr>
                  <th>SAP code (mat.number)</th>
                  <td class="break-word">{{deviceModel.materialNumber || '&mdash;'}}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="panel">
            <div class="panel-header no-separator">Tags</div>
            <div class="panel-section">
              <div class="tags-view" *ngIf="!tagsEditMode">
                <div class="text-grayed" *ngIf="!deviceModel?.tags?.length">
                  No tags added.<br/>
                  Add some to make you work with this model more comfortable.
                </div>
                <div class="tags-list" *ngIf="deviceModel?.tags?.length">
                  <div class="tag" *ngFor="let tag of deviceModel.tags">#{{tag.name}}</div>
                </div>
                <hr/>
                <div class="buttons">
                  <button class="primary" (click)="startEditTags()" [disabled]="(activeUser | async)?.isTechnician">Edit Tags</button>
                </div>
              </div>
              <div class="tags-edit-form" *ngIf="tagsEditMode">
                <div class="tags-copy align-container">
                  <div class="tags-copy-label">Copy Tags From</div>
                  <div class="tags-copy-model">
                    <app-lookup [(ngModel)]="copyTagsModel" [provider]="modelsSearchProvider"
                      placeholder="Model name"></app-lookup>
                  </div>
                  <div class="tags-copy-button">
                    <button class="primary" (click)="copyTags()" [disabled]="!copyTagsModel">Copy</button>
                  </div>
                </div>
                <div class="tags-edit">
                  <app-lookup [multi]="true" [(ngModel)]="tags" [provider]="tagsSearchProvider" [clearable]="true" [unique]="true"
                    placeholder="Type tag + press Enter or select previously created" [tagsItemsFlag]="true" [allowAddingNonExisting]="true"
                    maxItemsOverflowText="no more than 20 tags can be added"></app-lookup>
                </div>
                <hr/>
                <div class="buttons align-container">
                  <button class="secondary" (click)="cancelEditTags()">Cancel</button>
                  <button class="primary" (click)="endEditTags()">Save</button>
                </div>
              </div>
            </div>
          </div>
          <div class="panel">
            <div class="panel-header no-separator">
              <div class="panel-title">Instruction</div>
              <div class="status-flag" [ngClass]="{attention: !deviceModel.instruction, checked: deviceModel.instruction}"></div>
            </div>
            <div class="panel-section">
              <div class="text-grayed" *ngIf="!deviceModel.instruction">No instruction found</div>
              <table class="grayed-titles" *ngIf="deviceModel.instruction">
                <tr>
                  <th>Updated</th>
                  <td>{{deviceModel.instruction.updatedAt ? (deviceModel.instruction.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
                </tr>
              </table>
              <a class="button link-button" (click)="navigateInstructions()">
                <div class="link-icon navigate"></div><span>Device Instructions</span>
              </a>
            </div>
          </div>
          <div class="panel">
            <div class="panel-header no-separator">
              <div class="panel-title">Test Report</div>
              <div class="status-flag" [ngClass]="{
                attention: !deviceModel.testReport?.id || !deviceModel.modelConfig || !deviceModel.deviceConfig || !deviceModel.groupList?.length || !deviceModel.testReport?.byConfig,
                checked: deviceModel.testReport?.id && deviceModel.modelConfig && deviceModel.deviceConfig && deviceModel.groupList?.length}"></div>
            </div>
            <div class="panel-section">
              <table class="grayed-titles">
                <tr>
                  <th>Test Coverage</th>
                  <td *ngIf="deviceModel.testCover === true">Covered</td>
                  <td *ngIf="deviceModel.testCover === null || deviceModel.testCover === undefined">Not involved in testing</td>
                  <td *ngIf="!deviceModel.testCover && deviceModel?.testCover !== null && deviceModel?.testCover !== undefined">Not covered</td>
                  <td *ngIf="deviceModel.testCover && deviceModel.testCover !== true">{{ deviceModel.testCover | number:'0.0-2' }}</td>
                </tr>
                <tr *ngIf="deviceModel.testReport?.id">
                  <th>Date</th>
                  <td>{{deviceModel.testReport.updatedAt ? (deviceModel.testReport.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
                </tr>
                <tr *ngIf="deviceModel.testReport?.id">
                  <th>Status</th>
                  <td class="capitalize">{{deviceModel.testReport.status || '&mdash;'}}</td>
                </tr>
              </table>
              <div class="problem-messages">
                <div class="text-grayed" *ngIf="!deviceModel.modelConfig || !deviceModel.deviceConfig">Add LOG and EPP JSON's for the model to participate in testing</div>
                <div class="text-grayed" *ngIf="!deviceModel.groupList?.length">The model has not been added to any of the model groups</div>
                <div class="text-grayed" *ngIf="!deviceModel.testReport?.id">No test report found</div>
                <div class="text-grayed" *ngIf="!deviceModel.testReport?.byConfig">Existing active test report has logical and/or epp JSON that are different from those specified in the model</div>
              </div>
              <div class="test-report-links" *ngIf="deviceModel.deviceConfig && deviceModel.modelConfig">
                <a class="button link-button" *ngIf="!deviceModel.groupList?.length" [routerLink]="['/reports', 'model-groups']">
                  <div class="link-icon navigate"></div><span>Model Groups</span></a>
                <a class="button link-button" *ngIf="!deviceModel.testReport?.id" [routerLink]="['/reports', 'test-reports']">
                  <div class="link-icon navigate"></div><span>Test Reports</span></a>
                <a class="button link-button" *ngIf="deviceModel.testReport?.id" [routerLink]="['/reports', 'test-report', deviceModel.testReport.id]">
                  <div class="link-icon navigate"></div><span>Test Report</span></a>
              </div>
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="panel">
            <div class="panel-header no-separator">
              <div class="panel-title">Device JSON-config</div>
              <div class="status-flag" [ngClass]="{attention: !deviceModel.deviceConfig, checked: deviceModel.deviceConfig}"></div>
            </div>
            <div class="panel-section">
              <div class="text-grayed" *ngIf="!deviceModel.deviceConfig">No JSON-config files found for this device</div>
              <table class="grayed-titles" *ngIf="deviceModel.deviceConfig">
                <tr>
                  <th>Updated</th>
                  <td>{{deviceModel.deviceConfig.updatedAt ? (deviceModel.deviceConfig.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
                </tr>
              </table>
              <a class="button link-button" (click)="navigateJsonConfigs()">
                <div class="link-icon navigate"></div><span>Device JSON-configs</span>
              </a>
            </div>
          </div>
          <div class="panel">
            <div class="panel-header no-separator">
              <div class="panel-title">Model JSON-config</div>
              <div class="status-flag" [ngClass]="{attention: !deviceModel.modelConfig, checked: deviceModel.modelConfig}"></div>
            </div>
            <div class="panel-section">
              <div class="text-grayed" *ngIf="!deviceModel.modelConfig">No JSON-config files found for this model</div>
              <table class="grayed-titles" *ngIf="deviceModel.modelConfig">
                <tr>
                  <th>Updated</th>
                  <td>{{deviceModel.modelConfig.updatedAt ? (deviceModel.modelConfig.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
                </tr>
              </table>
              <a class="button link-button" (click)="navigateModelConfigs()">
                <div class="link-icon navigate"></div><span>Model JSON-configs</span>
              </a>
            </div>
          </div>
          <div class="panel">
            <div class="panel-header no-separator">
              <div class="panel-title">Firmware</div>
              <div class="status-flag attention" *ngIf="!deviceModel.firmwares"></div>
              <app-checkbox *ngIf="deviceModel.firmwares" [(ngModel)]="deviceModel.firmwares.approved"
                (ngModelChange)="setApproved()" [rightToLeft]="true">approved</app-checkbox>
            </div>
            <div class="panel-section">
              <div class="text-grayed" *ngIf="!deviceModel.firmwares">No firmware found</div>
              <ng-container *ngIf="deviceModel.firmwares">
                <div class="firmware-title">Firmware available for this model:</div>
                <table class="grayed-titles firmware-table">
                  <tr>
                    <td>
                      <div class="status-flag" [class.checked]="deviceModel.firmwares.firmwareTypes?.Universal"
                        [class.blocked]="!deviceModel.firmwares.firmwareTypes?.Universal"></div>
                    </td>
                    <td>{{deviceModel.firmwares.firmwareTypes?.Universal || '&mdash;'}}</td>
                    <th>Universal</th>
                  </tr>
                  <tr>
                    <td>
                      <div class="status-flag" [class.checked]="deviceModel.firmwares.firmwareTypes?.Typical"
                        [class.blocked]="!deviceModel.firmwares.firmwareTypes?.Typical"></div>
                    </td>
                    <td>{{deviceModel.firmwares.firmwareTypes?.Typical || '&mdash;'}}</td>
                    <th>Typical</th>
                  </tr>
                  <tr>
                    <td>
                      <div class="status-flag" [class.checked]="deviceModel.firmwares.firmwareTypes?.Model"
                        [class.blocked]="!deviceModel.firmwares.firmwareTypes?.Model"></div>
                    </td>
                    <td>{{deviceModel.firmwares.firmwareTypes?.Model || '&mdash;'}}</td>
                    <th>Model</th>
                  </tr>
                </table>
                <div class="firmware-hint">
                  Please check the section as ”approved”, if listed firmware is suitible and enough for this model, else go to Firmware Management:
                </div>
              </ng-container>
              <a class="button link-button" (click)="navigateFirmware()">
                <div class="link-icon navigate"></div><span>Firmware management</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
  <ngx-loading [show]="loading">
  </ngx-loading>
</div>
<ng-template #information>
  <div><b>Device models/model card</b> — all the information necessary to launch the model, the status of its presence, and completeness status:</div>
  <ul class="info-list top-space">
    <li><b>MODEL</b> — the name of the model. The list of available models is loaded automatically by the platform by loading information about the models and their involvement in a particular type from the external Haier data sources.</li>
    <li><b>DEVICE TYPE</b> — the type of device the model is according to. The type is determined by the platform internally on model data load.</li>
    <li><b>BOARD ID</b> — is a unique identifier of the motherboard that is installed in the device of this model.</li>
    <li><b>MATERIAL NUMBER</b> — the production code of the device type for this model.</li>
    <li><b>TAGS</b> — tags that can be set for each model for the further identification of the model or features it has. Here you can assign a tag (not more than 20) to a model, create a new tag, use an existing one, or copy tags from another model (when you select a model for copying, the system will show the presence of tags for the model in the form of their number). Tags are options - you don't have to add them to the model if it is not necessary.</li>
    <li><b>Device JSON config</b> — information about the presence of a JSON configuration for the devices that is suitable to this model, designed to be loaded into the device itself (to communicate with the device's motherboard). Only valid configurations (in the "active" status) are taken into account. Usually, one JSON configuration could be suitable for plenty of device models.</li>
    <li><b>Model JSON config</b> — information about the presence of a JSON configuration for a device model, intended for the operation of a mobile application with a given device model and describing features available for this model. It could be suitable for only one specific model.</li>
    <li><b>FIRMWARE</b> — information about the availability of the firmware suitable for this model. There may be more than one eligible firmware, depending on the different kinds of firmware - only the most recent and published firmware is considered eligible. It is necessary to put a final mark confirming the actual presence of the firmware required for this model - the presence of a mark affects the readiness status of the model (STATUS in the list of models and on the model card).</li>
    <li><b>TEST REPORT</b> – section describing the coverage of the model with tests. This block contains information about whether the model is covered by tests or not (Test Coverage). If there is an active test report for the model, information on this report is also displayed (Date, Status and a link to the report)</li>
  </ul>
</ng-template>
