import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, Input,
  ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { JSONEditor, Mode } from 'vanilla-jsoneditor'
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-json-edit',
  templateUrl: './json-edit.component.html',
  styleUrls: ['./json-edit.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => JsonEditComponent),
    multi: true
  }]
})
export class JsonEditComponent implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy {

  @Input()
  public disabled = false;

  @Input()
  public readonly = false;

  @Input()
  public schema: string;

  @ViewChild('editorContentArea') editorContentArea: ElementRef;
  @ViewChild('editorView') editorViewElement: ElementRef;
  @ViewChild(PerfectScrollbarComponent) scrollbar: PerfectScrollbarComponent;

  public json: string = '';
  public focused = false;
  public pristine = true;
  public editor: JSONEditor;
  public guttersWidth = 0;

  private onTouchedCallback: () => void;
  private initTimeout: number;
  protected commit(value: string) {};

  constructor() {}

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    const self = this;
    this.editor = new JSONEditor({
      target: this.editorContentArea.nativeElement,
      props: {
        mode: Mode.text,
        mainMenuBar: false,
        navigationBar: false,
        statusBar: false,
        indentation: 4,
        tabSize: 4,
        escapeControlCharacters: false,
        content: {text: this.json},
        onChange: (updatedContent, previousContent, { contentErrors, patchResult }) => {
          this.json = (updatedContent as any).text;
          this.commit(this.json);
        },
        onFocus: () => self.focused = true,
        onBlur: () => self.focused = false
      }
    });
    this.updateDisabledCloak();
  }

  public ngOnDestroy(): void {
    this.editor && this.editor.destroy();
  }

  public writeValue(value: string): void {
    let json = value || '';
    if (value) {
      try {
        let parsed = JSON.parse(value);
        json = JSON.stringify(parsed, null, 4);
      } catch(e) {}
    }
    if (this.editor && json !== this.json) {
      this.json = json;
      this.editor.set({text: this.json});
      this.updateDisabledCloak();
    }
  }

  public update(): void {
    this.ngOnDestroy();
    this.ngAfterViewInit();
    this.updateDisabledCloak();
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private updateDisabledCloak(): void {
    setTimeout(() => {
      this.guttersWidth = this.editor?.$$?.root?.querySelector('.cm-gutters')?.clientWidth || 0;
    });
  }
}
