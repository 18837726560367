import { Component, Input, OnInit, OnDestroy, AfterViewInit, Inject, forwardRef,
  ChangeDetectorRef, ElementRef, ViewChild, LOCALE_ID } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { CalendarPanelComponent } from '@components/calendar-panel/calendar-panel.component';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { MaskedInputDirective } from 'angular2-text-mask';
import { MOMENT_DATE, CALENDAR_TEXT_MASK } from '@constants/dates';
import { limitDate } from '@models/dates';
import moment from 'moment';

@Component({
  selector: 'app-datepicker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true
  }]
})
export class DatePickerComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {

  @Input()
  public clearable = false;

  @Input()
  public disabled = false;

  @Input()
  public minDate: Date = null;

  @Input()
  public maxDate: Date = null;

  @ViewChild(MaskedInputDirective) input: MaskedInputDirective;
  @ViewChild(MatDatepickerInput) matInput: MatDatepickerInput<void>;

  public value: Date;
  public text: string;
  public focused = false;
  public expanded = false;
  public CalendarPanel = CalendarPanelComponent;
  public CALENDAR_MASK = {mask: CALENDAR_TEXT_MASK, guide: false, showMask: false, keepCharPositions: false};

  private backupValue: Date;
  private destroyed = new BehaviorSubject<boolean>(false);
  private commit(value: Date) {}
  private onTouchedCallback: () => void;

  constructor(@Inject(LOCALE_ID) private locale: string, private cd: ChangeDetectorRef) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public ngAfterViewInit(): void {
    this.updateText();
  }

  public writeValue(value: Date) {
    if (this.destroyed.value) {
      return;
    }
    this.value = this.backupValue = value;
    this.updateText();
  }

  public clear(): void {
    if (this.clearable) {
      this.commitTo(null);
    }
  }

  public handleChange(event: any): void {
    const eventDate = event?.value?.toDate() || null;
    this.text = (this.input as any)?.inputElement?.value || '';
    const textDate = this.text ? moment(this.text, MOMENT_DATE).toDate() : null;
    this.commitTo(eventDate || textDate);
  }

  public commitTo(date: Date): void {
    if (!date && !this.clearable || date && !isFinite(date.getTime())) {
      this.recover();
    } else {
      const limitedDate = date ? limitDate(date, {from: this.minDate, to: this.maxDate}) : null;
      if (!this.value || !moment(this.value).isSame(limitedDate)) {
        this.value = this.backupValue = limitedDate;
        this.commit(this.value);
      }
      this.updateText();
    }
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  public setFocused(focused: boolean) {
    this.focused = focused;
  }

  public setExpanded(expanded: boolean) {
    this.expanded = expanded;
  }

  private updateText(): void {
    this.input && this.input.writeValue(this.value ? moment(this.value).format(MOMENT_DATE) : '');
    this.matInput && (this.matInput.value = this.value);
    this.cd.detectChanges();
  }

  private recover(): void {
    this.value = this.backupValue;
    this.updateText();
  }

}
