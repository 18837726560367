import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NGX_LOADING_CONFIG, CALENDAR_CONFIG, SCROLLBAR_CONFIG, MASK_CONFIG } from '@constants/config';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule} from '@angular/material/table';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { OverlayModule } from '@angular/cdk/overlay';

import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { StopPropagationDirective } from './directives/stop-propagation/stop-propagation.directive';
import { BigNumberPipe } from './pipes/big-number/big-number.pipe';
import { FileSizePipe } from './pipes/file-size/file-size.pipe';
import { CapitalizePipe } from './pipes/capitalize/capitalize.pipe';

import { NavigationComponent } from './components/navigation/navigation.component';
import { HeaderComponent } from './components/header/header.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LookupComponent } from './components/lookup/lookup.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { TreeViewComponent } from './components/tree-view/tree-view.component';
import { DropdownTreeComponent } from './components/dropdown-tree/dropdown-tree.component';
import { StaticTreeComponent } from './components/static-tree/static-tree.component';
import { ActionsComponent } from './components/actions/actions.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ButtonsDialogComponent } from './components/buttons-dialog/buttons-dialog.component';
import { TextEnterDialogComponent } from './components/text-enter-dialog/text-enter-dialog.component';
import { CoverageDialogComponent } from './components/coverage-dialog/coverage-dialog.component';
import { MovetoDialogComponent } from './components/moveto-dialog/moveto-dialog.component';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { FileFieldComponent } from './components/file-field/file-field.component';
import { SortableHeaderComponent } from './components/sortable-header/sortable-header.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { NotificationComponent } from './components/notification/notification.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CalendarPanelComponent } from './components/calendar-panel/calendar-panel.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { AccessDeniedComponent } from './components/access-denied/access-denied.component';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { WizardStepsComponent } from './components/wizard-steps/wizard-steps.component';
import { DatesRangeComponent } from './components/dates-range/dates-range.component';
import { MonthSelectComponent } from './components/month-select/month-select.component';
import { YearSelectComponent } from './components/year-select/year-select.component';
import { RadioComponent } from './components/radio/radio.component';
import { MultilineEditComponent } from './components/multiline-edit/multiline-edit.component';
import { JsonEditComponent } from './components/json-edit/json-edit.component';

const SHARED_MODULES = [
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  PerfectScrollbarModule,
  OverlayModule,
  NgbModule,
  NgxJsonViewerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatDialogModule,
  MatTooltipModule,
  MatTabsModule,
  MatProgressBarModule,
  MatRadioModule,
  MatMenuModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatSlideToggleModule,
  MatTableModule,
  MatPaginatorModule,
  TextMaskModule
];

const SHARED_COMPONENTS = [
  NavigationComponent,
  NotificationComponent,
  BreadcrumbsComponent,
  HeaderComponent,
  SearchBarComponent,
  DropdownComponent,
  LookupComponent,
  CheckboxComponent,
  RadioComponent,
  CalendarPanelComponent,
  DatePickerComponent,
  MonthSelectComponent,
  YearSelectComponent,
  DatesRangeComponent,
  PaginationComponent,
  SortableHeaderComponent,
  NoDataComponent,
  AccessDeniedComponent,
  TreeViewComponent,
  DropdownTreeComponent,
  StaticTreeComponent,
  ActionsComponent,
  WizardStepsComponent,
  UploadButtonComponent,
  FileFieldComponent,
  ImageUploadComponent,
  InformationDialogComponent,
  ConfirmationDialogComponent,
  ButtonsDialogComponent,
  TextEnterDialogComponent,
  CoverageDialogComponent,
  MovetoDialogComponent,
  MultilineEditComponent,
  JsonEditComponent
];

const PIPES_AND_STUFF = [
  BigNumberPipe,
  FileSizePipe,
  CapitalizePipe,
  ClickOutsideDirective,
  StopPropagationDirective,
];


@NgModule({
  imports: [
    ...SHARED_MODULES,
    NgxMaskPipe,
    NgxMaskDirective,
    NgxLoadingModule.forRoot(NGX_LOADING_CONFIG),
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    ...PIPES_AND_STUFF
  ],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...PIPES_AND_STUFF,
    NgxMaskPipe,
    NgxMaskDirective,
    NgxLoadingModule,
  ],
  providers: [
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: SCROLLBAR_CONFIG },
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: MAT_DATE_FORMATS, useValue: CALENDAR_CONFIG },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false, strict: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_DATE_FORMATS] },
    provideNgxMask(),
  ]
})
export class CommonModule { }
