import { Component, Inject, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceInstructionDialogData } from '../../models/dialog-data';
import { DeviceTypeSimple, DeviceTypesAndModels, ProductLine } from '@models/device-models';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { WizardStep } from '@models/actions';
import { DeviceInstruction, NewDeviceInstruction, DeviceInstructionType,
  DeviceInstructionUpdateResult, InstructionStatus } from '@models/device-instructions';
import { DeviceInstructionConflictDialogComponent }
  from '../device-instruction-conflict-dialog/device-instruction-conflict-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { DeviceInstructionsService } from '@services/device-instructions.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { ConfirmDialogIcon } from '@models/dialogs';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Utils } from '@services/utils';
import { Subject, Observable, of, takeUntil, finalize } from 'rxjs';

@Component({
  selector: 'app-device-instruction-edit-dialog',
  templateUrl: './device-instruction-edit-dialog.component.html',
  styleUrls: ['./device-instruction-edit-dialog.component.scss']
})
export class DeviceInstructionEditDialogComponent implements OnInit, OnDestroy {

  @ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;

  public isNew = true;
  public instruction: DeviceInstruction | NewDeviceInstruction = null;
  public wizardSteps = [
    {title: 'Information', invalid: true, visited: true, active: true, required: true},
    {title: 'Content', invalid: true, visited: false, active: false},
    {title: 'Types&Models', invalid: true, visited: false, active: false}
  ] as Array<WizardStep>;
  public instructionTypesItems: Array<DropdownItem> = [];
  public instructionType: DropdownItem;
  public deviceTypesItems: Array<DropdownItem> = [];
  public deviceType: DropdownItem;
  public productLines: Array<DropdownItem> = [];
  public productLine: DropdownItem;
  public modelsTagsProvider: LookupProvider;
  public modelsTag: DropdownItem;
  public query: string = '';
  public typesAndModels: DeviceTypesAndModels;
  public selectedTypesAndModels: DeviceTypesAndModels = {types: [], models: []};
  public selectedTab: number = 0;
  public highlightErrors = false;
  public progressing = false;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceInstructionDialogData,
    private dialogRef: MatDialogRef<DeviceInstructionEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private instructionsService: DeviceInstructionsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.isNew = this.data.isNew;
    this.typesAndModels = this.data.typesAndModels;
    this.instructionTypesItems = this.data.instructionTypes.map((x: string, index: number) => ({value: index, title: x}));
    this.instructionType = this.instructionTypesItems[0];
    this.deviceTypesItems = this.typesAndModels.types.map((x: DeviceTypeSimple) => ({value: x.id, title: x.name}));
    this.productLines = this.data.productLines.map((x: ProductLine) => ({value: x.id, title: x.name}));
    this.modelsTagsProvider = {search: (query: string): Observable<Array<DropdownItem>> => {
      return of(this.data.modelsTags
          .filter(x => x.name.toUpperCase().includes(query.toUpperCase()))
          .map(x => ({value: x.id, title: x.name})));
    }};
    this.instruction = this.data.deviceInstruction ? Utils.copyDeep<DeviceInstruction>(this.data.deviceInstruction) :
      {name: '', type: DeviceInstructionType.PROCESSING, instructions: null, types: [], models: [] } as NewDeviceInstruction;
    this.instruction.instructions = this.instruction.instructions || [{order: 0, description: ''}];
    const initialDeviceTypes = new Set((this.instruction.types || []).map(x => x.id));
    const initialModels = new Set((this.instruction.models || []).map(x => x.id));
    this.selectedTypesAndModels = {
      types: this.typesAndModels.types.filter(x => initialDeviceTypes.has(x.id)),
      models: this.typesAndModels.models.filter(x => initialModels.has(x.id))
    };
    this.validateTabs();
    if (this.data.deviceInstruction) {
      this.wizardSteps.forEach(x => x.visited = true);
    }
    this.dialogRef.afterOpened().pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.checkScrollbar();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public previousTab(): void {
    if (this.selectedTab === 0) {
      return;
    } else {
      this.wizardSteps[this.selectedTab].active = false;
      this.selectedTab--;
      this.wizardSteps[this.selectedTab].active = true;
      this.validateTabs();
    }
  }

  public nextTab(): void {
    if (this.selectedTab === this.wizardSteps.length - 1) {
      this.handleSave();
    } else {
      if (this.wizardSteps[this.selectedTab].required) {
        this.validateTabs();
        if (this.wizardSteps[this.selectedTab].invalid) {
          this.highlightErrors = true;
          return;
        }
      }
      this.wizardSteps[this.selectedTab].active = false;
      this.selectedTab++;
      this.wizardSteps[this.selectedTab].active = true;
      this.wizardSteps[this.selectedTab].visited = true;
      this.validateTabs();
    }
  }

  public handleSave(): void {
    if (this.isDraft()) {
      this.dialogService.showModal(ConfirmationDialogComponent, { maxWidth: '500px', data: {
        title: 'Obligatory information is not filled',
        text: `You are about to save instruction whithout setting devices types & models. This instruction will not be sent to mobile app and will be saved as a draft. `,
        icon: ConfirmDialogIcon.WARNING,
        agreeButtonText: 'Yes, save'
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
        if (result) {
          this.saveInstruction(true);
        }
      });
    } else {
      this.saveInstruction(false);
    }
  }

  public resetHighlight(): void {
    this.highlightErrors = false;
  }

  public addInstructionStep(): void {
    this.instruction.instructions.push({order: 0, description: ''});
    this.cdr.detectChanges();
    this.scrollStepsToTheEnd();
  }

  public removeInstructionStep(index: number): void {
    this.instruction.instructions.splice(index, 1);
  }

  public isDraft(): boolean {
    return this.selectedTypesAndModels.types.length + this.selectedTypesAndModels.models.length === 0
      || this.instruction.instructions.every(x => !x.description && !x.file);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public checkScrollbar(): void {
    this.scrollbar.directiveRef.update();
  }

  public scrollStepsToTheEnd(): void {
    this.scrollbar.directiveRef.scrollToBottom();
  }

  private saveInstruction(asDraft = false): void {
    this.instruction = {...this.instruction,
      ...{type: this.instructionType.title as DeviceInstructionType},
      ...{status: asDraft ? InstructionStatus.DRAFT : InstructionStatus.READY},
      types: this.selectedTypesAndModels.types,
      models: this.selectedTypesAndModels.models
    };
    this.instruction.instructions.forEach((step, index) => step.order = index + 1);
    const request = this.isNew ? this.instructionsService.createDeviceInstruction(this.instruction, null, false, asDraft) :
      this.instructionsService.updateDeviceInstruction(this.instruction as DeviceInstruction, null, false, asDraft);
    this.progressing = true;
    request.pipe(finalize(() => this.progressing = false)).subscribe((result: DeviceInstructionUpdateResult) => {
      if (result.conflicts) {
        this.dialogRef.addPanelClass('hidden-dialog');
        this.dialogService.showModal(DeviceInstructionConflictDialogComponent,
          { height: '600px', width: '560px', data: {
              isNew: this.isNew, draft: asDraft, typesAndModels: this.typesAndModels,
              instruction: this.instruction, conflicts: result.conflicts}})
            .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((resolveResult: DeviceInstructionUpdateResult) => {
          if (resolveResult) {
            this.dialogRef.close(resolveResult.instruction || {});
          } else {
            this.dialogRef.removePanelClass('hidden-dialog');
          }
        });
      } else {
        this.notificationService.success('Device Instruction successfully ' + (this.isNew ? 'created' : 'updated'));
        this.dialogRef.close(this.instruction);
      }
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to ' + (this.isNew ? 'create' : 'update') + ' Device Instruction');
      console.error(error);
    });
  }

  private validateTabs(): void {
    this.wizardSteps[0].invalid = !this.instruction.name || !this.instructionType;
    this.wizardSteps[1].invalid = !this.instruction.instructions?.length
      || !this.instruction.instructions.every(x => x.description || x.file);
    this.wizardSteps[2].invalid = !(this.selectedTypesAndModels.types.length + this.selectedTypesAndModels.models.length);
  }
}
