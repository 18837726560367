<div class="firmware-report-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Firmware Report</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section search-block align-container">
    <div class="filter-query">
      <app-search-bar (search)="search($event)" [searching]="loading"
          placeholder="Search firmware version or model"></app-search-bar>
    </div>
    <div class="device-type">
      <app-dropdown [items]="deviceTypesItems" [(ngModel)]="deviceType" (ngModelChange)="updateData()"
          placeholder="Select device type"></app-dropdown>
    </div>
    <div class="firmware-kind">
      <app-dropdown [items]="firmwareKinds" [(ngModel)]="firmwareKind" (ngModelChange)="updateData()"
          placeholder="Select kind"></app-dropdown>
    </div>
    <div class="firmware-type">
      <app-dropdown [items]="firmwareTypes" [(ngModel)]="firmwareType" (ngModelChange)="updateData()"
          placeholder="Select type"></app-dropdown>
    </div>
    <div class="incomplete-only">
      <app-checkbox [(ngModel)]="activeOnly" (ngModelChange)="updateData()">Active only</app-checkbox>
    </div>
    <app-actions [actions]="exportActions">
      <button class="button-export-options">Export</button>
    </app-actions>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th class="fixed-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.VERSION" (sort)="setSorting($event)">
                Version
              </app-sortable-header>
            </th>
            <th class="fixed-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.KIND" (sort)="setSorting($event)">
                Kind
              </app-sortable-header>
            </th>
            <th class="fixed-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.TYPE" (sort)="setSorting($event)">
                Type
              </app-sortable-header>
            </th>
            <th class="wide-column">
              Device Type
            </th>
            <th class="wide-column">
              Model
            </th>
            <th class="fixed-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.CREATED_AT" (sort)="setSorting($event)">
                Date
              </app-sortable-header>
            </th>
            <th class="fixed-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ACTIVE" (sort)="setSorting($event)">
                Active
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.AVAILABLE_FOR" (sort)="setSorting($event)">
                Available for (devices)
              </app-sortable-header>
            </th>
            <th class="fixed-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.INSTALLED_ON" (sort)="setSorting($event)">
                Installed on (devices)
              </app-sortable-header>
            </th>
            <th class="fixed-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.LAST_INSTALLED" (sort)="setSorting($event)">
                Last Installed
              </app-sortable-header>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of reportRows?.content">
            <td class="nowrap">{{row.version || '&mdash;'}}</td>
            <td class="nowrap capitalize">{{row.kind || '&mdash;'}}</td>
            <td class="nowrap capitalize">{{row.type || '&mdash;'}}</td>
            <td *ngIf="row.deviceTypes?.length">
              <div class="overflow-ellipsis"
                [ngbPopover]="fullList" triggers="manual" #popover="ngbPopover" container="body"
                (mouseenter)="showPopover(popover, row, true)" (mouseleave)="hidePopover(popover)">
                {{concat(row.deviceTypes)}}
              </div>
            </td>
            <td *ngIf="!row.deviceTypes?.length">&mdash;</td>
            <td *ngIf="row.deviceModels?.length">
              <div class="overflow-ellipsis"
                [ngbPopover]="fullList" triggers="manual" #popover="ngbPopover" container="body"
                (mouseenter)="showPopover(popover, row, false)" (mouseleave)="hidePopover(popover)">
                {{concat(row.deviceModels)}}
              </div>
            </td>
            <td *ngIf="!row.deviceModels?.length">&mdash;</td>
            <td class="nowrap">{{row.createdAt ? (row.createdAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td class="table-check" [class.checked]="row.state === FirmwareState.PUBLISHED"></td>
            <td class="nowrap">
              {{row.availableFor || 0}}&nbsp;
              <b *ngIf="row.availableForPercent !== undefined && row.availableForPercent !== null">
                ({{row.availableForPercent | number:(row.availableForPercent > 1 ? '0.0-1' : '0.0-2')}}%)
              </b>
            </td>
            <td class="nowrap">{{row.installedOn || 0}}</td>
            <td class="nowrap">{{row.lastInstalledAt ? (row.lastInstalledAt | date:DATE_FORMAT) : '&mdash;'}}</td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!reportRows || !reportRows?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="reportRows && reportRows.totalPages > 1">
      <div class="text-info">Showing {{reportRows.startElement}} to {{reportRows.endElement}} of {{reportRows.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="reportRows.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div>This report is for monitoring situations with IoT devices’ firmwares – situation “for now”.</div>
  <div class="top-space">Here you can see a lot of information about every firmware:</div>
  <div class="top-space"><b>STATICAL INFORMATION:</b></div>
  <ul class="info-list top-space">
    <li><b>Version</b> – version of this firmware</li>
    <li><b>Kind</b> – kind of his firmware, can be:<br/>
      <ol class="info-list top-space">
        <li><b>Universal</b> – for all devices</li>
        <li><b>Type</b> – for some device types</li>
        <li><b>Model</b> – for some device models</li>
      </ol>
    </li>
    <li><b>Type</b> – Device Type If this firmware has “Type” kind.</li>
    <li><b>Model</b> – Device Model If this firmware has “Model” kind.</li>
    <li><b>Date</b> – release date of this firmware.</li>
  </ul>
  <div class="top-space"><b>DYNAMIC INFORMATION:</b></div>
  <ul class="info-list top-space">
    <li><b>Active</b> – activity state of this firmware.</li>
    <li><b>Available for (devices)</b> – how many devices this firmware is suitable for. And percent of the total amount of activated devices.</li>
    <li><b>Installed on</b> – how many devices have this firmware. And percent of the total amount of devices that are suitable this firmware for (according to the set percentage of firmware rollout).</li>
  </ul>
  <div class="top-space">In this report you can:</div>
  <ul class="info-list top-space">
    <li>To search the firmware by the version or by device model
    <li>To use filters by
      <ul class="info-list">
        <li>Device type, firmware type, firmware kind.</li>
        <li>By state – to show only active firmwares.</li>
      </ul>
    </li>
    <li>To download results of the report to your PC – you can choose format: “*.xlsx” or “*.csv”.</li>
  </ul>
</ng-template>
<ng-template #fullList let-list="list">
  <div class="tip-content">
    <div class="tip-body">
      <div class="comma-separated">
        <span *ngFor="let item of list">{{item}}</span>
      </div>
    </div>
  </div>

