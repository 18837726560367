<div class="models-selection-area">
  <div class="filter-controls">
    <div class="device-type-select">
      <app-dropdown [items]="deviceTypes" [(ngModel)]="deviceType" [disabled]="disabled"
        (ngModelChange)="deviceTypesSelectionChange()" placeholder="Select device type">
      </app-dropdown>
    </div>
    <div class="product-line-select">
      <app-dropdown [items]="productLines" [(ngModel)]="productLine" [clearable]="true" [disabled]="disabled"
        (ngModelChange)="productLinesSelectionChange()" placeholder="Select product line">
      </app-dropdown>
    </div>
    <div class="query-field">
      <app-search-bar [ngModel]="query" (search)="search($event)"
        placeholder="Search model" [disabled]="disabled || !deviceType">
      </app-search-bar>
    </div>
  </div>
  <div class="info-bar align-container space-between">
    <div class="select-all">
      <app-checkbox [(ngModel)]="allModelsSelected" (ngModelChange)="selectAll($event)" [disabled]="!deviceType && !productLine || disabled">All</app-checkbox>
    </div>
    <div class="selected-counter">
      <span class="title">Selected Models:&nbsp;</span>
      <span class="counter">{{value?.length || 0}}</span>
    </div>
  </div>
  <div class="models-area">
    <div class="no-models-stub" *ngIf="!productLine && !deviceType">
      Please select device type or product line<br/>to see the list of models
    </div>
    <perfect-scrollbar *ngIf="productLine || deviceType">
      <div class="scroll-area">
        <ng-container *ngFor="let model of models">
          <div class="model-row" *ngIf="model.included" [class.invalid]="model.invalid" [class.hidden]="model.hidden">
            <app-checkbox [disabled]="disabled" [(ngModel)]="model.selected" (ngModelChange)="modelSelected(model)">
              {{model.model.name}}
            </app-checkbox>
          </div>
        </ng-container>
      </div>
    </perfect-scrollbar>
  </div>
</div>
