<div class="dialog-content">
  <div class="dialog-header">
    <span class="title">{{data.title}}</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <app-tree-view [root]="preparedTree" [selectedNode]="selectedNode" (nodeSelected)="selectNode($event)">
    </app-tree-view>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>{{data.cancelButtonText || 'Cancel'}}</button>
    <button class="primary" (click)="proceed()">{{data.agreeButtonText || 'OK'}}</button>
  </div>
</div>
