<div class="modifications-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Modifications</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section filtering-panel align-container space-between">
    <div class="filtering-block align-container">
      <div class="search-model">
        <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search name"></app-search-bar>
      </div>
      <div class="status">
        <app-dropdown [items]="statusItems" [(ngModel)]="status" (ngModelChange)="updateData(true)"
          placeholder="Select status"></app-dropdown>
      </div>
    </div>
    <div class="buttons">
      <button class="primary button-add" (click)="createEditModificationPatch()">Add New</button>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.NAME" (sort)="setSorting($event)">
                Name
              </app-sortable-header>
            </th>
            <th>
              Models
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                Updated
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.AUTHOR" (sort)="setSorting($event)">
                Author
              </app-sortable-header>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of modifications?.content">
            <td class="break-word">{{row.name || '&mdash;'}}</td>
            <td>
              <div class="count-wrapper" [ngbPopover]="rowInfo" triggers="manual" #popover="ngbPopover" container="body" [placement]="['bottom', 'top']"
                  (mouseenter)="showPopover(popover, row)" (mouseleave)="hidePopover(popover, row)" popoverClass="modification-models-popover">
                <span class="models-count">
                  <span class="successfull-count">{{(row.modelsCount || 0) - (row.invalidCount || 0)}}</span>
                  <span class="spacer" *ngIf="row.invalidCount">&nbsp;/&nbsp;</span>
                  <span class="invalid-count" *ngIf="row.invalidCount">{{row.invalidCount}}</span>
                </span>
              </div>
            </td>
            <td>
              <div class="capitalize" [class.activity-status]="!!row.status"
                  [class.active]="row.status === ModificationStatus.ACTIVE"
                  [class.inactive]="row.status !== ModificationStatus.ACTIVE">
                {{row.status || '&mdash;'}}
              </div>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td>{{row.author || '&mdash;'}}</td>
            <td><app-actions [actions]="row.actions" [disabled]="progressing"></app-actions></td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!modifications || !modifications?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="modifications && modifications.totalPages > 1">
      <div class="text-info">Showing {{modifications.startElement}} to {{modifications.endElement}} of {{modifications.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="modifications.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
<div><b>Modifications</b> - section for managing Modifications that is to be applied to model JSON configurations in order to change the model JSON configuration to maintain the specific behavior of the device.</div>
<div class="less-space"><b>Modification file (patch)</b> is a file containing information about modifications to the basic logical configuration file necessary to maintain the specific behavior of the device.</div>
<div class="top-space">In this section you can:</div>
<ol class="info-list min-space">
<li><b>Add a new Modification</b> - you can add a new modification by uploading a modification file, creating it manually in editor or with the help of constructor. A modification can be created for one or more model of the same device type.</li>
<li><b>Save a Modification</b> - you can save a Modification a draft and you can Save&amp;Apply the Modification.</li>
<li><b>Edit</b> an existing Modification.</li>
<li><b>Archive</b> a Modification.</li>
</ol>
<div class="less-space">The modification file <u>must</u> be a json with a structure corresponding to the JSON Patch format.</div>
<div class="less-space">A JSON Patch (modification) <u>must</u> contain an array of modification operations. The following modification operations supported by JSON Patch:</div>
<ul class="info-list min-space">
<li><b>add</b> - adds a value to an object or inserts it into an array</li>
<li><b>remove</b> - removes a value from an object or array</li>
<li><b>replace</b> - replaces a value. Equivalent to a “remove” followed by an “add”</li>
<li><b>stepToList</b> - a custom operation that changes the type of chosen json element</li>
</ul>
<div class="less-space">The operations are applied in order: if any of them fail then the whole patch operation should abort.</div>
</ng-template>
<ng-template #rowInfo let-row="row" let-popover="popover">
  <div class="tip-content" (mouseenter)="showPopover(popover, row)" (mouseleave)="hidePopover(popover, row)">
    <div class="tip-body">
      <div class="label">Device type:</div>
      <div class="field-value pt4">
        {{row.type?.name || '&mdash;'}}
      </div>
      <div class="label pt16">Device models:</div>
      <div class="field-value pt4 models-field scroll-container" *ngIf="row.models">
        <perfect-scrollbar>
          <div class="scroll-content">
            <span class="model-name" *ngFor="let model of row.models; index as index"
              [class.red]="model.invalid">{{model.name || '&mdash;'}}<span *ngIf="index < row.models.length - 1">,&nbsp;</span></span>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="field-value pt4" *ngIf="!row.models">&mdash;</div>
    </div>
  </div>
</ng-template>
