import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import { FirmwareService } from '@services/firmware.service';
import { TestingRecord, Testing, TestingDetails, TestingBase, TestingVirtualDevice, VirtualDeviceSimplified,
    TestingEntryStatus, TestingEntryStatusWeight, TestingStatus, TestingModel, TestingVirtualDeviceStatus, TestingDeviceStatus,
    DeviceFirmwareUpdateStatus, FirmwareUpdateRequest, TestingTestStatusUpdate } from '@models/testing';
import { TestingFirmware } from '@models/firmware';
import { Observable, Subject, Subscription, interval, merge, mergeMap, switchMap, map,
  of, filter, empty, tap, takeUntil, takeWhile, catchError, take } from 'rxjs';
import { THROW_IF_NOT_ALLOWED } from '@constants/http-params';

export const STATUS_REFRESH_TIMEOUT = 10000;

@Injectable({
  providedIn: 'root'
})
export class TestingService {

  private firmwareUpdateTrackRequest = new Subject<void>();
  private smartDevicesMonitoringTrackRequest = new Subject<void>();
  private smartDevicesMonitoringTracking = new Map<string, Observable<TestingVirtualDeviceStatus>>();
  private smartDevicesMonitoringConcentrator = new Subject<TestingVirtualDeviceStatus>();
  private smartDevicesMonitoring: Subscription;
  private testingTrackRequest = new Subject<void>();

  constructor(private http: HttpClient, private utils: Utils, private firmwareService: FirmwareService) {}

  public getTestingRecords(query: string, page: number, sorting: Sorting): Observable<Paged<TestingRecord>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<TestingRecord>>(`/test-firmware-service/api/v1/console/records`,
        {params}).pipe(tap((paged: Paged<TestingRecord>) => {
      Utils.extendPaged(paged);
      paged.content.forEach(x => this.fixDates(x));
    }));
  }

  public getTestings(query: string, page: number, sorting: Sorting): Observable<Paged<Testing>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<Testing>>(`/test-firmware-service/api/v1/console/tests`,
        {params}).pipe(tap((paged: Paged<Testing>) => {
      Utils.extendPaged(paged);
      paged.content.forEach(x => this.fixDates(x));
    }));
  }

  public getVirtualSmartDevices(page: number, sorting: Sorting): Observable<Paged<TestingVirtualDevice>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<TestingVirtualDevice>>(`/test-firmware-service/api/v1/console/virtual-devices/all`,
        {params}).pipe(tap((paged: Paged<TestingVirtualDevice>) => {
      Utils.extendPaged(paged);
    }));
  }

  public getTestingStatus(testingId: number): Observable<TestingStatus> {
    return this.http.get<TestingStatus>(`/test-firmware-service/api/v1/console/records/${testingId}/status`);
  }

  public getTestingDeviceAndStatus(deviceMac: string): Observable<TestingDeviceStatus> {
    return this.http.get<TestingDeviceStatus>(`/test-firmware-service/api/v1/console/device/recorder/record-is-possible`,
      {params: {deviceMac}});
  }

  public getTestingDevices(): Observable<Array<VirtualDeviceSimplified>> {
    return this.http.get<Array<VirtualDeviceSimplified>>(`/test-firmware-service/api/v1/console/tests/players`);
  }

  public getTestingModels(): Observable<Array<TestingModel>> {
    return this.http.get<Array<TestingModel>>(`/test-firmware-service/api/v1/console/tests/models`);
  }

  public getFirmwareUpdateStatus(trackId: string): Observable<TestingVirtualDeviceStatus> {
    return this.http.get<TestingVirtualDeviceStatus>(`/test-firmware-service/api/v1/console/device/upgrade/polling`, {params: {trackId}});
  }

  public trackFirmwareUpdateStatus(request: FirmwareUpdateRequest): Observable<TestingVirtualDeviceStatus> {
    this.cancelFirmwareUpdateTrack();
    return this.createFirmwareUpdateTrack(request, this.firmwareUpdateTrackRequest.asObservable());
  }

  public cancelFirmwareUpdateTrack(): void {
    this.firmwareUpdateTrackRequest.next();
  }

  public trackTestingStatus(trackId: number): Observable<TestingStatus> {
    this.cancelTestingTracking();
    return this.createTestingTracking(trackId);
  }

  public cancelTestingTracking(): void {
    this.testingTrackRequest.next();
  }

  public setTestingMonitoringOnTrack(testings: Array<TestingBase>): Observable<TestingTestStatusUpdate> {
    this.cancelTestingTracking();
    const tracks = testings.map(testing => this.createTestingTracking(testing.id)
      .pipe(tap((x) => testing.status = x), map((x) => ({testing, status: x}))));
    return merge(...tracks);
  }

  public setVirtualSmartDevicesMonitoringOnTrack(devices: Array<TestingVirtualDevice>): Observable<TestingVirtualDeviceStatus> {
    const cancel = this.smartDevicesMonitoringTrackRequest.asObservable();
    this.smartDevicesMonitoringTracking.clear();
    devices.forEach(device => this.smartDevicesMonitoringTracking.set(device.trackId,
      this.createFirmwareUpdateTrack(Object.assign({fwVersion: 'unknown'}, device), cancel)));
    this.updateSmartDevicesTracker();
    return this.smartDevicesMonitoringConcentrator.asObservable();
  }

  public appendVirtualSmartDeviceToMonitoring(device: TestingVirtualDevice, fwVersion: string): Observable<TestingVirtualDeviceStatus> {
    const cancel = this.smartDevicesMonitoringTrackRequest.asObservable();
    const deviceTrack = this.createFirmwareUpdateTrack(Object.assign({fwVersion}, device), cancel);
    this.smartDevicesMonitoringTracking.set(device.trackId, deviceTrack);
    this.updateSmartDevicesTracker();
    return this.smartDevicesMonitoringConcentrator.asObservable().pipe(filter(x => x.deviceMac === device.deviceMac));
  }

  public cancelVirtualSmartDeviceMonitoring(): void {
    this.smartDevicesMonitoringTrackRequest.next();
    if (this.smartDevicesMonitoring) {
      this.smartDevicesMonitoring.unsubscribe();
    }
  }

  public awaitsDeviceReady(deviceMac: string, cancel: Observable<any>): Observable<boolean> {
    return this.getTestingDeviceAndStatus(deviceMac).pipe(mergeMap(status => {
      const done = new Subject<void>();
      return this.isDeviceReady(status) ? of(true) : interval(STATUS_REFRESH_TIMEOUT).pipe(
        takeUntil(done),
        takeUntil(cancel || empty()),
        mergeMap(x => this.getTestingDeviceAndStatus(deviceMac)),
        map(status => this.isDeviceReady(status)),
        catchError(err => of(false)),
        filter(value => !!value),
        tap(x => done.next())
      );
    }));
  }

  public runUploadFirmware(deviceMac: string, firmware: TestingFirmware): Observable<string> {
    return this.http.post<string>(`/test-firmware-service/api/v1/console/device/upgrade/${deviceMac}`, {...firmware},
      {headers: new HttpHeaders({[THROW_IF_NOT_ALLOWED]: 'true'})});
  }

  public runUploadLatestFirmware(deviceMac: string): Observable<string> {
    return this.http.post<string>(`/test-firmware-service/api/v1/console/device/upgrade-for-recorder`, {}, {params: {deviceMac}});
  }

  public restoreOriginalFirmware(deviceMac: string): Observable<{fw: string, trackId: string}> {
    return this.firmwareService.getLatestTestingFirmware(deviceMac).pipe(
      switchMap((firmware: TestingFirmware) => this.runUploadFirmware(deviceMac, firmware)
        .pipe(map((trackId: string) => ({fw: firmware.version, trackId})))));
  }

  public runRecording(clientMac: string): Observable<TestingRecord> {
    return this.http.post<TestingRecord>(`/test-firmware-service/api/v2/ci-runner/recorder/trigger`, {clientMac});
  }

  public runTesting(clientMac: string, playerMac: string, modelId: number): Observable<Testing> {
    return this.http.post<Testing>(`/test-firmware-service/api/v2/ci-runner/player/trigger`,
      {clientMac, playerMac, modelId});
  }

  public getTestingDetails(testingId: number): Observable<TestingDetails> {
    return this.http.get<TestingDetails>(`/test-firmware-service/api/v1/console/records/${testingId}`).pipe(
        tap((testing: TestingDetails) => {
      testing.recordTestCases = testing.recordTestCases || [];
      this.fixDates(testing);
      const active = testing.recordTestCases.find(x => x.state === TestingEntryStatus.PROCESSING);
      if (active) {
        const testCases = [...testing.recordTestCases]
          .filter(x => x.state === TestingEntryStatus.PROCESSING)
          .sort((x, y) => x.orderNum - y.orderNum);
        testCases.length && (testCases[0].state = TestingEntryStatus.RUNNING);
      }
      testing.recordTestCases.forEach(x => x.sortStatus = TestingEntryStatusWeight[x.state]);
    }));
  }

  private createFirmwareUpdateTrack(request: FirmwareUpdateRequest, cancelTracking?: Observable<void>): Observable<TestingVirtualDeviceStatus> {
    const errorStatusStab = {deviceMac: request.deviceMac, fwVersion: request.fwVersion, status: DeviceFirmwareUpdateStatus.ERROR};
    const endTracking = new Subject<void>();
    return interval(STATUS_REFRESH_TIMEOUT).pipe(
      takeUntil(endTracking),
      takeUntil(cancelTracking || empty()),
      mergeMap(x => this.getFirmwareUpdateStatus(request.trackId)),
      catchError(err => of(errorStatusStab)),
      filter(status => this.relevantStatus(status.status)),
      take(1),
      tap(() => {endTracking.next(); this.smartDevicesMonitoringTracking.delete(request.trackId); })
    );
  }

  private createTestingTracking(testingId: number): Observable<TestingStatus> {
    const done = new Subject<void>();
    return interval(STATUS_REFRESH_TIMEOUT).pipe(
      takeUntil(done),
      takeUntil(this.testingTrackRequest.asObservable()),
      mergeMap(x => this.getTestingStatus(testingId)),
      catchError(x => of(TestingStatus.ERROR)),
      tap(x => this.isTerminalTestingStatus(x) && done.next())
    );
  }

  private updateSmartDevicesTracker(): void {
    this.cancelVirtualSmartDeviceMonitoring();
    if (this.smartDevicesMonitoringTracking?.size) {
      const combinedTrack = merge(...Array.from(this.smartDevicesMonitoringTracking.values()));
      this.smartDevicesMonitoring = combinedTrack.subscribe(value => this.smartDevicesMonitoringConcentrator.next(value));
    }
  }

  public isTerminalTestingStatus(status: TestingStatus): boolean {
    return status !== TestingStatus.PENDING && status !== TestingStatus.RUNNING;
  }

  public isDeviceReady(status: TestingDeviceStatus): boolean {
    return status.online && !status.upgradeId && !status.testingId;
  }

  private relevantStatus(status: DeviceFirmwareUpdateStatus): boolean {
    const substantialStatuses = [DeviceFirmwareUpdateStatus.CANCELLED,
      DeviceFirmwareUpdateStatus.ERROR, DeviceFirmwareUpdateStatus.COMPLETED];
    return substantialStatuses.includes(status);
  }

  private fixDates(test: TestingBase): TestingBase {
    test.started = test.started && new Date(test.started);
    test.ended = test.ended && new Date(test.ended);
    return test;
  }

}
