import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter, forwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MediaDescriptor, MediaType } from '@models/informational';
import { UtilitiesService } from '@services/utilities.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Subject, takeUntil, finalize, skip } from 'rxjs';
import { UNSUPPORTED, BAD_REQUEST, MAX_MEDIA_SIZE } from '@constants/http-params';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ImageUploadComponent),
    multi: true
  }]
})
export class ImageUploadComponent implements OnInit, ControlValueAccessor {

  public file: MediaDescriptor;
  public uploading = false;
  public error = '';
  public oversize = false;
  public disabled = false;
  public MediaType = MediaType;

  @Input()
  public dropOnDelete = false;

  @Input()
  public acceptedFormats = '.jpg, .jpeg, .png, .mp4';

  @Output()
  public progressing = new EventEmitter<boolean>();

  @ViewChild('input') public input: ElementRef;

  private destroyed = new BehaviorSubject<boolean>(false);
  private cancelled = new Subject<boolean>();
  private onTouchedCallback: () => void;
  protected commit(value: MediaDescriptor) {}

  constructor(private utilitiesService: UtilitiesService) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete(); 
  }

  public selectFile(): void {
    if (!this.disabled && !this.file) {
      this.input.nativeElement.click();
    }
  } 

  public fileSelected(event: Event): void {
    if (this.disabled || this.error || this.uploading) {
      return;
    }
    // @ts-ignore
    const files: File[] = event.target.files;
    if (files && files.length > 0) {
      if (files[0].size < MAX_MEDIA_SIZE) {
        this.uploading = true;
        this.progressing.emit(true);
        this.utilitiesService.uploadMedia(files[0])
          .pipe(takeUntil(this.cancelled), takeUntil(this.destroyed.pipe(skip(1))), finalize(() => {
            this.uploading = false;
            this.input.nativeElement.value = '';
          }))
          .subscribe((file: MediaDescriptor) => {
            this.file = file;
            this.commit(this.file);
          }, (error: HttpErrorResponse) => {
            if (error.status === BAD_REQUEST || error.status === UNSUPPORTED) {
              this.error = 'The following file formats are allowed: jpg, png, jpeg, mp4';
            } else {
              this.error = 'Failed to upload';
            }
          });
      } else {
        this.oversize = true;
      }
    }
  }

  public clear(event: MouseEvent): void {
    if (this.file && this.dropOnDelete) {
      this.utilitiesService.dropMedia(this.file).subscribe(
        () => {},
        (error: HttpErrorResponse) => {console.error('Failed to delete file');}
      );
    }
    this.file = null;
    this.input.nativeElement.value = '';
    this.commit(this.file);
    event.stopPropagation();
  }

  public cancel(event: MouseEvent): void {
    this.cancelled.next(true);
    event.stopPropagation();
  }

  public hideError(event: MouseEvent): void {
    this.error = null;
    this.oversize = false;
    this.input.nativeElement.value = '';
    event.stopPropagation();
  }

  public writeValue(value: MediaDescriptor) {
    if (this.destroyed.value) {
      return;
    }
    this.file = value;
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }


  

}

