import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { UserInfoService } from '@services/user-info.service';
import { NotificationService } from '@services/notification.service';
import { InformationalService } from '@services/informational.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { Utils } from '@services/utils';
import {DeviceModel, DeviceModelTags, GroupingType, ModelStatus} from '@models/device-models';
import { Breadcrumbs } from '@models/breadcrumbs';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { Tag } from '@models/informational';
import { UserInfo } from '@models/user-info';
import { BAD_REQUEST, HttpParams } from '@constants/http-params';
import { DATE } from '@constants/dates';
import { Observable, map, finalize, lastValueFrom, takeUntil, Subject } from 'rxjs';
import { ModelAssignGroupDialogComponent } from "../../components/model-assign-group-dialog/model-assign-group-dialog.component";
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogIcon } from "@models/dialogs";

@Component({
  selector: 'app-device-model-view',
  templateUrl: './device-model-view.component.html',
  styleUrls: ['./device-model-view.component.scss']
})
export class DeviceModelViewComponent implements OnInit, OnDestroy {

  public deviceModelId: number = null;
  public deviceModel: DeviceModel = null;
  public loading = false;
  public DATE_FORMAT = DATE;
  public tagsEditMode = false;
  public copyTagsModel: DropdownItem;
  public modelsSearchProvider: LookupProvider;
  public tagsSearchProvider: LookupProvider;
  public tags: Array<DropdownItem>;
  public approved = false;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Device Models', link: ['/administration', 'device-models']}];
  public activeUser: Observable<UserInfo>;
  @ViewChild('information') information: TemplateRef<any>;
  private destroyed = new Subject<void>();

  constructor(private modelsService: DeviceModelsService, private dialogService: DialogService,
    private informationalService: InformationalService, private notificationService: NotificationService,
    private userInfoService: UserInfoService, private utils: Utils, private router: Router) { }

  public ngOnInit(): void {
    this.activeUser = this.userInfoService.getActiveUser();
    this.deviceModelId = Number(this.utils.lookupParam(HttpParams.MODEL_ID));
    if (this.deviceModelId !== null) {
      this.reload();
    }
    this.modelsSearchProvider = {search: (query: string) => {
      return this.modelsService.lookupModelsTags(query).pipe(map((models: Array<DeviceModelTags>) => {
        return (models || []).map(x => ({value: x.id, title: x.modelName, additional: '' + (x.tags || []).length, original: x}));
      }));
    }};
    this.tagsSearchProvider = {search: (query: string) => {
      return this.modelsService.getModelsTags(query).pipe(map((tags : Array<Tag>) => {
        return (tags || []).map(x => ({value: x.id, title: x.name}));
      }));
    }};
  }

  public ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Model Card',
      text: this.information
    }});
  }

  public startEditTags(): void {
    this.copyTagsModel = null;
    this.tags = (this.deviceModel.tags || []).map(x => ({value: x.id, title: x.name}));
    this.tagsEditMode = true;
  }

  public copyTags(): void {
    if (this.copyTagsModel && this.tagsEditMode) {
      this.tags = (this.copyTagsModel.original.tags || []).map((x: Tag) => ({value: x.id, title: x.name}));
      this.copyTagsModel = null;
    }
  }

  public endEditTags(): void {
    if (this.tagsEditMode) {
      this.modelsService.applyModelTags(this.deviceModel.id, this.tags.map(x => ({id: x.value, name: x.title})))
          .subscribe((tags: Array<Tag>) => {
        this.deviceModel.tags = tags;
        this.tagsEditMode = false;
      }, (err: HttpErrorResponse) => {
        this.notificationService.error(err.message);
      });
    }
  }

  public cancelEditTags(): void {
    this.tagsEditMode = false;
  }

  public setApproved(): void {
    this.modelsService.setModelApproval(this.deviceModel.id, !!this.deviceModel.firmwares?.approved).subscribe(
      (updatedStatus: ModelStatus) => {
        this.deviceModel.status = updatedStatus;
        this.informationalService.updateGeneralInformation();
      },
      (err: HttpErrorResponse) => this.notificationService.error(err.message)
    );
  }

  public deleteModel(): void {
    this.dialogService.showModal(ConfirmationDialogComponent, {
      data: {
        title: 'Delete Model?',
        text: `Confirm you want to delete model. <br/>This action is irreversible`,
        agreeButtonText: 'Delete',
        icon: ConfirmDialogIcon.WARNING,
      }
    }).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.loading = true;
        this.modelsService.deleteModelByName(this.deviceModel.customerModel)
          .pipe(takeUntil(this.destroyed), finalize(() => this.loading = false))
          .subscribe(() => {
            this.notificationService.success(`Model ${this.deviceModel.customerModel} successfully deleted`);
            this.router.navigate(['/administration', 'device-models']);
          }, (err: HttpErrorResponse) => {
            if (err.status === BAD_REQUEST) {
              this.dialogService.showModal(InformationDialogComponent, { width: '600px', data: {
                  title: 'Model can not be delete',
                  text: 'This model has active devices attached and can not be deleted'
                }});
            } else {
              this.notificationService.error(`Failed to delete model ${this.deviceModel.customerModel}`);
            }
          });
      }
    });
  }

  public navigateInstructions(): void {
    this.navigateWithSecurityCheck(['/administration', 'instructions'], this.deviceModel.customerModel);
  }

  public navigateFirmware(): void {
    this.navigateWithSecurityCheck(['/provisioning', 'firmware'], this.deviceModel.customerModel);
  }

  public navigateModelConfigs(): void {
    this.navigateWithSecurityCheck(['/administration', 'model-configs'],
      this.deviceModel.modelConfig ? this.deviceModel.customerModel : null);
  }

  public navigateJsonConfigs(): void {
    this.navigateWithSecurityCheck(['/administration', 'device-configs'],
      this.deviceModel.deviceConfig ? this.deviceModel.boardId : null);
  }

  private reload(): void {
    this.loading = true;
    this.modelsService.getDeviceModelById(this.deviceModelId).pipe(finalize(() => this.loading = false))
        .subscribe((model: DeviceModel) => {
      this.deviceModel = model;
      this.breadcrumbs.push({title: model.customerModel});
      setTimeout(() => this.checkEppGroupAssigned(), 3000);
    });
  }

  private async navigateWithSecurityCheck(address: Array<string>, query?: string): Promise<void> {
    const activeUser = await lastValueFrom(this.activeUser);
    if (activeUser.isAdmin) {
      this.router.navigate(address, query ? {queryParams: {query}} : undefined);
    } else {
      this.userInfoService.showPermissionViolation();
    }
    return null;
  }

  private checkEppGroupAssigned(): void {
    if (this.deviceModel.modelConfig && this.deviceModel.deviceConfig) {
      if (this.deviceModel.testCover === null || !this.deviceModel.groupList?.length) {
        const groupsWithSameEpp = (this.deviceModel.groupWithSameIdentificationCode || []).filter(x => {
          return !this.deviceModel.groupList.some(y => y.id === x.id);
        });
        if (groupsWithSameEpp.length) {
          this.dialogService.showModal(ModelAssignGroupDialogComponent, { maxWidth: '500px', data: {
            model: {id: this.deviceModel.id, name: this.deviceModel.customerModel}, groups: this.deviceModel.groupWithSameIdentificationCode || []
          }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
            if (result) {
              this.reload();
            }
          });
        } else if (this.deviceModel.boardId) {
          this.dialogService.showModal(ConfirmationDialogComponent, {
            data: {
              title: 'Create Epp Group?',
              text: `Among the existing model groups, a suitable group was not found, you can create a new group for the model`,
              agreeButtonText: 'Create new group'
            }
          }).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
            if (result) {
              const formPrefill = {
                deviceTypeId: this.deviceModel.deviceTypeId,
                groupingType: GroupingType.EPP,
                groupingEpp: this.deviceModel.boardId,
                models: [this.deviceModelId],
                path: this.router.url,
              };
              this.router.navigate(['/reports', 'models-group', 'create'], {state: formPrefill});
            }
          });
        }
      }
    }
  }

}
