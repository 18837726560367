<div class="models-group-edit-view inner-view">
  <div class="view-header with-breadcrumbs">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1 class="align-container small-spacing">
      <span>{{modelsGroup ? 'Edit' : 'New'}}&nbsp;Models Group</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section">
    <div class="panel">
      <div class="form-container" (mousedown)="resetValidation()">
        <div class="label">Group name</div>
        <div class="value">
          <input type="text" class="input-field" [(ngModel)]="groupName"
            placeholder="Enter Group Name" maxlength="255"/>
          <div class="validation" *ngIf="errors?.groupName">
            <div class="validation-icon"></div>
            <div *ngIf="errors.groupName?.required">Group Name is required</div>
            <div *ngIf="errors.groupName?.invalid">Group Name must meet requirements</div>
          </div>
        </div>
        <div class="label">Device Type</div>
        <div class="value">
          <app-dropdown [items]="deviceTypes" [(ngModel)]="deviceType" [disabled]="modelsGroup?.editLimited"
            (ngModelChange)="deviceTypeUpdated($event)" placeholder="Select device type">
          </app-dropdown>
          <div class="validation" *ngIf="errors?.deviceType">
            <div class="validation-icon"></div>
            <div *ngIf="errors.deviceType?.required">Device Type is required</div>
          </div>
        </div>
        <div class="label">Grouping Type</div>
        <div class="value">
          <app-dropdown [items]="groupingTypes" [(ngModel)]="groupingType" [disabled]="modelsGroup?.editLimited || !deviceType?.value"
                        (ngModelChange)="groupingTypeUpdated($event)" placeholder="Select grouping type">
          </app-dropdown>
        </div>
        <div class="label">Epp (IC)</div>
        <div class="value">
          <app-lookup [provider]="groupingEppProvider" [(ngModel)]="groupingEpp" [allowSearchEmpty]="true"
                      [magnifyingGlass]="true" [disabled]="modelsGroup?.editLimited || groupingType?.value === GroupingType.NONE || !deviceType?.value"
                      (ngModelChange)="fillAvailables()" placeholder="Select Epp/IC">
          </app-lookup>
        </div>
        <div class="label models-row">Models</div>
        <div class="value models-row">
          <div class="models-container">
            <perfect-scrollbar #scrollbar>
              <div class="scroll-area">
                <div class="models-line" *ngFor="let row of rows">
                  <div class="dropdown-container">
                    <app-lookup [provider]="row.provider" [(ngModel)]="row.model"
                      [magnifyingGlass]="true" [allowSearchEmpty]="true" [disabled]="row.disabled"
                      (ngModelChange)="fillAvailables()" placeholder="Select device model">
                    </app-lookup>
                  </div>
                  <button class="button button-remove-line" (click)="removeRow(row)" [disabled]="row.disabled"></button>
                  <div class="model-alarm" [class.visible]="row.groups?.length"
                     [ngbPopover]="warning" triggers="manual" #popover="ngbPopover" container="body" [placement]="['top-end']"
                     (mouseenter)="showPopover(popover, row)" (mouseleave)="hidePopover(popover)" popoverClass="shift-left">
                  </div>
                </div>
                <div class="models-line new-model" *ngIf="!rows.length || availableNewModels.length">
                  <div class="dropdown-container">
                    <app-lookup [provider]="newModelProvider" [(ngModel)]="newModel"
                      [magnifyingGlass]="true" [allowSearchEmpty]="true" placeholder="Select model"
                      (ngModelChange)="addModel()" [disabled]="!availableNewModels.length">
                    </app-lookup>
                  </div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
          <div class="validation" *ngIf="errors?.models">
            <div class="validation-icon"></div>
            <div *ngIf="errors.models?.required">Models are required</div>
          </div>
        </div>
      </div>
      <div class="action-buttons">
        <button class="primary" [disabled]="errors || modelsGroup?.status === DeviceModelGroupStatus.INACTIVE" (click)="save()">Save</button>
        <button class="secondary" (click)="cancel()">Cancel</button>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading">
  </ngx-loading>
</div>
<ng-template #information>
  <div><b>Models Groups Card</b> – all information about the model group, including a list of the models that belong to it:</div>
  <ul class="top-space">
    <li><b>Group name</b> – model group name</li>
    <li><b>Device Type</b> – the model type within which the model group is contained. A model group can only be assigned to devices of the same type  (e.g. only to the Washing Machine type)</li>
    <li><b>Grouping Type</b> – the characteristic by which the models are grouped, can be None or by belonging to one Epp</li>
    <li><b>Epp (IC)</b> – Board ID value to which the grouped models belong</li>
    <li><b>Status</b> – models group status (Active/ Inactive). <br/><u>Note:</u> The status of a model group can only be changed to Inactive when adding models to an existing group, and if the old model group is to be replaced by a new one with a new set of models. In this case, the old model group goes to Inactive status</li>
    <li><b>Created</b> – model group creation date</li>
    <li><b>Updated</b> – model group update date</li>
    <li><b>Models</b> – list of models that are included in the model group. Each model in the list contains the model name and a link to the model card</li>
  </ul>
</ng-template>
<ng-template #warning let-row="row">
  <div class="tip-content">
    <div class="tip-body">
      <div *ngIf="row.nonSmart" class="label">Need to add logical and epp JSON</div>
      <ng-container *ngIf="row.groups?.length">
        <div class="label" [ngClass]="{pt8: row.nonSmart}">This model already included in following groups:</div>
        <div class="field-value pt4">
          <div class="model-name inline-block nowrap" *ngFor="let group of row.groups; index as index">
            {{group.name || '&mdash;'}}<span *ngIf="index < row.groups.length - 1">,&nbsp;</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
