<div class="dialog-content device-instruction-confict">
  <div class="dialog-header">
    <div class="title-container">
      <div class="icon warning"></div>
      <div class="title">Instruction already exists</div>
    </div>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="dialog-contents">
      <div class="label-text">
        You have already created instructions for the following models. Please check those for which instruction will be updated:
      </div>
      <div class="models-selection">
        <perfect-scrollbar #scrollbar>
          <div class="row select-all">
            <app-checkbox [(ngModel)]="allSelected" (ngModelChange)="setAllSelected()">All</app-checkbox>
          </div>
          <div class="row" *ngFor="let row of rows"
            [ngbPopover]="rowInfo" triggers="manual" #popover="ngbPopover" container="body"
            (mouseenter)="showPopover(popover, row)" (mouseleave)="hidePopover(popover)">
            <app-checkbox [(ngModel)]="row.checked" (ngModelChange)="rowSelected(row)">
              <span class="checkbox-title" *ngIf="row.model">{{row.model.name}} / {{row.type.name}}</span>
              <span class="checkbox-title device-type" *ngIf="!row.model">{{row.type.name}} (all models)</span>
            </app-checkbox>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Cancel</button>
    <button class="primary" (click)="proceed()" [disabled]="progressing">Update Instructions</button>
  </div>
</div>
<ng-template #rowInfo let-row="row">
  <div class="tip-content missing-info">
    <div class="tip-title">{{row.model?.name || row.type?.name || '&mdash;'}}</div>
    <div class="tip-body">
      <div class="product-line-info" *ngIf="row.type?.productLines">
        <span class="tip-info-title">Product Line: </span>
        <span class="product-lines comma-separated" >
          <span class="product-line" *ngFor="let productLine of row.type?.productLines">{{productLine.name}}</span>
        </span>
      </div>
      <div class="tags-info" *ngIf="row.model?.tags">
        <span class="tip-info-title">Tags: </span>
        <span class="tags comma-separated" >
          <span class="tag" *ngFor="let tag of row.model?.tags">#{{tag.name}}</span>
        </span>
      </div>
    </div>
  </div>
</ng-template>
