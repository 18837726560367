import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Notification, NotificationType } from '@models/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notificator = new Subject<Notification>();

  constructor() { }

  public subscribe(handler: (x: Notification) => void): Subscription {
    return this.notificator.asObservable().subscribe(handler);
  }

  public success(message: string): void {
    this.notificator.next({type: NotificationType.SUCCESS, message});
  }

  public error(message: string): void {
    this.notificator.next({type: NotificationType.ERROR, message});
  }
}
