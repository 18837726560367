<div class="dialog-content modification-edit">
  <div class="dialog-header">
    <span class="title">{{(modificationDetails?.id ? 'Edit' : 'New') + ' Modification'}}</span>
    <div class="close-icon" (click)="close()"></div>
  </div>
  <div class="dialog-body">
    <div class="wizard-steps">
      <app-wizard-steps [steps]="wizardSteps"></app-wizard-steps>
    </div>
    <mat-tab-group mat-align-tabs="start" [selectedIndex]="selectedTab" (selectedIndexChange)="selectedTab = $event">
      <mat-tab label="Settings">
        <div class="form-layout">
          <div class="field-label">Modification name</div>
          <div class="field">
            <input type="text" #nameField [(ngModel)]="modificationName" (focus)="resetErrors()" maxlength="250"/>
          </div>
          <div class="field-models">
            <app-models-selection-box [typesAndModels]="typesAndModels" [disabled]="validating || applying || !!data?.model"
              [(ngModel)]="models" [invalidModels]="forceUseModels ? [] : invalidModels" (ngModelChange)="resetErrors()">
            </app-models-selection-box>
          </div>
          <div class="models-errors align-container space-between validation-block" *ngIf="errors || showForceModels">
            <div class="validation" *ngIf="errors">
              <div class="validation-icon"></div>
              <div class="text">
                <div class="message" *ngIf="errors.modifications">
                  Some configs already have modifications, exclude them or select forceUpload option
                </div>
                <div class="message" *ngIf="errors.models">
                  Select models to apply patch to
                </div>
                <div class="message" *ngIf="errors.name">
                  Modification name is required
                </div>
              </div>
            </div>
            <div class="force-use-models">
              <app-checkbox *ngIf="showForceModels" [(ngModel)]="forceUseModels" (ngModelChange)="checkModels()">Force Upload</app-checkbox>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Patch">
        <div class="patch-view">
          <div class="header-controls">
            <div></div>
            <div class="view-controls">
              <div class="view-tab left-tab" [class.active]="editorView" (click)="setEditorView(true)">Editor</div>
              <div class="view-tab right-tab" [class.active]="!editorView" (click)="setEditorView(false)">Constructor</div>
            </div>
            <div class="upload-button">
              <app-upload-button [linkStyle]="true" text="Upload File" extensions=".json"
                (changed)="handleJsonFile($event)"></app-upload-button>
            </div>
          </div>
          <div class="patch-layout" [class.enable-constructor]="!editorView">
            <div class="editor-view">
              <app-json-edit [(ngModel)]="json" #jsonEditor (click)="resetErrors()"></app-json-edit>
            </div>
            <div class="constructor-view">
              <app-config-patch-constructor #constructorView [configTree]="modelConfig" [(ngModel)]="json">
              </app-config-patch-constructor>
            </div>
          </div>
          <div class="validation-block" *ngIf="errors && (editorView || errors?.empty)">
            <div class="validation">
              <div class="validation-icon"></div>
              <div class="text">
                <div *ngIf="errors.invalid" class="message">JSON code is not valid</div>
                <div *ngIf="errors.empty" class="message">The patch does not contain any operation, make corrections and try again</div>
                <div *ngIf="errors.structure" class="message">File structure does not comply with jsonpatch requirements</div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Result">
        <div class="progress-in-process" *ngIf="validating || applying">
          <div class="loader-block">
            <div class="loader"></div>
            <div class="text" *ngIf="validating">Checking compatibility with JSON configs</div>
            <div class="text" *ngIf="applying">Applying patch to {{modelsList.length || 0}} configs</div>
          </div>
        </div>
        <app-config-patch-results [results]="modelsList" *ngIf="!validating && !applying" [validationPhase]="!applied"
          (modelRemoved)="excludeModel($event)" (detailsRequested)="viewModelPatch($event)">
        </app-config-patch-results>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="dialog-footer controls align-container space-between">
    <button class="secondary" (click)="close()" *ngIf="!applied">Cancel</button>
    <div class="main-buttons align-container mid-spacing">
      <ng-container *ngIf="!applied">
        <button class="secondary button-back" *ngIf="selectedTab === 1 || selectedTab === 2"
          (click)="previousTab()" [disabled]="applying">Prev</button>
        <ng-container *ngIf="!modificationDetails?.id || modificationDetails?.status !== ModificationStatus.ACTIVE">
          <button class="primary button-save-draft" *ngIf="selectedTab === 1 || selectedTab === 2"
            (click)="saveAsDraft()" [disabled]="validating || applying || saving">Save Draft</button>
        </ng-container>
        <button class="primary button-forward" *ngIf="selectedTab === 0 || selectedTab === 1"
          (click)="nextTab()">Next</button>
        <button class="primary button-apply" *ngIf="selectedTab === 2"
          (click)="saveApply()" [disabled]="validating || applying || saving || errors">Apply</button>
      </ng-container>
      <button class="primary button-close" *ngIf="applied" (click)="close()">Close</button>
    </div>
  </div>
</div>
