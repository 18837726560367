import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { ReportService } from '@services/report.service';
import { NotificationService } from '@services/notification.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DeviceType } from '@models/device-models';
import { FirmwareKind, FirmwareType, FirmwareState } from '@models/firmware';
import { FirmwareReportRow } from '@models/report';
import { DropdownItem } from '@models/dropdown';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DATE } from '@constants/dates';
import { ExportType } from '@constants/export';
import { HttpErrorResponse } from '@angular/common/http'; 
import { finalize } from 'rxjs';

enum SortColumn {
  VERSION = 'version',
  KIND = 'kind',
  TYPE = 'type',
  CREATED_AT = 'createdAt',
  ACTIVE = 'state',
  AVAILABLE_FOR = 'availableFor',
  INSTALLED_ON = 'installedOn',
  LAST_INSTALLED = 'lastInstalledAt'
}

@Component({
  selector: 'app-firmware-report-view',
  templateUrl: './firmware-report-view.component.html',
  styleUrls: ['./firmware-report-view.component.scss']
})
export class FirmwareReportViewComponent implements OnInit {

  public reportRows: Paged<FirmwareReportRow>;
  public deviceTypesItems: Array<DropdownItem> = [];
  public deviceType: DropdownItem;
  public firmwareKinds: Array<DropdownItem> = [
    {value: null, title: 'All Kinds'},
    {value: FirmwareKind.UNIVERSAL, title: 'Universal'},
    {value: FirmwareKind.MODEL, title: 'Model'},
    {value: FirmwareKind.TYPE, title: 'Type'}
  ];
  public firmwareKind: DropdownItem;
  public firmwareTypes: Array<DropdownItem> = [
    {value: null, title: 'All Types'},
    {value: FirmwareType.CRITICAL, title: 'Critical'},
    {value: FirmwareType.FUNCTIONAL, title: 'Functional'}
  ];
  public firmwareType: DropdownItem;
  public query: string = '';
  public activeOnly = false;
  public activePage = 1;
  public loading = false;
  public DATE_FORMAT = DATE;
  public FirmwareState = FirmwareState;
  public sorting: Sorting = {column: SortColumn.CREATED_AT, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public exportActions = [
    {title: 'XLSX', action: () => this.exportReport(ExportType.XLSX)},
    {title: 'CSV', action: () => this.exportReport(ExportType.CSV)}
  ];

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private modelsService: DeviceModelsService, private dialogService: DialogService,
    private notificationService: NotificationService, private reportService: ReportService) { }

  public ngOnInit(): void {
    this.fillDeviceTypes();
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData(false);
  }

  public search(query: string): void {
    this.query = query;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Firmware Report',
      text: this.information
    }});
  }

  public showPopover(popover: NgbPopover, row: FirmwareReportRow, types = false): void {
    popover.open({list: types ? row.deviceTypes : row.deviceModels});
  }

  public hidePopover(popover: NgbPopover): void {
    popover.close();
  }

  public concat(list: Array<string>): string {
    return list && list.join(', ');
  }

  public exportReport(exportType: ExportType): void {
    this.loading = true;
    this.reportService.exportFirmwareReport(this.query, this.deviceType?.value,
      this.firmwareKind?.value, this.firmwareType?.value, this.activeOnly, this.sorting, exportType)
        .pipe(finalize(() => this.loading = false)).subscribe(() => {}, (err: HttpErrorResponse) => {
      this.notificationService.error('Failed to export report');
    });
  }

  public updateData(resetPage = true): void {
    this.loading = true;
    this.reportService.getFirmwareReportData(this.query, this.deviceType?.value,
      this.firmwareKind?.value, this.firmwareType?.value, this.activeOnly, resetPage ? 1 : this.activePage, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe((rows: Paged<FirmwareReportRow>) => {
      this.reportRows = rows;
      this.activePage = resetPage ? 1 : this.activePage;
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire report data');
    });
  }

  private fillDeviceTypes(): void {
    this.loading = true;
    this.modelsService.getDeviceTypes().pipe(finalize(() => this.loading = false)).subscribe((deviceTypes: Array<DeviceType>) => {
      this.deviceTypesItems = [{value: null, title: 'All Device types'}]
        .concat(deviceTypes.map((x: DeviceType) => ({value: x.id, title: x.name})));
      this.deviceType = this.deviceTypesItems && this.deviceTypesItems[0];
      this.firmwareKind = this.firmwareKinds[0];
      this.firmwareType = this.firmwareTypes[0];
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire device types');
    });
  }

}
