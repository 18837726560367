<div class="dialog-content modification-view">
  <div class="dialog-header">
    <span class="title">{{patch?.name || '&mdash;'}}</span>
    <div class="close-icon" (click)="close()"></div>
  </div>
  <div class="dialog-body">
    <mat-tab-group (selectedIndexChange)="tabChanged($event)">
      <mat-tab label="Models">
        <div class="models-layout">
          <app-config-patch-results [results]="results" [validationPhase]="details?.status !== ModificationStatus.ACTIVE"
            [removingAllowed]="false" (detailsRequested)="viewModelDetails($event)">
          </app-config-patch-results>
        </div>
      </mat-tab>
      <mat-tab label="Patch">
        <div class="patch-layout">
          <app-json-edit [ngModel]="details?.patch" [disabled]="true" #jsonEditor>
          </app-json-edit>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="dialog-footer controls">
    <button class="primary button-close" (click)="close()">Cancel</button>
  </div>
</div>
