import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirmwareViewComponent } from './pages/firmware-view/firmware-view.component';
import { FirmwareListViewComponent } from './pages/firmwares-view/firmwares-view.component';
import { WifiModulesViewComponent } from './pages/wifi-modules-view/wifi-modules-view.component';
import { WifiModuleViewComponent } from './pages/wifi-module-view/wifi-module-view.component';
import { DeviceModuleDiagnosticPermissionViewComponent }
  from './pages/device-module-diagnostic-permission-view/device-module-diagnostic-permission-view.component';
import { HttpParams } from '@constants/http-params';
import { UserRoles } from '@models/user-info';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'firmware'
  },
  {
    path: 'firmware',
    pathMatch: 'full',
    component: FirmwareListViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  },
  {
    path: 'firmware/:' + HttpParams.FIRMWARE_ID,
    component: FirmwareViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  },
  {
    path: 'wifi-modules',
    component: WifiModulesViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.TECHNICIAN]}
  },
  {
    path: 'device-module/:' + HttpParams.MODULE_ID,
    component: WifiModuleViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.TECHNICIAN]}
  },
  {
    path: 'device-module/:' + HttpParams.MODULE_ID + '/:' + HttpParams.TAB_NAME,
    component: WifiModuleViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.TECHNICIAN]}
  },
  {
    path: 'device-module/:' + HttpParams.MODULE_ID + '/permissions/:' + HttpParams.PERMISSION_ID,
    component: DeviceModuleDiagnosticPermissionViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.TECHNICIAN]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProvisioningRoutingModule {}
