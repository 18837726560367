import { Component, Inject, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Utils } from '@services/utils';
import { UserAction, UserActionTypes, UserActionTargetTypes, UserActionTypesTargetTypes } from '@models/user-actions';
import { JsonNodeGroup } from '@models/tree-node';
import { DATE_TIME } from '@constants/dates';

@Component({
  selector: 'app-user-action-details-dialog',
  templateUrl: './user-action-details-dialog.component.html',
  styleUrls: ['./user-action-details-dialog.component.scss']
})
export class UserActionDetailsDialogComponent implements OnInit, AfterViewInit {

  public action: UserAction;
  public json: any;
  public jsonTree: JsonNodeGroup;
  public prevValueTree: JsonNodeGroup;
  public currentValueTree: JsonNodeGroup;
  public recognizedTemplate: UserActionTypes;
  public recognizedTemplateType: UserActionTargetTypes;
  public UserActionTypes = UserActionTypes;
  public UserActionTargetTypes = UserActionTargetTypes;
  public DATE_TIME = DATE_TIME;

  @ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;
  @ViewChild('scrollbarLeft') private scrollbarLeft: PerfectScrollbarComponent;
  @ViewChild('scrollbarRight') private scrollbarRight: PerfectScrollbarComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {action: UserAction},
    private dialogRef: MatDialogRef<UserActionDetailsDialogComponent>
  ) {}

  public ngOnInit(): void {
    this.action = this.data?.action;
    this.json = null;
    try {
      this.json = this.action.jsonPayload && JSON.parse(this.action.jsonPayload);
    } catch {}
    this.jsonTree = this.json && Utils.buildJsonTree(this.json);
    this.prevValueTree = this.json?.previousValue && Utils.buildJsonTree(this.json.previousValue);
    this.currentValueTree = this.json?.currentValue && Utils.buildJsonTree(this.json.currentValue);
    this.recognizedTemplateType = UserActionTypesTargetTypes[this.action.action?.id];
    this.recognizedTemplate = this.recognizedTemplateType === this.action.targetType?.id ? this.action.action.id : null;
  }

  public ngAfterViewInit(): void {
    this.checkScrollbar();
  }

  public close(): void {
    this.dialogRef.close();
  }

  private checkScrollbar(): void {
    setTimeout(() => {
      this.scrollbar?.directiveRef.update();
      this.scrollbarLeft?.directiveRef.update();
      this.scrollbarRight?.directiveRef.update();
    }, 200);
  }

}
