<div class="modules-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Wi-Fi modules &amp; devices</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section filtering-panel">
    <div class="search-model">
      <app-search-bar (search)="search($event)" [searching]="loading" [searchOnInput]="false"
        placeholder="Search MAC address, SN or user ID" [transform]="formatMacAddress"></app-search-bar>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.MAC" (sort)="setSorting($event)">
                Wi-Fi Module (MAC)
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.SERIAL_NUMBER" (sort)="setSorting($event)">
                Serial Number
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_TYPE" (sort)="setSorting($event)">
                Device Type
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.MODEL" (sort)="setSorting($event)">
                Model
              </app-sortable-header>
            </th>
            <th class="centered">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.USERS_COUNT" (sort)="setSorting($event)">
                Users
              </app-sortable-header>
            </th>
            <th class="sortable centered">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ONLINE" (sort)="setSorting($event)" align="center">
                Online
              </app-sortable-header>
            </th>
            <th class="sortable centered">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ARCHIVED" (sort)="setSorting($event)" align="center">
                Archived
              </app-sortable-header>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of wifiModules?.content">
            <td>
              <a class="button link-button bold-link" *ngIf="row.macAddress" (click)="showDetails(row)">
                {{row.macAddress}}
              </a>
              <ng-container *ngIf="!row.macAddress">&mdash;</ng-container>
            </td>
            <td class="upper-case">{{row.serialNumber || '&mdash;'}}</td>
            <td>{{row.deviceType || '&mdash;'}}</td>
            <td>{{row.deviceModel || '&mdash;'}}</td>
            <td class="centered">{{row.usersCount || 0}}</td>
            <td class="online-status"><div class="online-marker" [class.online]="row.online"></div></td>
            <td class="centered">{{row.archived ? 'Yes' : 'No'}}</td>
            <td><app-actions [actions]="row.actions" [disabled]="progressing" (expanded)="refreshStatus(row)"></app-actions></td> 
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!wifiModules || !wifiModules?.content.length"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="wifiModules && wifiModules.totalPages > 1">
      <div class="text-info">Showing {{wifiModules.startElement}} to {{wifiModules.endElement}} of {{wifiModules.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="wifiModules.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
<div><b>Wi-Fi modules &amp; devices</b> - Section for monitoring smart home devices, information aboutwhich is in the Platform database - here you can find all devices that are activated or can be activated in the EvoHome. In this section, you can get, among other things, the history of changes in the state of the device, and its properties within the EvoHome. The main window of the section contains a list of devices with information about:</div>
<ul class="less-space">
  <li>Wi-Fi Module (MAC) - MAC address of the device</li>
  <li>Serial Number - Serial number of the device</li>
  <li>Device Type - device type</li>
  <li>Model - device model</li>
  <li>Users - the number of users who have access to this device. That. you can quickly determine that the device is activated (since it has users)</li>
  <li>ONLINE – device online status (it is online - green, or offline – gray)</li>
  <li>ARCHIVED - MAC-adress (WiFi-module) status (archived or still actual)</li>
</ul>
<div class="less-space">In the list of devices, it is possible to search for data by certain values, search by substring:by the MAC address or by SerNo of the device or by the user ID of the Haier IoT module user that has access to this device.</div>
<div><u>There you can also find the additional functionality</u> for each MAC/device by clicking the button <button class="button button-actions"></button>:</div>
<div class="top-space"><p><b>1.View Details.</b> Availability doesn’t depend on on/off status You can view more detailed information about the device by opening the device card, where the information is grouped into tabs:<br><u>Information on the "Info" tab:</u></p></div>
<ol class="less-space">
  <li>Wi-FI-module - MAC-address of the Wi-FI module</li>
  <li>Serial Number - device serial number</li>
  <li>Device Type - device type</li>
  <li>Device model - device model</li>
  <li>Device Name - device name</li>
  <li>Firmware version - firmware version on the device</li>
  <li>Firmware type</li>
  <li>Activation Date - date of device activation/connection status (to the Platform) – if notempty, it means connected</li>
  <li>Current Status - the current status of the device (whether it is online)</li>
  <li>Last Data Date - date and time of the last data received from the device</li>
  <li>JSON-config version – a version of JSON-file that is installed now on this device. <u>Pay attention:</u> it became to identify it from some moment, some (old) devices can be without this information.</li>
  <li>Motherboard Version - a version of motherboard firmware that has this device now. <u>Pay attention: it was supported on module firmware from version 1.4.0.</u></li>
</ol>
<div class="less-space"><u>Information at the "Spaces and Users" tab:</u><br>Information about users of this device</div>
<ol class="less-space">
  <li>User HaierID - unique device user identifier (ID in Mapp)</li>
  <li>User is Owner (Y/N) - a sign of (user type) whether the user is the owner of the device (the owner of the “home” space the device is attached)<br/>Information about the spaces this device is attached – for the activated device, in any case, there should be informed about which “room” space this device is assigned to with information about the “house” space to which this "room" is related (in the EvoHome system, the user cannot create rooms outside the home, cannot activate devices without binding to the room)</li>
  <li>SpaceId - unique identifier of the space the device is attached</li>
  <li>Space Type - the type of space the device is attached</li>
  <li>Space Name - the name of the space.</li>
</ol>
<div class="top-space"><u>Other tabs -</u><br>is a history of situations that have occurred with this device in the past. This information is divided into tabs according to the main types of events:</div>
<ol class="less-space">
  <li>Commands - commands to change the state of devices that came to the device.</li>
  <li>Alarms - device errors that occurred at the device</li>
  <li>Events - events that led to a change in the state and properties of the device, different than commands to change (for example participation in spaces, groups, firmware update, device activation, etc.)</li>
  <li>Sessions – a history of the device connections to the IoT platform (when it was online)</li>
  <li>Current Status – a history of the device statuses (current attribute’s values at some specific time)</li>
  <li>Firmware Alarms - firmware errors that occurred at the device.</li>
</ol>
<div class="less-space">In each tab, you can view records about situations by selecting a period, and type of change - each record is presented as a line with information about the type of change, dateand time, and author of the change.</div>
<div class="top-space"><p><b>2.Delete/Untie.</b> Available if a device is online and activated (enrolled)</p></div>
<div class="min-space"><p>You can delete (untie) an enrolled Device. Just be careful when you choose a device to untie it.</p></div>
<div class="top-space"><p><b>3.Change SN/Model.</b> Available only if a device is offline and not enrolled</p></div>
<div class="min-space"><p>You can change SerNo or/and a model for the device (MAC) – the IoT platform also can find a model by added SerNo if you want or you can choose a model by yourself.</p></div>
<div class="top-space"><p><b>4.Change access token.</b> Available if a module is offline and unenrolled</div>
<div class="min-space"><p>You can change an access token for each module if it is necessary. </p><br><p><u>PAY ATTENTION:</u> it is not for changing an access token on the enrolled devices. It is just for refreshing info about up-to-date access token (that is on module now)</p></div>
<div class="top-space"><p><b>5.Refresh epp-config.</b> Available if a device is online and enrolled</p></div>
<div class="min-space"><p>You can refresh an epp-config for each device if it is necessary</p></div>
<div class="top-space"><p><b>6.Move to another stand.</b> Available if a device is online and enrolled</p></div>
<div class="min-space"><p>You can switch a device to another stand if it is necessary.</p><br><p><u>PAY ATTENTION:</u>  you have to add a device to this other stand (MAC address, SerNO/model) – before you will use the function “<b>Move to another stand</b>”. And enroll it to get the possibility to control it (you can enroll only after you will add a device). This function just switches a device to this another stand.</p></div>
<div class="top-space"><p><b>7.Move to archive</b> - available if:
  <ol class="min-space" type="a">
    <li>the device of this record was deleted/Untied</li>
    <li>the device of this record is offline</li>
    <li>this record has a SerNo that is the same as in another not archived record (no less than in one)</li>
  </ol>
  <p>This function will add the tag “archived“ to a record that will help you to identify in future if this record (MAC-adress) no more actual (WiFi-module was replaced).</p>
</div>
<div class="top-space"><u>SCENARIO example for QAs:</u></div>
<ol class="min-space">
  <li>IF you want to move a WiFi module from one device to another, you have to:
    <ol type="a">
      <li>Delete/Untie. You can delete/untie even if you already move a WiFi module to another device – just connect this (another) device to an electricity network and try to delete/untie (and yes, you also need an active WiFi).</li>
      <li>Change SN/Model – second step</li>
      <li>After that you should activate/enroll a device</li>
    </ol>
  </li>
  <li>IF you want to switch a device to another stand, you have to:
    <ol type="a">
      <li>Add the device to this other stand (MAC address and SerNO/model) – only after that you can go to the 2<sup>nd</sup> step</li>
      <li>Use the function “<b>Move to another stand</b>” – if you will use it before you add the device, a device will “come back” to previous stand</li>
      <li>After that you should activate/enroll a device</li>
    </ol>
  </li>
</ol>
</ng-template>