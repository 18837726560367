import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { Utils } from '@services/utils';
import { DeviceTypeEditDialogComponent } from '../../components/device-type-edit-dialog/device-type-edit-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { DeviceTypeDialogData } from '../../models/dialog-data';
import { Subject, Observable } from 'rxjs';
import { takeUntil, shareReplay, finalize } from 'rxjs/operators';
import { flattenDeep } from 'lodash';
import { DeviceType, DeviceTypeView, ProductLine } from '@models/device-models';
import { SortOrder, Sorting } from '@models/sorting';
import { ConfirmDialogIcon } from '@models/dialogs';
import { DATE } from '@constants/dates';

enum SortColumn {
  NAME = 'name',
  UPDATED = 'updatedAt'
}

@Component({
  selector: 'app-device-types-view',
  templateUrl: './device-types-view.component.html',
  styleUrls: ['./device-types-view.component.scss']
})
export class DeviceTypesViewComponent implements OnInit, OnDestroy {

  public deviceTypes: Array<DeviceTypeView>;
  public loading = false;
  public progressing = false;
  public sorting: Sorting = {column: SortColumn.UPDATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public DATE = DATE;

  @ViewChild('information') information: TemplateRef<any>;

  private productLines: Array<ProductLine>;
  private ready: Observable<any>;
  private destroyed = new Subject<void>();

  constructor(private deviceModelsService: DeviceModelsService,
    private dialogService: DialogService, private notificationService: NotificationService) { }

  public ngOnInit(): void {
    this.updateData();
    this.loadProductLines();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public refreshSort(): void {
    this.deviceTypes.sort((a, b) => {
      return Utils.compare(a, b, this.sorting);
    });
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.refreshSort();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Device types',
      text: this.information
    }});
  }

  public updateData(): void {
    this.loading = true;
    this.deviceModelsService.getDeviceTypes().pipe(finalize(() => this.loading = false))
        .subscribe((deviceTypes: Array<DeviceType>) => {
      this.deviceTypes = deviceTypes;
      this.deviceTypes.forEach((deviceType) => this.setActions(deviceType));
      this.refreshSort();
    });
  }

  public createDeviceType(): void {
    this.progressing = true;
    this.ready.subscribe(() => {
      this.dialogService.showModal(DeviceTypeEditDialogComponent, { width: '500px', data: this.createDialogConfig(null)})
          .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: DeviceType) => {
        this.progressing = false;
        if (result) {
          this.deviceTypes.push(this.setActions(result));
          this.refreshSort();
        }
      });
    });
  }

  public editDeviceType(deviceType: DeviceType): void {
    this.progressing = true;
    this.ready.subscribe(() => {
      this.dialogService.showModal(DeviceTypeEditDialogComponent, { width: '500px', data: this.createDialogConfig(deviceType)})
          .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: DeviceType) => {
        this.progressing = false;
        if (result) {
          Object.assign(deviceType, result);
          this.refreshSort();
        }
      });
    });
  }

  public deleteDeviceType(deviceType: DeviceType): void {
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Delete Device Type',
      text: 'You are going to delete device type ' + deviceType.name + '. Are you sure?',
      icon: ConfirmDialogIcon.WARNING,
      agreeButtonText: 'Yes, delete'
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.progressing = true;
        this.deviceModelsService.deleteDeviceType(deviceType.id).pipe(finalize(() => this.progressing = false)).subscribe(() => {
          this.notificationService.success('Device Type successfully deleted');
          this.deviceTypes = this.deviceTypes.filter(anyDeviceType => anyDeviceType !== deviceType);
        }, (error) => {
          this.notificationService.error('Failed to delete Device Type');
          console.error(error);
        });
      }
    });
  }

  private setActions(deviceType: DeviceTypeView): DeviceTypeView {
    deviceType.actions = [{
      title: 'Edit',
      action: () => this.editDeviceType(deviceType)
    }, {
      title: 'Delete',
      action: () => this.deleteDeviceType(deviceType)
    }];
    return deviceType;
  }

  private createDialogConfig(deviceType: DeviceType): DeviceTypeDialogData {
    const otherTypes = (this.deviceTypes || []).filter(x => x !== deviceType);
    const productLinesInUse = new Set(flattenDeep(otherTypes.map(x => x.productLines)).map(x => x.id));
    const currentProductLines = new Set((deviceType?.productLines || []).map(x => x.id));
    return {
      isNew: !deviceType,
      deviceType,
      productLines: this.productLines.filter(x => !productLinesInUse.has(x.id) || currentProductLines.has(x.id)),
      knownNames: otherTypes.map(x => x.name)
    };
  }

  private loadProductLines(): void {
    this.ready = this.deviceModelsService.getProductLines().pipe(takeUntil(this.destroyed), shareReplay(1));
    this.ready.subscribe((productLines: Array<ProductLine>) => {
      this.productLines = productLines || [];
    });
  }

}
