import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserInfoService } from '@services/user-info.service';
import { UserInfo } from '@models/user-info';
import { Subject, takeUntil, take } from 'rxjs';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit, OnDestroy {

  @Input()
  public text = 'Upload';

  @Input()
  public linkStyle = false;

  @Input()
  public extensions: string = '*'; // '.xxx, .yyy' format

  @Input()
  public wide = false;

  @Input()
  public disabled = false;

  @Input()
  public prohibitReadonly = true;

  @Output()
  public changed = new EventEmitter<File>();

  @ViewChild('input') input: ElementRef;

  private selectedFile: File;
  private destroyed = new Subject<void>();

  constructor(private userInfoService: UserInfoService) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public selectFile(): void {
    this.input.nativeElement.click();
  }

  public fileSelected(event: Event): void {
    if (this.disabled) {
      return;
    }
    // @ts-ignore
    const files: File[] = event.target.files;
    if (files && files.length > 0) {
      this.selectedFile = files[0];
      this.input.nativeElement.value = '';
      this.userInfoService.getActiveUser().pipe(takeUntil(this.destroyed), take(1)).subscribe((user: UserInfo) => {
        if (user.isReadOnlyAdmin && this.prohibitReadonly) {
          this.userInfoService.showPermissionViolation();
        } else {
          this.changed.emit(this.selectedFile);
        }
      });
    }
  }

}
