import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumbs } from '@models/breadcrumbs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {

  @Input()
  public breadcrumbs: Array<Breadcrumbs>;

  constructor() { }

  public ngOnInit(): void {
  }

}
