import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { WiFiModuleDetailed, WiFiModuleEvent } from '@models/device-modules';
import { DeviceModulesService } from '@services/device-modules.service';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DatesRange, DATES_RANGE_TYPES, DatesRangeTypes } from '@models/dates';
import { DATE_TIME } from '@constants/dates';
import { finalize } from 'rxjs';

enum SortColumn {
  TIME = 'ts',
  NAME = 'name',
  AUTHOR = 'author'
}

@Component({
  selector: 'app-device-module-events-list',
  templateUrl: './device-module-events-list.component.html',
  styleUrls: ['./device-module-events-list.component.scss']
})
export class DeviceModuleEventsListComponent implements OnInit {

  @Input()
  public details: WiFiModuleDetailed;

  @Input()
  public justify = false;

  public range: DatesRange = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
  public query: string = '';

  public events: Paged<WiFiModuleEvent>;

  public activePage = 1;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.TIME, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  constructor(private deviceModulesService: DeviceModulesService) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.range = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public updateData(resetPage = false): void {
    if (this.details) {
      this.loading = true;
      this.deviceModulesService.getDeviceWiFiModuleEvents(
        this.details.macAddress, this.range, this.query, this.sorting, resetPage ? 1 : this.activePage)
      .pipe(finalize(() => this.loading = false))
      .subscribe((events: Paged<WiFiModuleEvent>) => {
        this.events = events;
        this.activePage = resetPage ? 1 : this.activePage;
      });
    }
  }

}
