import { Injectable, NgZone } from '@angular/core';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { UserInfo, UserRoles } from '@models/user-info';
import { Observable, from, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  private userInfo$: Observable<UserInfo>;
  private userInfo: UserInfo;

  constructor(private zone: NgZone, private keycloak: KeycloakService, private dialogService: DialogService) {
    this.userInfo$ = from(keycloak.loadUserProfile()).pipe(map((userData: any) => {
      return {
        userName: userData.username,
        firstName: userData.firstName,
        lastName: userData.lastName,
        imgUrl: userData.imgUrl,
        isAdmin: keycloak.isUserInRole(UserRoles.ADMIN) || keycloak.isUserInRole(UserRoles.READONLY_ADMIN),
        isAssembler: keycloak.isUserInRole(UserRoles.ASSEMBLER),
        isInstallator: keycloak.isUserInRole(UserRoles.INSTALLATOR),
        isManager: keycloak.isUserInRole(UserRoles.MANAGER),
        isTechnician: keycloak.isUserInRole(UserRoles.TECHNICIAN),
        isContentManager: keycloak.isUserInRole(UserRoles.CONTENT_MANAGER),
        isReadOnlyAdmin: keycloak.isUserInRole(UserRoles.READONLY_ADMIN)
      } as UserInfo;
    }), shareReplay(1));
    this.userInfo$.subscribe(userData => this.userInfo = userData);
  }

  public getActiveUser(): Observable<UserInfo> {
    return this.userInfo$;
  }

  public checkReadonlyPermission(): boolean {
    if (this.userInfo?.isReadOnlyAdmin) {
      this.showPermissionViolation();
    }
    return !this.userInfo?.isReadOnlyAdmin;
  }

  public logout(): void {
    this.keycloak.logout();
  }

  public showPermissionViolation(): void {
    this.zone.run(() => {
      this.dialogService.showModal(InformationDialogComponent, { maxWidth: '600px', data: {
        title: 'Readonly Permission',
        text: 'You are not allowed to perform this operation. Please contact your administrator'
      }});
    });
  }

}
