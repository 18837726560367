import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { POSITION_BELOW } from '@constants/overlay-position';
import { DropdownItem } from '@models/dropdown';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DropdownComponent),
    multi: true
  }]

})
export class DropdownComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  public placeholder = '';

  @Input()
  public items: Array<DropdownItem>;

  @Input()
  public multi = false;

  @Input()
  public clearable = false;

  @Input()
  public invalid = false;

  @Input()
  public narrow = false;

  @Input()
  public bindValue = false;

  @Output()
  public toggled = new EventEmitter<boolean>();

  @ViewChild('trigger') trigger: ElementRef;
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;

  public expanded = false;
  public disabled = false;
  public valueTip = '';
  public selectedItem: DropdownItem;
  public selectedItems: Array<DropdownItem> = [];
  public sizeBox: ClientRect;
  public PositionBelow = POSITION_BELOW;

  private destroyed = new BehaviorSubject<boolean>(false);
  private onTouchedCallback: () => void;
  protected commit(value: DropdownItem | Array<DropdownItem> | any | Array<any>) {}

  constructor(private changeDetection: ChangeDetectorRef) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public toggle(event: MouseEvent): void {
    if (!this.disabled) {
      this.sizeBox = this.trigger.nativeElement.getBoundingClientRect();
      this.expanded = !this.expanded;
      this.toggled.emit(this.expanded);
    }
    event.stopPropagation();
  }

  public toggleItem(item: DropdownItem, event: MouseEvent): void {
    if (!this.disabled) {
      this.selectedItem = this.multi ? null : item;
      this.selectedItems = this.multi ?
        (this.selectedItems.includes(item) ? this.selectedItems.filter(x => x !== item) : this.selectedItems.concat(item)) : [];
      this.changeDetection.detectChanges();
      this.expanded = !!this.multi;
      if (this.expanded) {
        this.overlay.overlayRef.updatePosition();
      } else {
        this.toggled.emit(this.expanded);
      }
      this.setToCommit(this.multi ? this.selectedItems : this.selectedItem);
    }
    event.stopPropagation();
  }

  public removeItem(item: DropdownItem, event: MouseEvent): void {
    if (!this.disabled) {
      this.selectedItems = this.selectedItems.filter(x => x !== item);
      this.selectedItem = null;
      this.setToCommit(this.multi ? this.selectedItems : this.selectedItem);
    }
    event.stopPropagation();
  }

  public clear(event: MouseEvent): void {
    if (!this.disabled) {
      this.selectedItem = null;
      this.selectedItems = [];
      this.setToCommit(this.multi ? this.selectedItems : this.selectedItem);
    }
    event.stopPropagation();
  }

  public writeValue(value: DropdownItem | Array<DropdownItem> | any | Array<any>) {
    if (this.destroyed.value) {
      return;
    }
    if (this.bindValue) {
      this.selectedItem = this.multi ? null : this.items.find(x => x.value === value as any);
      this.selectedItems = this.multi ? this.items.filter(x => (value as Array<DropdownItem> || []).includes(x.value)) : [];
    } else {
      this.selectedItem = this.multi ? null : value as DropdownItem;
      this.selectedItems = this.multi ? value as Array<DropdownItem> || [] : [];
    }
  }

  public setToCommit(value: DropdownItem | Array<DropdownItem>): void {
    if (value) {
      if (Array.isArray(value)) {
        this.commit(value.map(x => this.bindValue ? x.value : x));
      } else {
        this.commit(this.bindValue ? value.value : value);
      }
    } else {
      this.commit(value);
    }
  }

  public showTipForTruncated(event: MouseEvent): void {
    const element = event.target as HTMLElement;
    this.valueTip = element.offsetWidth < element.scrollWidth ? element.textContent : '';
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
