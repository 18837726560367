import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { WiFiModuleDetailed, WiFiModuleFirmwareAlarm, WiFiModuleFirmwareAlarmType } from '@models/device-modules';
import { DeviceModulesService } from '@services/device-modules.service';
import { Paged } from '@models/pageable';
import { DropdownItem } from '@models/dropdown';
import { Sorting, SortOrder } from '@models/sorting';
import { DatesRange, DATES_RANGE_TYPES, DatesRangeTypes } from '@models/dates';
import { DATE_TIME } from '@constants/dates';
import { finalize } from 'rxjs';

enum SortColumn {
  TIME = 'ts',
  ERR_NO = 'errNo',
  DESCRIPTION = 'description'
}

@Component({
  selector: 'app-device-module-firmware-alarms-list',
  templateUrl: './device-module-firmware-alarms-list.component.html',
  styleUrls: ['./device-module-firmware-alarms-list.component.scss']
})
export class DeviceModuleFirmwareAlarmsListComponent implements OnInit, OnChanges {

  @Input()
  public details: WiFiModuleDetailed;

  @Input()
  public firmwareAlarmTypes: Array<WiFiModuleFirmwareAlarmType>;

  @Input()
  public justify = false;

  public range: DatesRange = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
  public firmwareAlarmTypesList: Array<DropdownItem>;
  public firmwareAlarmType: DropdownItem;

  public alarms: Paged<WiFiModuleFirmwareAlarm>;

  public activePage = 1;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.TIME, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  constructor(private deviceModulesService: DeviceModulesService) { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.range = DATES_RANGE_TYPES[DatesRangeTypes.MONTH].defaultRange();
    this.firmwareAlarmTypesList = [{value: null, title: 'All'}].concat(
      (this.firmwareAlarmTypes || []).map(x => ({value: x.code, title: x.code + '. ' + x.errorName})));
    this.firmwareAlarmType = this.firmwareAlarmTypesList[0];
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public updateData(resetPage = false): void {
    if (this.details) {
      this.loading = true;
      this.deviceModulesService.getDeviceWiFiModuleFirmwareAlarms(this.details.macAddress,
        this.range, this.firmwareAlarmType?.value, this.sorting, resetPage ? 1 : this.activePage)
      .pipe(finalize(() => this.loading = false))
      .subscribe((alarms: Paged<WiFiModuleFirmwareAlarm>) => {
        this.alarms = alarms;
        this.activePage = resetPage ? 1 : this.activePage;
      });
    }
  }

}
