import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonsDialogData, DialogButton } from '@models/dialogs';

@Component({
  selector: 'app-buttons-dialog',
  templateUrl: './buttons-dialog.component.html',
  styleUrls: ['./buttons-dialog.component.scss']
})
export class ButtonsDialogComponent implements OnInit {

  public leftSideButtons: Array<DialogButton>;
  public rightSideButtons: Array<DialogButton>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ButtonsDialogData,
    private dialogRef: MatDialogRef<ButtonsDialogComponent>
  ) { }

  public ngOnInit(): void {
    this.leftSideButtons = (this.data.buttons || []).filter(x => !x.code);
    this.rightSideButtons = (this.data.buttons || []).filter(x => x.code);
  }

  public emit(button: DialogButton): void {
    this.dialogRef.close(button.code);
  }
}
