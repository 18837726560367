<div class="firmwares-view inner-view">
  <div class="view-header">
    <h1 class="view-header align-container small-spacing">
      <span>Firmware Management</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section search-block">
    <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search by model or by type"></app-search-bar>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.VERSION" (sort)="setSorting($event)">
                Version
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.TYPE" (sort)="setSorting($event)">
                Type
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.KIND" (sort)="setSorting($event)">
                Kind
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.COVERAGE" (sort)="setSorting($event)">
                Coverage
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                Update Date
              </app-sortable-header> 
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of firmwares?.content">
            <td>{{row.version || '&mdash;'}}</td>
            <td class="capitalize">{{row.type || '&mdash;'}}</td>
            <td class="capitalize">{{row.kind || '&mdash;'}}</td>
            <td>
              <div class="activity-status"
                [ngClass]="{active: row.state === FirmwareState.PUBLISHED, inactive: row.state !== FirmwareState.PUBLISHED}">
                {{row.state === FirmwareState.PUBLISHED ? 'Published' : 'Unpublished'}}
              </div>
            </td>
            <td *ngIf="row.state === FirmwareState.PUBLISHED">
              {{(row.coverage || 0)}}%
              <b *ngIf="row.installedOnPercent !== undefined && row.installedOnPercent !== null">
                ({{(row.installedOnPercent || 0) | number:(row.installedOnPercent > 1 ? '0.0-1' : '0.0-2')}}%)
              </b>
            </td>
            <td *ngIf="row.state !== FirmwareState.PUBLISHED">&mdash;</td>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td class="by-content"><a class="button secondary" [routerLink]="['/provisioning', 'firmware', row.id]">View Details</a>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!firmwares || !firmwares?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="firmwares && firmwares.totalPages > 1">
      <div class="text-info">Showing {{firmwares.startElement}} to {{firmwares.endElement}} of {{firmwares.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="firmwares.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
<div><b>Firmware Management</b> - Section for managing the firmware required to control smart home devices (firmware is installed on Wi-Fi modules of home devices).</div>
<div class="top-space">Here in the form of a list, you can see all the firmware available in the Platform database with firmware data:</div>
<ol class="info-list top-space">
  <li>Version - information about the firmware version. Firmware versioning is end-to-end for all types and types of firmware.</li>
  <li>Type - firmware type. It can be of two types: critical (contains bug fixes), functional - contains any changes (support for new device functions, etc.)</li>
  <li>Kind - a kind of firmware. It can be of three kinds: universal - for all devices; typical - for certain types of devices; model - for certain device models. For the device, the most priority is the model one (if any), then the typical one, then the universal one.</li>
  <li>Status - firmware status. It can have two statuses - unpublished (not published) and published. The new firmware will always be in the "not published" state. The EvoHome system provides only published firmware to home devices - you need to publish the necessary firmware for it to be available to devices (how to do this is described below).</li>
  <li>Coverage - coverage. Rollout percentage that you set/can set when publishing the firmware. It means for how many devices out of all active devices this firmware is available at the moment of coverage installation (more details about coverage installation are described below) – coverage plan.</li>
  <li>Update date - the date when any changes were made with this firmware.
    <div class="top-space"><b>In the list of firmware,</b> it is possible to search for data by substring by model or device type.</div>
    <div class="top-space"><b>To manage the firmware,</b> you need to open the firmware card by clicking the "View Details" button.</div>
    <div class="top-space"><b>In the firmware card, you can:</b></div>
    <ol class="info-list top-space">
      <li>To see information about the firmware:
        <ul class="info-list top-space">
          <li>Status</li>
          <li>Type of</li>
          <li>View</li>
          <li>Last modified date</li>
          <li>List of device types or models for this firmware (if it is for the type or the model of the device)</li>
        </ul>
      </li>
      <li>To publish firmware:
        <ul class="info-list top-space">
          <li>Click on the "Publish" button</li>
          <li>Choose the initial coverage percentage (rolling) - later you can change the coverage percentage as needed up to 100% but not more. You can set the percentage to zero if you do not want the firmware to send to real users at the moment, but you need this firmware in the "published" state.</li>
          <li>Confirm your decision to publish (or cancel it)</li>
        </ul>
      </li>
      <li>To change the percentage of rolling - in the card of the published version of the firmware you can find the field "Coverage" to change the percentage.</li>
      <li>To download the firmware version or download the utility for installing into a Wi-Fi module with this firmware version.</li>
      <li>To unpublish the firmware - you can unpublish the firmware. After unpublishing, the Platform will offer to all devices in which this firmware is installed, download and install another alternative (current) version of the firmware (for this device)</li>
    </ol>
  </li>
</ol>
<div class="top-space"><span class="max-attention">ATTENTION: </span><b>it should be understood that when choosing the most suitable firmware for a device, the Platform, in addition to the firmware status (acceptable only "published") and firmware kind, also take into account the version - the priority condition for firmware types is the highest in this case (even if a newer version is released firmware, but universal, the Platform will not offer it to a device where there is an older version, but a model one).</b></div>
</ng-template>