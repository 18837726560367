<div class="dropdown-tree">
  <div class="field-container input-container" [class.expanded]="expanded"
      [class.focused]="focused" [class.invalid]="invalid" [class.clearable]="clearable"
      [class.disabled]="disabled" cdkOverlayOrigin #trigger #origin="cdkOverlayOrigin" (click)="toggle($event)">
    <input type="text" class="lookup-input" #input [(ngModel)]="value" (ngModelChange)="scheduleSearch()"
        (focus)="setFocused(true)" (blur)="setFocused(false)" *ngIf="!multiline"
        [disabled]="disabled" [placeholder]="placeholder" [class.narrow-input]="narrow"/>
    <app-multiline-edit #multilineEdit [(ngModel)]="value" (ngModelChange)="scheduleSearch()"
        (focus)="setFocused(true)" (blur)="setFocused(false)" [narrow]="narrow" *ngIf="multiline"
        [disabled]="disabled" [placeholder]="placeholder" [clearable]="clearable"></app-multiline-edit>
    <div class="controls align-container" *ngIf="clearable && !multiline">
      <div class="button button-clear" *ngIf="value" (click)="clear($event)"></div>
    </div>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin" [cdkConnectedOverlayOpen]="expanded && tree"
  [cdkConnectedOverlayPositions]="PositionBelow" [cdkConnectedOverlayWidth]="sizeBox?.width!" (overlayOutsideClick)="setFocused(false)"> 
  <div class="dropdown-panel limited-height">
    <perfect-scrollbar>
      <div class="scroll-area">
        <div class="tree-container monospace" #treeContainer>
          <ng-container  [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{node: tree}">
          </ng-container>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</ng-template>
<ng-template #nodeTemplate let-node="node">
  <div class="node" [class.terminal]="node.terminal" *ngIf="node.visible !== false">
    <div class="node-row" [class.selected]="node === selectedNode" [class.hidden]="node === tree && hideRoot"
        (click)="selectNode(node)" appStopPropagation>
      <div class="node-icon" [class.expand-collapse]="!node.terminal" (click)="expandCollapse(node, $event)"
        [class.collapsed]="node.collapsed"></div>
      <div class="node-name label">{{node.name}}</div>
    </div>
    <div class="children-container" [class.collapsed]="node.collapsed" [class.no-padding]="node === tree && hideRoot"
      [ngStyle]="{'max-height.px': (node.rowsCount - 1) * 32}" *ngIf="!node.terminal && node.childrenCount">
      <ng-container *ngFor="let child of node.children">
        <ng-container [ngTemplateOutlet]="nodeTemplate" [ngTemplateOutletContext]="{node: child}">
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>