<div class="lookup">
  <div class="field" [class.expanded]="expanded" [class.focused]="focused" [class.invalid]="invalid" [class.disabled]="disabled"
      cdkOverlayOrigin #trigger #origin="cdkOverlayOrigin" (click)="toggle($event)">
    <div class="items-container tags-list">
      <div *ngFor="let item of selectedItems" class="item tag">
        <div class="item-name">{{(tagsItemsFlag ? '#' : '') + item.title}}</div>
        <div class="remove-item" (click)="removeItem(item, $event)"></div>
      </div>
      <ng-container *ngIf="!multi || (maxItemsCount && selectedItems?.length < maxItemsCount)">
        <input type="text" class="lookup-input" [class.first-one]="!multi || !selectedItems?.length" #input
          [(ngModel)]="query" (ngModelChange)="scheduleSearch()" (focus)="focus()" (blur)="blur()"
          (keydown.enter)="includeNewItem($event)" [disabled]="disabled" [placeholder]="focused || selectedItems?.length ? '' : placeholder"
          [mask]="textFieldMask" [patterns]="textFieldPatterns" [dropSpecialCharacters]="false"/>
      </ng-container>
      <div class="item overflow-warning text-grayed" *ngIf="multi && maxItemsCount && selectedItems?.length >= maxItemsCount">
        {{maxItemsOverflowText || 'no more than 20 items can be added'}}
      </div>
    </div>
    <div class="controls align-container">
      <div class="button button-clear" *ngIf="clearable && (selectedItems?.length || query)" (click)="clear($event)"></div>
      <div class="magnifying-glass" *ngIf="magnifyingGlass && (!multi || (maxItemsCount && selectedItems?.length < maxItemsCount))"></div>
    </div>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin" [cdkConnectedOverlayOpen]="expanded && items?.length"
  [cdkConnectedOverlayPositions]="PositionBelow" [cdkConnectedOverlayWidth]="sizeBox?.width!" (overlayOutsideClick)="blur()">
  <div class="dropdown-panel limited-height">
    <perfect-scrollbar>
      <div class="scroll-area">
        <div class="dropdown-item" *ngFor="let item of items" (click)="toggleItem(item, $event)" [ngClass]="{selected: item === selectedItem}">
          <div class="checkbox" [class.checked]="isChecked(item)" *ngIf="multi"></div>
          <div class="item-text">{{(tagsItemsFlag ? '#' : '') + item.title}}</div>
          <div class="additional" *ngIf="item.additional !== undefined">{{item.additional}}</div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</ng-template>
