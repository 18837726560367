import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UtilitiesModule } from './utilities/utilities.module';
import { ReportsModule } from './reports/reports.module';
import { TestingModule } from './testing/testing.module';
import { AdministrationModule } from './administration/administration.module';
import { ProvisioningModule } from './provisioning/provisioning.module';
import { TvAppsModule } from './tv-apps/tv-apps.module';
import { MobileBackendModule } from './mobile-backend/mobile-backend.module';
import { AppAuthGuard } from './common/guards/auth.guard';
import { EnvironmentGuard } from './common/guards/environment.guard';
import { AccessDeniedComponent } from '@components/access-denied/access-denied.component';

const routes: Routes = [
  {
    path: 'administration',
    loadChildren: () => AdministrationModule,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'provisioning',
    loadChildren: () => ProvisioningModule,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'mobile-backend',
    loadChildren: () => MobileBackendModule,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'utilities',
    loadChildren: () => UtilitiesModule,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'testing',
    loadChildren: () => TestingModule,
    canActivate: [AppAuthGuard, EnvironmentGuard],
    data: {devOnly: true}
  },
  {
    path: 'reports',
    loadChildren: () => ReportsModule,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'tv-apps',
    loadChildren: () => TvAppsModule,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'mobile-backend',
    loadChildren: () => MobileBackendModule,
    canActivate: [AppAuthGuard]
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'administration'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
