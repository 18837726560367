<div class="layout">
  <div class="header">
    <app-header></app-header>
  </div>
  <div class="sidebar">
    <app-navigation></app-navigation>
  </div>
  <section class="content">
    <router-outlet></router-outlet>
  </section>
</div>
<app-notification></app-notification>