import { NgModule } from '@angular/core';

import { ReportsRoutingModule } from './reports-routing.module';
import { CommonModule } from '../common/common.module';

import { DevicesReportViewComponent } from './pages/devices-report-view/devices-report-view.component';
import { FirmwareReportViewComponent } from './pages/firmware-report-view/firmware-report-view.component';
import { TestReportsViewComponent } from './pages/test-reports-view/test-reports-view.component';
import { TestReportViewComponent } from './pages/test-report-view/test-report-view.component';
import { StatisticsViewComponent } from './pages/statistics-view/statistics-view.component';
import { TestReportEditViewComponent } from './pages/test-report-edit-view/test-report-edit-view.component';
import { ModelsGroupsViewComponent } from './pages/models-groups-view/models-groups-view.component';
import { ModelsGroupViewComponent } from './pages/models-group-view/models-group-view.component';
import { ModelsGroupEditViewComponent } from './pages/models-group-edit-view/models-group-edit-view.component';

@NgModule({
  imports: [
    CommonModule,
    ReportsRoutingModule
  ],
  declarations: [
    DevicesReportViewComponent,
    FirmwareReportViewComponent,
    TestReportsViewComponent,
    TestReportViewComponent,
    StatisticsViewComponent,
    TestReportEditViewComponent,
    ModelsGroupsViewComponent,
    ModelsGroupViewComponent,
    ModelsGroupEditViewComponent
  ]
})
export class ReportsModule { }
