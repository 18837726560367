export enum FirmwareState {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED'
}

export enum FirmwareType {
  CRITICAL = 'CRITICAL',
  FUNCTIONAL = 'FUNCTIONAL'
}

export enum TestingFirmwareType {
  PLAYER = 'PLAYER',
  RECORDER = 'RECORDER'
}

export enum FirmwareKind {
  UNIVERSAL = 'UNIVERSAL',
  MODEL = 'MODEL',
  TYPE = 'TYPE'
}

export enum FirmwareTestReportAction {
  DEACTIVATE_TEST_REPORTS = 'DEACTIVATE_TEST_REPORTS',
  CHANGE_TEST_REPORTS_FIRMWARE_VERSION = 'CHANGE_TEST_REPORTS_FIRMWARE_VERSION',
}

export interface Model {
  typeName: string;
  modelName: string;
}

export interface FirmwareSimplified {
  id: number;
  kind: FirmwareKind;
  version: string;
}

export interface Firmware extends FirmwareSimplified {
  type: FirmwareType;
  state: FirmwareState;
  deviceType: string;
  typesAndModels: Array<Model>;
  firmwareURL: string;
  utilityURL: string;
  createdAt: Date;
  updatedAt: Date;
  coverage: number;
  installedOnPercent: number;
}


export interface TestingFirmware {
  id: number;
  type: TestingFirmwareType;
  version: string;
  updatedAt: Date;
  firmwareURL: string;
}

export interface FirmwareTypes {
  Model: string;
  Typical: string;
  Universal: string;
}

export interface FirmwareInfo {
  approved: boolean;
  firmwareTypes: FirmwareTypes;
}


