export enum TestingStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  RUNNING = 'IN_PROGRESS',
  PENDING = 'PENDING',
  ERROR = 'ERROR'
}

export interface TestingBase {
  id: number;
  deviceModel: string;
  deviceType: string;
  fwVersion: string;
  started: Date;
  ended: Date;
  status: TestingStatus;
}

export interface Testing extends TestingBase {
  deviceMac: string;
}

export interface TestingRecord extends TestingBase {
  identificationCode: string;
}

export interface FirmwareUpdateRequest {
  deviceMac: string;
  trackId: string;
  fwVersion: string;
}

export interface TestingVirtualDevice {
  id: number;
  deviceMac: string;
  deviceType: string;
  deviceTypeId: number;
  deviceModel: string;
  deviceModelId: number;
  deviceFwVersion: string;
  deviceOnline: boolean;
  playerMac: string;
  playerFwVersion: string;
  playerOnline: boolean;
  trackId: string;
}

export enum DeviceFirmwareUpdateStatus {
  CANCELLED = 'CANCELLED',
  ERROR = 'ERROR',
  SENT_TO_DEVICE = 'SENT_TO_DEVICE',
  COMMANDING = 'COMMANDING',
  IMG_VALIDATING = 'IMG_VALIDATING',
  IMG_VALIDATED = 'IMG_VALIDATED',
  DOWNLOADING = 'DOWNLOADING',
  DOWNLOAD_LATER = 'DOWNLOAD_LATER',
  UPGRADE_WAITING = 'UPGRADE_WAITING',
  UPGRADING = 'UPGRADING',
  COMPLETED = 'COMPLETED'
}

export interface TestingVirtualDeviceStatus {
  deviceMac: string;
  fwVersion: string;
  status: DeviceFirmwareUpdateStatus;
}

export interface TestingDeviceStatus {
  deviceId: number;
  deviceMac: string;
  deviceModel: string;
  deviceType: string;
  fwVersion: string;
  lastFwVersion: string;
  online: boolean;
  configExists: boolean;
  autotestExists: boolean;
  recorderInstalled: boolean;
  isBindToTestUser: boolean;
  upgradeId: string;
  testingId: number;
  upgradeFailed: boolean;
  upgradingToRecorder: boolean;
}

export enum TestingEntryStatus {
  PROCESSING = 'PROCESSING',
  RUNNING = 'RUNNING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS'
}

export const TestingEntryStatusWeight: {[x in TestingEntryStatus]: number} = {
  [TestingEntryStatus.FAILED]: 0,
  [TestingEntryStatus.SUCCESS]: 1,
  [TestingEntryStatus.RUNNING]: 2,
  [TestingEntryStatus.PROCESSING]: 3
}

export interface TestingEntry {
  id: number;
  command: string;
  orderNum: number;
  response: string;
  description: string;
  state: TestingEntryStatus;
  sortStatus: number;
}

export interface TestingModel {
  modelId: number;
  customerModel: string;
}

export interface VirtualDeviceSimplified {
  deviceMac: string;
  playerMac: string;
  offline?: boolean;
  busy?: boolean;
}

export interface TestingDetails extends Testing {
  recordTestCases: Array<TestingEntry>;
}

export interface TestingTestStatusUpdate {
  testing: TestingBase;
  status: TestingStatus;
}