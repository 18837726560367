import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BindingToolViewComponent } from './pages/binding-tool-view/binding-tool-view.component';
import { BindingsViewComponent } from './pages/bindings-view/bindings-view.component';
import { ExportImportViewComponent } from './pages/export-import-view/export-import-view.component';
import { UserActionsViewComponent } from './pages/user-actions-view/user-actions-view.component';
import { UserRoles } from '@models/user-info';

const routes: Routes = [
  {
    path: 'binding-tool',
    component: BindingToolViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.INSTALLATOR]}
  },
  {
    path: 'bindings',
    component: BindingsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.ASSEMBLER]}
  },
  {
    path: 'export-import',
    component: ExportImportViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  },
  {
    path: 'user-actions',
    component: UserActionsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UtilitiesRoutingModule {}
