<div class="user-actions-view inner-view">
  <div class="view-header">
    <h1 class="view-header align-container small-spacing">
      <span>User Actions</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section search-block">
    <div class="search-field">
      <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading"
        placeholder="Search" [minQueryLength]="3"></app-search-bar>
    </div>
    <div class="target-type-field">
      <app-dropdown [items]="targetTypes" [(ngModel)]="targetType" (ngModelChange)="filterActionTypes()"
          placeholder="Select target type"></app-dropdown>
    </div>
    <div class="action-field">
      <app-dropdown [items]="availableActionTypes" [(ngModel)]="actionType" (ngModelChange)="updateData()"
          placeholder="Select action"></app-dropdown>
    </div>
    <div class="range-field">
      <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData()" [typeSelector]="false">
      </app-dates-range>
    </div>
    <div class="export-controls">
      <button class="secondary button-export" (click)="exportXLS()">Export XLS</button>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DATE_TIME" (sort)="setSorting($event)">
                Date / time
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.AUTHOR" (sort)="setSorting($event)">
                Author
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ACTION" (sort)="setSorting($event)">
                Action
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.TARGET_TYPE" (sort)="setSorting($event)">
                Target type
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.TARGET" (sort)="setSorting($event)">
                Target 
              </app-sortable-header> 
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of userActions?.content">
            <td>{{row.dateTime ? (row.dateTime | date:DATE_TIME_FORMAT) : '&mdash;'}}</td>
            <td>{{row.author || '&mdash;'}}</td>
            <td>{{row.action?.name || '&mdash;'}}</td>
            <td>{{row.targetType?.name || '&mdash;'}}</td>
            <td class="break-word taget-column">{{row.target || '&mdash;'}}</td>
            <td class="by-content button-column">
              <a class="button button-view" (click)="showDetails(row)"></a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!userActions || !userActions?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="userActions && userActions.totalPages > 1">
      <div class="text-info">Showing {{userActions.startElement}} to {{userActions.endElement}} of {{userActions.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="userActions.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>User actions</b> - the section where you can find a history of data changes about devices/MAC addresses: who, when, and what (with some details).</div>
  <div class="top-space">The data in the section is presented as a list of records, which displays the main parameters of these records:</div>
  <ol class="less-space">
    <li>DATA/TIME - date and time of the changes. The list is by default sorted by this column, from newest to oldest (most recent entry on top).</li>
    <li>AUTHOR - change author, user</li>
    <li>ACTION - a type of change (action) performed on the object</li>
    <li>TARGET TYPE - a group of objects to which the modified object belongs</li>
    <li>TARGET - a specific object of a group of objects, for example, a specific MAC address</li>
    <li>Button for calling a separate form for displaying record details (“eye” button), which provides information about:
      <ol type="a">
        <li>TARGET with which changes were made</li>
        <li>An old value of changed data (maybe missing - means adding new data)</li>
        <li>new data value
      </ol>
    </li>
  </ol>
  <div class="less-space">A set of filters, and data search is available on the list form – you can search (filter out) data by:</div>
  <ol class="min-space">
    <li>AUTHOR</li>
    <li>TARGET and</li>
    <li>Other data from Details
    <li>TARGET TYPE</li>
    <li>ACTION</li>
    <li>DATE PERIOD</li>
  </ol>
  <div class="less-space">To see details, you should click the button&nbsp;&nbsp;&nbsp;<button class="button button-view"></button></div>
  <div class="less-space">Also, you can download search results to your PC (in xls-file) - just click the button “Export XLS”.</div>
  <div class="less-space">There are supported the next events in user actions for now:</div>
  <ol class="less-space">
    <li>Model adding “by hand”</li>
    <li>A device Deleting/Untying</li>
    <li>A SerNo Adding/changing</li>
    <li>A device model Adding/changing</li>
    <li>Access token changing</li>
    <li>Epp-config refreshing</li>
    <li>Device moving (between different stands)</li>
    <li>Operations with the App user instructions (without content changes’ detailing)</li>
    <li>Operations with device types (types catalog)</li>
    <li>Operations in “Wi-Fi module firmware” chapter</li>
    <li>Operations with firmwares (Firmware Management chapter)</li>
    <li>Data Export/Import</li>
    <li>Base operations in Testing chapter (add records, tested firmware refreshing, add test records)</li>
  </ol>
</ng-template>
