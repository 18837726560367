<div class="catalogue-view">
  <div class="layout-panel">
    <div class="left-side-panel inner-view">
      <div class="view-header align-container space-between">
        <div class="view-title align-container small-spacing">
          <span>Catalogue</span>
          <button class="information" (click)="showInfo()"></button>
        </div>
        <div class="search-block" (click)="cancelEvent($event)">
          <app-search-bar placeholder="Search element" (search)="filterItems($event)"></app-search-bar>
        </div>
      </div>
      <div class="view-section scroll-container" *ngIf="!loading">
        <perfect-scrollbar>
          <div class="scroll-area">
            <app-tree-view [root]="catalogueTree" [selectedNode]="selectedNode" (nodeSelected)="selectedNode = $event"></app-tree-view>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="right-side-panel" (click)="cancelEvent($event)">
      <app-text-preview *ngIf="selectedNode?.type === TreeNodeType.TEXT"
        [text]="selectedNode.original" [editable]="true" (updated)="updateNodeText($event)">
      </app-text-preview>
      <app-image-preview *ngIf="selectedNode?.type === TreeNodeType.IMAGE"
        [image]="selectedNode.original" [editable]="true" (updated)="updateNodeText($event)">
      </app-image-preview>
      <app-directory-preview *ngIf="selectedNode && !selectedNode.type"
        [directory]="selectedNode.original" [editable]="true">
      </app-directory-preview>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
<div>
  <div>Here you can find file content for the EVO IoT app (image-files) - it is stored in these catalogues.</div>
  <div class="top-space">You can find specific files (images) on the left side in the data structure (data tree) and by search box. </div>
  <div class="top-space">You can edit images but not delete or add new ones.</div>
  <div class="top-space">You can find an information where is used the content.</div>
  <div class="top-space">When you edit images it affects all of the templates using this data.</div>
</div>
</ng-template>