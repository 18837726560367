import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UserInfoService } from '@services/user-info.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { InformationalService } from '@services/informational.service';
import { UserInfo } from '@models/user-info';
import { BadgesInfo } from '@models/informational';
import { Observable, Subject, takeUntil } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {

  public activeUser: Observable<UserInfo>;
  public badgesInfo: BadgesInfo;
  public opened = false; // mobile if required
  public expanded: Array<boolean> = new Array(10).fill(false);
  public environment = environment;
  private destroyed = new Subject<void>(); 

  @ViewChild('badge') badgeTip: NgbPopover;

  constructor(private userService: UserInfoService, private informationalService: InformationalService) { }

  public ngOnInit(): void {
    this.activeUser = this.userService.getActiveUser();
    this.informationalService.generalInformationUpdate().pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.updateBadge();
    });
    this.updateBadge();
  }

  public ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public toggleOpen(): void {
    this.opened = !this.opened;
  }

  public toggle(index: number): void {
    this.expanded[index] = !this.expanded[index];
  }

  public expand(index: number): void {
    this.expanded[index] = true;
  }

  public showBadge(): void {
    this.badgeTip && this.badgeTip.open();
  }

  public hideBadge(): void {
    this.badgeTip && this.badgeTip.close();
  }

  private updateBadge(): void {
    this.informationalService.getBadgesInformation().subscribe((badgesInfo: BadgesInfo) => {
      this.badgesInfo = badgesInfo;
    });
  }

}
