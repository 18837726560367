<div class="dialog-content">
  <div class="dialog-header">
    <span class="title">{{data.title}}</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="info">{{data.text}}</div>
    <input type="text" [(ngModel)]="text" [attr.placeholder]="data.placeholder || 'Enter here'" class="text-field"/>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>{{data.cancelButtonText || 'Cancel'}}</button>
    <button class="primary" (click)="proceed()" [disabled]="!text && data.required !== false">{{data.agreeButtonText || 'Save'}}</button>
  </div>
</div>
