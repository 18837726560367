import { Injectable } from '@angular/core';
import { ComponentType } from "@angular/cdk/overlay";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  public showModal(component?: ComponentType<any>, config?: MatDialogConfig): MatDialogRef<any> {
    return this.dialog.open(component, {
      panelClass: 'custom-modal',
      backdropClass: 'custom-backdrop',
      minWidth: 500,
      maxHeight: '94vh',
      ...config,
      disableClose: true,
      autoFocus: false
    });
  }
}
