import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ConfigModificationsService } from '@services/config-modifications.service';
import { DeviceConfigsService } from '@services/device-configs.service';
import { NotificationService } from '@services/notification.service';
import { DialogService } from '@services/dialog.service';
import { ModelConfigurationViewDialogData } from '../../models/dialog-data';
import { ModificationPatch, ModificationPatchDetails,
  ModelModificationView, ModificationStatus } from '@models/config-modifications';
import { Subject, takeUntil } from 'rxjs';
import { JsonEditComponent } from '@components/json-edit/json-edit.component';

@Component({
  selector: 'app-modification-view-dialog',
  templateUrl: './modification-view-dialog.component.html',
  styleUrls: ['./modification-view-dialog.component.scss']
})
export class ModificationViewDialogComponent implements OnInit, OnDestroy {

  public patch: ModificationPatch;
  public details: ModificationPatchDetails;
  
  public results: Array<ModelModificationView>;
  public ModificationStatus = ModificationStatus;

  private destroyed = new Subject<void>();

  @ViewChild('jsonEditor') jsonEditor: JsonEditComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModelConfigurationViewDialogData,
    private dialogRef: MatDialogRef<ModificationViewDialogComponent>,
    private configModificationsService: ConfigModificationsService, private configService: DeviceConfigsService,
    private notificationService: NotificationService, private dialogService: DialogService) {}

  public ngOnInit(): void {
    this.patch = this.data.modification;
    this.configModificationsService.getConfigModificationDetails(this.patch.id)
        .subscribe((details: ModificationPatchDetails) => {
      this.details = details;
      this.results = (details.models || []).map(x =>
        ({model: x, deviceType: details.type, ok: !x.error, error: x.error, details: x.details, expanded: false}));
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire modification details');
      this.close();
    });
  }

  public tabChanged(tabIndex: number): void {
    if (tabIndex === 1) {
      this.jsonEditor.update();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public viewModelDetails(model: ModelModificationView): void {
    this.configService.getModelConfigWithModifications(model.model.name)
        .pipe(takeUntil(this.destroyed))
        .subscribe((configContents: Object) => {
      this.dialogService.showModal(InformationDialogComponent, { width: '720px', data: {
        title: (model.deviceType?.name || '-') + ' / ' + (model.model.name || '-'),
        disableScroll: true,
        text: !configContents && 'Details not available',
        json: configContents && JSON.stringify(configContents)
      }});
    }, (err: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire model config with patches');
    });
  }

}
