import { NgModule } from '@angular/core';

import { TestingRoutingModule } from './testing-routing.module';
import { CommonModule } from '../common/common.module';

import { FirmwaresViewComponent } from './pages/firmwares-view/firmwares-view.component';
import { VirtualDevicesViewComponent } from './pages/virtual-devices-view/virtual-devices-view.component';
import { RecordsViewComponent } from './pages/records-view/records-view.component';
import { TestsViewComponent } from './pages/tests-view/tests-view.component';
import { TestViewComponent } from './pages/test-view/test-view.component';

import { DeviceUploadFirmwareDialogComponent } from './components/device-upload-firmware-dialog/device-upload-firmware-dialog.component';
import { AddTestingRecordDialogComponent } from './components/add-testing-record-dialog/add-testing-record-dialog.component';
import { RunTestingDialogComponent } from './components/run-testing-dialog/run-testing-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TestingRoutingModule
  ],
  declarations: [
    FirmwaresViewComponent,
    VirtualDevicesViewComponent,
    RecordsViewComponent,
    TestsViewComponent,
    TestViewComponent,
    DeviceUploadFirmwareDialogComponent,
    AddTestingRecordDialogComponent,
    RunTestingDialogComponent
  ]
})
export class TestingModule { }
