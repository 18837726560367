import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UserInfoService } from '@services/user-info.service';
import { UserInfo } from '@models/user-info';
import { Subject, takeUntil, take } from 'rxjs';

@Component({
  selector: 'app-file-field',
  templateUrl: './file-field.component.html',
  styleUrls: ['./file-field.component.scss']
})
export class FileFieldComponent implements OnInit, OnDestroy {

  @Input()
  public text = '';

  @Input()
  public extensions: string = '*'; // '.xxx, .yyy' format

  @Input()
  public disabled = false;

  @Input()
  public prohibitReadonly = false;

  @Input()
  public placeholder = 'Select File';

  @Input()
  public clearable = false;

  @Input()
  public invalid = false;

  @Output()
  public changed = new EventEmitter<File>();

  @ViewChild('input') input: ElementRef;

  public selectedFile: File;
  public focused = false;
  private destroyed = new Subject<void>();

  constructor(private userInfoService: UserInfoService) { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public selectFile(): void {
    if (!this.disabled) {
      this.setFocused(true);
      this.input.nativeElement.click();
    }
  }

  public fileSelected(event: Event): void {
    if (this.disabled) {
      return;
    }
    // @ts-ignore
    const files: File[] = event.target.files;
    if (files && files.length > 0) {
      this.selectedFile = files[0];
      this.input.nativeElement.value = '';
      if (this.prohibitReadonly) {
        this.userInfoService.getActiveUser().pipe(takeUntil(this.destroyed), take(1)).subscribe((user: UserInfo) => {
          if (user.isReadOnlyAdmin) {
            this.userInfoService.showPermissionViolation();
          } else {
            this.changed.emit(this.selectedFile);
          }
        });
      } else {
        this.changed.emit(this.selectedFile);
      }
    }
  }

  public setFocused(focused: boolean): void {
    this.focused = focused;
  }

  public clear(event: MouseEvent): void {
    event.stopPropagation();
    this.selectedFile = null;
    this.input.nativeElement.value = '';
    this.changed.emit(null);
  }

}
