import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DevicesReportViewComponent } from './pages/devices-report-view/devices-report-view.component';
import { FirmwareReportViewComponent } from './pages/firmware-report-view/firmware-report-view.component';
import { TestReportsViewComponent } from './pages/test-reports-view/test-reports-view.component';
import { TestReportViewComponent } from './pages/test-report-view/test-report-view.component';
import { TestReportEditViewComponent } from './pages/test-report-edit-view/test-report-edit-view.component';
import { StatisticsViewComponent } from './pages/statistics-view/statistics-view.component';
import { ModelsGroupsViewComponent } from './pages/models-groups-view/models-groups-view.component';
import { ModelsGroupViewComponent } from './pages/models-group-view/models-group-view.component';
import { ModelsGroupEditViewComponent } from './pages/models-group-edit-view/models-group-edit-view.component';
import { UserRoles } from '@models/user-info';
import { HttpParams } from '@constants/http-params';

const routes: Routes = [
  {
    path: 'devices',
    component: DevicesReportViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'firmware',
    component: FirmwareReportViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'test-reports',
    component: TestReportsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'test-report/create',
    component: TestReportEditViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'test-report/:' + HttpParams.TEST_REPORT_ID,
    component: TestReportViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'test-report/:' + HttpParams.TEST_REPORT_ID + '/edit',
    component: TestReportEditViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'test-reports/statistics',
    component: StatisticsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'models-groups',
    component: ModelsGroupsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'models-group/create',
    component: ModelsGroupEditViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'models-group/:' + HttpParams.MODELS_GROUP_ID,
    component: ModelsGroupViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'models-group/:' + HttpParams.MODELS_GROUP_ID + '/edit',
    component: ModelsGroupEditViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
