import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserRoles } from '@models/user-info';

@Injectable({
  providedIn: 'root',
})
export class AppAuthGuard extends KeycloakAuthGuard {
  
  constructor(
    protected override readonly router: Router,
    protected readonly keycloak: KeycloakService
  ) {
    super(router, keycloak);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        redirectUri: window.location.origin + state.url,
      });
    }
    // Get the roles required from the route.
    const collectedRoles = new Set<string>();
    const collectRoles = (activated: ActivatedRouteSnapshot) => {
      const roles = activated.data['roles'] || [];
      roles.forEach((role: string) => collectedRoles.add(role));
      const childRoutes = activated.children || [];
      childRoutes.forEach(collectRoles);
    };
    collectRoles(route);
    const requiredRoles = Array.from(collectedRoles);

    // Allow the user to to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // Allow the user to proceed if all the required roles are present.
    if (requiredRoles.some((role) => this.roles.includes(role))) {
      return true;
    } else {
      // redirect to error page if the user doesn't have the nessecairy  role to access
      // we will define this routes in a bit
      if (this.roles.includes(UserRoles.ADMIN) || this.roles.includes(UserRoles.READONLY_ADMIN)) {
        this.router.navigate(['/administration', 'device-types']);
      } else if (this.roles.includes(UserRoles.INSTALLATOR)) {
        this.router.navigate(['/utilities', 'binding-tool']);
      } else if (this.roles.includes(UserRoles.ASSEMBLER)) {
        this.router.navigate(['/utilities', 'bindings']);
      } else if (this.roles.includes(UserRoles.TECHNICIAN)) {
        this.router.navigate(['/administration', 'device-models']);
      } else if (this.roles.includes(UserRoles.MANAGER)) {
        this.router.navigate(['/reports', 'devices']);
      } else if (this.roles.includes(UserRoles.CONTENT_MANAGER)) {
        this.router.navigate(['/mobile-backend', 'templates']);
      } else {
        this.router.navigate(['/access-denied']);
      }
      return false;
    }
  }
}