<div class="text-preview" appStopPropagation>
  <div class="layout" [formGroup]="form" (click)="resetValidation()">
    <div class="view-title">Text {{editMode ? 'Edit' : 'Preview'}}</div>
    <div class="input-block" *ngIf="text">
      <textarea class="value-edit" formControlName="value" (focus)="resetValidation()"
        [class.invalid]="editMode && submitted && form.controls.value.errors">
      </textarea>
      <div class="validation" *ngIf="editMode && form.invalid && submitted && form.controls.value.errors">
        <span class="validation-text" *ngIf="form.controls.value.errors">
          Field must contain 1-{{text?.maxLength || 500}} latin/cyrillic symbols or numbers.
        </span>
      </div>
    </div>
    <table class="information-block" *ngIf="!editMode">
      <tr class="row">
        <td class="label">Located</td>
        <td class="value">{{text.located || '&mdash;'}}</td>
      </tr>
      <tr class="row">
        <td class="label">Created</td>
        <td class="value">
          <span class="date-time" *ngIf="text.updatedAt || text.createdAt">
            {{(text.updatedAt || text.createdAt) | date:DATE_TIME}}</span>
          <span class="author-name" *ngIf="text.updatedBy || text.createdBy">, {{text.updatedBy || text.createdBy}}</span>
        </td>
      </tr>
      <!--<tr class="row">
        <td class="label">Updated</td>
        <td class="value">
          <span class="date-time" *ngIf="text.updatedAt">{{text.updatedAt | date:DATE_TIME}}</span>
          <span class="author-name" *ngIf="text.updatedBy">, {{text.updatedBy}}</span>
        </td>
      </tr>-->
      <tr class="row" *ngIf="text.usedCount">
        <td class="label">Used</td>
        <td class="value">{{text.usedCount}} times
          <a class="button link-button bold-link" *ngIf="!hideUsagesRef" (click)="showDetails()">Show Details</a>
        </td>
      </tr>
    </table>
  </div>
  <div class="controls-block" *ngIf="editable">
    <div class="start-edit" *ngIf="!editMode">
      <button class="primary" (click)="setEditMode(true)">Edit Text in Dictionary</button>
    </div>
    <div class="process-edit align-container space-between" *ngIf="editMode">
      <button class="secondary" (click)="setEditMode(false, true)">Cancel</button>
      <button class="primary" (click)="saveChanges()">Save</button>
    </div>
  </div>
</div>