import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TemplatesService } from '@services/templates.service';
import { DialogService } from '@services/dialog.service';
import { DictionaryItem, DictionaryGroup } from '@models/templates';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { TreeNode, ViewTreeNode, TreeNodeType } from '@models/tree-node';
import { Subject, finalize } from 'rxjs';
import { Utils } from '@services/utils';

@Component({
  selector: 'app-dictionaries-view',
  templateUrl: './dictionaries-view.component.html',
  styleUrls: ['./dictionaries-view.component.scss']
})
export class DictionariesViewComponent implements OnInit, OnDestroy {

  public dictionariesTree: ViewTreeNode<DictionaryGroup, DictionaryItem>;
  public dictionaries: DictionaryGroup;
  public selectedNode: ViewTreeNode<DictionaryGroup, DictionaryItem>;
  public loading = false;
  public TreeNodeType = TreeNodeType;

  private activatedNode: TreeNode;
  private destroyed = new Subject<void>();
  private query: string = '';

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private router: Router, private route: ActivatedRoute,
      private templatesService: TemplatesService, private dialogService: DialogService) {
    this.activatedNode = (this.router.getCurrentNavigation().extras.state as any)?.active;
  }

  public ngOnInit(): void {
    this.loading = true;
    this.templatesService.getDictionaries()
        .pipe(finalize(() => this.loading = false)).subscribe((dictionaries: DictionaryGroup) => {
      this.dictionaries = dictionaries;
      this.dictionariesTree = this.buildViewTree(dictionaries);
      Utils.updateTreeCounters(this.dictionariesTree);
      this.updateInformation(this.dictionariesTree);
      this.selectedNode = this.activatedNode && Utils.findNode(this.dictionariesTree, this.activatedNode);
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { width: '800px', data: {
      title: 'How to work with Dictionaries',
      text: this.information
    }});
  }

  public filterItems(query: string): void {
    this.query = query;
    Utils.filterTree(this.dictionariesTree, query);
    if (!this.selectedNode?.visible) {
      this.selectedNode = null;
    }
    this.dictionariesTree.visible = true; // assert shown even if not match
    Utils.updateTreeCounters(this.dictionariesTree);
    this.updateInformation(this.dictionariesTree);
  }

  public cancelEvent(event: MouseEvent): void {
    event.stopPropagation();
  }

  public updateNodeText(source: DictionaryItem): void {
    if (this.selectedNode) {
      Utils.updateNode(this.selectedNode, source, this.query);
    }
  }

  private buildViewTree(source: DictionaryGroup | DictionaryItem): ViewTreeNode<DictionaryGroup, DictionaryItem> {
    const root = source as DictionaryGroup;
    const terminal = !root.nodes && !root.leafs;
    const children = terminal ? null : ([] as Array<DictionaryGroup | DictionaryItem>)
      .concat(root.nodes || []).concat(root.leafs || []).map(x => this.buildViewTree(x));
    return {
      id: source.id,
      name: source.name,
      type: source.type,
      description: source.description,
      terminal,
      children,
      original: source
    } as ViewTreeNode<DictionaryGroup, DictionaryItem>;
  }

  private updateInformation(node: ViewTreeNode<DictionaryGroup, DictionaryItem>): void {
    node.additional = node.terminal ? '' : '' + node.itemsCount;
    (node.children || []).forEach(x => this.updateInformation(x));
  }

}
