import { ConnectionPositionPair } from '@angular/cdk/overlay';

export const POSITION_BELOW = [new ConnectionPositionPair({
  originX: 'end',
  originY: 'bottom'
}, {
  overlayX: 'end',
  overlayY: 'top'
}), new ConnectionPositionPair({
  originX: 'start',
  originY: 'bottom'
}, {
  overlayX: 'start',
  overlayY: 'top'

}), new ConnectionPositionPair({
  originX: 'end',
  originY: 'top'
}, {
  overlayX: 'end',
  overlayY: 'bottom'
}), new ConnectionPositionPair({
  originX: 'start',
  originY: 'top'
}, {
  overlayX: 'start',
  overlayY: 'bottom'
})];

export enum Align {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
};