import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bigNumber'
})
export class BigNumberPipe implements PipeTransform {

  public transform(value: number | undefined): string {
    if (isNaN(value) || value === null || value === undefined) {
      return '0';
    }
    return Number(value).toLocaleString('fr', {useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 3});
  }
}
