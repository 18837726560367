import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DeviceConfigsService } from '@services/device-configs.service';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { Utils } from '@services/utils';
import { DeviceConfigDialogComponent } from '../../components/device-config-dialog/device-config-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component'; 
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { DeviceConfig, DeviceConfigView, EppVersion } from '@models/device-configs';
import { DeviceType } from '@models/device-models';
import { DropdownItem } from '@models/dropdown';
import { Paged } from '@models/pageable';
import { SortOrder, Sorting } from '@models/sorting';
import { ConfirmDialogIcon } from '@models/dialogs';
import { DATE } from '@constants/dates';
import { CONFLICT, HttpParams } from '@constants/http-params';
import { HttpErrorResponse } from '@angular/common/http'; 
import { Observable, Subject, forkJoin, takeUntil, finalize, shareReplay } from 'rxjs';

enum SortColumn {
  NAME = 'fileName',
  DEVICE_TYPE = 'deviceType',
  BOARD_ID = 'identificationCode',
  EPP_VERSION = 'eppVersion',
  VERSION = 'id',
  ACTIVE = 'active',
  CREATED = 'createdAt'
}

@Component({
  selector: 'app-json-config-view',
  templateUrl: './json-config-view.component.html',
  styleUrls: ['./json-config-view.component.scss']
})
export class JsonConfigViewComponent implements OnInit, OnDestroy {

  public configs: Paged<DeviceConfigView>;
  public query: string = '';
  public activeOnly = true;
  public deviceTypesItems: Array<DropdownItem> = [];
  public deviceType: DropdownItem = null;
  public activePage = 1;
  public loading = false;
  public progressing = false;
  public sorting: Sorting = {column: SortColumn.CREATED, order: SortOrder.DESC};
  public SortColumn = SortColumn; 
  public DATE_FORMAT = DATE;

  @ViewChild('information') information: TemplateRef<any>;

  private deviceTypes: Array<DeviceType>;
  private eppVersions: Array<EppVersion>;
  private destroyed = new Subject<void>();
  private dialogDataReady: Observable<any>;

  constructor(private configsService: DeviceConfigsService, private modelsService: DeviceModelsService,
    private dialogService: DialogService, private notificationService: NotificationService, private utils: Utils) { }

  public ngOnInit(): void {
    this.query = this.utils.lookupParam(HttpParams.QUERY) || '';
    this.fillDeviceTypes();
    this.updateData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  } 

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Device JSON-configs',
      text: this.information
    }});
  }

  public updateData(resetPage = false): void {
    this.loading = true;
    this.configsService.getDeviceConfigs(this.query, this.deviceType?.value,
      this.activeOnly, this.sorting, resetPage ? 1 : this.activePage)
        .pipe(finalize(() => this.loading = false)).subscribe((configs: Paged<DeviceConfig>) => {
      this.configs = configs;
      this.activePage = resetPage ? 1 : this.activePage;
    });
  }

  public createDeviceConfig(): void {
    this.progressing = true;
    this.dialogDataReady.subscribe(() => {
      this.dialogService.showModal(DeviceConfigDialogComponent,
        { width: '470px', data: {deviceTypes: this.deviceTypesItems, eppVersions: this.eppVersions}})
          .afterClosed().pipe(takeUntil(this.destroyed), finalize(() => this.progressing = false))
          .subscribe((result: boolean) => {
        if (result) {
          this.updateData();
        }
      });
    });
  }

  // @Deprecated
  public deleteDeviceConfig(deviceConfig: DeviceConfig): void {
    this.progressing = true;
    this.configsService.deleteDeviceConfig(deviceConfig.id, false).pipe(finalize(() => this.progressing = false)).subscribe(() => {
      this.notificationService.success('Device Config successfull deleted');
      this.updateData();
    }, (error: HttpErrorResponse) => {
      if (error.status === CONFLICT) {
        this.dialogService.showModal(ConfirmationDialogComponent, { width: '500px', data: {
          title: 'Delete JSON-config',
          text: `You are about to delete active JSON-config file which is probably used to ensure some smart devices work.
                 <br/>Are you sure you want to delete this JSON-config?`,
          icon: ConfirmDialogIcon.WARNING,
          agreeButtonText: 'Yes, delete',
          cancelButtonText: 'Close'
        }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
          if (result) {
            this.configsService.deleteDeviceConfig(deviceConfig.id, true).pipe(finalize(() => this.progressing = false)).subscribe(() => {
              this.notificationService.success('Device Config successfull deleted');
              this.updateData();
            }, (error: HttpErrorResponse) => {
              this.notificationService.error('Failed to Delete Device Config');
              console.error(error);
            });
          }
        });
      } else {
        this.notificationService.error('Failed to Delete Device Config');
        console.error(error);
      }
    });
  }

  private fillDeviceTypes(): void {
    this.dialogDataReady = forkJoin({
      deviceTypes: this.modelsService.getDeviceTypes(),
      eppVersions: this.configsService.getEppVersions()
    }).pipe(shareReplay(1));
    this.dialogDataReady.subscribe(({deviceTypes, eppVersions}) => {
      this.deviceTypes = deviceTypes;
      this.deviceTypesItems = [{title: 'All Device Types', value: null}]
        .concat(this.deviceTypes.map((x: DeviceType) => ({value: x.id, title: x.name})));
      this.deviceType = this.deviceTypesItems[0];
      this.eppVersions = eppVersions;
    });
  }

  private downloadDeviceConfig(deviceConfig: DeviceConfig): void {
    this.utils.downloadFile(deviceConfig.presignedUrl, true);
  }

}