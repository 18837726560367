import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DeviceModulesService } from '@services/device-modules.service';
import { DialogService } from '@services/dialog.service';
import { InformationalService } from '@services/informational.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { WiFiModuleDetailed, WiFiModuleFirmwareAlarmType, WiFiModuleCommandType } from '@models/device-modules';
import { WiFiModuleCommandDetails } from '../../models/dialog-data';
import { HttpParams } from '@constants/http-params';
import { Breadcrumbs } from '@models/breadcrumbs';
import { Utils } from '@services/utils';
import { forkJoin, finalize } from 'rxjs';
import { DATE_TIME } from '@constants/dates';

const TAB_NAMES: { [key: string]: number } =
  {spaces: 1, commands: 2, alarms: 3, events: 4, sessions: 5, history: 6, firmware: 7, permissions: 8};

@Component({
  selector: 'app-wifi-module-view',
  templateUrl: './wifi-module-view.component.html',
  styleUrls: ['./wifi-module-view.component.scss']
})
export class WifiModuleViewComponent implements OnInit {

  public wifiModuleId: number = null;
  public wifiModule: WiFiModuleDetailed = null;
  public firmwareAlarmTypes: Array<WiFiModuleFirmwareAlarmType>;
  public loading = false;
  public tabIndex = 0;
  public animationDuration = 0;
  public breadcrumbs: Array<Breadcrumbs> = [{title: 'Device Modules', link: ['/provisioning', 'wifi-modules']}];
  @ViewChild('information') information: TemplateRef<any>;

  constructor(private modulesService: DeviceModulesService, private dialogService: DialogService,
    private informationalService: InformationalService, private utils: Utils, private datePipe: DatePipe) { }

  public ngOnInit(): void {
    this.wifiModuleId = Number(this.utils.lookupParam(HttpParams.MODULE_ID));
    const tabName = this.utils.lookupParam(HttpParams.TAB_NAME);
    if (tabName && TAB_NAMES[tabName]) {
      this.tabIndex = TAB_NAMES[tabName];
    }
    if (this.wifiModuleId !== null) {
      this.loading = true;
      forkJoin({
        moduleDetails: this.modulesService.getDeviceWiFiModuleDetailed(this.wifiModuleId),
        firmwareAlarmTypes: this.modulesService.getDeviceWiFiModuleFirmwareAlarmTypes()
      }).pipe(finalize(() => this.loading = false)).subscribe(({moduleDetails, firmwareAlarmTypes}) => {
        this.wifiModule = moduleDetails;
        this.breadcrumbs.push({title: moduleDetails.macAddress});
        this.firmwareAlarmTypes = (firmwareAlarmTypes || []).sort((x, y) => x.code - y.code);
      });
    }
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Device Module Card',
      text: this.information
    }});
  }

  public viewCommandDetails(event: WiFiModuleCommandDetails): void {
    const command = event.command;
    const dateTime = command.ts ? this.datePipe.transform(command.ts, DATE_TIME) : '-';
    const commandName = command.type === WiFiModuleCommandType.REQUEST ? (command.name || '-') : (command.errNo || 'Ok');
    const optionalAuthority = command.author ? ` by ${command.author}` : '';
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'Wi-fi module ' + this.wifiModule.macAddress,
      text: `<h2>Commands / ${commandName}</h2><span>${dateTime}${optionalAuthority}</span>`,
      json: JSON.stringify(event.details)
    }});
  }

}
