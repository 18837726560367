<div class="image-preview" appStopPropagation>
  <div class="layout">
    <div class="view-title">Image Preview</div>
    <perfect-scrollbar>
      <div class="scroll-area">
        <div class="preview-block" *ngIf="image">
          <img [src]="image.presignedUrl" class="image-preview"/>
        </div>
        <table class="information-block">
          <tr class="row">
            <td class="label">Name</td>
            <td class="value">{{image.name || '&mdash;'}}</td>
          </tr>
          <tr class="row">
            <td class="label">Size</td>
            <td class="value">{{(image.size || 0) | fileSize}}</td>
          </tr>
          <tr class="row">
            <td class="label">Located</td>
            <td class="value">{{image.located || '&mdash;'}}</td>
          </tr>
          <tr class="row">
            <td class="label">Created</td>
            <td class="value">
              <span class="date-time" *ngIf="image.updatedAt || image.createdAt">
                {{(image.updatedAt || image.createdAt) | date:DATE_TIME}}</span>
              <span class="author-name" *ngIf="image.updatedBy || image.createdBy">, {{image.updatedBy || image.createdBy}}</span>
            </td>
          </tr>
          <!--<tr class="row">
            <td class="label">Updated</td>
            <td class="value">
              <span class="date-time" *ngIf="image.updatedAt">{{image.updatedAt | date:DATE_TIME}}</span>
              <span class="author-name" *ngIf="image.updatedBy">, {{image.updatedBy}}</span>
            </td>
          </tr>-->
          <tr class="row" *ngIf="image.usedCount">
            <td class="label">Used</td>
            <td class="value">{{image.usedCount}} times&nbsp;
              <a class="button link-button bold-link" *ngIf="!hideUsagesRef" (click)="showDetails()">Show Details</a>
            </td>
          </tr>
        </table>
      </div>
    </perfect-scrollbar>
  </div>
  <div class="controls-block" *ngIf="editable">
    <app-upload-button text="Replace Image in Catalogue" (changed)="replaceImage($event)"
      [wide]="true" extensions=".jpg, .jpeg, .png, .webp">
    </app-upload-button>
  </div>
</div>