import { Component, OnInit, OnDestroy, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioComponent),
    multi: true
  }]
})
export class RadioComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  public value: any;

  @Input()
  public disabled = false;

  public selectedValue: any;

  private destroyed = new BehaviorSubject<boolean>(false);
  private onTouchedCallback: () => void;
  protected commit(value: boolean) {}

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public check(): void {
    if (!this.disabled) {
      this.selectedValue = this.value;
      this.commit(this.value);
    }
  }

  public writeValue(value: any) {
    if (this.destroyed.value) {
      return;
    }
    this.selectedValue = value;
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
