<div class="firmwares-view inner-view">
  <div class="view-header">
    <h1 class="view-header align-container small-spacing">
      <span>Firmwares</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.VERSION" (sort)="setSorting($event)">
                Version
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.TYPE" (sort)="setSorting($event)">
                Type
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                Update Date
              </app-sortable-header> 
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of firmwares?.content">
            <td>{{row.version || '&mdash;'}}</td>
            <td class="capitalize">{{row.type || '&mdash;'}}</td>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td class="by-content"><a class="button primary" (click)="download(row)">Download</a>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!firmwares || !firmwares?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="firmwares && firmwares.totalPages > 1">
      <div class="text-info">Showing {{firmwares.startElement}} to {{firmwares.endElement}} of {{firmwares.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="firmwares.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div class="top-space"><b>Firmwares</b> – section where user can find <b>Recorder</b> and <b>Player</b> firmware that are used for device testing.</div>
  <div class="top-space"><b>Recorder firmware</b> is a firmware for recording the device response to the commands.</div>
  <div class="top-space"><b>Player firmware</b> is a firmware that emulates the motherboard response to the commands as a device.</div>
  <div class="top-space">In this section the user is able to download a <b>Recorder</b> or <b>Player</b> firmware by tapping an appropriate button “<u>Download</u>”.</div>
</ng-template>