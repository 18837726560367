import { IConfig } from 'ngx-mask';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { MAC_SYMBOL } from '@constants/main';

export const SCROLLBAR_CONFIG = {
  suppressScrollX: true,
  wheelPropagation: false,
  minScrollbarLength: 30
};

export const NGX_LOADING_CONFIG = {
  backdropBackgroundColour: 'rgba(255, 255, 255, 0.8)',
  fullScreenBackdrop: false,
  primaryColour: '#1976D2',
  secondaryColour: '#E2E5E8'
};

export const CALENDAR_CONFIG = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthLabel: 'MMM',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'YYYY',
    monthYearA11yLabel: 'YYYY'
  }
};

export const TEXT_MASK_CONFIG = {
  keepCharPositions: true,
  showMask: false,
  guide: false
};

export class PopoverConfig {
  constructor(config: NgbPopoverConfig) {
    config.placement = 'bottom';
    config.triggers = 'hover';
  }
}

// DO NOT DEFINE patterns GLOBALLY! IT OVERRIDES STD PATTERNS. USE AS <input [patterns]=/> LOCALLY
export const MASK_CONFIG: Partial<IConfig> | (() => Partial<IConfig>) = {
  // patterns: {'P': {pattern: MAC_SYMBOL, symbol: 'P'}}
};

export const LOOKUP_DELAY = 700;
export const DEFAULT_MAX_LOOKUP_ITEMS = 20;
export const BLUR_DELAY = 250;
export const NOTIFICATION_DELAY = 7000;
export const NOTIFICATION_PAUSE = 500;
export const TAB_ANIMATION_DELAY = 700;
