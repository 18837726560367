import { Component, OnInit, OnDestroy, ChangeDetectorRef, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { TestingRecord, TestingDeviceStatus, TestingStatus,
  TestingVirtualDeviceStatus, DeviceFirmwareUpdateStatus } from '@models/testing';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { TestingService } from '@services/testing.service';
import { Subject, finalize, takeUntil, share, merge, distinctUntilChanged, tap, map } from 'rxjs';
import { NOT_FOUND, CONFLICT, PRECONDITION_FAILED, HttpParams } from '@constants/http-params';
import { MAC_ADDRESS_FORMAT } from '@constants/main';

@Component({
  selector: 'app-add-testing-record-dialog',
  templateUrl: './add-testing-record-dialog.component.html',
  styleUrls: ['./add-testing-record-dialog.component.scss']
})
export class AddTestingRecordDialogComponent implements OnInit, OnDestroy {

  public macAddress: string;
  public progressing = false;
  public searching = false;
  public missing = false;
  public failed = false;
  public invalidMac = false;
  public firmwareInstalled = false;
  public attemptedFwVersion = ''; // can't store as .lastFwVersion since this field should not be overwritten by roll back
  public recorder: TestingDeviceStatus;
  private destroyed = new Subject<void>();
  private newSearch = new Subject<string>();
  private newRequest = this.newSearch.asObservable().pipe(distinctUntilChanged(), share());

  constructor(private dialogRef: MatDialogRef<AddTestingRecordDialogComponent>,
    private changeDetectorRef: ChangeDetectorRef, private router: Router,
    private dialogService: DialogService, private notificationService: NotificationService,
    private testingService: TestingService) { }

  public ngOnInit(): void {
    this.newRequest.pipe(takeUntil(this.destroyed)).subscribe((mac: string) => this.runSearch(mac));
  }

  public ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.cancelTracking();
  }

  public findDevice(macAddress: string): void {
    if (this.searching) {
      return;
    }
    this.newSearch.next(this.macAddress.toUpperCase());
  }

  public runSearch(macAddress: string): void {
    this.invalidMac = this.missing = this.failed = this.firmwareInstalled = false;
    this.recorder = null;
    this.cancelTracking();
    if (!macAddress || !MAC_ADDRESS_FORMAT.test(this.macAddress)) {
      this.invalidMac = true;
      return;
    }
    this.searching = true;
    this.testingService.getTestingDeviceAndStatus(this.macAddress.toLowerCase())
        .pipe(finalize(() => this.searching = false)).subscribe((recorderStatus: TestingDeviceStatus) => {
      this.recorder = recorderStatus;
      if (this.recorder.configExists && this.recorder.autotestExists) {
        if (this.recorder.upgradeId) {
          this.startTrackingFirmwareInstall(this.recorder.upgradeId, this.recorder.lastFwVersion);
        } else if (this.recorder.testingId || !this.recorder.online) {
          this.awaitsDeviceReady();
        }
      }
    }, (err: HttpErrorResponse) => {
      if (err.status === NOT_FOUND) {
        this.missing = true;
      } else {
        this.failed = true;
      }
    });
  }

  public updateFirmware(upgradingToRecorder = false): void {
    if (!this.progressing && this.recorder && !this.recorder.upgradeId) {
      this.progressing = true;
      this.firmwareInstalled = false;
      this.recorder.upgradingToRecorder = upgradingToRecorder;
      let fwVersion = this.recorder.lastFwVersion;
      const action = upgradingToRecorder ? this.testingService.runUploadLatestFirmware(this.recorder.deviceMac)
        : this.testingService.restoreOriginalFirmware(this.recorder.deviceMac)
          .pipe(tap((track: {fw: string, trackId: string}) => fwVersion = track.fw), map((track) => track.trackId));
      action.pipe(finalize(() => this.progressing = false)).subscribe((trackId: string) => {
        this.recorder.upgradeId = trackId;
        setTimeout(() => this.startTrackingFirmwareInstall(trackId, fwVersion)); // preserve progressing from finalize
      }, (error: HttpErrorResponse) => {
        const baseErrMsg = `Failed to Update Firmware on device ${this.recorder.deviceMac}.`;
        this.handleBlockingOperationError(error, false, baseErrMsg, `${baseErrMsg}. Device is busy and unable to start process`);
      });
    }
  }

  public isOk(recorder: TestingDeviceStatus): boolean {
    return recorder && recorder.configExists && recorder.online && recorder.autotestExists && recorder.isBindToTestUser;
  }

  public runRecording(): void {
    if (!this.recorder || !this.recorder.recorderInstalled) {
      return;
    }
    this.testingService.runRecording(this.recorder.deviceMac).subscribe((recording: TestingRecord) => {
      this.router.navigate(['testing', 'record', recording.id]);
      this.close(true);
      this.notificationService.success('Recording started');
    }, (error: HttpErrorResponse) => {
      this.handleBlockingOperationError(error, true, `Failed to run Recording on device ${this.recorder.deviceMac}`);
    });
  }

  public close(force = false): void {
    if (!force && this.isOk(this.recorder) && (this.firmwareInstalled && this.recorder?.recorderInstalled || this.progressing && this.recorder?.upgradingToRecorder)) {
      const progressStatus = this.progressing ? 'is in process of being installed' : 'is successfully installed'
      this.dialogService.showModal(ConfirmationDialogComponent, { width: '500px', data: {
        title: 'Confirm Close',
        text: `The firmware v. ${this.recorder.lastFwVersion} ${progressStatus}. 
          If you close the window, recorder firmware would be permanently on the device. Are you sure you want to close this window?`,
        agreeButtonText: 'Yes, close'
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  private handleBlockingOperationError(error: HttpErrorResponse, silentAwait = true, errorMsg: string, busyMsg?: string): void {
    if (error.status === CONFLICT) {
      if (silentAwait) {
        this.awaitsDeviceReady(true);
      } else {
        this.notificationService.error(busyMsg);
        this.runSearch(this.recorder.deviceMac);
      }
    } else if (error.status === PRECONDITION_FAILED) {
      this.recorder.online = false;
      if (silentAwait) {
        this.awaitsDeviceReady();
      }
    } else {
      this.notificationService.error(errorMsg);
    }
  }

  private startTrackingFirmwareInstall(trackId: string, fwVersion: string): void {
    this.progressing = true;
    const trackRequest = {trackId, deviceMac: this.recorder?.deviceMac, fwVersion};
    this.attemptedFwVersion = fwVersion;
    delete this.recorder.upgradeFailed;
    this.testingService.trackFirmwareUpdateStatus(trackRequest).pipe(finalize(() => this.progressing = false))
        .subscribe((status: TestingVirtualDeviceStatus) => {
      delete this.recorder.upgradeId;
      delete this.recorder.upgradingToRecorder;
      if (status.status === DeviceFirmwareUpdateStatus.COMPLETED) {
        this.firmwareInstalled = true;
        this.recorder.fwVersion = status.fwVersion;
        this.recorder.recorderInstalled = status.fwVersion === this.recorder?.lastFwVersion;
      } else {
        this.recorder.upgradeFailed = true;
      }
    });
  }

  private cancelTracking(): void {
    this.testingService.cancelFirmwareUpdateTrack();
  }

  private awaitsDeviceReady(setBusy = false): void {
    if (setBusy) {
      this.recorder.testingId = -1;
    }
    this.testingService.awaitsDeviceReady(this.recorder.deviceMac, merge(this.destroyed, this.newRequest)).subscribe(() => {
      this.recorder.online = true;
      this.recorder.testingId = this.recorder.upgradeId = null;
      delete this.recorder.upgradingToRecorder;
      this.changeDetectorRef.detectChanges();
    });
  }
}
