<div class="types-models-select">
  <perfect-scrollbar>
    <div class="scroll-area">
      <div class="header">
        <div class="row-layout">
          <div class="base-info">
            <div class="toggle" [class.collapsed]="topNode.collapsed" (click)="toggleAll()"></div>
            <div class="title">Device types / models</div>
          </div>
          <div class="additional-info">
            <span class="selection-info">Selected: <b>Types - {{model.types.length}}, Models - {{model.models.length}}</b></span>
          </div>
        </div>
      </div>
      <div class="device-type row" *ngFor="let deviceType of view" [class.hidden]="deviceType.hidden">
        <div class="grayed-panel row-layout">
          <div class="base-info">
            <div class="toggle" [class.collapsed]="deviceType.collapsed" (click)="toggle(deviceType)"></div>
            <div class="check" [ngClass]="{checked: deviceType.checked}" (click)="checkDeviceType(deviceType)"></div>
            <div class="title">{{deviceType.object.name}}</div>
          </div>
          <div class="additional-info">
            <span class="selection-info">
              Models - <b>{{deviceType.children.length || 0}}</b> / Selected - <b>{{deviceType.selectedCount || 0}}</b>
            </span>
          </div>
        </div>
        <div class="container" [class.collapsed]="deviceType.collapsed" [style.max-height.px]="64 * deviceType.children.length">
          <div class="row-layout" *ngFor="let model of deviceType.children" [class.hidden]="model.hidden">
            <div class="base-info">
              <div class="check" [class.checked]="model.checked" (click)="checkModel(model)"></div>
              <div class="title">{{model.object.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</div>