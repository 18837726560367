<div class="dialog-content user-actions">
  <div class="dialog-header">
    <div class="title-container">
      <div class="title">Details</div>
    </div>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="details-info" *ngIf="!recognizedTemplate">
      <div class="general-info" *ngIf="prevValueTree || currentValueTree || !jsonTree">
        <div class="target-field align-container">
          <span class="target-name">MAC Address</span>
          <span class="target-value label">{{action?.target}}</span>
        </div>
      </div>
      <div class="details-caption" *ngIf="!prevValueTree && !currentValueTree && !jsonTree">
        No Operation Details Available
      </div>
      <div class="operation-details" *ngIf="!prevValueTree && !currentValueTree && jsonTree">
        <div class="scrollable-content">
          <perfect-scrollbar #scrollbar>
            <app-static-tree [json]="jsonTree" [heading]="treeHeader">
            </app-static-tree>
          </perfect-scrollbar>
        </div>
      </div>
      <div class="operation-details two-columns" *ngIf="prevValueTree || currentValueTree">
        <div class="left-side">
          <div class="details-caption">Previous Value</div>
        </div>
        <div class="right-side">
          <div class="details-caption">Current Value</div>
        </div>
        <div class="left-side">
          <div class="scrollable-content" *ngIf="prevValueTree">
            <perfect-scrollbar #scrollbarLeft>
              <app-static-tree [json]="prevValueTree">
              </app-static-tree>
            </perfect-scrollbar>
          </div>
          <div class="no-value" *ngIf="!prevValueTree">&mdash;</div>
        </div>
        <div class="right-side">
          <div class="scrollable-content" *ngIf="currentValueTree">
            <perfect-scrollbar #scrollbarRight>
              <app-static-tree [json]="currentValueTree">
              </app-static-tree>
            </perfect-scrollbar>
          </div>
          <div class="no-value" *ngIf="!currentValueTree">&mdash;</div>
        </div>
      </div>
    </div>
    <div class="details-info no-flex" *ngIf="recognizedTemplate">
      <ng-container [ngTemplateOutlet]="addNewModel" *ngIf="recognizedTemplate === UserActionTypes.ADD_NEW_MODEL">
      </ng-container>
      <ng-container [ngTemplateOutlet]="deleteUntie" *ngIf="recognizedTemplate === UserActionTypes.DELETE_OR_UNTIE">
      </ng-container>
      <ng-container [ngTemplateOutlet]="changeModel" *ngIf="recognizedTemplate === UserActionTypes.CHANGE_MODEL">
      </ng-container>
      <ng-container [ngTemplateOutlet]="changeSerial" *ngIf="recognizedTemplate === UserActionTypes.CHANGE_SERIAL">
      </ng-container>
      <ng-container [ngTemplateOutlet]="emptyTemplate" *ngIf="recognizedTemplate === UserActionTypes.CHANGE_ACCESS_TOKEN">
      </ng-container>
      <ng-container [ngTemplateOutlet]="updateEppConfig" *ngIf="recognizedTemplate === UserActionTypes.UPDATE_EPP_CONFIG">
      </ng-container>
      <ng-container [ngTemplateOutlet]="moveToStand" *ngIf="recognizedTemplate === UserActionTypes.MOVE_TO_ANOTHER_STAND">
      </ng-container>
      <ng-container [ngTemplateOutlet]="logFileUpdate" *ngIf="recognizedTemplate === UserActionTypes.UPLOAD_LOG_CONFIG">
      </ng-container>
      <ng-container [ngTemplateOutlet]="logFileUpdate" *ngIf="recognizedTemplate === UserActionTypes.DELETE_LOG_CONFIG">
      </ng-container>
      <ng-container [ngTemplateOutlet]="eppFileUpdate" *ngIf="recognizedTemplate === UserActionTypes.UPLOAD_EPP_CONFIG">
      </ng-container>
      <ng-container [ngTemplateOutlet]="eppFileUpdate" *ngIf="recognizedTemplate === UserActionTypes.DELETE_EPP_CONFIG">
      </ng-container>
      <ng-container [ngTemplateOutlet]="instruction" *ngIf="recognizedTemplateType === UserActionTargetTypes.APP_INSTRUCTION">
      </ng-container>
      <ng-container [ngTemplateOutlet]="deviceType" *ngIf="recognizedTemplateType === UserActionTargetTypes.DEVICE_TYPE">
      </ng-container>
      <ng-container [ngTemplateOutlet]="modelGroup" *ngIf="recognizedTemplate === UserActionTypes.CREATE_MODEL_GROUP">
      </ng-container>
      <ng-container [ngTemplateOutlet]="modelGroup" *ngIf="recognizedTemplate === UserActionTypes.EDIT_MODEL_GROUP">
      </ng-container>
      <ng-container [ngTemplateOutlet]="modelGroup" *ngIf="recognizedTemplate === UserActionTypes.DELETE_MODEL_GROUP">
      </ng-container>
      <ng-container [ngTemplateOutlet]="downloadFirmwareUtility" *ngIf="recognizedTemplate === UserActionTypes.DOWNLOAD_FIRMWARE_UTILITY">
      </ng-container>
      <ng-container [ngTemplateOutlet]="emptyTemplate" *ngIf="recognizedTemplate === UserActionTypes.UPLOAD_MAC_ADDRESSES">
      </ng-container>
      <ng-container [ngTemplateOutlet]="publishUnpublish" *ngIf="recognizedTemplate === UserActionTypes.PUBLISH_FIRMWARE">
      </ng-container>
      <ng-container [ngTemplateOutlet]="publishUnpublish" *ngIf="recognizedTemplate === UserActionTypes.UNPUBLISH_FIRMWARE">
      </ng-container>
      <ng-container [ngTemplateOutlet]="exportImport" *ngIf="recognizedTemplate === UserActionTypes.EXPORT_DATA">
      </ng-container>
      <ng-container [ngTemplateOutlet]="exportImport" *ngIf="recognizedTemplate === UserActionTypes.IMPORT_DATA">
      </ng-container>
      <ng-container [ngTemplateOutlet]="emptyTemplate" *ngIf="recognizedTemplate === UserActionTypes.START_TEST_FIRMWARE_RECORD">
      </ng-container>
      <ng-container [ngTemplateOutlet]="emptyTemplate" *ngIf="recognizedTemplate === UserActionTypes.START_FIRMWARE_TEST">
      </ng-container>
      <ng-container [ngTemplateOutlet]="updateTestFirmware" *ngIf="recognizedTemplate === UserActionTypes.UPDATE_TEST_FIRMWARE">
      </ng-container>
      <ng-container [ngTemplateOutlet]="testReport" *ngIf="recognizedTemplate === UserActionTypes.CREATE_TEST_REPORT">
      </ng-container>
      <ng-container [ngTemplateOutlet]="testReport" *ngIf="recognizedTemplate === UserActionTypes.EDIT_TEST_REPORT">
      </ng-container>
      <ng-container [ngTemplateOutlet]="testReport" *ngIf="recognizedTemplate === UserActionTypes.DELETE_TEST_REPORT">
      </ng-container>
      <ng-container [ngTemplateOutlet]="testReport" *ngIf="recognizedTemplate === UserActionTypes.ACTIVATE_TEST_REPORT">
      </ng-container>
    </div>
  </div>
  <div class="dialog-footer align-right">
    <button class="primary" (click)="close()">Close</button>
  </div>
</div>
<ng-template #addNewModel>
  <table class="values-table">
    <tr class="space-below">
      <th>Model Name</th>
      <td>{{json.modelName || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>SAP Codes</th>
      <td>
        <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.sapCodes}">
        </ng-container>
      </td>
    </tr>
    <tr>
      <th>Device Type</th>
      <td>{{json.deviceType || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Product Line</th>
      <td>{{json.productLine || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #deleteUntie>
  <table class="values-table">
    <tr class="space-below">
      <th>MAC Address</th>
      <td>{{json.macAddress}}</td>
    </tr>
    <tr>
      <th>Last Owners</th>
      <td>
        <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.owners}">
        </ng-container>
      </td>
    </tr>
  </table>
</ng-template>
<ng-template #changeModel>
  <table class="values-table">
    <tr class="space-below">
      <th>MAC Address</th>
      <td>{{json.macAddress || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Old Model</th>
      <td>{{json.oldModel || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>New Model</th>
      <td>{{json.newModel || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #changeSerial>
  <table class="values-table">
    <tr class="space-below">
      <th>MAC Address</th>
      <td>{{json.macAddress || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Old Serial Number</th>
      <td>{{json.oldSerial || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>New Serial Number</th>
      <td>{{json.newSerial || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #updateEppConfig>
  <table class="values-table">
    <tr class="space-below">
      <th>MAC Address</th>
      <td>{{json.macAddress || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Previous Config Version</th>
      <td>{{json.lastConfigId || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #moveToStand>
  <table class="values-table">
    <tr class="space-below">
      <th>MAC Address</th>
      <td>{{json.macAddress || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Stand</th>
      <td>{{json.lastStand || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>New Stand</th>
      <td>{{json.newStand || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #logFileUpdate>
  <table class="values-table">
    <tr class="space-below">
      <th>File Name</th>
      <td>{{json.fileName || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Device Type</th>
      <td>{{json.deviceType || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Device Model</th>
      <td>{{json.deviceModel || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #eppFileUpdate>
  <table class="values-table">
    <tr class="space-below">
      <th>File Name</th>
      <td>{{json.fileName || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Device Type</th>
      <td>{{json.deviceType || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Board ID</th>
      <td>{{json.boardId || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Epp Version</th>
      <td>{{json.eppVersion || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #modelGroup>
  <table class="values-table">
    <tr class="space-below">
      <th>Model Group</th>
      <td>{{json.groupName || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Device Type</th>
      <td>{{json.deviceType || '&mdash;'}}</td>
    </tr>
    <tr *ngIf="recognizedTemplate === UserActionTypes.EDIT_MODEL_GROUP">
      <th>Old Status</th>
      <td class="capitalize">{{json.oldStatus || '&mdash;'}}</td>
    </tr>
    <tr *ngIf="recognizedTemplate === UserActionTypes.EDIT_MODEL_GROUP">
      <th>New Status</th>
      <td class="capitalize">{{json.newStatus || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #instruction>
  <table class="values-table">
    <tr>
      <th>Instruction Id</th>
      <td>{{json.id || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Instruction Name</th>
      <td>{{json.instructionName || '&mdash;'}}</td>
    </tr>
    <tr class="space-above">
      <th>Instruction Type</th>
      <td>{{json.instructionType || '&mdash;'}}</td>
    </tr>
    <ng-container *ngIf="recognizedTemplate === UserActionTypes.ADD_INSTRUCTION || recognizedTemplate === UserActionTypes.EDIT_INSTRUCTION">
      <tr>
        <th>Instruction Status</th>
        <td>{{json.instructionStatus || '&mdash;'}}</td>
      </tr>
    </ng-container>
    <ng-container *ngIf="recognizedTemplate === UserActionTypes.DELETE_INSTRUCTION">
      <tr class="space-above">
        <th>Device Types</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.deviceTypes}">
          </ng-container>
        </td>
      </tr>
      <tr>
        <th>Device Models</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.deviceModel}">
          </ng-container>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="recognizedTemplate === UserActionTypes.EDIT_INSTRUCTION">
      <tr class="space-above">
        <th>Old Name</th>
        <td>{{json.oldName || '&mdash;'}}</td>
      </tr>
      <tr class="space-below">
        <th>New Name</th>
        <td>{{json.instructionName || '&mdash;'}}</td>
      </tr>
      <tr>
        <th>Old Device Types</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.oldDeviceTypes}">
          </ng-container>
        </td>
      </tr>
      <tr>
        <th>New Device Types</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.newDeviceTypes}">
          </ng-container>
        </td>
      </tr>
      <tr>
        <th>Old Device Models</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.oldDeviceModels}">
          </ng-container>
        </td>
      </tr>
      <tr>
        <th>New Device Models</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.newDeviceModels}">
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </table>
</ng-template>
<ng-template #deviceType>
  <table class="values-table">
    <tr>
      <th>Device Type Name</th>
      <td>{{json.name || '&mdash;'}}</td>
    </tr>
    <tr [class.space-below]="recognizedTemplate === UserActionTypes.EDIT_DEVICE_TYPE" [class.space-above]="recognizedTemplate !== UserActionTypes.EDIT_DEVICE_TYPE">
      <th>Device Type Code</th>
      <td>{{json.deviceTypeCode || '&mdash;'}}</td>
    </tr>
    <ng-container *ngIf="recognizedTemplate === UserActionTypes.ADD_DEVICE_TYPE || recognizedTemplate === UserActionTypes.DELETE_DEVICE_TYPE">
      <tr>
        <th>Product Lines</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.productLines}">
          </ng-container>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="recognizedTemplate === UserActionTypes.EDIT_DEVICE_TYPE">
      <tr>
        <th>Old Type Name</th>
        <td>{{json.oldDeviceTypeName || '&mdash;'}}</td>
      </tr>
      <tr>
        <th>New Type Name</th>
        <td>{{json.newDeviceTypeName || '&mdash;'}}</td>
      </tr>
      <tr>
        <th>Old Product Lines</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.oldProductLines}">
          </ng-container>
        </td>
      </tr>
      <tr>
        <th>New Product Lines</th>
        <td>
          <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.newProductLines}">
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </table>
</ng-template>
<ng-template #downloadFirmwareUtility>
  <table class="values-table">
    <tr class="space-below">
      <th>Firmware Version</th>
      <td>{{json.firmwareVersion || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Tokens Number</th>
      <td>{{json.tokenNumber || 0}}</td>
    </tr>
    <tr>
      <th>Models</th>
      <td>
        <ng-container [ngTemplateOutlet]="list" [ngTemplateOutletContext]="{list: json.models}">
        </ng-container>
      </td>
    </tr>
  </table>
</ng-template>
<ng-template #publishUnpublish>
  <table class="values-table">
    <tr class="space-below">
      <th>Firmware Version</th>
      <td>{{json.firmwareVersion || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Firmware Type</th>
      <td>{{json.type || '&mdash;'}}</td>
    </tr>
    <tr class="space-below">
      <th>Firmware Kind</th>
      <td>{{json.kind || '&mdash;'}}</td>
    </tr>
    <ng-container *ngIf="recognizedTemplate === UserActionTypes.PUBLISH_FIRMWARE">
      <tr>
        <th>Old Coverage</th>
        <td>{{json.oldCoverage || 0}}</td>
      </tr>
      <tr>
        <th>New Coverage</th>
        <td>{{json.newCoverage || 0}}</td>
      </tr>
    </ng-container>
    <ng-container *ngIf="recognizedTemplate === UserActionTypes.UNPUBLISH_FIRMWARE">
      <tr>
        <th>Coverage</th>
        <td>{{json.coverage || 0}}</td>
      </tr>
      <tr>
        <th>Devices for Update</th>
        <td>{{json.devicesForUpdate || 0}}</td>
      </tr>
      <tr>
        <th>Devices for Update Fact</th>
        <td>{{json.devicesForUpdateFact || 0}}</td>
      </tr>
    </ng-container>
  </table>
</ng-template>
<ng-template #exportImport>
  <table class="values-table">
    <tr>
      <th>{{recognizedTemplate === UserActionTypes.EXPORT_DATA ? 'Downloaded:' : 'Uploaded:'}}</th>
      <td>&nbsp;</td>
    </tr>
    <tr>
      <th class="pad-1">Texts</th>
      <td>{{(recognizedTemplate === UserActionTypes.EXPORT_DATA ? json.downloaded?.texts : json.uploaded?.texts) || 0}}</td>
    </tr>
    <tr>
      <th class="pad-1">Pictures</th>
      <td>{{(recognizedTemplate === UserActionTypes.EXPORT_DATA ? json.downloaded?.pictures : json.uploaded?.pictures) || 0}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #testReport>
  <table class="values-table">
    <tr class="space-below">
      <th>File Name</th>
      <td>{{json.fileName}}</td>
    </tr>
    <tr *ngIf="recognizedTemplate === UserActionTypes.CREATE_TEST_REPORT || recognizedTemplate === UserActionTypes.ACTIVATE_TEST_REPORT">
      <th>Status</th>
      <td class="capitalize">{{json.status}}</td>
    </tr>
    <tr *ngIf="recognizedTemplate === UserActionTypes.EDIT_TEST_REPORT">
      <th>Old Status</th>
      <td class="capitalize">{{json.oldStatus}}</td>
    </tr>
    <tr *ngIf="recognizedTemplate === UserActionTypes.EDIT_TEST_REPORT">
      <th>New Status</th>
      <td class="capitalize">{{json.newStatus}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #updateTestFirmware>
  <table class="values-table">
    <tr class="space-below">
      <th>Device MAC</th>
      <td>{{json.deviceMac || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Player MAC</th>
      <td>{{json.playerMac || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>Old Firmware</th>
      <td>{{json.oldFirmware || '&mdash;'}}</td>
    </tr>
    <tr>
      <th>New Firmware</th>
      <td>{{json.newFirmware || '&mdash;'}}</td>
    </tr>
  </table>
</ng-template>
<ng-template #emptyTemplate>
  Details are not available
</ng-template>
<ng-template #list let-list="list">
  <span *ngIf="!list || !list.length">&mdash;</span>
  <span *ngIf="list?.length" class="comma-separated" >
    <span class="item" *ngFor="let item of list">{{item}}</span>
  </span>
</ng-template>
<ng-template #treeHeader>
  <tr class="node-row">
    <th class="node-name space-below">MAC Address</th>
    <td class="node-value space-below">{{action?.target}}</td>
  </tr>
</ng-template>
