import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import { Firmware, FirmwareSimplified, FirmwareTestReportAction, TestingFirmware } from '@models/firmware';
import { VersionInfo } from "@models/report";

@Injectable({
  providedIn: 'root'
})
export class FirmwareService {

  constructor(private http: HttpClient) {}

  public getFirmwares(query: string, page: number, sorting: Sorting): Observable<Paged<Firmware>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<Firmware>>(`/device-firmware-service/api/v1/console/firmware`,
        {params}).pipe(tap((paged: Paged<Firmware>) => {
      Utils.extendPaged(paged);
      paged.content.forEach(this.processDates);
    }));
  }

  public getFirmwareDownloadAcceptableList(): Observable<Array<FirmwareSimplified>> {
    return this.http.get<Array<FirmwareSimplified>>(`/device-firmware-service/api/v1/console/firmware/by-name`, {params: {query: ''}});
  }

  public getTestingFirmwareList(page: number, sorting: Sorting): Observable<Paged<TestingFirmware>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<TestingFirmware>>(`/test-firmware-service/api/v1/console/all`,
        {params}).pipe(tap((paged: Paged<TestingFirmware>) => {
      Utils.extendPaged(paged);
      paged.content.forEach(this.processDates);
    }));
  }

  public getFirmwareVersions(): Observable<Array<VersionInfo>> {
    return this.http.get<Array<VersionInfo>>(`/device-firmware-service/api/v1/console/firmware/versions`);
  }

  public getTestingFirmwares(deviceMac: string): Observable<Array<TestingFirmware>> {
    return this.http.get<Array<TestingFirmware>>(`/device-firmware-service/api/v1/console/firmware/for-virtual-device/${deviceMac}`)
      .pipe(tap((firmware: Array<TestingFirmware>) => (firmware || []).forEach(this.processDates)));
  }

  public getLatestTestingFirmware(deviceMac: string): Observable<TestingFirmware> {
    return this.http.get<TestingFirmware>(`/device-firmware-service/api/v1/console/firmware/for-virtual-device/${deviceMac}/latest`);
  }

  public getFirmwareDetails(firmwareId: number): Observable<Firmware> {
    return this.http.get<Firmware>(`/device-firmware-service/api/v1/console/firmware/${firmwareId}`);
  }

  public publish(firmwareId: number, coverage: number, publishFirmwareAnswer?: FirmwareTestReportAction): Observable<{coverage: number}> {
    return this.http.post<{coverage: number}>(`/device-firmware-service/api/v1/console/firmware/${firmwareId}/publish`, {coverage, publishFirmwareAnswer});
  }

  public checkAssociatedTestReports(firmwareId: number): Observable<boolean> {
    return this.http.get<boolean>(`/device-firmware-service/api/v1/console/firmware/${firmwareId}/check-active-test-report-exists-by-lower-version`);
  }

  public checkTestReportExists(firmwareId: number): Observable<boolean> {
    return this.http.get<boolean>(`/device-firmware-service/api/v1/console/firmware/${firmwareId}/check-active-test-report-exists`);
  }

  public unpublish(firmwareId: number): Observable<string> {
    return this.http.post<string>(`/device-firmware-service/api/v1/console/firmware/${firmwareId}/unpublish`, null);
  }

  private processDates(entry: {updatedAt: Date}): void {
    entry.updatedAt = entry.updatedAt ? new Date(entry.updatedAt) : null;
  }

}
