import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { DeviceModuleDialogComponent }
  from '../../components/device-module-dialog/device-module-dialog.component';
import { DeviceModuleEditDialogComponent }
  from '../../components/device-module-edit-dialog/device-module-edit-dialog.component';
import { DeviceModuleAccessTokenDialogComponent }
  from '../../components/device-module-access-token-dialog/device-module-access-token-dialog.component';
import { DeviceModuleEppConfigDialogComponent }
  from '../../components/device-module-epp-config-dialog/device-module-epp-config-dialog.component';
import { DeviceModuleChangeStandDialogComponent }
  from '../../components/device-module-change-stand-dialog/device-module-change-stand-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceModulesService } from '@services/device-modules.service';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { UserInfoService } from '@services/user-info.service';
import { NotificationService } from '@services/notification.service';
import { InformationalService } from '@services/informational.service';
import { WiFiModule, WiFiModuleView, WiFiModuleStatusUpdate } from '@models/device-modules';
import { DeviceTypesAndModels } from '@models/device-models';
import { ConfirmDialogIcon } from '@models/dialogs';
import { SystemStandsInfo } from '@models/informational';
import { UserInfo } from '@models/user-info';
import { Paged } from '@models/pageable';
import { SortOrder, Sorting } from '@models/sorting';
import { Observable, Subscription, Subject, lastValueFrom, forkJoin, tap, takeUntil, finalize, shareReplay, interval } from 'rxjs';
import { SPECIAL_CHARS, MAC_SYMBOLS } from '@constants/main';
import { BAD_REQUEST } from '@constants/http-params';

enum SortColumn {
  MAC = 'macAddress',
  SERIAL_NUMBER = 'serialNumber',
  DEVICE_TYPE = 'deviceType',
  MODEL = 'deviceModel',
  USERS_COUNT = 'usersCount',
  ONLINE = 'online',
  ARCHIVED = 'archived'
}

const REFRESH_TIMEOUT = 10000;

@Component({
  selector: 'app-wifi-modules-view',
  templateUrl: './wifi-modules-view.component.html',
  styleUrls: ['./wifi-modules-view.component.scss']
})
export class WifiModulesViewComponent implements OnInit, OnDestroy {

  public wifiModules: Paged<WiFiModuleView>;
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public progressing = false;
  public sorting: Sorting = {column: SortColumn.MAC, order: SortOrder.ASC};
  public SortColumn = SortColumn;
  private destroyed = new Subject<void>();
  private ready: Observable<any>;
  private models: DeviceTypesAndModels;
  private stands: SystemStandsInfo;
  private timer: Subscription;
  private activeUser: UserInfo;

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private router: Router,
    private modelsService: DeviceModelsService,
    private deviceModulesService: DeviceModulesService,
    private dialogService: DialogService,
    private userInfoService: UserInfoService,
    private notificationService: NotificationService,
    private informationalService: InformationalService) {
  }

  public async ngOnInit(): Promise<UserInfo> {
    this.updateData();
    this.acquireModels();
    return this.activeUser = await lastValueFrom(this.userInfoService.getActiveUser());
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showDetails(wifiModule: WiFiModule): void {
    this.dialogService.showModal(DeviceModuleDialogComponent, { width: '800px', data: {deviceModule: wifiModule}});
  }

  public navigateDeviceModule(wifiModule: WiFiModule): void {
    this.router.navigate(['provisioning', 'device-module', wifiModule.id]);
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with WiFi Modules & Devices',
      text: this.information
    }});
  }

  public deleteUnbind(wifiModule: WiFiModule): MatDialogRef<any> {
    const result = this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Delete/unbind MAC address',
      text: `MAC address <b>${wifiModule.macAddress || '<NONE>'}</b><br/><br/>
             You are about to delete/unbind the device from selected MAC address.<br/>
             Are you sure you want to delete/unbind?<br/>`,
      icon: ConfirmDialogIcon.WARNING,
      agreeButtonText: 'Yes, delete/unbind',
      cancelButtonText: 'Close'
    }});
    result.afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: any) => {
      if (result) {
        this.loading = true;
        this.deviceModulesService.unbindWiFiModule(wifiModule.macAddress)
            .pipe(finalize(() => this.loading = false)).subscribe((result: WiFiModule) => {
          this.notificationService.success(`MAC address ${wifiModule.macAddress} successfully unbound`);
          this.setStatus(wifiModule, result);
        }, (error: HttpErrorResponse) => {
          this.notificationService.error(`Failed to unbind MAC address ${wifiModule.macAddress}`);
        });
      }
    });
    return result;
  }

  public moveToArchive(wifiModule: WiFiModule): MatDialogRef<any> {
    const result = this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Move Wi-Fi module to archive',
      text: `You are about to move Wi-Fi module ${wifiModule.macAddress} to archive. Are you sure?`,
      agreeButtonText: 'Yes, move',
      cancelButtonText: 'Cancel'
    }});
    result.afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: any) => {
      if (result) {
        this.loading = true;
        this.deviceModulesService.moveToArchiveWiFiModule(wifiModule.macAddress)
            .pipe(finalize(() => this.loading = false)).subscribe((result: void) => {
          this.notificationService.success(`Wi-Fi module ${wifiModule.macAddress} moved to archive`);
          this.setStatus(wifiModule, {archived: true});
        }, (error: HttpErrorResponse) => {
          if (error.status === BAD_REQUEST && error.error?.text) {
            this.notificationService.error(error.error.text);
          } else {
            this.notificationService.error(`Failed to move Wi-Fi module ${wifiModule.macAddress} to archive`);
          }
        });
      }
    });
    return result;
  }

  public runDialogUpdateOperation(wifiModule: WiFiModuleView, dialogClass: any, data: any): MatDialogRef<any> {
    this.progressing = true;
    const result = this.dialogService.showModal(dialogClass, { width: '500px', data});
    result.afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: WiFiModule) => {
      this.progressing = false;
      this.setStatus(wifiModule, result);
    });
    return result;
  }

  public updateData(resetPage = false): void {
    this.loading = true;
    if (this.timer) {
      this.timer.unsubscribe();
    }
    this.deviceModulesService.getDeviceWiFiModules(this.query, this.sorting, resetPage ? 1 : this.activePage)
        .pipe(finalize(() => this.loading = false)).subscribe((wifiModules: Paged<WiFiModule>) => {
      this.wifiModules = wifiModules;
      (this.wifiModules.content || []).forEach(wifiModule => this.setActions(wifiModule));
      this.activePage = resetPage ? 1 : this.activePage;
      this.timer = interval(REFRESH_TIMEOUT).pipe(takeUntil(this.destroyed)).subscribe(() => this.refreshStatus());
    });
  }

  public formatMacAddress(query: string): string {
    const queryStripped = (query || '').replace(new RegExp(`[${SPECIAL_CHARS}]`, 'g'), '');
    if (new RegExp(`^${MAC_SYMBOLS}{12}$`).test(queryStripped)) {
      return queryStripped.split('')
        .reduce((acc: string, char: string, index: number) => index % 2 === 0 && index > 0 ? acc + ':' + char : acc + char, '')
        .toUpperCase();
    } else {
      return query;
    }
  }

  private refreshStatus(wifiModule?: WiFiModuleView): void {
    const ids = wifiModule ? [wifiModule.id] : (this.wifiModules?.content || []).map(x => x.id);
    this.deviceModulesService.acquireWiFiModulesStatuses(ids)
        .pipe(takeUntil(this.destroyed)).subscribe((statuses: Array<WiFiModuleStatusUpdate>) => {
      (statuses || []).forEach((status: WiFiModuleStatusUpdate) => {
        this.setStatus((this.wifiModules?.content || []).find(x => x.id === status.id), status);
      });
    });
  }

  private pollOnlineStatus(wifiModule: WiFiModuleView, dialog: MatDialogRef<any>,
      check?: (x: WiFiModuleStatusUpdate) => boolean): void {
    const timer = interval(REFRESH_TIMEOUT)
        .pipe(takeUntil(this.destroyed), takeUntil(dialog.afterClosed()))
        .subscribe(() => {
      this.deviceModulesService.acquireWiFiModulesStatuses([wifiModule.id])
          .subscribe((statuses: Array<WiFiModuleStatusUpdate>) => {
        if (check ? !check(statuses[0]) : !statuses[0].online) {
          dialog.close(false);
          this.notificationService.error('Device operation is no longer available');
        }
        this.setStatus(wifiModule, statuses[0]);
      });
    });
  }

  private setStatus(wifiModule: WiFiModuleView, status: Partial<WiFiModuleStatusUpdate | WiFiModule>): void {
    Object.assign(wifiModule, status);
    wifiModule && this.setActions(wifiModule);
  }

  private setActions(wifiModule: WiFiModuleView) {
    wifiModule.actions = [{
      title: 'View Details',
      action: () => this.navigateDeviceModule(wifiModule)
    }, {
      title: 'Delete / Unbind',
      action: () => this.pollOnlineStatus(wifiModule, this.deleteUnbind(wifiModule),
        (status: WiFiModuleStatusUpdate) => !!status.usersCount),
      disabled: !wifiModule.usersCount || this.activeUser.isTechnician || wifiModule.archived
    }, {
      title: 'Change SN / Model',
      action: () => this.pollOnlineStatus(wifiModule, this.runDialogUpdateOperation(wifiModule,
        DeviceModuleEditDialogComponent, {deviceModule: wifiModule, models: this.models}),
          (status: WiFiModuleStatusUpdate) => !status.online && !status.usersCount),
      disabled: !!wifiModule.usersCount || wifiModule.online || wifiModule.archived
    }, {
      title: 'Change access token',
      action: () => this.pollOnlineStatus(wifiModule, this.runDialogUpdateOperation(wifiModule,
        DeviceModuleAccessTokenDialogComponent, {deviceModule: wifiModule}),
          (status: WiFiModuleStatusUpdate) => !status.online),
      disabled: wifiModule.online || wifiModule.archived || this.activeUser.isTechnician
    }, {
      title: 'Refresh epp-config',
      action: () => this.pollOnlineStatus(wifiModule, this.runDialogUpdateOperation(wifiModule,
        DeviceModuleEppConfigDialogComponent, {deviceModule: wifiModule})),
      disabled: !wifiModule.online || wifiModule.archived || this.activeUser.isTechnician
    }, {
      title: 'Move to another stand',
      action: () => this.pollOnlineStatus(wifiModule, this.runDialogUpdateOperation(wifiModule,
        DeviceModuleChangeStandDialogComponent, {deviceModule: wifiModule, stands: this.stands})),
      disabled: !wifiModule.online || wifiModule.archived || this.activeUser.isTechnician
    }];
    if ((this.activeUser.isTechnician || this.activeUser.isAdmin) && !wifiModule.archived) {
      wifiModule.actions.push({
        title: 'Move to archive',
        action: () => this.pollOnlineStatus(wifiModule, this.moveToArchive(wifiModule),
          (status: WiFiModuleStatusUpdate) => !status.online && !status.usersCount),
        disabled: wifiModule.online || !!wifiModule.usersCount
      });
    }
  }

  private acquireModels(): void {
    this.ready =
    forkJoin({
      models: this.modelsService.getDeviceTypesAndModels(),
      stands: this.informationalService.getSystemStands()
    }).pipe(takeUntil(this.destroyed), shareReplay(1));
    this.ready.subscribe(({models, stands}) => {
      this.models = models;
      this.stands = stands;
    });
  }

}

