export const PAGE_SIZE = 20;

export const SPECIAL_CHARS = '!@#\\$%\\^\\&\\*\\)\\(\\+=\\.\\,_\\-–:;\\\'\\"/\\\\|\\[\\]\\?°\u2103';

export const MAC_SYMBOLS = '[0-9A-Fa-f]';
export const MAC_SYMBOL = new RegExp(MAC_SYMBOLS);

export const MAC_ADDRESS_FORMAT = new RegExp(`^(${MAC_SYMBOLS}{2}:){5}${MAC_SYMBOLS}{2}$`);

export const MAC_ADDRESS_MASK = 'MM:MM:MM:MM:MM:MM';
const MAC_GROUP = [MAC_SYMBOL, MAC_SYMBOL, ':'];
export const MAC_ADDRESS_TEXT_MASK = new Array(5).fill(0).map(x => MAC_GROUP).flat().concat([MAC_SYMBOL, MAC_SYMBOL]);