import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EppVersion, DeviceConfig, ModelConfig, BaseConfig, CreateDeviceConfig,
  CreateDeviceConfigResponse, CreateModelConfigResponse } from '@models/device-configs';
import {DeviceModelGroupEpp, DeviceModelSimple} from '@models/device-models';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import { Observable, Subject, merge, tap, first, finalize, of, concatMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceConfigsService {

  constructor(private http: HttpClient, private utils: Utils) {}

  public getEppVersions(): Observable<Array<EppVersion>> {
    return this.http.get<Array<EppVersion>>(`/device-config-service/api/v1/console/epp-versions`);
  }

  public getEppIcs(): Observable<Array<DeviceModelGroupEpp>> {
    return this.http.get<Array<DeviceModelGroupEpp>>(`/device-config-service/api/v1/console/identification-code`);
  }

  public getDeviceConfigs(query: string, deviceTypeId: number,
      activeOnly: boolean, sorting: Sorting, page: number): Observable<Paged<DeviceConfig>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      activeOnly: !!activeOnly,
      ...(deviceTypeId ? {deviceTypeId} : null),
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<DeviceConfig>>(`/device-config-service/api/v1/console/configs`,
      {params}).pipe(tap((paged: Paged<DeviceConfig>) => {
        Utils.extendPaged(paged);
        paged.content.forEach(this.updateConfig);
    }));
  }

  public getModelConfigs(query: string, deviceTypeId: number,
      activeOnly: boolean, sorting: Sorting, page: number): Observable<Paged<ModelConfig>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      activeOnly: !!activeOnly,
      ...(deviceTypeId ? {deviceTypeId} : null),
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<ModelConfig>>(`/admin-console-service/api/v1/json-configs`,
      {params}).pipe(tap((paged: Paged<ModelConfig>) => {
        Utils.extendPaged(paged);
        paged.content.forEach(this.updateConfig);
    }));
  }

  public getDeviceConfigsVersions(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`/device-config-service/api/v1/console/versions`);
  }

  public lookupConfigAcceptableModels(configId: number, query: string): Observable<Array<DeviceModelSimple>> {
    const params = {configId, query};
    return this.http.get<Array<DeviceModelSimple>>(`/device-service/api/v1/console/config-models`, {params});
  }

  public createDeviceConfig(config: CreateDeviceConfig, forceSave = false): Observable<CreateDeviceConfigResponse> {
    const data = new FormData();
    data.append('file', config.file, config.file.name);
    data.append('typeId', '' + config.typeId);
    data.append('boardId', config.boardId);
    data.append('eppVersion', '' + config.eppVersion);
    return this.http.post<CreateDeviceConfigResponse>(`/device-config-service/api/v1/configuration/save-config-file`,
      data, {params: {forceSave}});
  }

  public createModelConfig(configFile: File, forceSave = false): Observable<CreateModelConfigResponse> {
    const data = new FormData();
    data.append('configFile', configFile, configFile.name);
    const upload = this.http.post<CreateModelConfigResponse>(`/admin-console-service/api/v1/json-configs/upload`,
      data, {params: {forceSave}});
    const readonlyCatch = new Subject<CreateModelConfigResponse>();
    return merge(upload.pipe(finalize(() => {
      readonlyCatch.next(null);
    })), readonlyCatch).pipe(first());
  }

  public deleteDeviceConfig(configId: number, forceDelete = false): Observable<void> {
    return this.http.delete<void>(`/device-config-service/api/v1/console/delete-config`, {params: {id: configId, forceDelete}});
  }

  public deleteModelConfig(configId: number, forceDelete = false): Observable<void> {
    return this.http.delete<void>(`/admin-console-service/api/v1/json-configs/${configId}`, {params: {forceDelete}});
  }

  public updateModelConfig(doClone: boolean, configId: number, newModelId: number, newBoardId: string): Observable<ModelConfig> {
    const params = {clone: doClone, model: newModelId, boardId: newBoardId};
    return this.http.post<ModelConfig>(`/admin-console-service/api/v1/json-configs/${configId}/update`, params)
      .pipe(tap((result: ModelConfig) => this.updateConfig(result)));
  }

  public getModelsAcceptableConfigTree(configId: number): Observable<Object> {
    return this.http.get<Object>(`/admin-console-service/api/v1/json-configs/${configId}/download`);
  }

  public getModelConfigWithModifications(model: string): Observable<Object> {
    return this.http.get<Object>(`/admin-console-service/api/v1/json-config-patch/patched-config-by-model`,
      {params: {model}});
  }

  private updateConfig(config: BaseConfig): void {
    config.createdAt = config.createdAt ? new Date(config.createdAt) : null;
  }
}
