import { TemplateRef } from '@angular/core';
import { ViewTreeNode } from '@models/tree-node';
import { DropdownItem } from '@models/dropdown';

export interface BaseDialogData {
  title: string;
  text: string | TemplateRef<any>;
  agreeButton?: boolean;
  agreeButtonText?: string;
  icon?: ConfirmDialogIcon
}

export interface JsonDialogData extends BaseDialogData {
  json: string;
  disableScroll?: boolean;
}

export interface TwoButtonsDialogData extends BaseDialogData {
  cancelButton?: boolean;
  cancelButtonText?: string;
}

export interface DialogButton {
  text: string;
  code?: string;
  class?: string;
}

export interface ButtonsDialogData {
  title: string;
  text: string;
  buttons: Array<DialogButton>;
}

export enum ConfirmDialogIcon {
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export interface ConfirmationDialogData extends TwoButtonsDialogData {
}

export interface TextEnterDialogData extends TwoButtonsDialogData {
  value?: string;
  placeholder?: string;
  required?: boolean;
}

export interface MovetoDialogData extends TwoButtonsDialogData {
  root: ViewTreeNode;
  source: ViewTreeNode;
}

export interface CoverageDialogData extends TwoButtonsDialogData {
  coverages: Array<DropdownItem>;
  additionalText?: string;
}
