import { Injectable, ErrorHandler } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { UserInfoService } from '@services/user-info.service';
import { Observable, catchError, throwError, empty } from 'rxjs';
import { FORBIDDEN, THROW_IF_NOT_ALLOWED } from '@constants/http-params';

@Injectable({
  providedIn: 'root',
})
export class ReadOnlyInterceptor implements HttpInterceptor {

  constructor(private userInfoService: UserInfoService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((err: any, caught: Observable<HttpEvent<any>>) => {
      if (err instanceof HttpErrorResponse && err.status === FORBIDDEN) {
        this.userInfoService.showPermissionViolation();
        return request.headers.get(THROW_IF_NOT_ALLOWED) ? throwError(err) : empty();
      }
      return throwError(err);
    }));
  }

}