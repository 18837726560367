import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { WiFiModuleDiagnosticPermissionDetails } from '@models/device-modules';
import { NotificationService } from '@services/notification.service';
import { DialogService } from '@services/dialog.service';
import { DeviceModulesService } from '@services/device-modules.service';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { Utils } from '@services/utils';
import { Breadcrumbs } from '@models/breadcrumbs';
import { HttpParams } from '@constants/http-params';
import { DATE_TIME } from '@constants/dates';
import { Subject, forkJoin, of, concatMap, finalize, takeUntil } from 'rxjs';

@Component({
  selector: 'app-device-module-diagnostic-permission-view',
  templateUrl: './device-module-diagnostic-permission-view.component.html',
  styleUrls: ['./device-module-diagnostic-permission-view.component.scss']
})
export class DeviceModuleDiagnosticPermissionViewComponent implements OnInit, OnDestroy {

  public loading = false;
  public DATE_TIME = DATE_TIME;
  public breadcrumbs: Array<Breadcrumbs> = [];

  public permissionRequestId: number;
  public moduleId: number;
  public request: WiFiModuleDiagnosticPermissionDetails;

  private destroyed = new Subject<void>();

  constructor(private deviceModulesService: DeviceModulesService, private dialogService: DialogService,
    private notificationService: NotificationService, private utils: Utils) {}

  public ngOnInit(): void {
    this.moduleId = Number(this.utils.lookupParam(HttpParams.MODULE_ID));
    this.permissionRequestId = Number(this.utils.lookupParam(HttpParams.PERMISSION_ID));
    if (this.permissionRequestId !== null) {
      this.loading = true;
      forkJoin({
        deviceDetails: this.moduleId ? this.deviceModulesService.getDeviceWiFiModuleDetailed(this.moduleId) : of(null),
        permissionDetails: this.deviceModulesService.getDeviceWiFiModuleDiagnosticPermissionDetails(this.permissionRequestId)
      }).pipe(finalize(() => this.loading = false)).subscribe(({deviceDetails, permissionDetails}) => {
        this.request = permissionDetails;
        this.breadcrumbs.push({title: 'Device Modules', link: ['/provisioning', 'wifi-modules']});
        if (deviceDetails && this.moduleId) {
          this.breadcrumbs.push({title: deviceDetails.macAddress, link: ['/provisioning', 'device-module', '' + this.moduleId, 'permissions']});
        }
        this.breadcrumbs.push({title: '' + this.permissionRequestId});
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public completeRequest(): void {
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Complete permission request',
      text: `You are about to complete the remote connection permission to the user's device. Are you sure?`,
      agreeButtonText: 'Yes, complete'
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.loading = true;
        this.deviceModulesService.completeDiagnosticRequest(this.permissionRequestId)
            .pipe(concatMap(() => 
          this.deviceModulesService.getDeviceWiFiModuleDiagnosticPermissionDetails(this.permissionRequestId)
        ), finalize(() => this.loading = false), takeUntil(this.destroyed)).subscribe((result: WiFiModuleDiagnosticPermissionDetails) => {
          this.request = result;
          this.notificationService.success(`Permission request is successfully completed`);
        }, (error: HttpErrorResponse) => {
          this.notificationService.error(`Something went wrong. Please try again`);
        });
      }
    });
  }

}
