<div class="firmwares-view inner-view">
  <div class="view-header">
    <h1 class="view-title">Mobile TV Apps Management</h1>
  </div>
  <div class="view-section search-block">
    <app-search-bar (search)="search($event)" [searching]="loading" placeholder="Search version or name"></app-search-bar>
  </div>
  <div class="results-block view-section scroll-right">
    <perfect-scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.VERSION" (sort)="setSorting($event)">
                App Version
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.APP_NAME" (sort)="setSorting($event)">
                App Name
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ENABLED" (sort)="setSorting($event)">
                Activity
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.COVERAGE" (sort)="setSorting($event)">
                Coverage
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                Update Date
              </app-sortable-header> 
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of versionsProcessed">
            <td>{{row.versionName || '&mdash;'}}</td>
            <td>{{row.applicationName || '&mdash;'}}</td>
            <td>
              <div class="activity-status"
                [ngClass]="{active: row.status === TvAppVersionStatus.PUBLISHED, inactive: row.status !== TvAppVersionStatus.PUBLISHED}">
                {{row.status === TvAppVersionStatus.PUBLISHED ? 'Published' : 'Unpublished'}}
              </div>
            </td>
            <td>
              <div class="activity-status"
                [ngClass]="{active: row.enabled, inactive: !row.enabled}">
                {{row.enabled ? 'Enabled' : 'Disabled'}}
              </div>
            </td>
            <td>{{row.coverage === null ? '' : row.coverage + '%'}}</td>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td>
              <app-actions [actions]="row.actions" [disabled]="progressing"></app-actions>
            </td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!versionsProcessed || !versionsProcessed?.length)"></app-no-data>
    </perfect-scrollbar>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>