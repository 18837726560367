<div class="dialog-content run-testing">
  <div class="dialog-header">
    <span class="title">Test Record</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="field-container">
      <div class="label">Device Mac</div>
      <div class="field">
        <app-dropdown [items]="availableDevices" placeholder="Select" [(ngModel)]="device" [disabled]="loading">
        </app-dropdown>
      </div>
    </div>
    <div class="field-container">
      <div class="label">Device Model</div>
      <div class="field">
        <app-dropdown [items]="availableModels" placeholder="Select" [(ngModel)]="model" [disabled]="loading">
        </app-dropdown>
      </div>
    </div>
    <div class="warning-line" *ngIf="device?.original.offline || device?.original.busy">
      <span class="warning" *ngIf="device?.original.busy"><span class="red">WARNING: </span>device is busy and unable to start tests</span>
      <span class="warning" *ngIf="device?.original.offline"><span class="red">WARNING: </span>device is not online</span>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="runTesting()" [disabled]="loading || !device || !model || device?.original.offline || device?.original.busy">Run</button>
  </div>
</div> 