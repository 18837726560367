import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceInstructionConflictDialogData } from '../../models/dialog-data';
import { DeviceTypeSimple, DeviceModelSimple } from '@models/device-models';
import { DeviceInstruction, NewDeviceInstruction, DeviceInstructionUpdateResult, InstructionStatus } from '@models/device-instructions';
import { DeviceInstructionsService } from '@services/device-instructions.service';
import { NotificationService } from '@services/notification.service';
import { DialogService } from '@services/dialog.service';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogIcon } from '@models/dialogs';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Subject, takeUntil, finalize } from 'rxjs';

interface ConflictRow {
  checked: boolean;
  type?: DeviceTypeSimple;
  model?: DeviceModelSimple;
}

@Component({
  selector: 'app-device-instruction-conflict-dialog',
  templateUrl: './device-instruction-conflict-dialog.component.html',
  styleUrls: ['./device-instruction-conflict-dialog.component.scss']
})
export class DeviceInstructionConflictDialogComponent implements OnInit, OnDestroy {

  @ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;

  public isNew: boolean;
  public rows: Array<ConflictRow>;
  public instruction: DeviceInstruction | NewDeviceInstruction;
  public allSelected = true;
  public progressing = false;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceInstructionConflictDialogData,
    private dialogRef: MatDialogRef<DeviceInstructionConflictDialogComponent>,
    private instructionsService: DeviceInstructionsService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.isNew = this.data.isNew;
    this.instruction = this.data.instruction;
    this.rows = [];
    const types = this.data.typesAndModels.types;
    const conflictedModels = new Set(this.data.conflicts?.models);
    (this.data.conflicts?.types || []).forEach((type: DeviceTypeSimple) => {
      this.rows.push({checked: true, type});
      const typeModels = this.data.conflicts?.models.filter(x => x.typeId === type.id);
      typeModels.forEach(x => conflictedModels.delete(x));
      this.rows = this.rows.concat(typeModels.map(model => ({checked: true, model, type})));
    });
    this.rows = this.rows.concat(Array.from(conflictedModels).map(model => 
      ({checked: true, model, type: types.find(x => x.id === model.typeId)})));
    this.dialogRef.afterOpened().pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.checkScrollbar();
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setAllSelected(): void {
    this.rows.forEach(row => row.checked = this.allSelected);
  }

  public rowSelected(row: ConflictRow): void {
    this.allSelected = this.rows.every(x => x.checked);
  }

  public showPopover(popover: NgbPopover, row: ConflictRow): void {
    popover.open({row});
  }

  public hidePopover(popover: NgbPopover): void {
    popover.close();
  }

  public checkScrollbar(): void {
    this.scrollbar.directiveRef.update();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public proceed(): void {
    if (!this.data.draft && this.rows.filter(x => x.checked).length === 0
        && this.instruction.types.length === this.data.conflicts?.types?.length
        && this.instruction.models.length === this.data.conflicts?.models?.length) {
      this.dialogService.showModal(ConfirmationDialogComponent, { maxWidth: '500px', data: {
        title: 'Obligatory information is not filled',
        text: `You are about to save instruction whithout setting devices types & models. This instruction will not be sent to mobile app and will be saved as a draft.`,
        icon: ConfirmDialogIcon.WARNING,
        agreeButtonText: 'Yes, save'
      }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
        if (result) {
          this.saveInstruction(true);
        }
      });
    } else {
      this.saveInstruction(this.data.draft);
    }
  }

  private saveInstruction(asDraft = false): void {
    const types = this.rows.filter(x => x.checked && x.type && !x.model).map(x => x.type);
    const models = this.rows.filter(x => x.checked && x.model).map(x => x.model);
    const selection = {types, models};
    this.instruction.status = asDraft ? InstructionStatus.DRAFT : InstructionStatus.READY;
    const request = this.isNew ? this.instructionsService.createDeviceInstruction(this.instruction, selection, true, asDraft) :
        this.instructionsService.updateDeviceInstruction(this.instruction as DeviceInstruction, selection, true, asDraft);
    this.progressing = true;
    request.pipe(finalize(() => this.progressing = false)).subscribe((result: DeviceInstructionUpdateResult) => {
      this.notificationService.success('Device Instruction successfully ' + (this.isNew ? 'created' : 'updated'));
      this.dialogRef.close(result); 
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to ' + (this.isNew ? 'create' : 'update') + ' Device Instruction');
      console.error(error);
    });
  }
}