import { Action } from '@models/actions';
import { FirmwareKind } from '@models/firmware';
import { Attribute } from '@models/informational';

export enum WiFiModuleSpaceType {
  HOUSE = 'house',
  ROOM = 'room'
}

export enum WiFiModuleStatus {
  ONLINE = 'on',
  OFFLINE = 'off'
}

export interface WiFiModuleSpace {
  id: number;
  name: string;
  type: string;
}

export interface WiFiModuleUser {
  id: number;
  owner: boolean;
}

export interface WiFiModule {
  id: number;
  macAddress: string;
  serialNumber: string;
  accessToken: string;
  deviceType: string;
  deviceModel: string;
  deviceModelId: number;
  usersCount: number;
  spaceType: WiFiModuleSpaceType;
  online: boolean;
  archived: boolean;
}

export interface WiFiModuleView extends WiFiModule {
  actions?: Array<Action>;
}

export interface WiFiModuleDetailed extends WiFiModule {
  deviceName: string;
  deviceModelId: number;
  networkStatus: WiFiModuleStatus;
  firmwareId: string;
  firmwareType: FirmwareKind;
  configId: number;
  mbVersion: string;
  activatedAt: Date;
  lastSeenAt: Date;
  binaryFirmware: boolean;
  spaces: Array<WiFiModuleSpace>;
  users: Array<WiFiModuleUser>;
}

export interface WiFiModuleLogEntry {
  id: number;
  ts: Date;
}

export interface WiFiModuleAuthoredEntry extends WiFiModuleLogEntry {
  name: string;
  author: string;
}

export enum WiFiModuleCommandType {
  REQUEST = 'request',
  RESPONSE = 'response'
}

export interface WiFiModuleCommand extends WiFiModuleAuthoredEntry {
  traceTs: number;
  type: WiFiModuleCommandType;
  trace: string;
  errNo: number;
  requestId: string;
}

export interface WiFiModuleAlarm extends WiFiModuleAuthoredEntry {
}

export interface WiFiModuleEvent extends WiFiModuleAuthoredEntry {
  payload: string;
}

export interface WiFiModuleFirmwareAlarmType {
  id: number;
  code: number;
  errorName: string;
  description: string;
}

export interface WiFiModuleFirmwareAlarm extends WiFiModuleLogEntry {
  errorNumber: number;
  description: string;
}

export interface WiFiModuleStatusEntry extends WiFiModuleLogEntry {
  name: string;
  value: string;
}

// corresponds to icon variations
export enum WiFiModuleDiagnosticPermissionStatus {
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  APPROVE_PENDING = 'APPROVE_PENDING',
  DENIED = 'DENIED',
  COMPLETED = 'COMPLETED',
  COMPLETE_PENDING = 'COMPLETE_PENDING',
  NEVER_COMPLETED = 'NEVER_COMPLETED'
}

export interface WiFiModuleDiagnosticPermissionBase {
  ownerId: string;
  mac: string;
  duration: number;
}

export interface WiFiModuleDiagnosticPermission extends WiFiModuleDiagnosticPermissionBase, WiFiModuleLogEntry {
  author: string;
  status: WiFiModuleDiagnosticPermissionStatus;
}

export interface WiFiModuleDiagnosticPermissionRequest extends WiFiModuleDiagnosticPermissionBase {
  showAll: boolean;
}

export interface WiFiModuleDiagnosticPermissionHistoryEntry {
  status: WiFiModuleDiagnosticPermissionStatus;
  text?: string;
  ts?: Date;
  createdBy?: string;
  details?: Array<WiFiModuleDiagnosticPermissionHistoryEntry>;
  expanded?: boolean;
}

export interface WiFiModuleDiagnosticPermissionDetails extends WiFiModuleDiagnosticPermission {
  statusUpdated: Date;
  history: Array<WiFiModuleDiagnosticPermissionHistoryEntry>;
  completed: boolean;
}

export interface WiFiModuleSession {
  id: number;
  onTime: Date;
  offTime: Date;
}

export interface WiFiModuleCommandEntryDetails {
  commandName: string;
  ts: Date;
  attributes: Array<Attribute>;
  value: string;
}

export interface WiFiModuleLogData {
  entry: WiFiModuleLogEntry;
  details: string;
}

export interface WiFiModuleStatusUpdate {
  id: number;
  online: boolean;
  usersCount: number;
}
