<div class="inner-view device-types-view">
  <div class="view-header align-container space-between">
    <h1 class="view-header align-container small-spacing">
      <span>Device Types</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
    <div class="controls">
      <button class="primary button-add" (click)="createDeviceType()">Add Device Type</button>
    </div>
  </div>
  <div class="view-section">
    <perfect-scrollbar>
      <div class="scroll-area contents-area">
        <table class="results-table">
          <thead>
            <tr>
              <th class="device-type-column">
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.NAME" (sort)="setSorting($event)">
                  Device Type
                </app-sortable-header>
              </th>
              <th class="product-line-column">
                Product Line
              </th>
              <th class="date-column">
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                  Updated
                </app-sortable-header>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of deviceTypes">
              <td>{{row.name || '&mdash;'}}</td>
              <td class="thin-space">
                <div class="tags-list">
                  <div class="tag" *ngFor="let productLine of row.productLines">{{productLine.name}}</div>
                </div>
              </td>
              <td>{{row.updatedAt ? (row.updatedAt | date:DATE) : '&mdash;'}}</td>
              <td><app-actions [actions]="row.actions" [disabled]="progressing"></app-actions></td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!loading && !deviceTypes?.length"></app-no-data>
      </div>
    </perfect-scrollbar>
    <ngx-loading [show]="loading"></ngx-loading>
  </div>
</div>
<ng-template #information>
  <div><b>Device types</b> – section for device types management. In this section, the user is able to:</div>
  <ol class="info-list top-space">
    <li>Add a new device type</li>
    <li>Edit, delete an existing device type (<span class="max-attention">WARNING: </span><b>you should be extremely careful if you are going to delete a device type). The device type used in the system can only be edited, it is only possible to add new “Product Line” values.</b>).</li>
  </ol>
  <div class="top-space">When adding a device type, the user must specify:</div>
  <ol class="info-list top-space">
    <li>Device type name</li>
    <li>“Product Line” values for this type of device - in fact, the name of the “Product Line” for this device, used in the Haier accounting system (SAP). A single device type can be associated with several “Product Line” values.</li>
  </ol>
</ng-template>