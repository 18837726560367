<div class="dialog-content">
  <div class="dialog-header">
    <div class="title-container">
      <div class="icon" *ngIf="data.icon" [ngClass]="data.icon"></div>
      <div class="title">{{data.title}}</div>
    </div>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="info" [innerHTML]="data.text"></div>
    <div class="selector-container">
      <app-dropdown [items]="coverages" [(ngModel)]="coverage"></app-dropdown>
    </div>
    <div class="additional-info" *ngIf="data.additionalText" [innerHTML]="data.additionalText"></div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>{{data.cancelButtonText || 'Cancel'}}</button>
    <button class="primary" (click)="proceed()" [disabled]="!coverage || coverage.value === null">{{data.agreeButtonText || 'OK'}}</button>
  </div>
</div>
