import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { POSITION_BELOW } from '@constants/overlay-position';

interface YearView {
  value: number;
  disabled: boolean;
}

const YEARS_ITEMS = 28;
const YEARS_SHIFT = 4;

@Component({
  selector: 'app-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => YearSelectComponent),
    multi: true
  }]
})
export class YearSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  public placeholder = '';

  @Input()
  public clearable = false;

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  public selectedYear: number = null;

  public expanded = false;
  public disabled = false;
  public years: Array<YearView>;
  public PositionBelow = POSITION_BELOW;

  private onTouchedCallback: () => void;
  protected commit(value: Date) {}

  constructor() { }

  public ngOnInit(): void {
    this.years = new Array(YEARS_ITEMS).fill(0)
      .map((x, i) => new Date().getFullYear() + i - YEARS_ITEMS + YEARS_SHIFT)
      .map(x => ({
        value: x,
        disabled: (this.minDate && x < this.minDate.getFullYear()) || (this.maxDate && x > this.maxDate.getFullYear())
      }));
  }

  public toggle(event: MouseEvent): void {
    if (!this.disabled) {
      this.expanded = !this.expanded;
    }
    event.stopPropagation();
  }

  public selectYear(year: YearView, event: MouseEvent): void {
    if (!this.disabled && !year.disabled) {
      this.selectedYear = year.value;
      this.expanded = false;
      this.commit(new Date(this.selectedYear, 0));
    }
    event.stopPropagation();
  }

  public clear(): void {
    if (!this.disabled) {
      this.selectedYear = null;
      this.commit(null);
    }
  }

  public writeValue(value: Date) {
    this.selectedYear = value ? value.getFullYear() : null;
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
