import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DictionaryGroup, DictionaryItem, CatalogueGroup, CatalogueItem,
  Template, TemplatesItem, TemplatesItemGroup, TemplateGroup } from '@models/templates';
import { TreeNodeType } from '@models/tree-node';
import { Observable, map, tap } from 'rxjs';
import { Utils } from '@services/utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {

  constructor(private httpClient: HttpClient) {
  }

  public getDictionaries(): Observable<DictionaryGroup> {
    return this.httpClient.get<DictionaryGroup>(`/admin-console-service/api/v1/dictionary/tree`)
      .pipe(tap((dictionaries: DictionaryGroup) => this.updateGroupDates(dictionaries)));
  }

  public getImagesCatalogue(): Observable<CatalogueGroup> {
    return this.httpClient.get<CatalogueGroup>(`/admin-console-service/api/v1/catalogue/tree`)
      .pipe(tap((catalogue: CatalogueGroup) => this.updateGroupDates(catalogue)));
  }

  public getTemplates(query: string, sorting: Sorting, page: number): Observable<Paged<Template>> {
    const params = {
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.httpClient.get<Paged<Template>>(`/admin-console-service/api/v1/templates`, {params})
      .pipe(tap((paged: Paged<Template>) => {
        Utils.extendPaged(paged);
        paged.content.forEach((entry: Template) =>
          entry.updatedAt = entry.updatedAt ? new Date(entry.updatedAt) : null);
    }));
  }

  public getPageTemplate(templateId: number): Observable<TemplateGroup> {
    return this.httpClient.get<TemplateGroup>(`/admin-console-service/api/v1/templates/${templateId}`)
      .pipe(tap((pageTemplate: TemplateGroup) => this.updateGroupDates(pageTemplate)));
  }

  public getComponentInfo(componentType: TreeNodeType, componentId: number): Observable<TemplatesItem> {
    const typePath = componentType === TreeNodeType.IMAGE ? 'catalogue' : 'dictionary';
    return this.httpClient.get<TemplatesItem>(`/admin-console-service/api/v1/${typePath}/tree/leaf/${componentId}`)
      .pipe(tap((componentInfo: TemplatesItem) => this.updateTemplateItemDates(componentInfo)));
  }

  public getComponentUsages(componentType: TreeNodeType, componentId: number): Observable<Array<TemplateGroup>> {
    const typePath = componentType === TreeNodeType.IMAGE ? 'IMAGE' : 'RECORD';
    return this.httpClient.get<Array<TemplateGroup>>(`/admin-console-service/api/v1/usages/${typePath}/${componentId}`)
      .pipe(
        map((usages: Array<TemplateGroup>) => (usages as any)?.treeNodeDTOS || []),
        tap((usages: Array<TemplateGroup>) => usages.forEach(x => this.updateGroupDates(x))));
  }

  public updateDictionaryRecord(recordId: number, value: string): Observable<DictionaryItem> {
    return this.httpClient.put<DictionaryItem>(`/admin-console-service/api/v1/record/${recordId}`, {value})
      .pipe(tap((item: DictionaryItem) => this.updateTemplateItemDates(item)));
  }

  public updateCatalogueRecord(recordId: number, image: File): Observable<CatalogueItem> {
    const data = new FormData();
    data.append('file', image, image.name);
    return this.httpClient.patch<CatalogueItem>(`/admin-console-service/api/v1/image/${recordId}`, data)
      .pipe(tap((item: CatalogueItem) => this.updateTemplateItemDates(item)));
  }

  private updateGroupDates(group: TemplatesItemGroup): void {
    (group.leafs || []).forEach(x => this.updateTemplateItemDates(x));
    (group.nodes || []).forEach(x => this.updateGroupDates(x));
  }

  private updateTemplateItemDates(item: TemplatesItem): void {
    item.createdAt = item.createdAt ? new Date(item.createdAt) : null;
    item.updatedAt = item.updatedAt ? new Date(item.updatedAt) : null;
  }

}
