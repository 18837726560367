import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MONTHS } from '@constants/dates';
import { POSITION_BELOW } from '@constants/overlay-position';

interface MonthView {
  value: Date;
  title: string;
  selected: boolean;
  disabled: boolean;
}

@Component({
  selector: 'app-month-select',
  templateUrl: './month-select.component.html',
  styleUrls: ['./month-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MonthSelectComponent),
    multi: true
  }]
})
export class MonthSelectComponent implements OnInit, ControlValueAccessor {

  @Input()
  public placeholder = '';

  @Input()
  public clearable = false;

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  public selectedDate: Date;
  public text: string;

  public expanded = false;
  public disabled = false;
  public year = new Date().getFullYear();
  public prevYearAllowed = true;
  public nextYearAllowed = true;
  public months: Array<MonthView>;
  public PositionBelow = POSITION_BELOW;

  private onTouchedCallback: () => void;
  protected commit(value: Date) {}

  constructor() { }

  public ngOnInit(): void {
  }

  public toggle(event: MouseEvent): void {
    if (!this.disabled) {
      this.expanded = !this.expanded;
      this.year = this.selectedDate?.getFullYear() || new Date().getFullYear();
      this.yearChanged();
    }
    event.stopPropagation();
  }

  public prevYear(event: MouseEvent): void {
    if (this.prevYearAllowed) {
      this.year--;
      this.yearChanged();
    }
    event.stopPropagation();
  }

  public nextYear(event: MouseEvent): void {
    if (this.nextYearAllowed) {
      this.year++;
      this.yearChanged();
    }
    event.stopPropagation();
  }

  public selectMonth(month: MonthView, event: MouseEvent): void {
    if (!this.disabled && !month.disabled) {
      this.selectedDate = month.value;
      this.text = month.title + ' ' + this.year;
      this.expanded = false;
      this.commit(this.selectedDate);
    }
    event.stopPropagation();
  }

  public clear(): void {
    if (!this.disabled) {
      this.selectedDate = null;
      this.text = '';
      this.commit(this.selectedDate);
    }
  }

  public writeValue(value: Date) {
    this.selectedDate = value;
    this.text = value ? MONTHS[value.getMonth()] + ' ' + value.getFullYear() : '';
    this.yearChanged();
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private yearChanged(): void {
    if (this.maxDate) {
      const maxYear = this.maxDate.getFullYear();
      this.year = Math.min(this.year, maxYear);
      this.nextYearAllowed = this.year < maxYear;
    }
    if (this.minDate) {
      const minYear = this.minDate.getFullYear();
      this.year = Math.max(this.year, minYear);
      this.prevYearAllowed = this.year > minYear;
    }
    this.months = new Array(12).fill(0).map((x, index) => {
      const monthNumber = index % 2 === 0 ? index / 2 : 6 + (index - 1) / 2;
      const date = new Date(this.year, monthNumber);
      return {
        value: date,
        title: MONTHS[monthNumber],
        disabled: (this.minDate && date < this.minDate) || (this.maxDate && date > this.maxDate),
        selected: this.selectedDate && this.selectedDate.getFullYear() === date.getFullYear()
          && this.selectedDate.getMonth() === date.getMonth()
      } as MonthView;
    });
  }

}
