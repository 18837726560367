<div class="firmware-view inner-view">
  <div class="view-header with-breadcrumbs">
    <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    <h1 class="view-title">Version: {{firmware?.version || '&nbsp;'}}</h1>
  </div>
  <ng-container *ngIf="!loading">
    <div class="view-section">
      <div class="view-layout" [class.wide]="firmware?.typesAndModels?.length">
        <div class="panel info-block" *ngIf="firmware">
          <div class="panel-header">Firmware details</div>
          <div class="panel-section block-layout">
            <div class="row">
              <div class="label-base">Status</div>
              <div class="info">
                <div class="activity-status"
                  [ngClass]="{active: firmware.state === FirmwareState.PUBLISHED, inactive: firmware.state !== FirmwareState.PUBLISHED}">
                  {{firmware.state === FirmwareState.PUBLISHED ? 'Published' : 'Unpublished'}}
                </div>
              </div>
            </div>
            <div class="row">
              <table class="main-info">
                <tr>
                  <th>Type</th>
                  <th>Kind</th>
                  <th *ngIf="firmware.state === FirmwareState.PUBLISHED">Coverage</th>
                </tr>
                <tr>
                  <td class="capitalize text-grayed">{{firmware.type || '&mdash;'}}</td>
                  <td class="capitalize text-grayed">{{firmware.kind || '&mdash;'}}</td>
                  <td *ngIf="firmware.state === FirmwareState.PUBLISHED">
                    <div class="coverage-field">
                      <app-dropdown [items]="coverages" [(ngModel)]="coverage" (ngModelChange)="setCoverage($event)"></app-dropdown>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="row">
              <div class="label-base">Update date</div>
              <div class="info">
                <span class="text-grayed">{{firmware.updatedAt ? (firmware.updatedAt | date:DATE) : '&mdash;'}}</span>
              </div>
            </div>
          </div>
          <div class="controls panel-section no-separator">
            <div class="status-buttons">
              <button class="primary" (click)="publish()" *ngIf="firmware.state !== FirmwareState.PUBLISHED">Publish</button>
              <button class="primary" (click)="unpublish()" *ngIf="firmware.state === FirmwareState.PUBLISHED">Unpublish</button>
            </div>
            <div class="download-buttons" *ngIf="firmware.state === FirmwareState.PUBLISHED">
              <button class="button secondary" (click)="downloadFirmware()" [disabled]="firmware.state !== FirmwareState.PUBLISHED">
                Download firmware
              </button>
            </div>
          </div>
        </div>
        <div class="panel models-block" *ngIf="firmware?.typesAndModels?.length">
          <div class="panel-header">List of models</div>
          <perfect-scrollbar>
            <table class="panel-section results-table static-borders">
              <tr>
                <th>Type</th>
                <th>Model</th>
              </tr>
              <tr *ngFor="let model of firmware.typesAndModels">
                <td>{{model.typeName || '&mdash;'}}</td>
                <td>{{model.modelName || '&mdash;'}}</td>
              </tr>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="loading-dialog">
    <ngx-loading [show]="downloading" [config]="{fullScreenBackdrop: true}" [template]="loadingTemplate"></ngx-loading>
  </div>
  <ngx-loading [show]="loading">
  </ngx-loading>
</div>
<ng-template #loadingTemplate>
  <div class="loading-banner">
    <div class="loader centered"></div>
    <div class="text">Preparing data<br/>Please wait...</div>
  </div>
</ng-template>
