import { Component, OnInit, Input, ChangeDetectorRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownItem } from '@models/dropdown';
import { DatesRangeType, DatesRangeTypes, DatesRange, DATES_RANGE_TYPES_STD, DATES_RANGE_TYPES,
  minDate, maxDate, limitDate } from '@models/dates';

@Component({
  selector: 'app-dates-range',
  templateUrl: './dates-range.component.html',
  styleUrls: ['./dates-range.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatesRangeComponent),
    multi: true
  }]
})
export class DatesRangeComponent implements OnInit, ControlValueAccessor {

  @Input()
  public minDate: Date;

  @Input()
  public maxDate: Date;

  @Input()
  public tillNow = true;

  @Input()
  public typeSelector = false;

  @Input()
  public rangeTypes: Array<DatesRangeType> = Object.values(DATES_RANGE_TYPES_STD);

  @Input()
  public rangeType: DatesRangeTypes = this.typeSelector ? DatesRangeTypes.YEAR : DatesRangeTypes.CUSTOM;

  @Input()
  public clearable = false;

  public rangeItems: Array<DropdownItem<DatesRangeType>> = [];
  public rangeItem: DropdownItem<DatesRangeType>;

  public range: DatesRange;
  public disabled = false;
  public lowerBound: DatesRange;
  public upperBound: DatesRange;
  public DatesRangeTypes = DatesRangeTypes;

  private onTouchedCallback: () => void;
  private commit(value: DatesRange) {}

  constructor(private cd: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.rangeItems = (this.rangeTypes || []).map(x => ({
      value: x.type,
      title: x.label,
      original: x
    }));
    this.rangeItem = this.rangeItems.find(x => x.value === this.rangeType);
    this.range = DATES_RANGE_TYPES[this.rangeType].defaultRange();
    this.updateBounds();
    this.limitBounds();
  }

  public writeValue(value: DatesRange) {
    this.range = value || {from: null, to: null};
    const recognizedType = Object.values((this.rangeTypes || [])).find(x => x.recognize(this.range));
    this.rangeType = this.typeSelector ? (recognizedType?.type || DatesRangeTypes.CUSTOM) : DatesRangeTypes.CUSTOM;
    this.rangeItem = this.rangeItems.find(x => x.value === this.rangeType);
    this.updateBounds();
    this.limitBounds();
  }

  public onRangeTypeSelected(rangeType: DropdownItem<DatesRangeType>): void {
    this.rangeType = rangeType.value;
    this.range = rangeType.original.defaultRange();
    this.updateBounds();
    this.limitBounds();
    this.commit(this.range);
  }

  public onStartDateSelected(date: Date): void {
    this.range = {from: date, to: this.range.to};
    this.limitStartDate();
    this.range.to = this.rangeItem.original.complete ? this.rangeItem.original.complete(date) : this.range.to;
    this.commit(this.range);
    this.updateBounds();
    setTimeout(() => this.cd.detectChanges());
  }

  public onEndDateSelected(date: Date): void {
    this.range = {from: this.range.from, to: date};
    this.limitEndDate();
    this.commit(this.range);
    this.updateBounds();
    setTimeout(() => this.cd.detectChanges());
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private limitBounds(): void {
    this.limitEndDate();
    this.limitStartDate();
  }

  private updateBounds(): void {
    const startBottomLimitations = [this.minDate];
    const startTopLimitations = [this.maxDate, this.isCustom() ? this.range.to : null, this.tillNow ? new Date() : null];
    this.lowerBound = {from: maxDate(startBottomLimitations), to: minDate(startTopLimitations)};
    const endBottomLimitations = [this.minDate, this.range.from];
    const endTopLimitations = [this.maxDate, this.tillNow ? new Date() : null];
    this.upperBound = {from: maxDate(endBottomLimitations), to: minDate(endTopLimitations)};
  }

  private limitStartDate(): void {
    this.range.from = limitDate(this.range.from, this.lowerBound);
  }

  private limitEndDate(): void {
    this.range.to = limitDate(this.range.to, this.upperBound);
  }

  private isCustom(): boolean {
    return this.rangeType === DatesRangeTypes.CUSTOM || !this.typeSelector;
  }

}
