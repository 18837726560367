<div class="dialog-content access-token-edit">
  <div class="dialog-header">
    <span class="title">Change access token</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="mac-address">
      MAC address <b>{{data.deviceModule.macAddress}}</b>
    </div>
    <div class="field-container">
      <div class="field-name">New access token</div>
      <div class="field">
        <input type="text" class="input" maxlength="20" [(ngModel)]="newAccessToken" (ngModelChange)="updateChanges()"/>
        <div class="validation">
          <ng-container *ngIf="errors && changed">
            <div *ngIf="errors.invalidToken">
              Field must contain 1-20 latin symbols, digits and/or special symbols excluding <b>',";</b>
            </div>
            <div *ngIf="errors.sameToken && !errors.invalidToken">New access token can not be the same as current one</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()" [disabled]="errors || progressing">Save changes</button>
  </div>
</div> 