import { NgModule } from '@angular/core';

import { MobileBackendRoutingModule } from './mobile-backend-routing.module';
import { CommonModule } from '../common/common.module';

import { DictionariesViewComponent } from './pages/dictionaries-view/dictionaries-view.component';
import { ImageCatalogueViewComponent } from './pages/image-catalogue-view/image-catalogue-view.component';
import { TemplatesViewComponent } from './pages/templates-view/templates-view.component';
import { TextPreviewComponent } from './components/text-preview/text-preview.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { DirectoryPreviewComponent } from './components/directory-preview/directory-preview.component';
import { PageTemplateViewComponent } from './pages/template-view/template-view.component';
import { ComponentUsagesViewComponent } from './pages/component-usages-view/component-usages-view.component';

@NgModule({
  imports: [
    CommonModule,
    MobileBackendRoutingModule
  ],
  declarations: [
    DictionariesViewComponent,
    ImageCatalogueViewComponent,
    TemplatesViewComponent,
    TextPreviewComponent,
    ImagePreviewComponent,
    DirectoryPreviewComponent,
    PageTemplateViewComponent,
    ComponentUsagesViewComponent
  ]
})
export class MobileBackendModule { }
