import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FirmwareService } from '@services/firmware.service';
import { DialogService } from '@services/dialog.service';
import { Firmware, FirmwareState } from '@models/firmware';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DATE } from '@constants/dates';
import { HttpParams } from '@constants/http-params';
import { Utils } from '@services/utils';
import { finalize } from 'rxjs';

enum SortColumn {
  VERSION = 'version',
  TYPE = 'criticality',
  KIND = 'kind',
  STATUS = 'state',
  COVERAGE = 'coverage',
  UPDATED = 'updatedAt'
} 

@Component({
  selector: 'app-firmwares-view',
  templateUrl: './firmwares-view.component.html',
  styleUrls: ['./firmwares-view.component.scss']
})
export class FirmwareListViewComponent implements OnInit {

  public firmwares: Paged<Firmware>;
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public FirmwareState = FirmwareState;
  public DATE_FORMAT = DATE;
  public sorting: Sorting = {column: SortColumn.UPDATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private firmwareService: FirmwareService, private dialogService: DialogService, private utils: Utils) { }

  public ngOnInit(): void {
    this.query = this.utils.lookupParam(HttpParams.QUERY) || '';
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Firmware Management',
      text: this.information
    }});
  }

  private updateData(resetPage = false): void {
    this.loading = true;
    this.firmwareService.getFirmwares(this.query, resetPage ? 1 : this.activePage, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe((firmwares: Paged<Firmware>) => {
      this.firmwares = firmwares;
      this.activePage = resetPage ? 1 : this.activePage;
    });
  }

}
