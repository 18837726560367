import { DropdownItem } from '@models/dropdown';

// should be missing: test, move, copy
export enum ConfigPatchOperationType {
  ADD = 'add',
  REMOVE = 'remove',
  REPLACE = 'replace',
  REPLACE_STEP = 'replaceStepToList',
  TEST = 'test',
  MOVE = 'move',
  COPY = 'copy'
};

export const ConfigPatchOperations: Array<DropdownItem> = [
  {value: ConfigPatchOperationType.ADD, title: 'add'},
  {value: ConfigPatchOperationType.REMOVE, title: 'remove'},
  {value: ConfigPatchOperationType.REPLACE, title: 'replace'},
  {value: ConfigPatchOperationType.REPLACE_STEP, title: 'replaceStepToList'},
  //{value: ConfigPatchOperationType.TEST, title: 'test'},
  //{value: ConfigPatchOperationType.MOVE, title: 'move'},
  //{value: ConfigPatchOperationType.COPY, title: 'copy'}
];

export const CONFIG_PATCH_SCHEMA = {
  "$schema": "http://json-schema.org/draft-04/schema#",
  "definitions": {
    "path": {
      "description": "A JSON Pointer path.",
      "type": "string"
    }
  },
  "id": "https://json.schemastore.org/json-patch.json",
  "items": {
    "oneOf": [
      {
        "additionalProperties": false,
        "required": ["value", "op", "path"],
        "properties": {
          "path": {
            "$ref": "#/definitions/path"
          },
          "op": {
            "description": "The operation to perform.",
            "type": "string",
            "enum": ["add", "replace", "test"]
          },
          "value": {
            "description": "The value to add, replace or test."
          }
        }
      },
      {
        "additionalProperties": false,
        "required": ["op", "path"],
        "properties": {
          "path": {
            "$ref": "#/definitions/path"
          },
          "op": {
            "description": "The operation to perform.",
            "type": "string",
            "enum": ["remove", "replaceStepToList"]
          }
        }
      },
      {
        "additionalProperties": false,
        "required": ["from", "op", "path"],
        "properties": {
          "path": {
            "$ref": "#/definitions/path"
          },
          "op": {
            "description": "The operation to perform.",
            "type": "string",
            "enum": ["move", "copy"]
          },
          "from": {
            "$ref": "#/definitions/path",
            "description": "A JSON Pointer path pointing to the location to move/copy from."
          }
        }
      }
    ]
  },
  "title": "JSON schema for JSONPatch files",
  "type": "array"
};
