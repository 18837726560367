import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Sorting, SortOrder, switchSort, toggleSort } from '@models/sorting';
import { Align } from '@constants/overlay-position';

@Component({
  selector: 'app-sortable-header',
  templateUrl: './sortable-header.component.html',
  styleUrls: ['./sortable-header.component.scss']
})
export class SortableHeaderComponent implements OnInit {

  @Input()
  public sorting: Sorting;

  @Input()
  public column: string;

  @Input()
  public clearSortingAllowed = false;

  @Input()
  public align: Align = Align.LEFT;

  @Output()
  public sort = new EventEmitter<Sorting>();

  public SortOrder = SortOrder;
  public Align = Align;

  constructor() { }

  public ngOnInit(): void {
  }

  public emit(): void {
    this.sort.emit(this.clearSortingAllowed ? switchSort(this.column, this.sorting) : toggleSort(this.column, this.sorting));
  }

}
