import { Component, OnInit, AfterViewInit, OnDestroy, Inject, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ConfigPatchConstructorComponent } from '../config-patch-constructor/config-patch-constructor.component';
import { DeviceModelSimple, DeviceTypesAndModels } from '@models/device-models';
import { ModelModificationInfo, ModificationPatchDetails,
    ModelModificationView, ModificationStatus } from '@models/config-modifications';
import { ModelConfigurationEditDialogData } from '../../models/dialog-data';
import { WizardStep } from '@models/actions';
import { JsonEditComponent } from '@components/json-edit/json-edit.component';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { DeviceConfigsService } from '@services/device-configs.service';
import { ConfigModificationsService } from '@services/config-modifications.service';
import { Subject, forkJoin, of, takeUntil, finalize } from 'rxjs';
import { Utils } from '@services/utils';
import { validate } from 'jsonschema';
import { CONFIG_PATCH_SCHEMA } from '@constants/patches';

@Component({
  selector: 'app-modification-edit-dialog',
  templateUrl: './modification-edit-dialog.component.html',
  styleUrls: ['./modification-edit-dialog.component.scss']
})
export class ModificationEditDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  public selectedTab = 0;
  public wizardSteps = [
    {title: 'Settings', invalid: false, visited: true, active: true},
    {title: 'Patch', invalid: false, visited: false, active: false},
    {title: 'Result', invalid: false, visited: false, active: false}
  ] as Array<WizardStep>;
  public modificationDetails: ModificationPatchDetails;
  public modificationName = '';
  public typesAndModels: DeviceTypesAndModels;
  public models: Array<DeviceModelSimple> = [];
  public modelsModifications: Array<ModelModificationInfo> = [];
  public modelsList: Array<ModelModificationView>;
  public invalidModels: Array<number> = [];
  public modelConfig: Object;
  public showForceModels = false;
  public forceUseModels = false;
  public json = '[]';
  public editorView = true;
  public validating = false;
  public validated = false;
  public applying = false;
  public applied = false;
  public saving = false;
  public errors: any;
  public ModificationStatus = ModificationStatus;

  private destroyed = new Subject<void>();

  @ViewChild('jsonEditor') jsonEditor: JsonEditComponent;
  @ViewChild('constructorView') consturctorView: ConfigPatchConstructorComponent;
  @ViewChild('nameField') nameField: ElementRef<HTMLInputElement>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModelConfigurationEditDialogData,
    private dialogRef: MatDialogRef<ModificationEditDialogComponent>,
    private configService: DeviceConfigsService, private utils: Utils,
    private dialogService: DialogService, private notificationService: NotificationService,
    private patchService: ConfigModificationsService, private changeDetectorRef: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.loadModificationDetails();
  }

  public ngAfterViewInit(): void {
    setTimeout(() => this.nameField.nativeElement.focus());
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public previousTab(): void {
    this.selectedTab--;
    this.errors = null;
    this.adjustWizardBar();
  }

  public nextTab(): void {
    if (this.selectedTab === 0 && !this.validateSummary()) {
      return;
    }
    if (this.selectedTab === 1 && !this.validatePatchContents()) {
      return;
    }
    this.selectedTab++;
    this.wizardSteps[this.selectedTab].visited = true;
    this.adjustWizardBar();
    if (this.selectedTab === 1) {
      this.setModelConfig();
      this.changeDetectorRef.detectChanges();
      this.jsonEditor.update();
    }
    if (this.selectedTab === 2) {
      this.validated = this.applied = false;
      this.validate();
    }
  }

  public setEditorView(editorView: boolean): void {
    this.editorView = editorView;
    this.resetErrors();
    if (editorView) {
      this.jsonEditor.update();
    } else {
      setTimeout(() => this.consturctorView.updateView());
    }
  }

  public handleJsonFile(file: File): void {
    const reader = new FileReader();
    const self = this;
    reader.onload = function() {
      self.json = reader.result as string;
      self.validatePatchContents();
    };
    reader.onerror = function() {
      self.notificationService.error('Failed to upload file ${file.fileName}');
    };
    reader.readAsText(file);
  }

  public validate(): void {
    this.modelsList = this.models.map(x =>
      ({model: x, deviceType: (this.typesAndModels.types || []).find(y => y.id === x.typeId), ok: true}));
    this.validating = true;
    const patchDetails = this.createPatchDetails(true);
    this.patchService.validateConfigPatch(patchDetails)
        .pipe(takeUntil(this.destroyed), finalize(() => this.validating = false))
        .subscribe((validationResults: ModificationPatchDetails) => {
      this.setModelsOperationResults(validationResults);
      this.validated = true;
    });
  }

  public saveAsDraft(): void {
    const patchDetails = this.createPatchDetails(true);
    const promise = patchDetails.id ? this.patchService.editConfigPatch(patchDetails, true)
      : this.patchService.createConfigPatch(patchDetails, true);
    this.saving = true;
    promise.pipe(takeUntil(this.destroyed), finalize(() => this.saving = false))
        .subscribe((result: ModificationPatchDetails) => {
      this.notificationService.success('Modification draft successfully saved');
      this.close();
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Something went wrong. Please try again');
    });
  }

  public saveApply(update = false): void {
    if (this.validated) {
      const patchDetails = this.createPatchDetails();
      const promise = patchDetails.id ? this.patchService.editConfigPatch(patchDetails, false)
        : this.patchService.createConfigPatch(patchDetails, false);
      update ? this.saving = true : this.applying = true;
      promise.pipe(takeUntil(this.destroyed), finalize(() => this.applying = this.saving = false))
          .subscribe((results: ModificationPatchDetails) => {
        this.modificationDetails = results;
        this.setModelsOperationResults(results);
        this.applied = true;
      }, (error: HttpErrorResponse) => {
        this.notificationService.error('Something went wrong. Please try again');
      });
    }
  }

  public excludeModel(model: ModelModificationView): void {
    this.models = this.models.filter(x => x !== model.model);
    this.modelsList = this.modelsList.filter(x => x !== model);
    this.errors = this.modelsList.every(x => x.ok) ? null : {invalidModels: true};
    if (this.applied) {
      this.saveApply(true);
    }
  }

  public viewModelPatch(model: ModelModificationView): void {
    this.configService.getModelConfigWithModifications(model.model.name).pipe(takeUntil(this.destroyed))
        .subscribe((configContents: Object) => {
      this.dialogService.showModal(InformationDialogComponent, { width: '720px', data: {
        title: (model.deviceType?.name || '-') + ' / ' + (model.model.name || '-'),
        disableScroll: true,
        text: !configContents && 'Details not available',
        json: configContents && JSON.stringify(configContents)
      }});
    }, (err: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire model config with patches');
    });
  }

  public resetErrors(): void {
    this.errors = null;
    this.invalidModels = [];
  }

  public close(): void {
    this.dialogRef.close(true);
  }

  public checkModels(): void {
    this.resetErrors();
    const modelsWithModifications = (this.modelsModifications || [])
      .filter(x => x.hasModification).map(x => x.model?.id).filter(x => !!x);
    this.invalidModels = this.models.filter(x => modelsWithModifications.includes(x.id)).map(x => x.id);
    this.showForceModels = this.showForceModels || !!this.invalidModels.length;
  }

  private validateSummary(): any {
    this.checkModels();
    return !(this.errors = Utils.errors({name: !this.modificationName,
      models: !this.models.length, modifications: !this.forceUseModels && this.invalidModels.length}));
  }

  private validatePatchContents(): any {
    this.errors = null;
    let patch;
    try {
      patch = JSON.parse(this.json);
    } catch {
      this.errors = {invalid: true};
    }
    if (patch && !patch.length) {
      this.errors = {empty: true};
    } else {
      const validationResults = validate(patch, CONFIG_PATCH_SCHEMA);
      if (validationResults.errors?.length) {
        this.errors = {structure: true};
      }
    }
    return !this.errors;
  }

  private setModelsOperationResults(results: ModificationPatchDetails): void {
    this.modelsList.forEach((model: ModelModificationView) => {
      const result = (results.models || []).find(x => x.id === model.model.id);
      if (result) {
        Object.assign(model, {ok: !result.error, error: result.error, details: result.details});
      } else {
        Object.assign(model, {ok: true, error: null, details: null});
      }
    });
    this.errors = this.modelsList.every(x => x.ok) ? null : {invalidModels: true};
  }

  private loadModificationDetails(): void {
    forkJoin({
      modificationDetails: this.data.modification ?
        this.patchService.getConfigModificationDetails(this.data.modification.id) : of(null),
      modelsModifications: this.patchService.getModelsModifications(this.data.modification?.id, this.data.typesAndModels.models || [])
    }).pipe(takeUntil(this.destroyed))
        .subscribe(({modificationDetails, modelsModifications}) => {
      this.modificationDetails = modificationDetails;
      this.modificationName = modificationDetails?.name || '';
      this.modelsModifications = modelsModifications;
      const fixedModel = this.data.model ? this.data.typesAndModels.models.find(x => x.name === this.data.model) : null;
      const typesAndModels = {} as DeviceTypesAndModels;
      if (fixedModel) {
        this.models = [fixedModel];
        typesAndModels.models = [fixedModel];
        typesAndModels.types = [this.data.typesAndModels.types.find(x => x.id === fixedModel.typeId)];
      } else {
        const modelsIds = (modificationDetails?.models || []).map(x => x.id);
        this.models = (this.data.typesAndModels.models || []).filter(x => modelsIds.includes(x.id));
        typesAndModels.models = this.data.typesAndModels.models.filter(x => {
          const modificationInfo = modelsModifications.find(y => y.model.id === x.id);
          return modificationInfo && modificationInfo.configId;
        });
        typesAndModels.types = this.data.typesAndModels.types.filter(x => {
          return this.data.typesAndModels.models.find(y => y.typeId === x.id);
        });
      }
      this.typesAndModels = typesAndModels;
      if (modificationDetails?.patch) {
        this.json = modificationDetails?.patch;
      }
    });
  }

  private setModelConfig(): void {
    if (this.models?.length) {
      const modelIds = this.models.map(x => x.id);
      const modifications = this.modelsModifications.filter(x => modelIds.includes(x.model.id) && x.configId);
      if (modifications.length) {
        this.configService.getModelsAcceptableConfigTree(modifications[0].configId)
          .subscribe((rawConfig: Object) => this.modelConfig = rawConfig);
      } else {
        this.modelConfig = null;
      }
    }
  }

  private adjustWizardBar(): void {
    this.wizardSteps.forEach((x, index) => x.active = false);
    this.wizardSteps[this.selectedTab].active = true;
  }

  private createPatchDetails(draft = false): ModificationPatchDetails {
    return {
      id: this.modificationDetails?.id,
      name: this.modificationName,
      status: draft ? ModificationStatus.DRAFT : ModificationStatus.ACTIVE,
      type: this.models.length ? this.typesAndModels.types.find(x => x.id === this.models[0].typeId) : null,
      models: this.models.map(x => ({id: x.id, name: x.name})),
      patch: this.json,
      updatedAt: new Date()
    };
  }

}
