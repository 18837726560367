<div class="dialog-content device-module-diagnostic-permission">
  <div class="dialog-header">
    <span class="title">Add Permission Request</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="field-container">
      <div class="field-label">MAC address</div>
      <div class="field-value">
        <input type="text" class="input upper-case" placeholder="MAC address" [disabled]="macAddressLocked"
            [textMask]="macAddressMask" [(ngModel)]="macAddress"
            (ngModelChange)="onMacAddressChanged()" (keydown.enter)="updateOwners()">
      </div>
    </div>
    <div class="field-container">
      <div class="field-label">Owner ID</div>
      <div class="field-value">
        <app-dropdown [items]="owners" [(ngModel)]="owner" placeholder="Select"
          [disabled]="macAddressIncomplete || macAddressChanged || askAllOwners || (!ownersLoading && !owners?.length)">
        </app-dropdown>
        <div class="validation" *ngIf="macAddressIncomplete || macAddressChanged">
          <div>MAC Address should be filled properly to select owner</div>
        </div>
        <div class="validation" *ngIf="!ownersLoading && !owners?.length">
          <div>No device owner has been found. It is not possible to create a permission request.</div>
        </div>
      </div>
    </div>
    <div class="field-container">
      <app-checkbox [(ngModel)]="askAllOwners" (ngModelChange)="resetOwner()">Ask all owners</app-checkbox>
    </div>
    <div class="field-container">
      <div class="field-label">Duration, h</div>
      <div class="field-value">
        <app-dropdown [items]="durations" [(ngModel)]="duration" placeholder="Select Duration">
        </app-dropdown>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="createPermission()" [disabled]="!macAddress || macAddressIncomplete || macAddressChanged || (!owner && !askAllOwners) || !duration || (!ownersLoading && !owners?.length)">Save</button>
  </div>
</div> 