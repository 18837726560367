import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { DeviceModelsService } from '@services/device-models.service';
import { NotificationService } from '@services/notification.service';
import { ReportService } from '@services/report.service';
import { DialogService } from '@services/dialog.service';
import { Utils } from '@services/utils';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { DeviceTypesAndModels } from '@models/device-models';
import { Paged } from '@models/pageable';
import { SortOrder, Sorting } from '@models/sorting';
import { ExportType } from '@constants/export';
import { DatesRange } from '@models/dates';
import { DeviceReportTotals, DeviceReportData, DeviceReportModels,
  DeviceReportDataTotals, DeviceReportDataRow } from '@models/report';
import { DatesRangeTypes, DATES_RANGE_TYPES, DATES_RANGE_TYPES_YSTER } from '@models/dates';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of, finalize } from 'rxjs';

enum SortColumn {
  DEVICE_TYPE = 'deviceType',
  MODELS = 'model',
  PRODUCED = 'totalNumberOfDevices',
  ENROLLED = 'countOfPaired',
  NOT_ENROLLED = 'countOfUnpaired',
  HAS_BEEN_ONLINE = 'countOfBeenOnline'
}

@Component({
  selector: 'app-devices-report-view',
  templateUrl: './devices-report-view.component.html',
  styleUrls: ['./devices-report-view.component.scss']
})
export class DevicesReportViewComponent implements OnInit {

  public typesAndModels: DeviceTypesAndModels;
  public deviceTypes: Array<DropdownItem>;
  public deviceTypesSelected: Array<DropdownItem> = [];
  public models: Array<DropdownItem> = [];
  public modelsProvider: LookupProvider;
  public modelsSelected: Array<DropdownItem> = [];
  public activePage = 1;
  public sorting: Sorting = {column: SortColumn.DEVICE_TYPE, order: SortOrder.ASC};
  public SortColumn = SortColumn; 
  public data: DeviceReportData;
  public dataPage: Paged<DeviceReportDataRow>;
  public dataTotals: DeviceReportDataTotals;
  public overallTotals: DeviceReportTotals;
  public datesRangeTypes = Object.values(DATES_RANGE_TYPES_YSTER);
  public datesRange: DatesRange = DATES_RANGE_TYPES[DatesRangeTypes.YEAR].defaultRange();
  public exportActions = [
    {title: 'XLSX', action: () => this.exportReport(ExportType.XLSX)},
    {title: 'CSV', action: () => this.exportReport(ExportType.CSV)}
  ];
  public loading = false;
  public progressing = false;
  public showModels = false;

  @ViewChild('information') information: TemplateRef<any>;
  @ViewChild('viewScroll') private scrollbar: PerfectScrollbarComponent;

  constructor(private deviceModelsService: DeviceModelsService, private dialogService: DialogService,
    private notificationService: NotificationService, private reportService: ReportService) { }

  public ngOnInit(): void {
    this.loadDeviceTypesAndModels();
    this.updateTotals();
    this.updateData();
    this.modelsProvider = {search: (query: string): Observable<Array<DropdownItem>> => {
      const deviceTypes = new Set((this.deviceTypesSelected || []).map(x => x.value)); 
      return of(this.models.filter(x => {
        return x.title.toUpperCase().includes(query.toUpperCase())
          && (!deviceTypes.size || deviceTypes.has(x.original.typeId));;
      }));
    }};
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.dataPage = Utils.createPage<DeviceReportDataRow>(this.data?.rows, activePage);
    this.scrollbar.directiveRef.scrollToTop();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    (this.data?.rows || []).sort((a: DeviceReportDataRow, b: DeviceReportDataRow) => {
      return Utils.compare(a, b, this.sorting);
    });
    this.setActivePage(this.activePage);
  } 

  public deviceTypesChanged(): void {
    const deviceTypes = new Set((this.deviceTypesSelected || []).map(x => x.value));
    const currentlySelected = new Set((this.modelsSelected || []).map(x => x.value));
    this.modelsSelected = this.models.filter(x => {
      return currentlySelected.has(x.value) && (!deviceTypes.size || deviceTypes.has(x.original.typeId));
    });
    this.updateData();
  }

  public updateData(): void {
    this.loading = true;
    this.reportService.getDeviceReportData(this.getTypesAndModels(), this.datesRange, this.showModels)
        .pipe(finalize(() => this.loading = false)).subscribe((results: DeviceReportData) => {
      this.data = results;
      this.dataTotals = results.total;
      this.activePage = 1;
      this.setSorting(this.sorting);
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire report data');
    });
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Device Report',
      text: this.information
    }});
  }

  public exportReport(exportType: ExportType): void {
    this.loading = true;
    this.reportService.exportDevicesReport(this.getTypesAndModels(), this.datesRange, this.showModels, this.sorting, exportType)
        .pipe(finalize(() => this.loading = false)).subscribe(() => {}, (err: HttpErrorResponse) => {
      this.notificationService.error('Failed to export report');
    });
  }

  private updateTotals(): void {
    this.reportService.getDeviceReportTotals().subscribe((results: DeviceReportTotals) => {
      this.overallTotals = results;
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire report totals');
    });
  }

  private getTypesAndModels(): DeviceReportModels {
    let deviceTypeIds = this.deviceTypesSelected.map(x => x.value);
    if (this.modelsSelected?.length && !deviceTypeIds.length) {
      deviceTypeIds = Array.from(new Set(this.modelsSelected.map(x => x.original.typeId)));
    }
    return {types: deviceTypeIds.map(deviceTypeId => ({
      deviceTypeId,
      modelIds: (this.modelsSelected || []).filter(y => y.original.typeId === deviceTypeId).map(y => y.value)
    }))};
  }

  private loadDeviceTypesAndModels(): void {
    this.deviceModelsService.getDeviceTypesAndModels().subscribe((deviceTypesAndModels: DeviceTypesAndModels) => {
      this.typesAndModels = deviceTypesAndModels;
      this.deviceTypes = (this.typesAndModels?.types || []).map(x => ({value: x.id, title: x.name}));
      this.models = (this.typesAndModels?.models || []).map(x => ({value: x.id, title: x.name, original: x}));
    }, (error: HttpErrorResponse) => {
      this.notificationService.error('Failed to acquire models');
    });
  }

}
