<div class="datepicker-panel">
  <div class="panel-content">
    <div class="date-switch">
      <button class="button button-prev" (click)="previousClicked('year')"></button>
      <span class="switch-text text-black">{{yearLabel}}</span>
      <button class="button button-next" (click)="nextClicked('year')"></button>
    </div>
    <div class="date-switch">
      <button class="button button-prev" (click)="previousClicked('month')"></button>
      <span class="switch-text text-black">{{monthLabel}}</span>
      <button class="button button-next" (click)="nextClicked('month')"></button>
    </div>
  </div>
</div>