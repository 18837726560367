import { Injectable } from '@angular/core';
import {concatMap, Observable, of, tap} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  DeviceType, DeviceModel, DeviceModelSimple, DeviceModelsGroup, DeviceModelsGroupDetails,
  DeviceModelsGroupConfigs, DeviceModelGroups, DeviceModelTags, DeviceTypesAndModels,
  ModelStatus, DeviceModelUniquinessResponse, ProductLine, DeviceModelGroupStatus, DeviceSmartModelSimple
} from '@models/device-models';
import { Tag } from '@models/informational';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import {
  RequestAnswer,
  RequestAnswers,
  RequestMessage,
  RequestMessages,
  RequestQuestion,
  RequestQuestions,
  RequestResult
} from "@models/request";
import {ConfirmationDialogComponent} from "@components/confirmation-dialog/confirmation-dialog.component";
import {DialogService} from "@services/dialog.service";
import {ButtonsDialogComponent} from "@components/buttons-dialog/buttons-dialog.component";
import {DatesRange, fixRange} from "@models/dates";

@Injectable({
  providedIn: 'root'
})
export class DeviceModelsService {

  constructor(private http: HttpClient, private dialogService: DialogService) {}

  public getProductLines(): Observable<Array<ProductLine>> {
    return this.http.get<Array<ProductLine>>(`/device-service/api/v1/console/product-lines`);
  }

  public getDeviceTypes(): Observable<Array<DeviceType>> {
    return this.http.get<Array<DeviceType>>(`/device-service/api/v1/console/types`)
      .pipe(tap((res: Array<DeviceType>) => {
        res.forEach((deviceType: DeviceType) => this.deviceTypeDate(deviceType));
      }));
  }

  public createDeviceType(deviceType: DeviceType): Observable<DeviceType> {
    return this.http.post<DeviceType>(`/device-service/api/v1/console/types`, {...deviceType})
      .pipe(tap((res: DeviceType) => this.deviceTypeDate(res)));
  }

  public updateDeviceType(deviceType: DeviceType): Observable<DeviceType> {
    return this.http.put<DeviceType>(`/device-service/api/v1/console/types`, {...deviceType})
      .pipe(tap((res: DeviceType) => this.deviceTypeDate(res)));
  }

  public deleteDeviceType(deviceTypeId: number): Observable<void> {
    return this.http.delete<void>(`/device-service/api/v1/console/types/${deviceTypeId}`);
  }

  public getDeviceModels(query: string, deviceTypeId: number, tagIds: Array<number>,
      page: number, sorting: Sorting): Observable<Paged<DeviceModel>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null),
      ...(deviceTypeId ? {deviceTypeId} : null),
      ...(tagIds?.length ? {tagIds} : null)
    };
    return this.http.get<Paged<DeviceModel>>(`/device-service/api/v1/console/models`, {params})
        .pipe(tap((paged: Paged<DeviceModel>) => {
      Utils.extendPaged(paged);
      paged.content.forEach(model => this.modelDates(model));
    }));
  }

  public getSmartDeviceModels(): Observable<Array<DeviceSmartModelSimple>> {
    return this.http.get<Array<DeviceSmartModelSimple>>(`/device-service/api/v1/console/smart-models`);
  }

  public lookupModelsTags(query = ''): Observable<Array<DeviceModelTags>> {
    return this.http.get<Array<DeviceModelTags>>(`/device-service/api/v1/console/models/tags`, {params: {query}});
  }

  public lookupModels(query = ''): Observable<Array<DeviceModelSimple>> {
    return this.http.get<Array<DeviceModelSimple>>(`/device-service/api/v1/console/models/by-name`, {params: {query}});
  }

  public lookupModelsBySn(query = ''): Observable<Array<DeviceModelSimple>> {
    return this.http.get<Array<DeviceModelSimple>>(`/device-service/api/v1/console/models/by-serial-number`,
      {params: {serialNumber: query}});
  }

  public getDeviceModelById(modelId: number): Observable<DeviceModel> {
    return this.http.get<DeviceModel>(`/device-service/api/v1/console/models/${modelId}`).pipe(tap((model: DeviceModel) => {
      this.modelDates(model);
    }));
  }

  public getDeviceTypesAndModels(smartOnly = false): Observable<DeviceTypesAndModels> {
    return this.http.get<DeviceTypesAndModels>(`/device-service/api/v1/console/models/all`, {params: {smartOnly}});
  }

  public getModelsTags(query = ''): Observable<Array<Tag>> {
    return this.http.get<Array<Tag>>(`/device-service/api/v1/console/tags`, {params: {query}});
  }

  public checkModelNameAndSapUniqueness(deviceTypeId: number, customerModel: string,
      matNumber: string): Observable<DeviceModelUniquinessResponse> {
    const params = {deviceTypeId, customerModel, matNumber};
    return this.http.post<DeviceModelUniquinessResponse>(`/catalogue-integration-service/api/v1/console/validate-fields`, params);
  }

  public createDeviceModel(customerModel: string, matNumber: string,
      deviceTypeId: number, productLineId: number): Observable<void> {
    const params = {deviceTypeId, productLineId, customerModel, matNumber};
    return this.http.post<void>(`/catalogue-integration-service/api/v1/console/device-model`, params);
  }

  public applyModelTags(modelId: number, tags: Array<Tag>): Observable<Array<Tag>> {
    return this.http.post<Array<Tag>>(`/device-service/api/v1/console/models/${modelId}/tags`, tags);
  }

  public setModelApproval(modelId: number, approved: boolean): Observable<ModelStatus> {
    const approve = approved ? 'approved' : 'unapproved'
    return this.http.post<ModelStatus>(`/device-service/api/v1/console/models/${modelId}/firmware/${approve}`, {});
  }

  public deleteModelByName(customerModel: string): Observable<void> {
    return this.http.delete<void>(`/device-service/api/v1/console/model/by-customer-model`, {params: {customerModel}});
  }

  public getDeviceModelsGroups(page: number, sorting: Sorting, query: string, datesRange: DatesRange, status: DeviceModelGroupStatus): Observable<Paged<DeviceModelsGroup>> {
    const range = fixRange(datesRange);
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(range?.from ? {from: range.from.toISOString()} : null),
      ...(range?.to ? {to: range.to.toISOString()} : null),
      ...(status ? {status} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null),
    };
    return this.http.get<Paged<DeviceModelsGroup>>(`/test-report-service/api/v1/console/device-model-group/pageable`, {params})
        .pipe(tap((paged: Paged<DeviceModelsGroup>) => {
      Utils.extendPaged(paged);
      paged.content.forEach((modelsGroup: DeviceModelsGroup) => this.modelsGroupDates(modelsGroup));
    }));
  }

  public getAllDeviceModelsGroups(): Observable<Array<DeviceModelsGroup>> {
    return this.http.get<Array<DeviceModelsGroup>>(`/test-report-service/api/v1/console/device-model-group/groups-catalog`)
      .pipe(tap((modelsGroups: Array<DeviceModelsGroup>) => {
        (modelsGroups || []).forEach((modelsGroup: DeviceModelsGroup) => this.modelsGroupDates(modelsGroup));
    }));
  }

  public getDeviceModelsGroup(id: number): Observable<DeviceModelsGroupDetails> {
    return this.http.get<DeviceModelsGroupDetails>(`/test-report-service/api/v1/console/device-model-group/detailed/${id}`)
      .pipe(tap((modelsGroup: DeviceModelsGroupDetails) => this.modelsGroupDates(modelsGroup)));
  }

  public getDeviceModelsGroupConfigs(groupId: number): Observable<DeviceModelsGroupConfigs> {
    return this.http.get<DeviceModelsGroupConfigs>(`/test-report-service/api/v1/console/device-model-group/groups-catalog-with-configs`,
      {params: {deviceModelGroupId: groupId}});
  }

  public getSpecifiedDeviceModelsGroups(modelIds: number[]): Observable<Array<DeviceModelGroups>> {
    return this.http.get<Array<DeviceModelGroups>>(`/test-report-service/api/v1/console/model-groups-details/model-groups`, {params: {modelIds}});
  }

  public createDeviceModelsGroup(modelsGroup: DeviceModelsGroupDetails): Observable<void> {
    return this.http.post<void>(`/test-report-service/api/v1/console/device-model-group`, modelsGroup);
  }

  public updateDeviceModelsGroup(modelsGroup: DeviceModelsGroupDetails, answers: Array<RequestAnswer>): Observable<RequestQuestion | RequestMessage> {
    const saveRequest = Object.assign({}, modelsGroup, {answerList: answers});
    return this.http.put<RequestQuestion | RequestMessage>(`/test-report-service/api/v1/console/device-model-group`, saveRequest);
  }

  public appendModelToGroup(modelsGroupId: number, deviceModelId: number): Observable<void> {
    return this.http.put<void>(`/test-report-service/api/v1/console/device-model-group/model`, {}, {params: {deviceModelId, groupModelId: modelsGroupId}});
  }

  public updateDeviceModelsGroupProcedure(modelsGroup: DeviceModelsGroupDetails): Observable<RequestResult> {
    const answers = [{question: RequestQuestions.WITHOUT_QUESTION, answer: RequestAnswers.YES}];
    return this.updateDeviceModelsGroup(modelsGroup, answers)
        .pipe(concatMap(((result: RequestQuestion | RequestMessage) => {
      const res = result as RequestQuestion;
      if ((result as RequestMessage).resultMessage === RequestMessages.MODEL_GROUP_UPDATED) {
        return of({success: true, cancel: false});
      } else if (res.question === RequestQuestions.SAVE_IN_TARGET_GROUP_OR_CREATE_NEW) {
        const text = 'For this model group active report exists. Save selected models in the current group or create a new model group?<br /><br/><b>Note:</b> the new model group will retain the existing name, while the old one will become Inactive';
        return this.dialogService.showModal(ButtonsDialogComponent, { width: '500px', data: {
          title: 'Connected test report exists',
          text,
          buttons: [{text: 'Cancel', class: 'secondary'}, {text: 'Update', code: RequestAnswers.UPDATE_CURRENT}, {text: 'Create', code: RequestAnswers.CREATE_NEW}]
        }}).afterClosed().pipe(concatMap((dialogResult: string) => {
          if (dialogResult) {
            answers.push({question: RequestQuestions.SAVE_IN_TARGET_GROUP_OR_CREATE_NEW, answer: dialogResult as RequestAnswers});
            return this.updateDeviceModelsGroup(modelsGroup, answers).pipe(concatMap((result2: RequestQuestion | RequestMessage) => {
              const ok = dialogResult === RequestAnswers.UPDATE_CURRENT && (result2 as RequestMessage).resultMessage === RequestMessages.MODEL_GROUP_UPDATED
                || dialogResult === RequestAnswers.CREATE_NEW && (result2 as RequestMessage).resultMessage === RequestMessages.NEW_MODEL_GROUP_CREATED;
              return of({success: ok, cancel: false});
            }));
          } else {
            return of({success: false, cancel: true});
          }
        }));
      } else {
        return of({success: false, cancel: false});
      }
    })));
  }

  public deleteDeviceModelsGroup(modelsGroup: DeviceModelsGroup): Observable<void> {
    return this.http.delete<void>(`/test-report-service/api/v1/console/device-model-group/${modelsGroup.id}`);
  }

  private deviceTypeDate(deviceType: DeviceType): DeviceType {
    deviceType.updatedAt = deviceType.updatedAt ? new Date(deviceType.updatedAt) : null;
    return deviceType;
  }

  private modelDates(model: DeviceModel): DeviceModel {
    model.updatedAt = model.updatedAt ? new Date(model.updatedAt) : null;
    if (model.deviceConfig) {
      model.deviceConfig.updatedAt = model.deviceConfig.updatedAt ? new Date(model.deviceConfig.updatedAt) : null;
    }
    if (model.modelConfig) {
      model.modelConfig.updatedAt = model.modelConfig.updatedAt ? new Date(model.modelConfig.updatedAt) : null;
    }
    return model;
  }

  private modelsGroupDates(group: DeviceModelsGroup): DeviceModelsGroup {
    group.createdAt = group.createdAt ? new Date(group.createdAt) : null;
    group.updatedAt = group.updatedAt ? new Date(group.updatedAt) : null;
    return group;
  }
}
