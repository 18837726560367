import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceModuleDialogData } from '../../models/dialog-data';
import { DeviceModulesService } from '@services/device-modules.service';
import { NotificationService } from '@services/notification.service';
import { WiFiModule } from '@models/device-modules';
import { Subject, finalize, takeUntil } from 'rxjs';

@Component({
  selector: 'app-device-module-epp-config-dialog',
  templateUrl: './device-module-epp-config-dialog.component.html',
  styleUrls: ['./device-module-epp-config-dialog.component.scss']
})
export class DeviceModuleEppConfigDialogComponent implements OnInit, OnDestroy {

  public progressing = false;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceModuleDialogData,
    private dialogRef: MatDialogRef<DeviceModuleEppConfigDialogComponent>,
    private deviceModulesService: DeviceModulesService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public proceed(): void {
    const wifiModule = this.data.deviceModule;
    this.progressing = true;
    this.deviceModulesService.refreshModuleEppConfig(wifiModule.macAddress)
        .pipe(takeUntil(this.destroyed), finalize(() => this.progressing = false))
        .subscribe((result: WiFiModule) => {
      this.notificationService.success(`Epp-config is successfully refreshed`);
      this.dialogRef.close(result);
    }, (error) => {
      this.notificationService.error(`Failed to update Epp config for module ${wifiModule.macAddress}`);
      this.dialogRef.close(null);
    });
  }

}
