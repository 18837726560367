import { Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { DeviceConfigDialogData } from '../../models/dialog-data';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { DropdownItem } from '@models/dropdown';
import { CreateDeviceConfig, CreateDeviceConfigResponse } from '@models/device-configs';
import { DeviceConfigsService } from '@services/device-configs.service';
import { NotificationService } from '@services/notification.service';
import { DialogService } from '@services/dialog.service';
import { ConfirmDialogIcon } from '@models/dialogs';
import { Subject, finalize, takeUntil } from 'rxjs';
import { UNSUPPORTED, EXPECTATION_FAILED, CONFLICT } from '@constants/http-params';

@Component({
  selector: 'app-device-config-dialog',
  templateUrl: './device-config-dialog.component.html',
  styleUrls: ['./device-config-dialog.component.scss']
})
export class DeviceConfigDialogComponent implements OnInit, OnDestroy {

  @ViewChild('file') file: ElementRef;

  public boardId: string;
  public deviceTypesItems: Array<DropdownItem>;
  public deviceType: DropdownItem;
  public eppVersions: Array<DropdownItem>;
  public eppVersion: DropdownItem;
  public fileItem: File;
  public loading = false;
  private destroyed = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceConfigDialogData,
    private dialogRef: MatDialogRef<DeviceConfigDialogComponent>,
    private deviceConfigsService: DeviceConfigsService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {}

  public ngOnInit(): void {
    this.deviceTypesItems = this.data.deviceTypes.filter(x => x.value);
    this.eppVersions = this.data.eppVersions.map(x => ({value: x.value, title: '' + x.value}));
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public selectFile(): void {
    this.file.nativeElement.click();
  }

  public fileSelected(event: Event): void {
    // @ts-ignore
    const files: File[] = event.target.files;
    if (files && files.length > 0) {
      this.fileItem = files[0];
    }
  }

  public removeFile(): void {
    this.fileItem = null;
  }

  public proceed(): void {
    if (this.fileItem && this.boardId && this.deviceType?.value) {
      const newConfig = {
        file: this.fileItem,
        boardId: this.boardId,
        typeId: this.deviceType.value,
        eppVersion: this.eppVersion.value
      } as CreateDeviceConfig;
      this.loading = true;
      this.deviceConfigsService.createDeviceConfig(newConfig, false)
          .pipe(finalize(() => this.loading = false)).subscribe((response: CreateDeviceConfigResponse) => {
        if (response.configSaved) {
          this.handleSuccess(response);
        } else {
          const boardIds = (response.boardIds || []).map(x => x || '-').join(', ');
          this.dialogService.showModal(ConfirmationDialogComponent, { maxWidth: '700px', data: {
            title: 'Config already exists',
            text: `You are going to upload <b>${response.fileName}</b>.
              JSON-config files with the same names are already exist and used for devices with next
              Board IDs: <b>${boardIds}</b>.<br/><br/>
              If you click “Continue“ the system will have one more JSON-config file with the same name
              used for different Board IDs.`,
            icon: ConfirmDialogIcon.WARNING,
            agreeButtonText: 'Continue'
          }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
            if (result) {
              this.loading = true;
              this.deviceConfigsService.createDeviceConfig(newConfig, true)
                  .pipe(finalize(() => this.loading = false)).subscribe((response: CreateDeviceConfigResponse) => {
                if (response.configSaved) {
                  this.handleSuccess(response);
                } else {
                  this.showError();
                }
                this.dialogRef.close(response.configSaved);
              }, (error: HttpErrorResponse) => this.showError(error));
            }
          });
        }
      }, (error: HttpErrorResponse) => this.showError(error));
    }
  }

  private handleSuccess(response: CreateDeviceConfigResponse): void {
    this.notificationService.success(`Configs are successfully uploaded`);
    if (response.testReportGroups?.length) {
      this.dialogService.showModal(InformationDialogComponent, {
        maxWidth: '600px', data: {
          title: 'Test Report found',
          text: `A new EPP json has been loaded for this model.<br/>The model is in group(s):
                 <ul class="min-space">${response.testReportGroups.map(x => '<li>' + x.name + '</li>').join('')}</ul>
                 <b>Note: </b>The reports related to these groups will go to the Inactive status`
        }
      });
    }
    this.dialogRef.close(true);
  }

  private showError(error?: HttpErrorResponse): void {
    const ending = `Please, check file or contact the platform administrator.`;
    if (error?.status === UNSUPPORTED) {
      this.notificationService.error(`Sorry, you can add a file only one of these file formats: JSON, TXT. ${ending}`);
    } else if (error?.status === EXPECTATION_FAILED) {
      this.notificationService.error(`Sorry, your JSON file is broken and has invalid structure. ${ending}`);
    } else if (error?.status === CONFLICT) {
      this.notificationService.error(`Sorry, your JSON file has invalid structure for the 1th version. ${ending}`);
    } else {
      this.notificationService.error(`Failed to create JSON-config. ${ending}`);
    }
  }

}
