import { Action } from '@models/actions';
import { DeviceTypesAndModels, DeviceTypeSimple, DeviceModelSimple } from '@models/device-models';
import { MediaDescriptor } from '@models/informational';

export enum DeviceInstructionType {
  PROCESSING = 'PROCESSING'
}

export enum InstructionStatus {
  DRAFT = 'DRAFT',
  READY = 'READY'
}

export interface InstructionStep {
  order: number;
  description?: string;
  file?: MediaDescriptor;
}

export interface NewDeviceInstruction extends DeviceTypesAndModels {
  name: string;
  type: DeviceInstructionType;
  instructions: Array<InstructionStep>;
  types: Array<DeviceTypeSimple>;
  models: Array<DeviceModelSimple>;
  status: InstructionStatus;
}

export interface DeviceInstruction extends NewDeviceInstruction {
  id: number;
  updatedAt: Date;
}

export interface DeviceInstructionView extends DeviceInstruction {
  actions?: Array<Action>;
}

export interface DeviceInstructionUpdateResult {
  instruction: DeviceInstruction;
  conflicts: DeviceTypesAndModels;
}
