import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DeviceInstructionsService } from '@services/device-instructions.service';
import { DeviceModelsService } from '@services/device-models.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { DeviceInstructionEditDialogComponent }
  from '../../components/device-instruction-edit-dialog/device-instruction-edit-dialog.component';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { DeviceInstructionDialogData } from '../../models/dialog-data';
import { DeviceInstruction, DeviceInstructionView, InstructionStatus } from '@models/device-instructions';
import { DeviceTypeSimple, DeviceTypesAndModels, ProductLine } from '@models/device-models';
import { Tag } from '@models/informational';
import { DropdownItem } from '@models/dropdown';
import { Paged } from '@models/pageable';
import { SortOrder, Sorting } from '@models/sorting';
import { ConfirmDialogIcon } from '@models/dialogs';
import { DATE } from '@constants/dates';
import { HttpParams } from '@constants/http-params';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { takeUntil, finalize, shareReplay } from 'rxjs/operators';
import { Utils } from '@services/utils';

enum SortColumn {
  NAME = 'name',
  TYPE = 'type',
  STATUS = 'status',
  UPDATED = 'updatedAt'
}

@Component({
  selector: 'app-instructions-view',
  templateUrl: './instructions-view.component.html',
  styleUrls: ['./instructions-view.component.scss']
})
export class InstructionsViewComponent implements OnInit, OnDestroy {

  public instructions: Paged<DeviceInstructionView>;
  public query: string = '';
  public deviceTypesItems: Array<DropdownItem> = [];
  public deviceType: DropdownItem = null;
  public activePage = 1;
  public loading = false;
  public progressing = false;
  public sorting: Sorting = {column: SortColumn.UPDATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public DATE_FORMAT = DATE;
  public InstructionStatus = InstructionStatus;

  @ViewChild('information') information: TemplateRef<any>;

  private typesAndModels: DeviceTypesAndModels;
  private instructionTypes: Array<string>;
  private productLines: Array<ProductLine>;
  private modelsTags: Array<Tag>;
  private destroyed = new Subject<void>();
  private dialogDataReady: Observable<any>;

  constructor(private instructionsService: DeviceInstructionsService, private modelsService: DeviceModelsService,
    private dialogService: DialogService, private notificationService: NotificationService, private utils: Utils) { }

  public ngOnInit(): void {
    this.query = this.utils.lookupParam(HttpParams.QUERY) || '';
    this.fillDeviceTypesAndModels();
    this.updateData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Device instructions',
      text: this.information
    }});
  }

  public updateData(resetPage = false): void {
    this.loading = true;
    this.instructionsService.getDeviceInstructions(this.query, this.deviceType?.value, this.sorting, resetPage ? 1 : this.activePage)
        .pipe(finalize(() => this.loading = false)).subscribe((instructions: Paged<DeviceInstruction>) => {
      this.instructions = instructions;
      this.instructions.content.forEach((deviceInstruction: DeviceInstruction) => this.setActions(deviceInstruction));
      this.activePage = resetPage ? 1 : this.activePage;
    });
  }

  public createDeviceInstruction(): void {
    this.dialogDataReady.subscribe(() => {
      this.progressing = true;
      this.dialogService.showModal(DeviceInstructionEditDialogComponent,
        { height: '720px', width: '800px', data: this.createDialogConfig(null)})
          .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: DeviceInstruction) => {
        this.progressing = false;
        if (result) {
          this.updateData();
        }
      });
    });
  }

  public editDeviceInstruction(deviceInstruction: DeviceInstruction): void {
    this.dialogDataReady.subscribe(() => {
      this.progressing = true;
      this.dialogService.showModal(DeviceInstructionEditDialogComponent,
        { height: '720px', width: '800px', data: this.createDialogConfig(deviceInstruction)})
          .afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: DeviceInstruction) => {
        this.progressing = false;
        if (result) {
          this.updateData();
        }
      });
    });
  }

  public deleteDeviceInstruction(deviceInstruction: DeviceInstruction): void {
    this.progressing = true;
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: 'Delete Device Instruction',
      text: 'You are going to delete device instruction ' + deviceInstruction.name + '. Are you sure?',
      icon: ConfirmDialogIcon.WARNING,
      agreeButtonText: 'Yes, delete'
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: boolean) => {
      if (result) {
        this.instructionsService.deleteDeviceInstruction(deviceInstruction.id)
            .pipe(finalize(() => this.progressing = false)).subscribe(() => {
          this.notificationService.success('Device Instruction successfull deleted');
          this.updateData();
        }, (error: HttpErrorResponse) => {
          this.notificationService.error('Failed to delete Device Instruction');
          console.error(error);
        });
      } else {
        this.progressing = false;
      }
    });
  }

  public duplicateDeviceInstruction(deviceInstruction: DeviceInstruction): void {
    const clone = Utils.copyDeep<DeviceInstruction>(deviceInstruction);
    delete clone.id;
    this.editDeviceInstruction(clone);
  }

  private fillDeviceTypesAndModels(): void {
    this.dialogDataReady = forkJoin({
      deviceTypesAndModels: this.modelsService.getDeviceTypesAndModels(),
      instructionTypes: this.instructionsService.getDeviceInstructionTypes(),
      productLines: this.modelsService.getProductLines(),
      modelsTags: this.modelsService.getModelsTags()
    }).pipe(shareReplay(1));
    this.dialogDataReady.subscribe(({deviceTypesAndModels, instructionTypes, productLines, modelsTags}) => {
      this.typesAndModels = deviceTypesAndModels;
      this.productLines = productLines;
      this.modelsTags = modelsTags;
      this.deviceTypesItems = [{title: 'All Device Types', value: null}]
        .concat(this.typesAndModels.types.map((x: DeviceTypeSimple) => ({value: x.id, title: x.name})));
      this.deviceType = this.deviceTypesItems[0];
      this.instructionTypes = instructionTypes;
    });
  }

  private setActions(deviceInstruction: DeviceInstructionView): DeviceInstructionView {
    deviceInstruction.actions = [{
      title: 'Edit',
      action: () => this.editDeviceInstruction(deviceInstruction)
    }, {
      title: 'Duplicate',
      action: () => this.duplicateDeviceInstruction(deviceInstruction)
    }, {
      title: 'Delete',
      action: () => this.deleteDeviceInstruction(deviceInstruction)
    }];
    return deviceInstruction;
  }

  private createDialogConfig(deviceInstruction: DeviceInstruction): DeviceInstructionDialogData {
    return {
      isNew: !deviceInstruction?.id,
      deviceInstruction,
      typesAndModels: this.typesAndModels,
      instructionTypes: this.instructionTypes,
      productLines: this.productLines,
      modelsTags: this.modelsTags
    };
  }

}
