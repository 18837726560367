<div class="test-view inner-view">
  <div class="view-header with-breadcrumbs">
    <div class="small-spacing">
      <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    </div>
    <h1 class="align-container small-spacing">
      <span>{{recording ? 'Record' : 'Test'}} #{{testingId}}</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section main-info">
    <div class="test-stats">
      <div class="label">MAC Address</div>
      <div class="value">{{testing?.deviceMac || '&mdash;'}}</div>
      <div class="label">Firmware Version</div>
      <div class="value">{{testing?.fwVersion || '&mdash;'}}</div>
      <div class="label">Device Model</div>
      <div class="value">{{testing?.deviceModel || '&mdash;'}}</div>
      <div class="label">Started</div>
      <div class="value">{{testing?.started ? (testing.started | date:DATE_TIME) : '&mdash;'}}</div>
      <div class="label">Device Type</div>
      <div class="value">{{testing?.deviceType || '&mdash;'}}</div>
      <div class="label">Ended</div>
      <div class="value">{{testing?.ended ? (testing.ended | date:DATE_TIME) : '&mdash;'}}</div>
    </div>
    <div class="controls" *ngIf="recording">
      <button class="button primary" (click)="restoreOriginalFirmware()" [disabled]="!testing || testing?.status === TestingStatus.PENDING || loading || testingInProcess || restoringFirmware">
        Install Latest Firmware
      </button>
    </div>
  </div>
  <div class="waiting-block view-section" *ngIf="testing?.status === TestingStatus.PENDING">
    <div class="waiting-title label">Initializing environment, please wait</div>
    <div class="waiting-loader loader"></div>
  </div>
  <div class="results-block view-section" *ngIf="!testing || testing?.status !== TestingStatus.PENDING">
    <perfect-scrollbar #scrollbar>
      <table class="results-table ">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.EXECUTION_ORDER" (sort)="setSorting($event)">
                #
              </app-sortable-header> 
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.RECORD" (sort)="setSorting($event)">
                Record
              </app-sortable-header> 
            </th>
            <th class="status-column">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header> 
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of entries?.content">
            <td>{{row.orderNum + 1}}</td>
            <td>{{row.description || '&mdash;'}}</td>
            <td class="by-content">
              <div class="no-status" *ngIf="!row.state">&mdash;</div>
              <div class="testing-status" [ngClass]="'status-' + row.state" *ngIf="row.state">{{row.state | capitalize}}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!entries || !entries?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="entries && entries.totalPages > 1">
      <div class="text-info">Showing {{entries.startElement}} to {{entries.endElement}} of {{entries.totalElements}} entries</div>
      <app-pagination [activePage]="activePage" [totalPages]="entries.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
  <div class="loading-dialog" *ngIf="restoringFirmware">
    <ngx-loading [show]="restoringFirmware" [config]="{fullScreenBackdrop: true}" [template]="loadingTemplate"></ngx-loading>
  </div>
</div>
<ng-template #loadingTemplate>
  <div class="loading-banner">
    <div class="loader centered"></div>
    <div class="text">Installing Firmware<br/>Please wait...</div>
  </div>
</ng-template>
<ng-template #information>
  <ng-container *ngIf="recording">
    <div>Here you can find information about record:</div>
    <ul class="less-space">
      <li>Record number</li>
      <li>MAC address – MAC address of the device</li>
      <li>Device Model</li>
      <li>Device Type</li>
      <li>Firmware version – firmware version that was installed on the device</li>
      <li>Started – the time when record was started</li>
      <li>Ended – the time when record was ended</li>
      <li>Action record status</li>
    </ul>
  </ng-container>
  <ng-container *ngIf="!recording">
    <div>Here you can find information about firmware test:</div>
    <ul class="less-space">
      <li>Test number</li>
      <li>MAC address – MAC address of the device</li>
      <li>Device Model</li>
      <li>Device Type</li>
      <li>Firmware version – firmware version that was installed on the device</li>
      <li>Started – the time when test was started</li>
      <li>Ended – the time when test was ended</li>
      <li>Action records testing status</li>
    </ul>
  </ng-container>
</ng-template>