import { Injectable } from '@angular/core';
import { Observable, map, mergeMap, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Firmware } from '@models/firmware';
import { MediaDescriptor } from '@models/informational';
import { ContentExportImport, ExportImportResult, ExportResult } from '@models/export-import'

const TEXT_RESPONSE = {headers: new HttpHeaders(), responseType: 'text' as 'json'};

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private http: HttpClient, private utils: Utils) {}

  public uploadMedia(file: File): Observable<MediaDescriptor> {
    const data = new FormData();
    data.append('file', file, file.name);
    return this.http.post<MediaDescriptor>(`/device-service/api/v1/console/mediafile`, data);
  }

  public dropMedia(descriptor: MediaDescriptor): Observable<void> {
    return this.http.delete<void>(`/device-service/api/v1/console/mediafile`, {params: {id: descriptor.id}});
  }

  public downloadBindingUtility(): Observable<string> {
    return this.utils.downloadFileWithAuth(`/product-line-integration-service/api/v1/utility/console/download?type=BINDING`);
  }

  public downloadFirmwareUtilityById(firmwareId: number): Observable<string> {
    return this.utils.downloadFileWithAuth(
      `/product-line-integration-service/api/v1/utility/console/download/${firmwareId}`);
  }

  public downloadFirmwareByUrl(firmwareUrl: string): Observable<string> {
    return this.utils.downloadFileWithAuth(firmwareUrl, null, null, true);
  }

  public downloadFirmwareArchive(firmwareId: number, firmwareVersion: string, modelsIds: Array<number>, requestedTokens?: number): Observable<string> {
    const params = {swVersionId: firmwareId, swVersion: firmwareVersion, deviceModels: modelsIds, emittingSize: requestedTokens} as any;
    return this.utils.downloadFileWithAuth(
      `/product-line-integration-service/api/v1/utility/console/package`, {}, params);
  }

  public downloadUtilityByUrl(utilityURL: string): Observable<string> {
    return this.utils.downloadFileWithAuth(utilityURL);
  }

  public downloadUtilityArchive(firmwareId: string): Observable<string> {
    return this.utils.downloadFileWithAuth(
      `/device-firmware-service/api/v1/console/firmware/merged/${firmwareId}`);
  }

  public downloadMobileBackendContent(context: ContentExportImport): Observable<ExportResult> {
    const params = {...context};
    return this.http.get<ExportResult>(`/admin-console-service/api/v1/data/export`, {params})
        .pipe(mergeMap((result: ExportResult) => {
      return result.exception ? throwError(() => result.exception) : 
        this.utils.downloadFileWithAuth(result.url, null, null, true).pipe(map(() => result));
    }));
  }

  public uploadBindingMacAddressesWhiteList(macAddresses: File): Observable<string> {
    const data = new FormData();
    data.append('file', macAddresses, macAddresses.name);
    return this.http.put<string>(`/device-service/api/v1/console/modules`, data, TEXT_RESPONSE);
  }

  public uploadMacAddressesBindings(macAddresses: File): Observable<string> {
    const data = new FormData();
    data.append('file', macAddresses, macAddresses.name);
    return this.http.put<string>(`/device-service/api/v1/console/modules/assembly`, data, TEXT_RESPONSE);
  }

  public uploadQaResults(qaResults: File): Observable<string> {
    const data = new FormData();
    data.append('file', qaResults, qaResults.name);
    return this.http.put<string>(`/device-service/api/v1/console/modules/qa`, data);
  }

  public uploadMobileBackendContent(file: File, context: ContentExportImport): Observable<ExportImportResult> {
    const data = new FormData();
    data.append('archive', file, file.name);
    const params = { ...context};
    return this.http.post<ExportImportResult>(`/admin-console-service/api/v1/data/import`, data, {params});
  }

  public downloadBindingMacAddressesWhiteList(): Observable<string> {
    return this.utils.downloadFileWithAuth(`/device-service/api/v1/console/modules/macs`);
  }

  public downloadErrorLog(errorLog: string): void {
    return this.utils.downloadFile(errorLog);
  }
}
