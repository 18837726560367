import { Component, OnChanges, OnDestroy, Input, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DeviceTypesAndModels, DeviceModelSimple, DeviceTypeSimple, ProductLine } from '@models/device-models';
import { DropdownItem } from '@models/dropdown';
import { BehaviorSubject } from 'rxjs';

interface ModelLine extends DeviceModelSimple {
  model: DeviceModelSimple;
  productLines: Array<ProductLine>;
  deviceType: DeviceTypeSimple;
  included: boolean;
  selected: boolean;
  invalid?: boolean;
  hidden?: boolean;
}

@Component({
  selector: 'app-models-selection-box',
  templateUrl: './models-selection-box.component.html',
  styleUrls: ['./models-selection-box.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ModelsSelectionBoxComponent),
    multi: true
  }]
})
export class ModelsSelectionBoxComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  @Input()
  public typesAndModels: DeviceTypesAndModels;

  @Input()
  public invalidModels: Array<number>;

  @Input()
  public disabled = false;

  public deviceTypes: Array<DropdownItem<DeviceTypeSimple>> = [];
  public deviceType: DropdownItem<DeviceTypeSimple> = null;
  public allProductLines: Array<DropdownItem> = [];
  public productLines: Array<DropdownItem> = [];
  public productLine: DropdownItem = null;
  public models: Array<ModelLine> = [];
  public allModelsSelected = false;
  public value: Array<DeviceModelSimple> = [];
  public query: string;

  private destroyed = new BehaviorSubject<boolean>(false); 
  private commit(value: Array<DeviceModelSimple>) {}
  private onTouchedCallback: () => void;

  constructor() { }

  public ngOnChanges(changes: SimpleChanges): void {
    if ((changes as any).typesAndModels) {
      this.fillContents();
      this.writeValue(this.value);
    } else if ((changes as any).invalidModels) {
      this.markInvalid();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete(); 
  }

  public deviceTypesSelectionChange(): void {
    this.adjustProductLines();
    this.filterModels();
  }

  public productLinesSelectionChange(): void {
    const deviceTypes = this.deviceTypes.filter(x => !this.productLine
      || x.original.productLines.some(y => y.id === this.productLine.value));
    this.deviceType = this.deviceType && deviceTypes.includes(this.deviceType) ? this.deviceType
      : (deviceTypes.length ? deviceTypes[0] : null);
    this.adjustProductLines();
    this.filterModels();
  }

  public search(query: string): void {
    this.query = query;
    this.filterModels();
  }

  public modelSelected(model: ModelLine): void {
    this.allModelsSelected = this.models.every(x => !x.included || x.selected);
    this.commitState();
  }

  public selectAll(withValue: boolean): void {
    this.models.filter(x => x.included && !x.hidden).forEach(x => x.selected = withValue);
    this.commitState();
  }

  public filterModels(): void {
    let selectionChanged = false;
    this.models.forEach((model: ModelLine) => {
      model.included = (!this.deviceType || model.deviceType.id === this.deviceType.value)
        && (!this.productLine || (model.productLines || []).some(x => this.productLine.value === x.id));
      const oldChecked = model.selected;
      model.selected = model.selected && model.included;
      selectionChanged = selectionChanged || (model.selected !== oldChecked);
      model.hidden = this.query && !model.name.toLowerCase().includes(this.query.toLowerCase());
    });
    this.allModelsSelected = this.models.every(x => !x.included || x.selected);
    if (selectionChanged) {
      this.commitState();
    }
  }

  public writeValue(value: Array<DeviceModelSimple>): void {
    this.value = value || [];
    this.query = '';
    this.models.forEach((model: ModelLine) => {
      model.included = true;
      model.hidden = false;
      model.selected = !!this.value.find(x => x.id === model.id);
    });
    this.deviceType = this.value.length ? this.deviceTypes.find(x => x.value === this.value[0].typeId) : null;
    this.adjustProductLines();
    this.filterModels();
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private fillContents(): void {
    this.models = (this.typesAndModels?.models || []).map(x => {
      const deviceType = (this.typesAndModels?.types || []).find(y => y.id === x.typeId);
      return Object.assign({}, x, {model: x, deviceType, productLines: deviceType?.productLines || [],
        included: true, selected: false});
    });
    this.deviceTypes = (this.typesAndModels?.types || []).map(x => ({value: x.id, title: x.name, original: x}));
    this.deviceType = null;
    this.allProductLines = (this.typesAndModels?.types || []).reduce((acc: Array<ProductLine>, value: DeviceTypeSimple) => {
      const productLines = value?.productLines || [];
      const newProductLines = productLines.filter(x => !acc.map(y => y.id).includes(x.id));
      return acc.concat(newProductLines);
    }, []).map(x => ({value: x.id, title: x.name}));
    this.productLines = this.allProductLines;
    this.productLine = null;
  }

  private adjustProductLines(): void {
    const productLines = this.deviceType ? (this.deviceType.original.productLines || []).map(x => x.id) : [];
    this.productLines = this.allProductLines.filter(x => !this.deviceType || productLines.includes(x.value));
    this.productLine = this.productLines.includes(this.productLine) ? this.productLine : null;
  }

  private commitState(): void {
    this.value = this.models.filter(x => x.selected).map(x => x.model);
    this.commit(this.value);
  }

  private markInvalid(): void {
    this.models.forEach((model: ModelLine) => {
      model.invalid = (this.invalidModels || []).includes(model.id);
    });
  }

}
