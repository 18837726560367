import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TestReport, TestReportStatus, TestReportView } from '@models/report';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { ReportService } from '@services/report.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { DATE_TIME } from '@constants/dates';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { finalize, forkJoin, of, Subject, takeUntil } from 'rxjs';
import { DropdownItem } from "@models/dropdown";
import { DatesRange } from "@models/dates";
import { RequestResult } from "@models/request";

enum SortColumn {
  FILE_NAME = 'fileName',
  TYPE = 'type.name',
  MODELS_GROUP = 'modelsGroup.name',
  WIFI_MODULE = 'wiFiModule.version',
  EPP_JSON = 'eppVersion',
  IOS_VERSION = 'iosVersion',
  ANDROID_VERSION = 'androidVersion',
  STATUS = 'status',
  UPDATED_BY = 'updatedBy',
  UPDATED_AT = 'updatedAt'
}

@Component({
  selector: 'app-test-reports-view',
  templateUrl: './test-reports-view.component.html',
  styleUrls: ['./test-reports-view.component.scss']
})
export class TestReportsViewComponent implements OnInit, OnDestroy {

  public types: Array<DropdownItem> = [];
  public type: DropdownItem;
  public statuses: Array<DropdownItem> = [];
  public status: DropdownItem;
  public range: DatesRange;
  public testReports: Paged<TestReportView>;
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public DATE_FORMAT = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.UPDATED_AT, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  public TestReportStatus = TestReportStatus;

  @ViewChild('information') information: TemplateRef<any>;
  private destroyed = new Subject<void>();

  constructor(private dialogService: DialogService, private notificationService: NotificationService,
    private reportService: ReportService, private router: Router) { }

  public ngOnInit(): void {
    forkJoin({
      types: this.reportService.getTestReportTypes(),
      statuses: of([{name: 'Active', code: TestReportStatus.ACTIVE}, {name: 'Inactive', code: TestReportStatus.INACTIVE},
        {name: 'Deleted', code: TestReportStatus.DELETED}, {name: 'Draft', code: TestReportStatus.DRAFT}]),
    }).subscribe(({types, statuses}) => {
      this.types = [{value: null, title: 'All'}].concat((types || []).map(x => ({value: x.code, title: x.name})));
      this.statuses = [{value: null, title: 'All'}].concat(statuses.map(x => ({value: x.code, title: x.name})));
    });
    this.updateData();
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData(false);
  }

  public search(query: string): void {
    this.query = query;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Test Reports',
      text: this.information
    }});
  }

  public createTestReport(): void {
    this.router.navigate(['/reports', 'test-report', 'create']);
  }

  public viewDetails(testReport: TestReport): void {
    this.router.navigate(['/reports', 'test-report', testReport.id]);
  }

  public editTestReport(testReport: TestReport): void {
    this.router.navigate(['/reports', 'test-report', testReport.id, 'edit']);
  }

  public deleteTestReport(testReport: TestReport): void {
    this.reportService.deleteTestReportProcedure(testReport).subscribe((result: RequestResult) => {
      if (!result.cancel) {
        this.updateData();
      }
    }, () => {
      this.notificationService.error(`Failed to delete Test Report ${testReport.fileName}`);
    });
  }

  public activateTestReport(testReport: TestReport): void {
    this.reportService.activateTestReportProcedure(testReport.id, testReport.fileName).pipe(takeUntil(this.destroyed)).subscribe((activateResult: RequestResult) => {
      if (!activateResult.cancel) {
        this.updateData();
      }
    }, () => {
      this.notificationService.error(`Failed to activate Test Report ${testReport.fileName}`);
    });
  }

  public deactivateTestReport(testReport: TestReport): void {
    this.reportService.deactivateTestReportProcedure(testReport).pipe(takeUntil(this.destroyed)).subscribe((deactivateResult: RequestResult) => {
      if (!deactivateResult.cancel) {
        this.updateData();
      }
    }, () => {
      this.notificationService.error(`Failed to deactivate Test Report ${testReport.fileName}`);
    });
  }

  public updateData(resetPage = true): void {
    this.loading = true;
    this.reportService.getTestReports(this.query, this.range, this.type?.value, this.status?.value, resetPage ? 1 : this.activePage, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe((reports: Paged<TestReport>) => {
      this.testReports = reports;
      (this.testReports.content || []).forEach((report: TestReportView) => {
        report.actions = [{
          title: 'View',
          action: () => this.viewDetails(report)
        }, {
          title: 'Edit',
          action: () => this.editTestReport(report),
          disabled: report.status === TestReportStatus.DELETED
        }];
        if (report.status !== TestReportStatus.ACTIVE && report.status !== TestReportStatus.DELETED) {
          report.actions.push({
            title: 'Activate',
            action: () => this.activateTestReport(report)
          });
        }
        if (report.status === TestReportStatus.ACTIVE) {
          report.actions.push({
            title: 'Deactivate',
            action: () => this.deactivateTestReport(report)
          });
        }
        if (report.status !== TestReportStatus.DELETED) {
          report.actions.push({
            title: 'Delete',
            action: () => this.deleteTestReport(report)
          })
        }
      });
      this.activePage = resetPage ? 1 : this.activePage;
    }, (error: HttpErrorResponse) => {
      console.error(error);
    });
  }
}
