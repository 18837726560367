<div class="devices-report-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Devices Report</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section">
    <perfect-scrollbar #viewScroll>
      <div class="scroll-area">
        <div class="view-section total-cards">
          <div class="total-card card-users">
            <div class="card-name">Total number of registered users of IOT platform</div>
            <div class="card-values">
              <div class="card-value">{{overallTotals?.numberOfUsers | bigNumber}}</div>
              <div class="card-icon"></div>
            </div>
          </div>
          <div class="total-card card-devices">
            <div class="card-name">Average number of user’s devices</div>
            <div class="card-values">
              <div class="card-value">{{overallTotals?.averageNumberOfDevices | bigNumber}}</div>
              <div class="card-icon"></div>
            </div>
          </div>
          <div class="total-card card-online">
            <div class="card-name">Now online</div>
            <div class="card-values">
              <div class="card-value">{{overallTotals?.onlineNow | bigNumber}}</div>
              <div class="card-icon"></div>
            </div>
          </div>
          <div class="total-card card-update-needed">
            <div class="card-name">Update needed now</div>
            <div class="card-values">
              <div class="card-value">{{overallTotals?.needToUpdateCount | bigNumber}}</div>
              <div class="card-icon"></div>
            </div>
          </div>
        </div>
        <div class="view-section search-block">
          <div class="label-base">Device types</div>
          <div class="label-base period-label">Period</div>
          <div></div>  
          <div class="device-types">
            <app-dropdown [items]="deviceTypes" [(ngModel)]="deviceTypesSelected" (ngModelChange)="deviceTypesChanged()"
                [multi]="true" [clearable]="true" placeholder="Select device types"></app-dropdown>
          </div>
          <div class="period">
            <app-dates-range [(ngModel)]="datesRange" (ngModelChange)="updateData()" [tillNow]="true"
              [typeSelector]="true" [rangeTypes]="datesRangeTypes">
            </app-dates-range>
          </div>
          <div class="export">
            <app-actions [actions]="exportActions">
              <button class="button-export-options">Export</button>
            </app-actions>
          </div>
          <div class="label-base">Device models</div>
          <div></div>
          <div></div>
          <div class="device-models">
            <app-lookup [provider]="modelsProvider" [(ngModel)]="modelsSelected" (ngModelChange)="updateData()"
                [multi]="true" [clearable]="true" [magnifyingGlass]="true" placeholder="Select models" [allowSearchEmpty]="true">
            </app-lookup>
          </div>
          <div class="show-models">
            <app-checkbox [(ngModel)]="showModels" [toggleView]="true" (ngModelChange)="updateData()">
              <span class="text">Show models </span>
              <span class="text-grayed">(every model statictics is shown separately)</span>
            </app-checkbox>
          </div>
          <div></div>
        </div>
        <div class="results-block view-section">
          <table class="results-table">
            <thead>
              <tr>
                <th>
                  <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_TYPE" (sort)="setSorting($event)">
                    Device Types
                  </app-sortable-header>
                </th>
                <th>
                  <app-sortable-header [sorting]="sorting" [column]="SortColumn.MODELS" (sort)="setSorting($event)">
                    Device Models
                  </app-sortable-header>
                </th>
                <th>
                  <app-sortable-header [sorting]="sorting" [column]="SortColumn.PRODUCED" (sort)="setSorting($event)">
                    IOT produced
                  </app-sortable-header>
                </th>
                <th>
                  <app-sortable-header [sorting]="sorting" [column]="SortColumn.ENROLLED" (sort)="setSorting($event)">
                    Enrolled
                  </app-sortable-header>
                </th>
                <th>
                  <app-sortable-header [sorting]="sorting" [column]="SortColumn.NOT_ENROLLED" (sort)="setSorting($event)">
                    Not enrolled
                  </app-sortable-header>
                </th>
                <th>
                  <app-sortable-header [sorting]="sorting" [column]="SortColumn.HAS_BEEN_ONLINE" (sort)="setSorting($event)">
                    Has been online
                  </app-sortable-header>
                </th>
              </tr>
              <tr *ngIf="dataTotals" class="totals-row">
                <th>TOTAL</th>
                <th></th>
                <th>{{dataTotals.totalNumberOfDevices || 0}}</th>
                <th>{{dataTotals.countOfPaired || 0}}</th>
                <th>{{dataTotals.countOfUnpaired || 0}}</th>
                <th>{{dataTotals.countOfBeenOnline || 0}}</th>
              <tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of dataPage?.content">
                <td>{{row.deviceType || '&mdash;'}}</td>
                <td>{{row.model || '&mdash;'}}</td>
                <td>{{row.totalNumberOfDevices || 0}}</td>
                <td>{{row.countOfPaired || 0}}</td>
                <td>{{row.countOfUnpaired || 0}}</td>
                <td>{{row.countOfBeenOnline || 0}}</td>
              </tr>
            </tbody>
          </table>
          <app-no-data *ngIf="!loading && (!dataPage || !dataPage?.content.length)"></app-no-data>
          <div class="pagination-block" *ngIf="dataPage && dataPage.totalPages > 1">
            <div class="text-info">Showing {{dataPage.startElement}} to {{dataPage.endElement}} of {{dataPage.totalElements}} items</div>
            <app-pagination [activePage]="activePage" [totalPages]="dataPage.totalPages" (pageChanged)="setActivePage($event)">
            </app-pagination>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <ngx-loading [show]="loading"></ngx-loading>
  </div>
</div>
<ng-template #information>
  <div>This report is for monitoring SMART devices’ common indicators.
  In this report we have two types of indicators by SMART-devices’ models (if the model will become NON-SMART, SMART devices of this model should still count in this report):
  </div>
  <div class="top-space"><b>FOR NOW:</b></div>
  <ol class="info-list top-space">
    <li><b>Total number of registered users of IoT platform</b> — how many users have registered in the Platform at all</li>
    <li><b>Average number of users’ devices</b> — the average number of registered devices per user</li>
    <li><b>Now online</b> — how many users are now online</li>
    <li><b>Update needed now</b> — how many devices need to update their firmware (only for devices that “have the right for this”)</li>
  </ol>
  <div class="top-space"><b>PERIODICAL, FOR THE PERIOD:</b></div>
  <ol class="info-list top-space">
    <li><b>IoT produced</b> – how many IoT devices were produced. For Platform, it means how many new devices (new Serial numbers) were added to the platform from Factory during this period.</li>
    <li><b>Enrolled</b> – how many devices were activated (enrolled) in the Platform</li>
    <li><b>Not enrolled</b> – how many devices were in the state “not enrolled” during this period, and during this period these devices weren’t any time in the state “enrolled”.</li>
    <li><b>Has been online</b> – how many devices were online (had active session) during this period (at least once).</li>
  </ol>
  <div class="top-space">In this report you can:</div>
  <ul class="info-list top-space">
    <li>See indicators values in types (granularity), by default.</li>
    <li>Use filters by Type and Model, and choose a time period.  You can also use multi-filter if it is necessary (to choose more than one Type or Model).</li>
  </ul>
  <div class="top-space">these conditions are available and influence only for periodical indicators.</div>
  <ul class="info-list top-space">
    <li>You can download results of the report to your PC - you can choose format: "*.xlsx" or "*.csv"</li>
  </ul>
</ng-template>
