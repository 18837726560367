<div class="year-select">
  <div class="field" [class.expanded]="expanded" [class.disabled]="disabled"
      (click)="toggle($event)" cdkOverlayOrigin #origin="cdkOverlayOrigin">
    <div class="value" [class.empty]="!selectedYear">
      {{selectedYear || placeholder || 'Not Selected'}}
    </div>
    <div class="controls">
      <div class="button button-clear" *ngIf="clearable && selectedYear" (click)="clear($event)"></div>
      <div class="calendar-button" [class.expanded]="expanded"></div>
    </div>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin" [cdkConnectedOverlayOpen]="expanded"
  [cdkConnectedOverlayPositions]="PositionBelow" (overlayOutsideClick)="toggle($event)"> 
  <div class="dropdown-panel items-panel">
    <div class="years-container items-container">
      <div class="year-item selectable-item" *ngFor="let year of years" (click)="selectYear(year)"
        [class.selected]="year.value === selectedYear" [class.disabled]="year.disabled">
        {{year.value}}
      </div>
    </div>
  </div>
</ng-template>