import { NgModule } from '@angular/core';

import { UtilitiesRoutingModule } from './utilities-routing.module';
import { CommonModule } from '../common/common.module';

import { BindingToolViewComponent } from './pages/binding-tool-view/binding-tool-view.component';
import { BindingsViewComponent } from './pages/bindings-view/bindings-view.component';
import { ExportImportViewComponent } from './pages/export-import-view/export-import-view.component';
import { UserActionsViewComponent } from './pages/user-actions-view/user-actions-view.component';
import { UserActionDetailsDialogComponent } from './components/user-action-details-dialog/user-action-details-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    UtilitiesRoutingModule
  ],
  declarations: [
    BindingToolViewComponent,
    BindingsViewComponent,
    ExportImportViewComponent,
    UserActionsViewComponent,
    UserActionDetailsDialogComponent
  ]
})
export class UtilitiesModule { }
