import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FirmwaresViewComponent } from './pages/firmwares-view/firmwares-view.component';
import { VirtualDevicesViewComponent } from './pages/virtual-devices-view/virtual-devices-view.component';
import { RecordsViewComponent } from './pages/records-view/records-view.component';
import { TestsViewComponent } from './pages/tests-view/tests-view.component';
import { TestViewComponent } from './pages/test-view/test-view.component';
import { UserRoles } from '@models/user-info';
import { HttpParams } from '@constants/http-params';

const routes: Routes = [
  {
    path: 'firmware',
    component: FirmwaresViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'virtual-devices',
    component: VirtualDevicesViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'records',
    component: RecordsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'record/:' + HttpParams.TESTING_ID,
    component: TestViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER], [HttpParams.TESTING_MODE]: HttpParams.TESTING_RECORD}
  },
  {
    path: 'tests',
    component: TestsViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER]}
  },
  {
    path: 'test/:' + HttpParams.TESTING_ID,
    component: TestViewComponent,
    data: {roles: [UserRoles.ADMIN, UserRoles.READONLY_ADMIN, UserRoles.MANAGER], [HttpParams.TESTING_MODE]: HttpParams.TESTING_TEST}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TestingRoutingModule {}
