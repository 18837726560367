import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FirmwareService } from '@services/firmware.service';
import { DialogService } from '@services/dialog.service';
import { UtilitiesService } from '@services/utilities.service';
import { NotificationService } from '@services/notification.service';
import { TestingFirmware } from '@models/firmware';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DATE } from '@constants/dates';
import { finalize } from 'rxjs';

enum SortColumn {
  VERSION = 'version',
  TYPE = 'type',
  UPDATED = 'updatedAt'
} 

@Component({
  selector: 'app-firmwares-view',
  templateUrl: './firmwares-view.component.html',
  styleUrls: ['./firmwares-view.component.scss']
})
export class FirmwaresViewComponent implements OnInit {

  public firmwares: Paged<TestingFirmware>;
  public activePage = 1;
  public loading = false;
  public downloading = false;
  public DATE_FORMAT = DATE;
  public sorting: Sorting = {column: SortColumn.UPDATED, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private firmwareService: FirmwareService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private utilityService: UtilitiesService) { }

  public ngOnInit(): void {
    this.updateData();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '600px', data: {
      title: 'Firmwares',
      text: this.information
    }});
  }

  public download(firmware: TestingFirmware): void {
    this.downloading = true;
    this.utilityService.downloadFirmwareByUrl(firmware.firmwareURL).pipe(finalize(() => this.downloading = false)).subscribe(
      () => {},
      (error: HttpErrorResponse) => {
        this.notificationService.error('Firmware Download Failed');
    });
  }

  private updateData(resetPage = false): void {
    this.loading = true;
    this.firmwareService.getTestingFirmwareList(resetPage ? 1 : this.activePage, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe((firmwares: Paged<TestingFirmware>) => {
      this.firmwares = firmwares;
      this.activePage = resetPage ? 1 : this.activePage;
    });
  }

}
