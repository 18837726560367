<div class="image-upload" (click)="selectFile()" [class.blue]="uploading"
    [class.disabled]="disabled" [class.busy]="uploading || error || oversize || disabled">
  <div class="welcome-block" *ngIf="!uploading && !error && !oversize && !file">
    <div class="icon"></div>
    <div class="title">Upload File</div>
    <div class="formats">Image or video, max 50 Mb</div>
  </div>
  <div class="image-block" *ngIf="file">
    <img class="uploaded-image" [src]="file.url" *ngIf="file.type !== MediaType.VIDEO"/>
    <video class="uploaded-image" [src]="file.url" *ngIf="file.type === MediaType.VIDEO"></video>
    <div class="remove" (click)="clear($event)"></div>
  </div>
  <div class="loading-block" *ngIf="uploading">
    <div class="loader"></div>
    <a class="link" (click)="cancel($event)">Cancel</a>
  </div>
  <div class="error-block" *ngIf="error || oversize">
    <div class="icon"></div>
    <div class="text" *ngIf="error">{{error}}</div>
    <div class="text" *ngIf="oversize">Uploaded file must not<br/> exceed 50 MB</div>
    <a class="link" (click)="hideError($event)">OK</a>
  </div>
  <input type="file" #input [hidden]="true" class="hidden" [accept]="acceptedFormats" (change)="fileSelected($event)"/> 
</div>