export enum UserActionTargetTypes {
  DEVICE_MODEL = 'DEVICE_MODEL',
  MODELS_GROUP = 'MODELS_GROUP',
  LOG_FILE = 'LOG_FILE',
  EPP_FILE = 'EPP_FILE',
  MAC_ADDRESS = 'MAC_ADDRESS',
  APP_INSTRUCTION = 'APP_INSTRUCTION',
  DEVICE_TYPE = 'DEVICE_TYPE',
  FIRMWARE_UTILITY = 'FIRMWARE_UTILITY',
  FIRMWARE = 'FIRMWARE',
  TEST_REPORT = 'TEST_REPORT',
  DATA_EXPORT = 'DATA_EXPORT',
  DATA_IMPORT = 'DATA_IMPORT',
  FIRMWARE_TESTING = 'TEST_FIRMWARE'
}

export enum UserActionTypes {
  ADD_NEW_MODEL = 'ADD_NEW_MODEL',
  DELETE_OR_UNTIE = 'DELETE_OR_UNTIE',
  CHANGE_MODEL = 'CHANGE_MODEL',
  CHANGE_SERIAL = 'CHANGE_SERIAL',
  CHANGE_ACCESS_TOKEN = 'CHANGE_ACCESS_TOKEN',
  MOVE_TO_ANOTHER_STAND = 'MOVE_TO_ANOTHER_STAND',
  CREATE_MODEL_GROUP = 'CREATE_MODEL_GROUP',
  EDIT_MODEL_GROUP = 'EDIT_MODEL_GROUP',
  DELETE_MODEL_GROUP = 'DELETE_MODEL_GROUP',
  UPLOAD_LOG_CONFIG = 'UPLOAD_LOG_CONFIG',
  DELETE_LOG_CONFIG = 'DELETE_LOG_CONFIG',
  CLONE_LOG_CONFIG = 'CLONE_LOG_CONFIG',
  UPDATE_EPP_CONFIG = 'UPDATE_EPP_CONFIG',
  UPLOAD_EPP_CONFIG = 'UPLOAD_EPP_CONFIG',
  DELETE_EPP_CONFIG = 'DELETE_EPP_CONFIG',
  ADD_INSTRUCTION = 'ADD_NEW_INSTRUCTION',
  EDIT_INSTRUCTION = 'EDIT_INSTRUCTION',
  DELETE_INSTRUCTION = 'DELETE_INSTRUCTION',
  ADD_DEVICE_TYPE = 'ADD_DEVICE_TYPE',
  EDIT_DEVICE_TYPE = 'UPDATE_DEVICE_TYPE',
  DELETE_DEVICE_TYPE = 'DELETE_DEVICE_TYPE',
  DOWNLOAD_FIRMWARE_UTILITY = 'DOWNLOAD_FIRMWARE_UTILITY',
  UPLOAD_MAC_ADDRESSES = 'UPLOAD_MAC_ADDRESSES',
  DOWNLOAD_FIRMWARE = 'DOWNLOAD_FIRMWARE',
  PUBLISH_FIRMWARE = 'PUBLISH_FIRMWARE',
  UNPUBLISH_FIRMWARE = 'UNPUBLISH_FIRMWARE',
  EXPORT_DATA = 'EXPORT_DATA',
  IMPORT_DATA = 'IMPORT_DATA',
  UPDATE_TEST_FIRMWARE = 'UPDATE_TEST_FIRMWARE',
  START_TEST_FIRMWARE_RECORD = 'START_TEST_FIRMWARE_RECORD',
  START_FIRMWARE_TEST = 'START_FIRMWARE_TEST',
  CREATE_TEST_REPORT = 'CREATE_TEST_REPORT',
  EDIT_TEST_REPORT = 'EDIT_TEST_REPORT',
  DELETE_TEST_REPORT = 'DELETE_TEST_REPORT',
  ACTIVATE_TEST_REPORT = 'ACTIVATE_TEST_REPORT'
}

export const UserActionTypesTargetTypes: { [key in UserActionTypes] : UserActionTargetTypes } = {
  [UserActionTypes.ADD_NEW_MODEL]: UserActionTargetTypes.DEVICE_MODEL,
  [UserActionTypes.DELETE_OR_UNTIE]: UserActionTargetTypes.MAC_ADDRESS,
  [UserActionTypes.CHANGE_MODEL]: UserActionTargetTypes.MAC_ADDRESS,
  [UserActionTypes.CHANGE_SERIAL]: UserActionTargetTypes.MAC_ADDRESS,
  [UserActionTypes.CHANGE_ACCESS_TOKEN]: UserActionTargetTypes.MAC_ADDRESS,
  [UserActionTypes.MOVE_TO_ANOTHER_STAND]: UserActionTargetTypes.MAC_ADDRESS,
  [UserActionTypes.CREATE_MODEL_GROUP]: UserActionTargetTypes.MODELS_GROUP,
  [UserActionTypes.EDIT_MODEL_GROUP]: UserActionTargetTypes.MODELS_GROUP,
  [UserActionTypes.DELETE_MODEL_GROUP]: UserActionTargetTypes.MODELS_GROUP,
  [UserActionTypes.UPLOAD_LOG_CONFIG]: UserActionTargetTypes.LOG_FILE,
  [UserActionTypes.DELETE_LOG_CONFIG]: UserActionTargetTypes.LOG_FILE,
  [UserActionTypes.CLONE_LOG_CONFIG]: UserActionTargetTypes.LOG_FILE,
  [UserActionTypes.UPDATE_EPP_CONFIG]: UserActionTargetTypes.MAC_ADDRESS,
  [UserActionTypes.UPLOAD_EPP_CONFIG]: UserActionTargetTypes.EPP_FILE,
  [UserActionTypes.DELETE_EPP_CONFIG]: UserActionTargetTypes.EPP_FILE,
  [UserActionTypes.ADD_INSTRUCTION]: UserActionTargetTypes.APP_INSTRUCTION,
  [UserActionTypes.EDIT_INSTRUCTION]: UserActionTargetTypes.APP_INSTRUCTION,
  [UserActionTypes.DELETE_INSTRUCTION]: UserActionTargetTypes.APP_INSTRUCTION,
  [UserActionTypes.ADD_DEVICE_TYPE]: UserActionTargetTypes.DEVICE_TYPE,
  [UserActionTypes.EDIT_DEVICE_TYPE]: UserActionTargetTypes.DEVICE_TYPE,
  [UserActionTypes.DELETE_DEVICE_TYPE]: UserActionTargetTypes.DEVICE_TYPE,
  [UserActionTypes.DOWNLOAD_FIRMWARE_UTILITY]: UserActionTargetTypes.FIRMWARE_UTILITY,
  [UserActionTypes.UPLOAD_MAC_ADDRESSES]: UserActionTargetTypes.FIRMWARE_UTILITY,
  [UserActionTypes.DOWNLOAD_FIRMWARE]: UserActionTargetTypes.FIRMWARE,
  [UserActionTypes.PUBLISH_FIRMWARE]: UserActionTargetTypes.FIRMWARE,
  [UserActionTypes.UNPUBLISH_FIRMWARE]: UserActionTargetTypes.FIRMWARE,
  [UserActionTypes.EXPORT_DATA]: UserActionTargetTypes.DATA_EXPORT,
  [UserActionTypes.IMPORT_DATA]: UserActionTargetTypes.DATA_IMPORT,
  [UserActionTypes.UPDATE_TEST_FIRMWARE]: UserActionTargetTypes.FIRMWARE_TESTING,
  [UserActionTypes.START_TEST_FIRMWARE_RECORD]: UserActionTargetTypes.FIRMWARE_TESTING,
  [UserActionTypes.START_FIRMWARE_TEST]: UserActionTargetTypes.FIRMWARE_TESTING,
  [UserActionTypes.CREATE_TEST_REPORT]: UserActionTargetTypes.TEST_REPORT,
  [UserActionTypes.EDIT_TEST_REPORT]: UserActionTargetTypes.TEST_REPORT,
  [UserActionTypes.DELETE_TEST_REPORT]: UserActionTargetTypes.TEST_REPORT,
  [UserActionTypes.ACTIVATE_TEST_REPORT]: UserActionTargetTypes.TEST_REPORT,
}

export interface UserActionTargetType {
  id: UserActionTargetTypes;
  name: string;
}

export interface UserActionType {
  id: UserActionTypes;
  name: string;
  targetType: UserActionTargetTypes;
}

export interface UserAction {
  id: string;
  author: string;
  action: UserActionType;
  targetType: UserActionTargetType;
  target: string;
  jsonPayload: string;
  dateTime: Date;
}
