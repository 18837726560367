import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogueItem } from '@models/templates';
import { TemplatesService } from '@services/templates.service';
import { NotificationService } from '@services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DATE_TIME } from '@constants/dates';
import { BAD_REQUEST } from '@constants/http-params';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss']
})
export class ImagePreviewComponent implements OnInit {

  @Input()
  public image: CatalogueItem;

  @Input()
  public editable = true;

  @Input()
  public hideUsagesRef = false;

  @Output()
  public updated = new EventEmitter<CatalogueItem>();

  public DATE_TIME = DATE_TIME;

  constructor(private router: Router, private templatesService: TemplatesService,
    private notificationService: NotificationService) { }

  public ngOnInit(): void {
  }

  public replaceImage(newFile: File): void {
    if (this.image) {
      this.templatesService.updateCatalogueRecord(this.image.id, newFile)
          .subscribe((updated: CatalogueItem) => {
        Object.assign(this.image, updated); // update instead of replace to protect hierarchy ties
        this.updated.emit(this.image);
        this.notificationService.success(`Image was succesfully replaced in ${this.image.usedCount || 0} locations`);
      }, (error: HttpErrorResponse) => {
        if (error.status === BAD_REQUEST && error?.error?.message === 'FILE_NAME_ALREADY_EXISTS') {
          this.notificationService.error('File with this name already exists.');
        } else {
          this.notificationService.error('Images is not changed. Image format can be JPEG, PNG, JPG or WEBP only.');
        }
      });
    }
  }

  public showDetails(): void {
    if (this.image?.type) {
      const nav = ['/mobile-backend', 'component', this.image.type.toLowerCase(), this.image.id, 'usages'];
      const returnData = {path: this.router.url, data: {active: this.image}};
      this.router.navigate(nav, {state: returnData});
    }
  }

}
