import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModificationPatch, ModificationPatchDetails, ModelModificationInfo } from '@models/config-modifications';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Base } from '@models/base';
import { Sorting } from '@models/sorting';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigModificationsService {

  constructor(private http: HttpClient) {}

  public getConfigModifications(query: string, status: number, sorting: Sorting, page: number): Observable<Paged<ModificationPatch>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(status ? {status} : null),
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<ModificationPatch>>(`/admin-console-service/api/v1/json-config-patch/all`,
      {params}).pipe(tap((paged: Paged<ModificationPatch>) => {
        Utils.extendPaged(paged);
        paged.content.forEach(this.processTimestamps);
    }));
  }

  public getModelsModifications(excludePatchId: number, modelsList: Array<Base>): Observable<Array<ModelModificationInfo>> {
    return this.http.post<Array<ModelModificationInfo>>(`/admin-console-service/api/v1/json-config-patch/validation/models-with-patch`,
      modelsList, excludePatchId ? {params: {excludePatchId}} : {});
  }

  public getConfigModificationDetails(modificationPatchId: number): Observable<ModificationPatchDetails> {
    return this.http.get<ModificationPatchDetails>(`/admin-console-service/api/v1/json-config-patch/${modificationPatchId}`)
      .pipe(tap((modification: ModificationPatchDetails) => this.processTimestamps(modification)));
  }

  public validateConfigPatch(patchDetails: ModificationPatchDetails): Observable<ModificationPatchDetails> {
    return this.http.post<ModificationPatchDetails>(`/admin-console-service/api/v1/json-config-patch/validate/before-apply`, patchDetails)
      .pipe(tap((modification: ModificationPatchDetails) => this.processTimestamps(modification)));
  }

  public createConfigPatch(patchDetails: ModificationPatchDetails, asDraft = false): Observable<ModificationPatchDetails> {
    return this.http.post<ModificationPatchDetails>(`/admin-console-service/api/v1/json-config-patch/create`, patchDetails, {params: {asDraft}})
      .pipe(tap((modification: ModificationPatchDetails) => this.processTimestamps(modification)));
  }

  public editConfigPatch(patchDetails: ModificationPatchDetails, asDraft = false): Observable<ModificationPatchDetails> {
    return this.http.put<ModificationPatchDetails>(`/admin-console-service/api/v1/json-config-patch/update`, patchDetails, {params: {asDraft}})
      .pipe(tap((modification: ModificationPatchDetails) => this.processTimestamps(modification)));
  }

  public archiveConfigPatch(patchId: number): Observable<void> {
    return this.http.post<void>(`/admin-console-service/api/v1/json-config-patch/archive`, {}, {params: {patchId}});
  }

  private processTimestamps(modification: ModificationPatch): void {
    modification.updatedAt = modification.updatedAt ? new Date(modification.updatedAt) : null;
  }

}
