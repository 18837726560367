<div class="inner-view test-reports-view">
  <div class="view-header align-container space-between">
    <h1 class="align-container small-spacing">
      <span>Test Reports</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section control-panel">
    <div class="query-filter filter-item">
      <app-search-bar (search)="search($event)" [searching]="loading" placeholder="Search Test report"></app-search-bar>
    </div>
    <div class="range-select filter-item">
      <span class="label">Period:</span>
      <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData()" [typeSelector]="false" [clearable]="true">
      </app-dates-range>
    </div>
    <div class="status-selector filter-item">
      <span class="label">Status:</span>
      <app-dropdown [items]="statuses" [(ngModel)]="status" (ngModelChange)="updateData()" placeholder="Select status">
      </app-dropdown>
    </div>
    <div class="type-selector filter-item">
      <span class="label">Type:</span>
      <app-dropdown [items]="types" [(ngModel)]="type" (ngModelChange)="updateData()" placeholder="Select type">
      </app-dropdown>
    </div>
    <button class="primary button-add" (click)="createTestReport()">Add New</button>
  </div>
  <div class="view-section results-block">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.FILE_NAME" (sort)="setSorting($event)">
                File Name
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.TYPE" (sort)="setSorting($event)">
                Type
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.MODELS_GROUP" (sort)="setSorting($event)">
                Models Group
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.WIFI_MODULE" (sort)="setSorting($event)">
                Wi-Fi Module
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.EPP_JSON" (sort)="setSorting($event)">
                Epp Json
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.IOS_VERSION" (sort)="setSorting($event)">
                IOS Version
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ANDROID_VERSION" (sort)="setSorting($event)">
                Android Version
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED_BY" (sort)="setSorting($event)">
                Editor
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED_AT" (sort)="setSorting($event)">
                Updated
              </app-sortable-header>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of testReports?.content">
            <td class="break-word">{{row.fileName || '&mdash;'}}</td>
            <td class="capitalize">{{row.type?.name || '&mdash;'}}</td>
            <td class="break-word">{{row.modelGroup?.name || '&mdash;'}}</td>
            <td>{{row.wifiModuleVersion?.version || '&mdash;'}}</td>
            <td>{{row.eppVersion || '&mdash;'}}</td>
            <td>{{row.ios ? row.iosVersion || '&mdash;' : '&mdash;'}}</td>
            <td>{{row.android ? row.androidVersion || '&mdash;' : '&mdash;'}}</td>
            <td>
              <div class="capitalize" [class.activity-status]="!!row.status"
                  [class.active]="row.status === TestReportStatus.ACTIVE"
                  [class.inactive]="row.status === TestReportStatus.INACTIVE"
                  [class.deleted]="row.status === TestReportStatus.DELETED">
                {{row.status || '&mdash;'}}
              </div>
            </td>
            <td class="break-word">{{row.updatedBy || '&mdash;'}}</td>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td><app-actions [actions]="row.actions"></app-actions></td>
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!testReports || !testReports?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="testReports && testReports.totalPages > 1">
      <div class="text-info">Showing {{testReports.startElement}} to {{testReports.endElement}} of {{testReports.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="testReports.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Test Reports</b> – section to manage the main features and test report files. Maintaining the test reports section is necessary to determine the percentage of test coverage for smart devices supported in the evo application.</div>
  <div class="min-space">Each test report file contains information about which functions of smart devices have been successfully tested. One report covers a group of device models (hereinafter referred to as Model Group), which are united on the basis of matching functional characteristics (devices with identical functionality, usually with the same Device JSON-config).</div>
  <div class="less-space">In this section you can:</div>
  <ol class="min-space">
    <li>Upload a new test report</li>
    <li>Download an existing test report file</li>
    <li>Edit the characteristics of an existing test report</li>
    <li>Delete a report (deleted reports are saved in the list of test reports)</li>
  </ol>
  <div class="less-space">The main window of the section contains a list of test reports with information about:</div>
  <ol class="min-space">
    <li><b>FILE NAME</b> – name of the uploaded test report file</li>
    <li><b>TYPE</b> – type of testing that was performed according to the test report (Regression/Functional)</li>
    <li><b>MODELS GROUP</b> – name of the model group to which the test report belongs</li>
    <li><b>WI-FI MODULE</b> – version of the Wi-Fi module used in testing</li>
    <li><b>EPP (IC)</b> – an ID of a motherboard of a device, which this file is available for</li>
    <li><b>iOS VERSION</b> – iOS app version used in testing</li>
    <li><b>ANDROID VERSION</b> – Android app version used in testing</li>
    <li><b>STATUS</b> – test report status (Active/ Inactive/ Deleted/ Draft)</li>
    <li><b>EDITOR</b> – the name of the user who last edited the test report</li>
    <li><b>UPDATED</b> – date and time of the last report editing in the admin console</li>
  </ol>
</ng-template>
