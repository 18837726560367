import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { DeviceModelsService } from '@services/device-models.service';
import { FirmwareService } from '@services/firmware.service';
import { UtilitiesService } from '@services/utilities.service';
import { UserInfoService } from '@services/user-info.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { ConfirmDialogIcon } from '@models/dialogs';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { FirmwareSimplified } from '@models/firmware';
import { DeviceModelSimple } from '@models/device-models';
import { UserInfo } from '@models/user-info';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { Observable, Subject, takeUntil, finalize, map } from 'rxjs';

@Component({
  selector: 'app-binding-tool-view',
  templateUrl: './binding-tool-view.component.html',
  styleUrls: ['./binding-tool-view.component.scss']
})
export class BindingToolViewComponent implements OnInit, OnDestroy {

  public firmwares: Array<DropdownItem>;
  public firmware: DropdownItem;
  public models: Array<DropdownItem>;
  public uploading = false;
  public downloading = false;
  public tokensRequested: string = '';
  public modelsProvider: LookupProvider;
  public activeUser: Observable<UserInfo>;

  @ViewChild('information') information: TemplateRef<any>;

  private destroyed = new Subject<void>();

  constructor(private firmwareService: FirmwareService, private modelsService: DeviceModelsService,
    private utilitiesService: UtilitiesService, private userService: UserInfoService,
    private notificationService: NotificationService, private dialogService: DialogService) { }

  public ngOnInit(): void {
    this.activeUser = this.userService.getActiveUser();
    this.firmwareService.getFirmwareDownloadAcceptableList().subscribe((firmwares: Array<FirmwareSimplified>) => {
      this.firmwares = firmwares.map(x => ({value: x.id, title: x.version + '(' + x.kind + ')', original: x}));
      this.firmware = this.firmwares[0];
    });
    this.modelsProvider = {search: (query: string): Observable<Array<DropdownItem>> => {
      return this.modelsService.lookupModels(query).pipe(map((models: Array<DeviceModelSimple>) => {
        return models.map(x => ({value: x.id, title: x.name}));
      }));
    }};
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'How to work with Wi-Fi module firmware',
      text: this.information
    }});
  }

  public uploadMacAddresses(file: File): void {
    this.uploading = true;
    const entity = 'MAC Addresses';
    this.utilitiesService.uploadBindingMacAddressesWhiteList(file).subscribe((parseErrorLog: string) => {
      this.finishUploading(entity, parseErrorLog);
    }, (error) => {
      this.finishUploading(entity, null, error);
    });
  }

  public uploadQaResults(file: File): void {
    this.uploading = true;
    const entity = 'QA Results';
    this.utilitiesService.uploadQaResults(file).subscribe((parseErrorLog: string) => {
      this.finishUploading(entity, parseErrorLog);
    }, (error) => {
      this.finishUploading(entity, null, error);
    });
  }

  public downloadArchive(): void {
    const tokensRequested = Number(this.tokensRequested);
    const modelsIds = this.models.map(x => x.value);
    this.downloading = true;
    this.utilitiesService.downloadFirmwareArchive(this.firmware.value, this.firmware.original.version,
        modelsIds, tokensRequested).pipe(takeUntil(this.destroyed), finalize(() => this.downloading = false))
        .subscribe((fileName: string) => {},
      (err: HttpErrorResponse) => this.notificationService.error(err.message));
  }

  private finishUploading(entityName: string, parseErrorLog: string, error?: HttpErrorResponse): void {
    this.uploading = false;
    this.dialogService.showModal(ConfirmationDialogComponent, { data: {
      title: parseErrorLog || error ? 'Error' : 'Uploaded',
      text: error ? 'Error uploading file' : (parseErrorLog ?
        `Some of ${entityName} were not loaded in the platform. For more details please check Activity log.` :
          `${entityName} successfully uploaded.`),
      agreeButton: !!parseErrorLog,
      agreeButtonText: 'Open Activity log',
      cancelButtonText: 'Close',
      icon: parseErrorLog || error ? ConfirmDialogIcon.ERROR : null
    }}).afterClosed().pipe(takeUntil(this.destroyed)).subscribe((result: any) => {
      if (parseErrorLog && result) {
        this.utilitiesService.downloadErrorLog(parseErrorLog);
      }
    });
  }

}
