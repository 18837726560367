import { NgModule } from '@angular/core';

import { ProvisioningRoutingModule } from './provisioning-routing.module';
import { CommonModule } from '../common/common.module';

import { FirmwareViewComponent } from './pages/firmware-view/firmware-view.component';
import { FirmwareListViewComponent } from './pages/firmwares-view/firmwares-view.component';
import { WifiModulesViewComponent } from './pages/wifi-modules-view/wifi-modules-view.component';
import { WifiModuleViewComponent } from './pages/wifi-module-view/wifi-module-view.component';
import { DeviceModuleDiagnosticPermissionViewComponent }
  from './pages/device-module-diagnostic-permission-view/device-module-diagnostic-permission-view.component';

import { DeviceModuleEditDialogComponent }
  from './components/device-module-edit-dialog/device-module-edit-dialog.component';
import { DeviceModuleAccessTokenDialogComponent }
  from './components/device-module-access-token-dialog/device-module-access-token-dialog.component';
import { DeviceModuleEppConfigDialogComponent }
  from './components/device-module-epp-config-dialog/device-module-epp-config-dialog.component';
import { DeviceModuleChangeStandDialogComponent }
  from './components/device-module-change-stand-dialog/device-module-change-stand-dialog.component';
import { DiagnosticPermissionDialogComponent }
  from './components/diagnostic-permission-dialog/diagnostic-permission-dialog.component';

import { DeviceModuleDialogComponent } from './components/device-module-dialog/device-module-dialog.component';
import { DeviceModuleInfoViewComponent }
  from './components/device-module-info-view/device-module-info-view.component';
import { DeviceModuleSpacesViewComponent }
  from './components/device-module-spaces-view/device-module-spaces-view.component';
import { DeviceModuleCommandsListComponent }
  from './components/device-module-commands-list/device-module-commands-list.component';
import { DeviceModuleAlarmsListComponent }
  from './components/device-module-alarms-list/device-module-alarms-list.component';
import { DeviceModuleEventsListComponent }
  from './components/device-module-events-list/device-module-events-list.component';
import { DeviceModuleSessionsListComponent }
  from './components/device-module-sessions-list/device-module-sessions-list.component';
import { DeviceModuleStatusHistoryComponent }
  from './components/device-module-status-history/device-module-status-history.component';
import { DeviceModuleFirmwareAlarmsListComponent }
  from './components/device-module-firmware-alarms-list/device-module-firmware-alarms-list.component';
import { DeviceModulePermissionsListComponent } from './components/device-module-permissions-list/device-module-permissions-list.component';

@NgModule({
  imports: [
    CommonModule,
    ProvisioningRoutingModule
  ],
  declarations: [
    FirmwareViewComponent,
    FirmwareListViewComponent,
    WifiModulesViewComponent,
    WifiModuleViewComponent,
    DeviceModuleDiagnosticPermissionViewComponent,
    DeviceModuleEditDialogComponent,
    DeviceModuleAccessTokenDialogComponent,
    DeviceModuleEppConfigDialogComponent,
    DeviceModuleChangeStandDialogComponent,
    DeviceModuleDialogComponent,
    DiagnosticPermissionDialogComponent,
    DeviceModuleInfoViewComponent,
    DeviceModuleSpacesViewComponent,
    DeviceModuleCommandsListComponent,
    DeviceModuleAlarmsListComponent,
    DeviceModuleEventsListComponent,
    DeviceModuleSessionsListComponent,
    DeviceModuleStatusHistoryComponent,
    DeviceModuleFirmwareAlarmsListComponent,
    DeviceModulePermissionsListComponent
  ]
})
export class ProvisioningModule { }
