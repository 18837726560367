export interface UserInfo {
  userName: string;
  firstName: string;
  lastName: string;
  imgUrl?: string;
  isAdmin: boolean;
  isAssembler: boolean;
  isInstallator: boolean;
  isManager: boolean;
  isTechnician: boolean;
  isContentManager: boolean;
  isReadOnlyAdmin: boolean;
}

export enum UserRoles {
  ADMIN = 'administrator',
  ASSEMBLER = 'assembler',
  INSTALLATOR = 'installator',
  TECHNICIAN = 'technician',
  MANAGER = 'manager',
  CONTENT_MANAGER = 'content_manager',
  READONLY_ADMIN = 'read_only_admin'
}