import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DictionaryItem } from '@models/templates';
import { TemplatesService } from '@services/templates.service';
import { NotificationService } from '@services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DATE_TIME } from '@constants/dates';
import { finalize } from 'rxjs';
import { SPECIAL_CHARS } from '@constants/main';

const ALLOWED_PATTERN = new RegExp(`^[\\d\\sa-zA-Zа-яА-ЯёЁ${SPECIAL_CHARS}]+$`);
const NOT_SPACE_ONLY_PATTERN = /\S+/;

@Component({
  selector: 'app-text-preview',
  templateUrl: './text-preview.component.html',
  styleUrls: ['./text-preview.component.scss']
})
export class TextPreviewComponent implements OnInit {

  @Input()
  public text: DictionaryItem;

  @Input()
  public editable = true;

  @Input()
  public hideUsagesRef = false;

  @Output()
  public updated = new EventEmitter<DictionaryItem>();

  public form: FormGroup;
  public editMode = false;
  public submitted = false;
  public snapshot: {};
  public DATE_TIME = DATE_TIME;

  constructor(private router: Router, private formBuilder: FormBuilder,
    private templatesService: TemplatesService,
    private notificationService: NotificationService) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      value: [this.text?.value || '',
        [Validators.required, Validators.pattern(ALLOWED_PATTERN),
          Validators.pattern(NOT_SPACE_ONLY_PATTERN), Validators.maxLength(this.text?.maxLength || 500)]]
    });
    this.form.disable();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.setEditMode(false);
    if (this.form) {
      this.form.patchValue({value: this.text?.value || ''});
    }
  }

  public resetValidation(): void {
    this.submitted = false;
  }

  public setEditMode(editMode: boolean, revert = false): void {
    this.editMode = editMode;
    this.submitted = false;
    if (this.form) {
      if (editMode) {
        this.snapshot = this.form.value;
        this.form.enable();
      } else {
        revert && this.form.setValue(this.snapshot);
        this.form.disable();
      }
    }
  }

  public showDetails(): void {
    if (this.text?.type) {
      const nav = ['/mobile-backend', 'component', this.text.type.toLowerCase(), this.text.id, 'usages'];
      const returnData = {path: this.router.url, data: {active: this.text}};
      this.router.navigate(nav, {state: returnData});
    }
  }

  public saveChanges(): void {
    if (!this.form.invalid) {
      const text = this.form.value.value;
      let confirmedChanges = false;
      this.templatesService.updateDictionaryRecord(this.text.id, text)
          .pipe(finalize(() => this.setEditMode(false, !confirmedChanges))).subscribe((updated: DictionaryItem) => {
        confirmedChanges = true;
        Object.assign(this.text, updated); // update instead of replace to protect hierarchy ties
        this.updated.emit(this.text);
        this.notificationService.success(`Text was succesfully replaced in ${this.text.usedCount || 0} locations`);
      }, (error: HttpErrorResponse) => {
        this.notificationService.error('Failed to replace text');
      });
    } else {
      this.submitted = true;
    }
  }
}
