<div class="device-module-view inner-view">
  <div class="view-header with-breadcrumbs">
    <div class="small-spacing">
      <app-breadcrumbs [breadcrumbs]="breadcrumbs"></app-breadcrumbs>
    </div>
    <h1 class="align-container small-spacing">
      <span>{{wifiModule ? wifiModule.macAddress : '&nbsp;'}}</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section scroll-container">
    <mat-tab-group mat-align-tabs="start" [animationDuration]="1000" [selectedIndex]="tabIndex" (selectedIndexChange)="tabIndex = $event">
      <mat-tab label="Info">
        <app-device-module-info-view [details]="wifiModule">
        </app-device-module-info-view>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Spaces & Users">
        <app-device-module-spaces-view [details]="wifiModule">
        </app-device-module-spaces-view>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Commands">
        <app-device-module-commands-list [details]="wifiModule" (viewDetails)="viewCommandDetails($event)">
        </app-device-module-commands-list>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Alarms">
        <app-device-module-alarms-list [details]="wifiModule">
        </app-device-module-alarms-list>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Events">
        <app-device-module-events-list [details]="wifiModule">
        </app-device-module-events-list>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Sessions">
        <app-device-module-sessions-list [details]="wifiModule">
        </app-device-module-sessions-list>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Current Status">
        <app-device-module-status-history [details]="wifiModule">
        </app-device-module-status-history>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Firmware Alarms">
        <app-device-module-firmware-alarms-list [details]="wifiModule" [firmwareAlarmTypes]="firmwareAlarmTypes">
        </app-device-module-firmware-alarms-list>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
      <mat-tab label="Diagnostic Permissions">
        <app-device-module-permissions-list [details]="wifiModule">
        </app-device-module-permissions-list>
        <div class="loader centered" *ngIf="loading"></div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<ng-template #information>
  <div><b>Wi-Fi Module &amp; Device Card</b></div>
  <div class="top-space">Here you can view more detailed information about the device. Information is grouped into tabs:</div>
  <div class="top-space"><u>Information on the "Info" tab:</u></div>
  <ol class="less-space">
    <li>Wi-FI-module - MAC-address of the Wi-FI module</li>
    <li>Serial Number - device serial number</li>
    <li>Device Type - device type</li>
    <li>Device model - device model</li>
    <li>Device Name - device name</li>
    <li>Firmware version - firmware version on the device</li>
    <li>Firmware type</li>
    <li>Activation Date - date of device activation/connection status (to the Platform) – if notempty, it means connected</li>
    <li>Current Status - the current status of the device (whether it is online)</li>
    <li>Last Data Date - date and time of the last data received from the device</li>
    <li>JSON-config version – a version of JSON-file that is installed now on this device. <u>Pay attention:</u> it became to identify it from some moment, some (old) devices can be without this information.</li>
    <li>Motherboard Version - a version of motherboard firmware that has this device now. <u>Pay attention: it was supported on module firmware from version 1.4.0.</u></li>
  </ol>
  <div class="less-space"><u>Information at the "Spaces and Users" tab:</u><br>Information about users of this device</div>
  <ol class="less-space">
    <li>User HaierID - unique device user identifier (ID in Mapp)</li>
    <li>User is Owner (Y/N) - a sign of (user type) whether the user is the owner of the device (the owner of the “home” space the device is attached)<br/>Information about the spaces this device is attached – for the activated device, in any case, there should be informed about which “room” space this device is assigned to with information about the “house” space to which this "room" is related (in the EvoHome system, the user cannot create rooms outside the home, cannot activate devices without binding to the room)</li>
    <li>SpaceId - unique identifier of the space the device is attached</li>
    <li>Space Type - the type of space the device is attached</li>
    <li>Space Name - the name of the space.</li>
  </ol>
  <div class="top-space"><u>Other tabs -</u><br>is a history of situations that have occurred with this device in the past. This information is divided into tabs according to the main types of events:</div>
  <ol class="less-space">
    <li>Commands - commands to change the state of devices that came to the device.</li>
    <li>Alarms - device errors that occurred at the device</li>
    <li>Events - events that led to a change in the state and properties of the device, different than commands to change (for example participation in spaces, groups, firmware update, device activation, etc.)</li>
    <li>Sessions – a history of the device connections to the IoT platform (when it was online)</li>
    <li>Current Status – a history of the device statuses (current attribute’s values at some specific time)</li>
    <li>Firmware Alarms - firmware errors that occurred at the device.</li>
  </ol>
  <div class="less-space">In each tab, you can view records about situations by selecting a period, and type of change - each record is presented as a line with information about the type of change, dateand time, and author of the change.</div>
</ng-template>