import { Pipe, PipeTransform } from '@angular/core';
import { Utils } from '@services/utils';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  public transform(value: string): string {
    if (!value) {
      return '-';
    }
    return (value === value.toUpperCase() || /[\-_]/.test(value)) ? Utils.unpackSnakeCase(value) : Utils.unpackCamelCase(value);
  }

}
