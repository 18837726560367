import { Base } from './base';
import { Action } from './actions';
import { DeviceTypeSimple, DeviceModelSimple } from '@models/device-models';

export enum TreeNodeType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE'
}

export interface TreeNode extends Base {
  description?: string;
  type?: TreeNodeType;
}

export interface TreeNodeLeaf extends TreeNode {
  // not any special yet extended
}

export interface TreeNodeGroup extends TreeNode {
  nodes: Array<TreeNodeGroup>;
  leafs: Array<TreeNodeLeaf>;
}

export interface ViewTreeNode<T = any, U = any> extends TreeNode {
  type?: TreeNodeType;
  children: Array<ViewTreeNode<T, U>>; // only for non-terminal, children of both types mixed
  terminal: boolean;
  collapsed?: boolean; // only for non-terminal, collapse status
  visible?: boolean;
  original: T | U; // T for non-terminal, U for terminal
  itemsCount?: number; // only non-terminal, visible terminal final items count
  rowsCount?: number; // only non-terminal, visible subrows count including self
  childrenCount?: number; // only non-terminal, visible direct children count
  nameHighlighted?: string;
  actions?: Array<Action>;
  warning?: boolean; // deprecated
  additional?: string;
}

export enum JsonNodeType {
  PLAIN,
  NUMERIC,
  DATE,
  OBJECT,
  ARRAY
}

export interface JsonNode {
  key: string;
  value: any | JsonNodeGroup;
  type: JsonNodeType;
}

export interface JsonNodeGroup {
  level: number;
  children: Array<JsonNode | JsonNodeGroup>;
}

