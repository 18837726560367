export const HttpParams = {
  FIRMWARE_ID: 'firmwareId',
  MODEL_ID: 'modelId',
  MODELS_GROUP_ID: 'modelsGroupId',
  MODULE_ID: 'moduleId',
  QUERY: 'query',
  TAB_NAME: 'tab',
  TEMPLATE_ID: 'templateId',
  PERMISSION_ID: 'permissionId',
  COMPONENT_TYPE: 'componentType',
  COMPONENT_ID: 'componentId',
  TEST_REPORT_ID: 'testReportId',
  TESTING_ID: 'testingId',
  TESTING_MODE: 'testingMode',
  TESTING_RECORD: 'record',
  TESTING_TEST: 'test',
  JUST_CREATED: 'justCreated'
};

export const NO_AUTH_HEADER = 'no-auth';
export const THROW_IF_NOT_ALLOWED = 'throw_forbidden';

export const BAD_REQUEST = 400;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const CONFLICT = 409;
export const PRECONDITION_FAILED = 412;
export const UNSUPPORTED = 415;
export const EXPECTATION_FAILED = 417;

export const MEGABYTE = 1024 * 1024;
export const MAX_MEDIA_SIZE = 50 * MEGABYTE;
