import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'; 
import { DeviceTypeSimple, DeviceModelSimple, DeviceTypesAndModels,
  DeviceTypeNode, DeviceModelNode, ProductLine } from '@models/device-models';
import { Tag } from '@models/informational';
import { flattenDeep } from 'lodash';

@Component({
  selector: 'app-types-models-selection',
  templateUrl: './types-models-selection.component.html',
  styleUrls: ['./types-models-selection.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TypesModelsSelectionComponent),
    multi: true
  }] 
})
export class TypesModelsSelectionComponent implements OnInit, OnChanges, ControlValueAccessor { 

  @Input()
  public typesAndModels: DeviceTypesAndModels;

  @Input()
  public filterQuery: string;

  @Input()
  public filterType: number;

  @Input()
  public filterProductLine: number;

  @Input()
  public filterTag: number;

  public model: DeviceTypesAndModels = {types: [], models: []};
  public view: Array<DeviceTypeNode>;
  public topNode: DeviceTypeNode = {object: null, checked: false, collapsed: true, children: []};

  private onTouchedCallback: () => void;
  protected commit(value: DeviceTypesAndModels) {} 

  constructor() { }

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['typesAndModels']) {
      this.rebuildModelAndView();
    } else {
      this.updateFiltering();
    }
  }

  public checkDeviceType(type: DeviceTypeNode): void {
    type.checked = !type.checked;
    if (type.checked) {
      type.children.forEach(x => x.checked = false);
    }
    this.syncModelToView();
  }

  public checkModel(model: DeviceModelNode): void {
    model.checked = !model.checked;
    if (model.checked) {
      model.parent.checked = false;
    }
    this.syncModelToView();
  }

  public toggle(type: DeviceTypeNode): void {
    type.collapsed = !type.collapsed;
    this.topNode.collapsed = this.view.every((x: DeviceTypeNode) => x.collapsed);
  }

  public toggleAll(): void {
    this.topNode.collapsed  = !this.topNode.collapsed;
    this.view.forEach((deviceType: DeviceTypeNode) => deviceType.collapsed = this.topNode.collapsed);
  }

  public writeValue(value: DeviceTypesAndModels) {
    if (this.model !== value) {
      this.model = value || {types: [], models: []};
      this.syncViewToModel();
    }
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(isDisabled: boolean) {
  }

  private rebuildModelAndView(): void {
    this.view = (this.typesAndModels?.types || []).map((deviceType: DeviceTypeSimple) => ({
      object: deviceType,
      collapsed: true,
      checked: false,
      selectedCount: 0,
      children: (this.typesAndModels?.models || [])
        .filter((model: DeviceModelSimple) => model.typeId === deviceType.id)
        .map((model: DeviceModelSimple) => ({object: model, checked: false, parent: null}))
    }));
    this.view.forEach((deviceType: DeviceTypeNode) => {
      deviceType.children.forEach((model: DeviceModelNode) => model.parent = deviceType);
    });
    this.updateFiltering();
    this.syncViewToModel();
  }

  private syncModelToView(): void {
    this.model = {
      types: this.view.filter(x => x.checked).map(x => x.object),
      models: flattenDeep(this.view.map(x => x.children)).filter(x => x.checked).map(x => x.object)
    };
    this.commit(this.model);
    this.updateCounters();
  }

  private syncViewToModel(): void {
    const selectedTypes = this.model.types.map((type: DeviceTypeSimple) => type.id);
    const selectedModels = this.model.models.map((model: DeviceModelSimple) => model.id);
    this.view.forEach((typeView: DeviceTypeNode) => {
      typeView.checked = selectedTypes.includes(typeView.object.id);
      typeView.children.forEach((modelView: DeviceModelNode) => {
        modelView.checked = selectedModels.includes(modelView.object.id);
      });
    });
    this.updateCounters();
  }

  private updateCounters(): void {
    this.view.forEach(x => x.selectedCount = x.children.filter(y => y.checked).length);
  }

  private updateFiltering(): void {
    const isSet = (x: any): boolean => x !== null && x !== undefined && x !== '';
    const match = (name: string) => name.toUpperCase().includes(this.filterQuery.toUpperCase());
    this.view.forEach(x => {
      const hiddenByType = isSet(this.filterType) && x.object.id !== this.filterType;
      const hiddenByProductLine = isSet(this.filterProductLine)
        && !x.object.productLines.some(y => y.id === this.filterProductLine);
      const hiddenByTag = isSet(this.filterTag)
        && !x.children.some(y => (y.object.tags || []).some(z => z.id === this.filterTag));
      const hiddenByQuery = isSet(this.filterQuery)
        && !(match(x.object.name) || x.children.some(y => match(y.object.name)));
      x.hidden = hiddenByType || hiddenByProductLine || hiddenByTag || hiddenByQuery;
      x.children.forEach(y => {
        const hiddenByTag = isSet(this.filterTag)
          && !y.object.tags.some(z => z.id == this.filterTag);
        const hiddenByQuery = isSet(this.filterQuery) && !match(y.object.name);
        y.hidden = hiddenByTag || hiddenByQuery;
      });
    });
  }
}
