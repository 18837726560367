import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { TemplatesService } from '@services/templates.service';
import { DialogService } from '@services/dialog.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { Template } from '@models/templates';
import { DATE } from '@constants/dates';
import { finalize } from 'rxjs';

enum SortColumn {
  TEMPLATE_NAME = 'name',
  EDITED_AT = 'updatedAt',
  EDITED_BY = 'updatedBy',
  NAME = 'name'
}

@Component({
  selector: 'app-templates-view',
  templateUrl: './templates-view.component.html',
  styleUrls: ['./templates-view.component.scss']
})
export class TemplatesViewComponent implements OnInit {

  public templates: Paged<Template>;
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public DATE_FORMAT = DATE;
  public sorting: Sorting = {column: SortColumn.EDITED_AT, order: SortOrder.DESC};
  public SortColumn = SortColumn;

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private templatesService: TemplatesService, private dialogService: DialogService) { }

  public ngOnInit(): void {
    this.updateData(false);
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData(false);
  }

  public search(query: string): void {
    this.query = query;
    this.updateData();
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { width: '800px', data: {
      title: 'How to work with Templates',
      text: this.information
    }});
  }

  public updateData(resetPage = true): void {
    this.loading = true;
    this.templatesService.getTemplates(this.query, this.sorting, resetPage ? 1 : this.activePage)
        .pipe(finalize(() => this.loading = false)).subscribe((templates: Paged<Template>) => {
      this.templates = templates;
      this.activePage = resetPage ? 1 : this.activePage;
    }, (error: any) => {
      console.error(error);
    });
  }
}

