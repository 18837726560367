import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WiFiModule, WiFiModuleDetailed, WiFiModuleStatusUpdate, WiFiModuleCommandEntryDetails, WiFiModuleFirmwareAlarmType,
  WiFiModuleLogEntry, WiFiModuleCommand, WiFiModuleCommandType, WiFiModuleAlarm, WiFiModuleEvent, WiFiModuleSession,
  WiFiModuleStatusEntry, WiFiModuleFirmwareAlarm, WiFiModuleDiagnosticPermission, WiFiModuleDiagnosticPermissionDetails,
  WiFiModuleDiagnosticPermissionStatus, WiFiModuleDiagnosticPermissionHistoryEntry, WiFiModuleDiagnosticPermissionRequest } from '@models/device-modules';
import { Utils, provided } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import { DatesRange, fixRange } from '@models/dates';
import { Observable, of, tap, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceModulesService {

  constructor(private http: HttpClient) { }

  public getDeviceWiFiModules(query: string, sorting: Sorting, page: number): Observable<Paged<WiFiModule>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<WiFiModule>>(`/device-service/api/v1/console/modules-devices`,
      {params}).pipe(tap((paged: Paged<WiFiModule>) => {
        Utils.extendPaged(paged);
    }));
  }

  public getDeviceWiFiModuleDetailed(id: number): Observable<WiFiModuleDetailed> {
    return this.http.get<WiFiModuleDetailed>(`/device-service/api/v1/console/modules-devices/${id}`)
        .pipe(tap((data: WiFiModuleDetailed) => {
      data.id = id;
      data.activatedAt = data.activatedAt ? new Date(data.activatedAt) : null;
      data.lastSeenAt = data.lastSeenAt ? new Date(data.lastSeenAt) : null;
    }));
  }

  public acquireWiFiModulesStatuses(devicesId: Array<number>): Observable<Array<WiFiModuleStatusUpdate>> {
    const params = {devicesId};
    return devicesId && devicesId.length ? 
      this.http.get<Array<WiFiModuleStatusUpdate>>(`/device-service/api/v1/console/devices-connection-status`, {params}) : of([]);
  }

  public getDeviceWiFiModuleCommands(wifiModuleMac: string, datesRange: DatesRange,
        query: string, sorting: Sorting, page: number): Observable<Paged<WiFiModuleCommand>> {
    const params = this.prepareLogsQueryParams(datesRange, query, sorting, page);
    const result = this.http.get<Paged<WiFiModuleCommand>>(
      `/persist-service/api/v1/console/commands/${wifiModuleMac}`, {params});
    return this.processTimestamps<WiFiModuleCommand>(result);
  }

  public getDeviceWiFiModuleCommandDetails(command: WiFiModuleCommand): Observable<WiFiModuleCommandEntryDetails> {
    const path = command.type === WiFiModuleCommandType.REQUEST ? `info` : `response`;
    let params = command.type === WiFiModuleCommandType.REQUEST ? {id: command.requestId} : {trace: command.trace};
    params = {...params as any, traceTs: command.traceTs};
    return this.http.get<WiFiModuleCommandEntryDetails>(`/persist-service/api/v1/console/commands/${path}`, {params})
      .pipe(tap((data: WiFiModuleCommandEntryDetails) => data.ts = data.ts ? new Date(data.ts) : data.ts));
  }

  public getDeviceWiFiModuleAlarms(wifiModuleMac: string, datesRange: DatesRange,
        query: string, sorting: Sorting, page: number): Observable<Paged<WiFiModuleAlarm>> {
    const params = this.prepareLogsQueryParams(datesRange, query, sorting, page);
    const result = this.http.get<Paged<WiFiModuleAlarm>>(
      `/persist-service/api/v1/console/alarms/${wifiModuleMac}`, {params});
    return this.processTimestamps<WiFiModuleAlarm>(result);
  }

  public getDeviceWiFiModuleEvents(wifiModuleMac: string, datesRange: DatesRange,
        query: string, sorting: Sorting, page: number): Observable<Paged<WiFiModuleEvent>> {
    const params = this.prepareLogsQueryParams(datesRange, query, sorting, page);
    const result = this.http.get<Paged<WiFiModuleEvent>>(
      `/persist-service/api/v1/console/events/${wifiModuleMac}`, {params});
    return this.processTimestamps<WiFiModuleEvent>(result);
  }

  public getDeviceWiFiModuleFirmwareAlarmTypes(): Observable<Array<WiFiModuleFirmwareAlarmType>> {
    return this.http.get<Array<WiFiModuleFirmwareAlarmType>>(`/device-service/api/v1/console/errors`);
  }

  public getDeviceWiFiModuleFirmwareAlarms(wifiModuleMac: string, datesRange: DatesRange,
        typeId: number, sorting: Sorting, page: number): Observable<Paged<WiFiModuleFirmwareAlarm>> {
    const params = Object.assign(this.prepareLogsQueryParams(datesRange, null, sorting, page), provided(typeId) ? {typeId} : null);
    const result = this.http.get<Paged<WiFiModuleFirmwareAlarm>>(
      `/persist-service/api/v1/console/firmware-alarms/${wifiModuleMac}`, {params});
    return this.processTimestamps<WiFiModuleFirmwareAlarm>(result);
  }

  public getDeviceWiFiModuleStatusHistory(wifiModuleMac: string, datesRange: DatesRange,
        query: string, sorting: Sorting, page: number): Observable<Paged<WiFiModuleStatusEntry>> {
    const params = this.prepareLogsQueryParams(datesRange, query, sorting, page);
    const result = this.http.get<Paged<WiFiModuleStatusEntry>>(
      `/persist-service/api/v1/console/statuses/${wifiModuleMac}`, {params});
    return this.processTimestamps<WiFiModuleStatusEntry>(result);
  }

  public getDeviceWiFiModuleSessions(deviceId: number, datesRange: DatesRange,
        sorting: Sorting, page: number): Observable<Paged<WiFiModuleSession>> {
    const params = Object.assign(this.prepareLogsQueryParams(datesRange, null, sorting, page), provided(deviceId) ? {deviceId} : null);
    return this.http.get<Paged<WiFiModuleSession>>(
        `/device-service/api/v1/console/sessions`, {params}).pipe(tap((paged: Paged<WiFiModuleSession>) => {
      Utils.extendPaged(paged);
      paged.content.forEach((entry: WiFiModuleSession) => {
        entry.onTime = entry.onTime ? new Date(entry.onTime) : null;
        entry.offTime = entry.offTime ? new Date(entry.offTime) : null;
      });
    }));
  }

  public getDeviceWiFiModuleDiagnosticPermissions(wifiModuleMac: string, datesRange: DatesRange,
        query: string, sorting: Sorting, page: number): Observable<Paged<WiFiModuleDiagnosticPermission>> {
    const params = this.prepareLogsQueryParams(datesRange, query, sorting, page);
    const result = this.http.get<Paged<WiFiModuleDiagnosticPermission>>(
      `/device-service/api/v1/console/diagnostic/all`, {params: {mac: wifiModuleMac, ...params}});
    return this.processTimestamps<WiFiModuleDiagnosticPermission>(result); 
  }

  public getDeviceWiFiModuleDiagnosticPermissionDetails(permissionId: number): Observable<WiFiModuleDiagnosticPermissionDetails> {
    return this.http.get<WiFiModuleDiagnosticPermissionDetails>(`/device-service/api/v1/console/diagnostic/${permissionId}`)
        .pipe(tap((result: WiFiModuleDiagnosticPermissionDetails) => {
      const Status = WiFiModuleDiagnosticPermissionStatus;
      result.ts = result.ts ? new Date(result.ts) : null;
      result.statusUpdated = result.statusUpdated ? new Date(result.statusUpdated) : null;
      const historyData = (result.history || [])
        .filter(x => x.ts).map(x => {x.ts = new Date(x.ts); return x; })
        .sort((x, y) => x.ts.getTime() - y.ts.getTime());
      const detailsStatuses = [Status.APPROVED, Status.DENIED];
      const historyDetails = historyData.filter(x => detailsStatuses.includes(x.status));
      const create = (status: WiFiModuleDiagnosticPermissionStatus, text?: string): WiFiModuleDiagnosticPermissionHistoryEntry => {
        return {status, text};
      };
      const findLast = (status: WiFiModuleDiagnosticPermissionStatus, createEmpty = false): WiFiModuleDiagnosticPermissionHistoryEntry => {
        const entries = historyData.filter(x => x.status === status);
        return entries.length ? entries[entries.length - 1] : (createEmpty ? create(status) : null);
      };
      const history = [];
      history.push(findLast(WiFiModuleDiagnosticPermissionStatus.SENT, true));
      const denied = findLast(WiFiModuleDiagnosticPermissionStatus.DENIED);
      const approved = findLast(WiFiModuleDiagnosticPermissionStatus.APPROVED);
      const completed = findLast(WiFiModuleDiagnosticPermissionStatus.COMPLETED);
      result.completed = !!completed;
      if (denied?.ts && (!approved?.ts || denied?.ts > approved?.ts)) {
        denied.details = historyDetails.filter(x => x !== denied);
        history.push(denied);
        const never = create(Status.NEVER_COMPLETED, Status.COMPLETED);
        history.push(completed ? Object.assign(completed, never) : never);
      } else if (approved?.ts && (!denied?.ts || approved?.ts > denied?.ts)) {
        approved.details = historyDetails.filter(x => x !== approved);
        history.push(approved);
        history.push(completed || create(Status.COMPLETE_PENDING, Status.COMPLETED));
      } else {
        history.push(create(Status.APPROVE_PENDING, Status.APPROVED));
        history.push(completed || create(Status.COMPLETE_PENDING, Status.COMPLETED));
      }
      result.history = history;
    }));
  }

  public completeDiagnosticRequest(permissionId: number): Observable<void> {
    return this.http.post<void>(`/device-service/api/v1/console/diagnostic/${permissionId}/complete`, {});
  }

  public createDiagnosticRequest(request: WiFiModuleDiagnosticPermissionRequest): Observable<void> {
    return this.http.post<void>(`/device-service/api/v1/console/diagnostic/send`, request);
  }

  public getDeviceOwners(macAddress: string): Observable<Array<string>> {
    return this.http.get<Array<string>>(`/device-service/api/v1/console/owners/${macAddress}`);
  }

  public unbindWiFiModule(macAddress: string): Observable<WiFiModule> {
    return this.http.delete<WiFiModule>(`/device-service/api/v1/console/device-routine/${macAddress}`);
  }

  public updateDeviceWiFiModule(macAddress: string, newModelId: number, newSerial: string): Observable<WiFiModule> {
    const serial = newSerial ? newSerial.toUpperCase() : null;
    const params = {modelId: newModelId || null, serial};
    return this.http.put<WiFiModule>(`/device-service/api/v1/console/device-routine/${macAddress}/model`, params);
  }

  public checkModuleAccessToken(mac: string, accessToken: string): Observable<boolean> {
    return this.http.post<boolean>(`/device-service/api/v1/status/check/access-token`, {}, {params: {mac, accessToken}})
      .pipe(map((value: boolean) => !value));
  }

  public updateModuleAccessToken(macAddress: string, accessToken: string): Observable<WiFiModule> {
    return this.http.put<WiFiModule>(`/device-service/api/v1/console/change-access-token`, {macAddress, accessToken});
  }

  public refreshModuleEppConfig(macAddress: string): Observable<WiFiModule> {
    return this.http.put<WiFiModule>(`/device-service/api/v1/console/update-epp-configs`, {}, {params: {mac: macAddress}});
  }

  public moveModuleToAnotherStand(macAddress: string, standId: number): Observable<WiFiModule> {
    return this.http.post<WiFiModule>(`/device-service/api/v1/console/change-stand-for-device`, {mac: macAddress, standId});
  }

  public moveToArchiveWiFiModule(macAddress: string): Observable<void> {
    return this.http.post<void>(`/device-service/api/v1/console/device/${macAddress}/archive`, {});
  }

  private prepareLogsQueryParams(datesRange: DatesRange, query: string, sorting: Sorting, page: number): any {
    const range = fixRange(datesRange);
    return {
      paged: true,
      page: page - 1,
      size: 20,
      ...(range?.from ? {from: range.from.toISOString()} : null),
      ...(range?.to ? {to: range.to.toISOString()} : null),
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
  }

  private processTimestamps<T extends {ts: Date}>(entries: Observable<Paged<T>>): Observable<Paged<T>> {
    return entries.pipe(tap((paged: Paged<T>) => {
      Utils.extendPaged(paged);
      paged.content.forEach((entry: T) => entry.ts = entry.ts ? new Date(entry.ts) : null);
    }));
  }
}
