import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { TestingService } from '@services/testing.service';
import { DialogService } from '@services/dialog.service';
import { Testing, TestingBase, TestingStatus, TestingTestStatusUpdate } from '@models/testing';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { RunTestingDialogComponent } from '../../components/run-testing-dialog/run-testing-dialog.component';
import { Paged } from '@models/pageable';
import { Sorting, SortOrder } from '@models/sorting';
import { DATE_TIME } from '@constants/dates';
import { Subject, finalize, takeUntil } from 'rxjs';

enum SortColumn {
  ID = 'id',
  DEVICE_MODEL = 'deviceModel',
  DEVICE_MAC = 'deviceMac',
  FW_VERSION = 'fwVersion',
  STARTED = 'started',
  ENDED = 'ended',
  STATUS = 'status'
}

@Component({
  selector: 'app-tests-view',
  templateUrl: './tests-view.component.html',
  styleUrls: ['./tests-view.component.scss']
})
export class TestsViewComponent implements OnInit, OnDestroy {

  public tests: Paged<Testing>;
  public query: string = '';
  public activePage = 1;
  public loading = false;
  public DATE_TIME = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.STARTED, order: SortOrder.DESC};
  public SortColumn = SortColumn;
  private destroyed = new Subject<void>();

  @ViewChild('information') information: TemplateRef<any>;

  constructor(private testingService: TestingService, private dialogService: DialogService) { }

  public ngOnInit(): void {
    this.updateData();
  }

  public ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.testingService.cancelTestingTracking();
  }

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.updateData();
  }

  public search(query: string): void {
    this.query = query;
    this.updateData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.updateData();
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'Tests',
      text: this.information
    }});
  }

  public startTest(): void {
    this.dialogService.showModal(RunTestingDialogComponent, {width: '500px'});
  }

  private updateData(resetPage = false): void {
    this.loading = true;
    this.testingService.cancelTestingTracking();
    this.testingService.getTestings(this.query, resetPage ? 1 : this.activePage, this.sorting)
        .pipe(finalize(() => this.loading = false)).subscribe((tests: Paged<Testing>) => {
      this.tests = tests;
      this.activePage = resetPage ? 1 : this.activePage;
      const unfinished = (this.tests?.content || []).filter(x => !this.testingService.isTerminalTestingStatus(x.status));
      const progressStarted = new Map<TestingBase, boolean>();
      this.testingService.setTestingMonitoringOnTrack(unfinished)
          .pipe(takeUntil(this.destroyed)).subscribe((x: TestingTestStatusUpdate) => {
        if (this.testingService.isTerminalTestingStatus(x.status)) {
          this.updateEntry(x.testing);
        } else if (!progressStarted.get(x.testing) && x.status === TestingStatus.RUNNING) {
          progressStarted.set(x.testing, true);
          this.updateEntry(x.testing);
        }
      });
    });
  }

  private updateEntry(entry: TestingBase): void {
    this.testingService.getTestingDetails(entry.id).subscribe((testing: Testing) => {
      Object.assign(entry, testing);
    });
  }

}
