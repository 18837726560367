import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TextEnterDialogData } from '@models/dialogs';

@Component({
  selector: 'app-text-enter-dialog',
  templateUrl: './text-enter-dialog.component.html',
  styleUrls: ['./text-enter-dialog.component.scss']
})
export class TextEnterDialogComponent implements OnInit {

  public text: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TextEnterDialogData,
    private dialogRef: MatDialogRef<TextEnterDialogComponent>
  ) { }

  public ngOnInit(): void {
    this.text = this.data.value || '';
  }

  public close(): void {
    this.dialogRef.close();
  }

  public proceed(): void {
    if (this.text || this.data.required !== false) {
      this.dialogRef.close(this.text);
    }
  }
}
