<div class="dialog-content device-model-edit">
  <div class="dialog-header">
    <span class="title">{{(isNew ? 'Add new' : 'Edit') + ' Model'}}</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="field-name">Model Name</div>
    <div class="field model-name">
      <div class="input-container" [class.invalid]="errors?.name">
        <input type="text" maxlength="50" [(ngModel)]="modelName" (ngModelChange)="pipeCheck()"/>
        <div class="controls"><div class="button validation-bubble"></div></div>
      </div>
      <div class="validation" *ngIf="errors?.name">
        <div *ngIf="errors.name.invalid">Field must contain 1-50 latin/special symbols and/or numbers</div>
        <div *ngIf="errors.name.nonunique">Model already exists</div>
      </div>
    </div>
    <div class="field-name">SAP-code (mat.number)</div>
    <div class="field sap-code">
      <div class="input-container" [class.invalid]="errors?.sap">
        <input type="text" maxlength="11" [(ngModel)]="sapCode" (ngModelChange)="pipeCheck()"/>
        <div class="controls"><div class="button validation-bubble"></div></div>
      </div>
      <div class="validation" *ngIf="errors?.sap">
        <div *ngIf="errors.sap.invalid">Field must contain 11 latin symbols and/or numbers and must end with RU</div>
        <div *ngIf="errors.sap.nonunique">SAP already exists</div>
      </div>
    </div>
    <div class="field-name">Device type</div>
    <div class="field device-type">
      <app-dropdown [items]="deviceTypes" placeholder="Select" [(ngModel)]="deviceType" (ngModelChange)="updateProductLines()">
      </app-dropdown>
    </div>
    <div class="field-name">Product line</div>
    <div class="field product-line">
      <app-dropdown [items]="productLines" placeholder="Select" [(ngModel)]="productLine">
      </app-dropdown>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()"
      [disabled]="!productLine || !deviceType || !modelName || errors">{{isNew ? 'Add' : 'Save'}}</button>
  </div>
</div>
