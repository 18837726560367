<div class="dialog-content epp-config-refresh">
  <div class="dialog-header">
    <span class="title">Refresh epp-config</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <table class="module-status">
      <tr>
        <td class="field-label">MAC address</td>
        <td class="field-value">{{data.deviceModule.macAddress}}</td>
      </tr>
      <tr>
        <td class="field-label">Current status</td>
        <td class="field-value">{{data.deviceModule.online ? 'Online' : 'Offline'}}</td>
      </tr>
    </table>
    <div class="request">
      Are you sure you want to Refresh epp-config for selected MAC address?
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()">Yes, refresh</button>
  </div>
</div> 