import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeviceModelsService } from '@services/device-models.service';
import { NotificationService } from '@services/notification.service';
import { Subject, takeUntil, debounceTime, finalize } from 'rxjs';
import { DropdownItem } from "@models/dropdown";
import { ModelAssignGroupDialogData } from "../../models/dialog-data";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: 'app-model-assign-group-dialog',
  templateUrl: './model-assign-group-dialog.component.html',
  styleUrls: ['./model-assign-group-dialog.component.scss']
})
export class ModelAssignGroupDialogComponent implements OnInit, OnDestroy {

  public modelGroups: Array<DropdownItem>;
  public modelGroup: DropdownItem;
  public progressing = false;

  private destroyed = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModelAssignGroupDialogData,
    private dialogRef: MatDialogRef<ModelAssignGroupDialogComponent>,
    private deviceModelsService: DeviceModelsService,
    private notificationService: NotificationService
  ) {}

  public ngOnInit(): void {
    this.modelGroups = this.data.groups.map(x => ({value: x.id, title: x.name}));
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  public close(): void {
    this.dialogRef.close();
  }

  public assignModelGroup(): void {
    if (this.modelGroup) {
      this.deviceModelsService.appendModelToGroup(this.modelGroup.value, this.data.model.id).pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.notificationService.success(`Model ${this.data.model.name} successfully included to Group ${this.modelGroup.title}`);
        this.dialogRef.close(true);
      }, (err: HttpErrorResponse) => {
        this.notificationService.error(`Failed to add Model ${this.data.model.name} to Group ${this.modelGroup.title}`)
      });
    }
  }

}
