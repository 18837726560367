import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceInstruction, NewDeviceInstruction, DeviceInstructionUpdateResult } from '@models/device-instructions';
import { DeviceTypesAndModels } from '@models/device-models';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceInstructionsService {

  constructor(private http: HttpClient) {}

  public getDeviceInstructions(query: string, deviceTypeId: number, sorting: Sorting, page: number): Observable<Paged<DeviceInstruction>> {
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(deviceTypeId ? {deviceTypeId} : null),
      ...(query ? {query} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null)
    };
    return this.http.get<Paged<DeviceInstruction>>(`/device-service/api/v1/console/instruction`,
      {params}).pipe(tap((paged: Paged<DeviceInstruction>) => {
        Utils.extendPaged(paged);
        paged.content.forEach(this.updateInstruction);
    }));
  }

  public getDeviceInstructionTypes(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`/device-service/api/v1/console/instruction/types`);
  }

  public createDeviceInstruction(instruction: NewDeviceInstruction, override: DeviceTypesAndModels,
      forceSave = false, asDraft = false): Observable<DeviceInstructionUpdateResult> {
    return this.http.post<DeviceInstructionUpdateResult>(`/device-service/api/v1/console/instruction`,
      {instruction, override}, {params: {forceSave, isDraft: asDraft}})
        .pipe(tap((instruction: DeviceInstructionUpdateResult) => {
      this.updateInstruction(instruction.instruction);
    }));
  }

  public updateDeviceInstruction(instruction: DeviceInstruction, override: DeviceTypesAndModels,
      forceSave = false, asDraft = false): Observable<DeviceInstructionUpdateResult> {
    return this.http.put<DeviceInstructionUpdateResult>(`/device-service/api/v1/console/instruction`,
      {instruction, override}, {params: {forceSave, isDraft: asDraft}})
        .pipe(tap((instruction: DeviceInstructionUpdateResult) => {
      this.updateInstruction(instruction.instruction);
    }));
  }

  public deleteDeviceInstruction(instructionId: number): Observable<void> {
    return this.http.delete<void>(`/device-service/api/v1/console/instruction/${instructionId}`);
  }

  private updateInstruction(instruction: DeviceInstruction): void {
    if (instruction) {
      instruction.updatedAt = instruction.updatedAt ? new Date(instruction.updatedAt) : null;
    }
  }

}
