<div class="configs-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Model JSON-configs</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>
  </div>
  <div class="view-section filtering-panel align-container">
    <div class="filtering-block align-container">
      <div class="search-model">
        <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search config name or model"></app-search-bar>
      </div>
      <div class="device-type">
        <app-dropdown [items]="deviceTypesItems" [(ngModel)]="deviceType" (ngModelChange)="updateData(true)"
          placeholder="Select device type"></app-dropdown>
      </div>
      <div class="active-only">
        <app-checkbox [(ngModel)]="activeOnly" (ngModelChange)="updateData(true)">Active only</app-checkbox>
      </div>
    </div>
    <div class="buttons">
      <button class="primary button-upload" (click)="selectModelFile()" [disabled]="progressing">Upload Config</button>
      <input type="file" #file [hidden]="true" class="hidden" (change)="fileSelected($event)" accept=".json, .txt" multiple/>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.NAME" (sort)="setSorting($event)">
                Name
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.DEVICE_TYPE" (sort)="setSorting($event)">
                Device Type
              </app-sortable-header>
            </th>
            <th>
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.MODEL" (sort)="setSorting($event)">
                Model
              </app-sortable-header>
            </th>
            <th class="centered">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.ACTIVE" (sort)="setSorting($event)">
                Status
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.CREATED" (sort)="setSorting($event)">
                Created
              </app-sortable-header>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of configs?.content">
            <td class="break-all">{{row.fileName || '&mdash;'}}</td>
            <td>{{row.deviceType || '&mdash;'}}</td>
            <td>{{row.model || '&mdash;'}}</td>
            <td>
              <div class="activity-status" [ngClass]="{active: row.active, inactive: !row.active}">
                {{row.active ? 'Active' : 'Inactive'}}
              </div>
            </td>
            <td>{{row.createdAt ? (row.createdAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td><app-actions [actions]="row.actions" [disabled]="progressing"></app-actions></td> 
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!configs || !configs?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="configs && configs.totalPages > 1">
      <div class="text-info">Showing {{configs.startElement}} to {{configs.endElement}} of {{configs.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="configs.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Model JSON-configs</b> — section for managing model JSON configurations intended for devices of certain models. The model JSON configuration is intended for the mobile app to form the content of screens according to this device model. In the section, you can see both the current (currently active) configurations, and the outdated ones - for each configuration there is a mark about its status.</div>
    <div class="top-space">In this section you can:</div>
  <ol class="info-list top-space">
    <li>Upload configuration</li>
    <li>Download available in the IoT platform</li>
    <li>Create config modification</li>
  </ol>
</ng-template>