<div class="permissions-view sub-view">
  <div class="controls-panel view-header align-container">
    <div class="label-base">Period</div>
    <div class="range-select">
      <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData(true)" [typeSelector]="false">
      </app-dates-range>
    </div>
    <div class="query-field">
      <app-search-bar (search)="search($event)" [searching]="loading" placeholder="Author or owner ID">
      </app-search-bar>
    </div>
    <div class="controls-container">
      <button class="primary" (click)="createPermission()">Add permission</button>
    </div>
  </div>
  <div class="view-section">
    <div class="contents scroll-container">
      <perfect-scrollbar>
        <table class="results-table">
          <thead>
            <tr>
              <th>
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.REQUEST_ID" (sort)="setSorting($event)">
                  Request Id
                </app-sortable-header>
              </th>
              <th>
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.OWNER_ID" (sort)="setSorting($event)">
                  Owner Id
                </app-sortable-header>
              </th>
              <th>
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.AUTHOR" (sort)="setSorting($event)">
                  Author
                </app-sortable-header>
              </th>
              <th>
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.CREATED" (sort)="setSorting($event)">
                  Created
                </app-sortable-header>
              </th>
              <th>
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.DURATION" (sort)="setSorting($event)">
                  Duration
                </app-sortable-header>
              </th>
              <th>
                <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                  Status
                </app-sortable-header>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of permissions?.content">
              <td>{{row.id || '&mdash;'}}</td>
              <td class="break-word">{{row.ownerId || '&mdash;'}}</td>
              <td class="break-word">{{row.author || '&mdash;'}}</td>
              <td>{{row.ts ? (row.ts | date:DATE_TIME) : '&mdash;'}}</td>
              <td>{{row.duration ? (row.duration + 'h') : '&mdash;'}}</td>
              <td><div class="capitalize">{{row.status || '&mdash;'}}</div></td>
              <td>
                <a class="button button-view" [routerLink]="['/provisioning', 'device-module', '' + details.id, 'permissions', '' + row.id]">
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!permissions || !permissions?.content.length" [bordered]="true"></app-no-data>
      </perfect-scrollbar>
    </div>
    <div class="pagination-block attached" *ngIf="permissions && permissions.totalPages > 1">
      <div class="text-info">Showing {{permissions.startElement}} to {{permissions.endElement}} of {{permissions.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="permissions.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
</div>