import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoverageDialogData } from '@models/dialogs';
import { DropdownItem } from '@models/dropdown';

@Component({
  selector: 'app-coverage-dialog',
  templateUrl: './coverage-dialog.component.html',
  styleUrls: ['./coverage-dialog.component.scss']
})
export class CoverageDialogComponent implements OnInit {

  public coverages: Array<DropdownItem> = [];
  public coverage: DropdownItem;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CoverageDialogData,
    private dialogRef: MatDialogRef<CoverageDialogComponent>
  ) { }

  public ngOnInit(): void {
    this.coverages = this.data.coverages;
  }

  public close(): void {
    this.dialogRef.close();
  }

  public proceed(): void {
    if (this.coverage && this.coverage.value !== null) {
      this.dialogRef.close(this.coverage);
    }
  }
}
