<div class="file-container align-container space-between" [class.clearable]="clearable" [class.disabled]="disabled" [class.invalid]="invalid"
      [class.focused]="focused" (click)="selectFile()" appClickOutside (clickOutside)="setFocused(false)">
  <div class="field align-container">
    <span class="selected-file" *ngIf="selectedFile">{{selectedFile.name}}</span>
    <span class="placeholder" *ngIf="!selectedFile && placeholder">{{placeholder}}</span>
  </div>
  <div class="controls align-container">
    <div class="button button-clear" *ngIf="clearable && selectedFile" (click)="clear($event)"></div>
    <a class="button link-button bold-link file-button" *ngIf="text">{{text}}</a>
    <button class="button button-file-select file-button" *ngIf="!text" [disabled]="disabled">{{text}}</button>
  </div>
  <input type="file" #input [hidden]="true" class="hidden" [accept]="extensions" (change)="fileSelected($event)"/>
</div>
