import { Component, OnInit, AfterViewInit, Input, Output, ViewChild,
  ChangeDetectorRef, forwardRef, ElementRef, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import autosizeModule from 'autosize';
let autosize = (autosizeModule as any).default;

@Component({
  selector: 'app-multiline-edit',
  templateUrl: './multiline-edit.component.html',
  styleUrls: ['./multiline-edit.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MultilineEditComponent),
    multi: true
  }]

})
export class MultilineEditComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() public maxlength?: string | number;
  @Input() public placeholder?: string;
  @Input() public readOnly?: boolean;
  @Input() public disabled?: boolean;
  @Input() public clearable = false;
  @Input() public invalid = false;
  @Input() public narrow = false;

  @Output() public focus = new EventEmitter<void>();
  @Output() public blur = new EventEmitter<void>();

  @ViewChild('textarea') textArea: ElementRef;

  public focused = false;
  public value = '';

  private blockCommit = false;
  private onTouchedCallback: () => void;
  protected commit(value: string) {}

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
  }

  public ngAfterViewInit(): void {
    this.textArea?.nativeElement && autosize(this.textArea.nativeElement);
  }

  public setFocused(focused: boolean): void {
    this.focused = focused;
    focused ? this.focus.emit() : this.blur.emit();
  }

  public returnFocus(): void {
    this.textArea?.nativeElement && this.textArea.nativeElement.focus();
  }

  public clear(): void {
    this.value = '';
    this.commit(this.value);
    this.updateHeight();
  }

  public writeValue(value: string) {
    this.value = value || '';
    this.changeDetectorRef.detectChanges();
    this.updateHeight();
  }

  public commitValue(): void {
    if (!this.blockCommit && !this.disabled) {
      this.commit(this.value);
    }
  }

  public updateHeight(): void {
    setTimeout(() => {
      if (this.textArea?.nativeElement) {
        this.blockCommit = true;
        this.textArea.nativeElement.dispatchEvent(new Event('input', { bubbles: false }));
        this.blockCommit = false;
      }
    });
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

}
