import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { BadgesInfo, SystemStandsInfo } from '@models/informational';

@Injectable({
  providedIn: 'root'
})
export class InformationalService {

  private generalInformationRequiresUpdate = new Subject<void>();

  constructor(private httpClient: HttpClient) {
  }

  public getBadgesInformation(): Observable<BadgesInfo> {
    return this.httpClient.get<BadgesInfo>(`/device-service/api/v1/console/informational-service/badges-count`);
  }

  public getSystemStands(): Observable<SystemStandsInfo> {
    return this.httpClient.get<SystemStandsInfo>(`/device-service/api/v1/console/stand-info`);
  }

  public generalInformationUpdate(): Observable<void> {
    return this.generalInformationRequiresUpdate.asObservable();
  }

  public updateGeneralInformation(): void {
    this.generalInformationRequiresUpdate.next();
  }

}
