<div class="dialog-content upload-firmware">
  <div class="dialog-header">
    <span class="title">Upload Firmware</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="info-line">
      <div class="label">Device Mac</div>
      <div class="value">{{virtualDevice?.deviceMac || '&mdash;'}}</div>
    </div>
    <div class="firmware-line">
      <div class="label">Select needed firmware</div>
      <div class="value">
        <app-dropdown [items]="firmware" [(ngModel)]="selected" placeholder="Select firmware to install"
          [disabled]="loading || processing"></app-dropdown>
      </div>
    </div>
    <div class="warning-line">
      <span class="warning" *ngIf="deviceBusy"><span class="red">WARNING: </span>device is busy and unable to run updates</span>
      <span class="warning" *ngIf="!virtualDevice.deviceOnline"><span class="red">WARNING: </span>device is not online</span>
    </div>
    <div class="loading-container" *ngIf="processing">
      <div class="loader centered"></div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="runUploadFirmware()" [disabled]="loading || processing || !selected || deviceBusy || !virtualDevice.deviceOnline">Upload</button>
  </div>
</div> 