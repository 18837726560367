<div class="dialog-content device-module-view">
  <div class="dialog-header">
    <span class="title">Wi-fi module {{deviceModule.macAddress}}</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="view-layout" [class.show-event]="commandDetailsMode">
      <div class="left-side">
        <mat-tab-group mat-align-tabs="start" (selectedIndexChange)="checkDialogSize($event)">
          <mat-tab label="Info">
            <app-device-module-info-view [details]="details" (navigated)="close()">
            </app-device-module-info-view>
            <div class="loader centered" *ngIf="loading"></div>
          </mat-tab>
          <mat-tab label="Spaces & Users">
            <app-device-module-spaces-view [details]="details">
            </app-device-module-spaces-view>
            <div class="loader centered" *ngIf="loading"></div>
          </mat-tab>
          <mat-tab label="Commands">
            <app-device-module-commands-list [details]="details" [justify]="true" (viewDetails)="viewCommandDetails($event)">
            </app-device-module-commands-list>
            <div class="loader centered" *ngIf="loading"></div>
          </mat-tab>
          <mat-tab label="Alarms">
            <app-device-module-alarms-list [details]="details" [justify]="true">
            </app-device-module-alarms-list>
            <div class="loader centered" *ngIf="loading"></div>
          </mat-tab>
          <mat-tab label="Events">
            <app-device-module-events-list [details]="details" [justify]="true">
            </app-device-module-events-list>
            <div class="loader centered" *ngIf="loading"></div>
          </mat-tab>
        </mat-tab-group>
      </div>
      <div class="right-side event-view">
        <div class="event-header">
          <div class="arrow-return" (click)="returnBack()"></div>
          <div class="event-info">
            <h2 class="event-title panel-header">Commands / {{command?.type === CommandType.REQUEST ? (command?.name || '&mdash;') : (command?.errNo || 'Ok')}}</h2>
            <div class="event-details">
              <span class="event-date">{{command?.ts | date:DATE_TIME}}</span>
              <span class="event-author" *ngIf="command?.author"> by {{command?.author}}</span>
            </div>
          </div>
        </div>
        <div class="event-data" *ngIf="commandDetails">
          <perfect-scrollbar>
            <div class="scroll-area">
              <ngx-json-viewer [json]="commandDetails"></ngx-json-viewer>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
  <div class="dialog-footer align-right">
    <button class="primary" mat-dialog-close>Close</button>
  </div>
</div>
