<div class="sessions-view sub-view">
  <div class="controls-panel view-header align-container">
    <div class="label-base">Period</div>
    <div class="range-select">
      <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData(true)" [typeSelector]="false">
      </app-dates-range>
    </div>
  </div>
  <div class="view-section">
    <div class="contents scroll-container">
      <perfect-scrollbar>
        <table class="results-table stripes-table">
          <thead>
            <tr>
              <th>
                On Time
              </th>
              <th>
                Off Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of sessions?.content">
              <td>{{row.onTime ? (row.onTime | date:DATE_TIME) : '&mdash;'}}</td>
              <td>{{row.offTime ? (row.offTime | date:DATE_TIME) : '&mdash;'}}</td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!sessions || !sessions?.content.length" [bordered]="true"></app-no-data>
      </perfect-scrollbar>
    </div>
    <div class="pagination-block attached" *ngIf="sessions && sessions.totalPages > 1">
      <div class="text-info">Showing {{sessions.startElement}} to {{sessions.endElement}} of {{sessions.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="sessions.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
</div>