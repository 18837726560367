import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Utils } from './utils';
import { Paged } from '@models/pageable';
import { Sorting } from '@models/sorting';
import { DatesRange, fixRange } from '@models/dates';
import { UserAction, UserActionType, UserActionTargetType } from '@models/user-actions';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http: HttpClient, private utils: Utils) {}

  public getAuditUserActions(query: string, targetType: string, actionType: string,
      datesRange: DatesRange, page: number, sorting: Sorting): Observable<Paged<UserAction>> {
    const range = fixRange(datesRange);
    const params = {
      paged: true,
      page: page - 1,
      size: 20,
      ...(query ? {query} : null),
      ...(targetType ? {targetType} : null),
      ...(actionType ? {actionType} : null),
      ...(range?.from ? {from: range.from.toISOString()} : null),
      ...(range?.to ? {to: range.to.toISOString()} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null) 
    };
    return this.http.get<Paged<UserAction>>(`/persist-service/api/v1/console/user-actions`,
        {params}).pipe(tap((paged: Paged<UserAction>) => {
      Utils.extendPaged(paged);
      paged.content.forEach(x => x.dateTime = x.dateTime && new Date(x.dateTime));
    }));
  }

  public getAuditActionTypes(): Observable<Array<UserActionType>> {
    return this.http.get<Array<UserActionType>>(`/persist-service/api/v1/console/user-actions/action-types`);
  }

  public getAuditTargetTypes(): Observable<Array<UserActionTargetType>> {
    return this.http.get<Array<UserActionTargetType>>(`/persist-service/api/v1/console/user-actions/target-types`);
  }

  public exportUserActions(query: string, targetType: number, actionType: number,
      datesRange: DatesRange, sorting: Sorting): Observable<string> {
    const range = fixRange(datesRange);
    const params = {
      ...(query ? {query} : null),
      ...(targetType ? {targetType} : null),
      ...(actionType ? {actionType} : null),
      ...(range?.from ? {from: range.from.toISOString()} : null),
      ...(range?.to ? {to: range.to.toISOString()} : null),
      ...(sorting ? {sort: sorting.column + ',' + sorting.order} : null) 
    };
    return this.utils.downloadFileWithAuth(`/persist-service/api/v1/console/user-actions/export`, params);
  }
}
