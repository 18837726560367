import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams, CONFLICT, PRECONDITION_FAILED } from '@constants/http-params';
import { HttpErrorResponse } from '@angular/common/http'; 
import { TestingService } from '@services/testing.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { TestingDetails, TestingEntry, TestingEntryStatus,
  DeviceFirmwareUpdateStatus, TestingStatus, TestingVirtualDeviceStatus } from '@models/testing';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';
import { Paged } from '@models/pageable';
import { Breadcrumbs } from '@models/breadcrumbs';
import { Sorting, SortOrder } from '@models/sorting';
import { DATE_TIME } from '@constants/dates';
import { PAGE_SIZE } from '@constants/main';
import { Subject, finalize, interval, takeUntil, switchMap } from 'rxjs';
import { Utils } from '@services/utils';

const REFRESH_TIME = 4000;
const NAVIGATE_DELAY = 2000;

enum SortColumn {
  RECORD = 'description',
  STATUS = 'sortStatus',
  EXECUTION_ORDER = 'orderNum'
}

@Component({
  selector: 'app-test-view',
  templateUrl: './test-view.component.html',
  styleUrls: ['./test-view.component.scss']
})
export class TestViewComponent implements OnInit, OnDestroy {

  public recording = false;
  public breadcrumbs: Array<Breadcrumbs>;
  public testingId: number;
  public testingInProcess = false;
  public testing: TestingDetails;
  public firstUpdate = true;
  public entries: Paged<TestingEntry>;
  public activePage = 1;
  public cancelTrace = false;
  public loading = false;
  public restoringFirmware = false;
  public cancelPolling = new Subject<void>();
  public DATE_TIME = DATE_TIME;
  public sorting: Sorting = {column: SortColumn.EXECUTION_ORDER, order: SortOrder.ASC};
  public SortColumn = SortColumn;
  public TestingStatus = TestingStatus;
  private destroyed = new Subject<void>();

  @ViewChild('information') information: TemplateRef<any>;
  @ViewChild('scrollbar') private scrollbar: PerfectScrollbarComponent;

  constructor(private testingService: TestingService, private dialogService: DialogService,
      private notificationService: NotificationService, private utils: Utils,
      private router: Router, private route: ActivatedRoute) {
  }

  public ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.recording = data[HttpParams.TESTING_MODE] === HttpParams.TESTING_RECORD;
    });
    this.breadcrumbs = [{title: this.recording ? 'Records' : 'Tests', link: ['/testing', this.recording ? 'records' : 'tests']}];
    this.testingId = Number(this.utils.lookupParam(HttpParams.TESTING_ID));
    this.breadcrumbs.push({title: (this.recording ? 'Record # ' : 'Test # ') + this.testingId});
    this.updateData(true);
  }

  public ngOnDestroy(): void {
    this.cancelPolling.next();
    this.testingService.cancelFirmwareUpdateTrack();
    this.destroyed.next();
    this.destroyed.complete();
  } 

  public setActivePage(activePage: number): void {
    this.activePage = activePage;
    this.sliceData(true);
  }

  public setSorting(sorting: Sorting): void {
    this.sorting = sorting;
    this.sliceData(true);
  }

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '600px', data: {
      title: this.recording ? 'Record #' : 'Test #',
      text: this.information
    }});
  }

  public restoreOriginalFirmware(): void {
    this.restoringFirmware = true;
    this.testingService.restoreOriginalFirmware(this.testing.deviceMac).pipe(switchMap((track: {fw: string, trackId: string}) => {
        return this.testingService.trackFirmwareUpdateStatus({trackId: track.trackId, deviceMac: this.testing.deviceMac, fwVersion: track.fw});
      }), finalize(() => this.restoringFirmware = false)).subscribe((status: TestingVirtualDeviceStatus) => {
        if (status.status === DeviceFirmwareUpdateStatus.COMPLETED) {
          this.notificationService.success(`Latest firmware successfully installed for device ${this.testing.deviceMac}`);
          setTimeout(() => this.router.navigate(['/testing', 'tests']), NAVIGATE_DELAY);
        } else {
          this.notificationService.error(`Failed to install latest firmware for device ${this.testing.deviceMac}`);
        }
    }, (error: HttpErrorResponse) => {
      const baseMsg = `Failed to Install Latest Firmware on device ${this.testing.deviceMac}.`;
      if (error.status === CONFLICT) {
        this.notificationService.error(`${baseMsg} Device is busy and unable to start process`);
      } else if (error.status === PRECONDITION_FAILED) {
        this.notificationService.error(`${baseMsg} Device is offline`);
      } else {
        this.notificationService.error(baseMsg);
      }
    });
  }

  private updateData(showLoading = false): void {
    this.loading = showLoading;
    this.testingService.getTestingDetails(this.testingId)
        .pipe(finalize(() => this.loading = false)).subscribe((testing: TestingDetails) => {
      this.testing = testing;
      this.sliceData(false);
      const prevState = this.testingInProcess;
      this.testingInProcess = !this.testingService.isTerminalTestingStatus(this.testing.status);
      if (this.firstUpdate && this.testingInProcess) {
        interval(REFRESH_TIME).pipe(takeUntil(this.destroyed), takeUntil(this.cancelPolling)).subscribe(() => {
          this.updateData(false);
        });
      }
      if (prevState && !this.testingInProcess) {
        const status = 'result is ' + (this.testing.status === TestingStatus.SUCCESS ? 'success' : 'failure');
        this.notificationService.success(this.recording ? `Record completed, ${status}` : `Test completed, ${status}`);
        this.cancelPolling.next();
      }
      this.firstUpdate = false;
    }, (err: HttpErrorResponse) => {
      this.notificationService.error('Something went wrong. Please try again.');
      this.cancelPolling.next();
    });
  }

  private sliceData(cancelTrace = false): void {
    if (this.testing) {
      this.testing.recordTestCases = this.testing.recordTestCases.sort((a, b) => {
        return Utils.compare(a, b, this.sorting);
      });
      const maxPage = Math.max(1, Math.ceil(this.testing.recordTestCases.length / PAGE_SIZE));
      const prevActivePage = this.activePage;
      this.activePage = Math.min(this.activePage, maxPage);
      this.cancelTrace = this.cancelTrace || cancelTrace;
      const running = this.testing.recordTestCases.findIndex(x => x.state === TestingEntryStatus.RUNNING);
      if (!this.cancelTrace && running >= 0) {
        this.activePage = Math.ceil((1 + running) / PAGE_SIZE);
      }
      this.entries = Utils.createPage<TestingEntry>(this.testing.recordTestCases, this.activePage, PAGE_SIZE);
      if (this.activePage !== prevActivePage && this.scrollbar) {
        this.scrollbar.directiveRef.scrollToTop();
      }
    }
  }

}
