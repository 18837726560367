<div class="instructions-view inner-view">
  <div class="view-header">
    <h1 class="align-container small-spacing">
      <span>Device instructions</span>
      <button class="information" (click)="showInfo()"></button>
    </h1>

  </div>
  <div class="view-section filtering-panel align-container">
    <div class="filtering-block align-container">
      <div class="search-model">
        <app-search-bar [(ngModel)]="query" (search)="search($event)" [searching]="loading" placeholder="Search name or model"></app-search-bar>
      </div>
      <div class="device-type">
        <app-dropdown [items]="deviceTypesItems" [(ngModel)]="deviceType" (ngModelChange)="updateData(true)"
          placeholder="Select device type"></app-dropdown>
      </div>
    </div>
    <div class="buttons">
      <button class="primary button-add" (click)="createDeviceInstruction()">Add New</button>
    </div>
  </div>
  <div class="results-block view-section">
    <perfect-scrollbar>
      <table class="results-table">
        <thead>
          <tr>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.NAME" (sort)="setSorting($event)">
                Name
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.TYPE" (sort)="setSorting($event)">
                Type
              </app-sortable-header>
            </th>
            <th>Device Types / model</th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.STATUS" (sort)="setSorting($event)">
                Status
              </app-sortable-header>
            </th>
            <th class="sortable">
              <app-sortable-header [sorting]="sorting" [column]="SortColumn.UPDATED" (sort)="setSorting($event)">
                Updated
              </app-sortable-header>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of instructions?.content">
            <td>{{row.name || '&mdash;'}}</td>
            <td class="capitalize">{{row.type || '&mdash;'}}</td>
            <td>
              <span [class.red]="(row.types?.length || 0) + (row.models?.length || 0) === 0">
                {{row.types?.length || 0}} / {{row.models?.length || 0}}</span>
            </td>
            <td>
              <div class="capitalize" [class.activity-status]="!!row.status"
                [ngClass]="{active: row.status === InstructionStatus.READY, inactive: row.status !== InstructionStatus.READY}">
                {{row.status || '&mdash;'}}
              </div>
            </td>
            <td>{{row.updatedAt ? (row.updatedAt | date:DATE_FORMAT) : '&mdash;'}}</td>
            <td><app-actions [actions]="row.actions" [disabled]="progressing"></app-actions></td> 
          </tr>
        </tbody>
      </table>
      <app-no-data *ngIf="!loading && (!instructions || !instructions?.content.length)"></app-no-data>
    </perfect-scrollbar>
    <div class="pagination-block" *ngIf="instructions && instructions.totalPages > 1">
      <div class="text-info">Showing {{instructions.startElement}} to {{instructions.endElement}} of {{instructions.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="instructions.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
<ng-template #information>
  <div><b>Device Instructions</b> – instructions management section. At the moment, it is available to manage only with instructions the Mapp users can see while enrolling devices (when an instruction is created, it is set to the provisioning state by default). These instructions are a brief description of the actions of Mapp users that are necessary to connect any device to the IoT platform.</div>
  <div class="top-space">The user in this section has the ability to:</div>
  <ol class="info-list top-space">
    <li>Add new instructions (including duplication)</li>
    <li>Edit, delete an existing instruction</li>
  </ol>
  <div class="top-space">Instructions can be added both for the device type as a whole (more than one type is possible) and for the device model (several models are possible). It is possible to create an instruction without specifying the type or model of the device - the IoT platform will consider such instruction as a draft, and it will not be available for the mobile application.</div>
</ng-template>