import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { TvAppVersion, TvAppVersionStatus } from '@models/tv-app';
import { Utils } from './utils';

@Injectable({
  providedIn: 'root'
})
export class TvAppsService {

  constructor(private http: HttpClient, private utils: Utils) {}

  public getTvAppsVersions(): Observable<Array<TvAppVersion>> {
    return this.http.get<Array<TvAppVersion>>(`/statistics-service/api/v1/console/versions`, {})
      .pipe(tap((appVersions: Array<TvAppVersion>) => {
        appVersions.forEach((tvAppVersion: TvAppVersion) => {
          tvAppVersion.applicationName = tvAppVersion?.application.name;
          tvAppVersion.updatedAt = tvAppVersion.updatedAt ? new Date(tvAppVersion.updatedAt) : null;
        });
      }));
  }

  public publishTvAppVersion(tvAppVersion: TvAppVersion, coverage: number): Observable<TvAppVersion> {
    return this.http.post<void>(`/statistics-service/api/v1/console/versions/${tvAppVersion.id}/publish`, {coverage}).pipe(map(() => {
      tvAppVersion.updatedAt = new Date();
      tvAppVersion.coverage = coverage;
      tvAppVersion.status = TvAppVersionStatus.PUBLISHED;
      return tvAppVersion;
    }));
  }

  public unpublishTvAppVersion(tvAppVersion: TvAppVersion): Observable<TvAppVersion> {
    return this.http.post<void>(`/statistics-service/api/v1/console/versions/${tvAppVersion.id}/unpublish`, {}).pipe(map(() => {
      tvAppVersion.updatedAt = new Date();
      tvAppVersion.status = TvAppVersionStatus.UNPUBLISHED;
      tvAppVersion.coverage = null;
      return tvAppVersion;
    }));
  }

  public setVersionEnabled(tvAppVersion: TvAppVersion, enabled: boolean): Observable<TvAppVersion> {
    const path = enabled ? 'enable' : 'disable';
    return this.http.post<void>(`/statistics-service/api/v1/console/versions/${tvAppVersion.id}/${path}`, {}).pipe(map(() => {
      tvAppVersion.updatedAt = new Date();
      tvAppVersion.enabled = enabled;
      return tvAppVersion;
    }));
  }

  public downloadTvAppVersionApk(tvAppVersion: TvAppVersion): void {
    this.utils.downloadFile(tvAppVersion.presignedUrl);
  }

}
