<div class="dropdown">
  <div class="field" [class.expanded]="expanded" [class.disabled]="disabled" [class.invalid]="invalid"
      [class.narrow]="narrow" (click)="toggle($event)" cdkOverlayOrigin #trigger #origin="cdkOverlayOrigin">
    <div class="value single-item" [class.empty]="!selectedItem" *ngIf="!multi"
         (mouseenter)="showTipForTruncated($event)" (mouseleave)="valueTip = ''" [attr.title]="valueTip">
      {{selectedItem ? selectedItem.title : (placeholder || 'Not Selected')}}
    </div>
    <div class="value tags-list" [class.empty]="!selectedItems?.length" *ngIf="multi">
      <div class="value-part tag" *ngFor="let item of selectedItems">
        {{item.title}}<div class="remove-item" (click)="removeItem(item, $event)"></div>
      </div>
      {{selectedItems?.length ? '' : (placeholder || 'Not Selected')}}
    </div>
    <div class="controls">
      <div class="button button-clear" *ngIf="clearable && (selectedItem || selectedItems?.length)" (click)="clear($event)"></div>
      <div class="arrow" [class.expanded]="expanded"></div>
    </div>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin" [cdkConnectedOverlayOpen]="expanded"
  [cdkConnectedOverlayPositions]="PositionBelow" [cdkConnectedOverlayWidth]="sizeBox?.width!" (overlayOutsideClick)="toggle($event)">
  <div class="dropdown-panel limited-height">
    <perfect-scrollbar>
      <div class="scroll-area">
        <div class="dropdown-item" *ngFor="let item of items" (click)="toggleItem(item, $event)" [ngClass]="{selected: item === selectedItem}">
          <div class="checkbox" [class.checked]="selectedItems.includes(item)" *ngIf="multi"></div>
          <div class="item-text">{{item.title}}</div>
          <div class="additional" *ngIf="item.additional !== undefined">{{item.additional}}</div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</ng-template>
