<div class="dialog-content device-type-edit">
  <div class="dialog-header">
    <span class="title">{{(isNew ? 'Add' : 'Edit') + ' Device Type'}}</span>
    <div class="close-icon" mat-dialog-close></div>
  </div>
  <div class="dialog-body">
    <div class="field-name">Device Type</div>
    <div class="field">
      <input type="text" maxlength="50" [(ngModel)]="name" (ngModelChange)="resetErrors()" [class.invalid]="errors?.name"/>
      <div class="validation" *ngIf="errors?.name">
        <div>Device Type name is required and should be unique</div>
      </div>
    </div>
    <div class="field-name">Product lines</div>
    <div class="field product-lines">
      <app-dropdown [items]="productLinesItems" [multi]="true" [clearable]="true"
        [invalid]="errors?.productLines" placeholder="Select Product Lines"
        [(ngModel)]="productLinesSelected" (ngModelChange)="resetErrors()" (toggled)="resetErrors()">
      </app-dropdown>
      <div class="validation" *ngIf="errors?.productLines">
        <div>Product Lines are required and should be unique</div>
      </div>
    </div>
  </div>
  <div class="dialog-footer">
    <button class="secondary" mat-dialog-close>Close</button>
    <button class="primary" (click)="proceed()" [disabled]="progressing">Save</button>
  </div>
</div>
