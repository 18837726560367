import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UtilitiesService } from '@services/utilities.service';
import { DialogService } from '@services/dialog.service';
import { NotificationService } from '@services/notification.service';
import { InformationDialogComponent } from '@components/information-dialog/information-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, takeUntil, finalize } from 'rxjs';
import { ContentExportImport, ExportImportResult, ExportResult } from '@models/export-import';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-export-import-view',
  templateUrl: './export-import-view.component.html',
  styleUrls: ['./export-import-view.component.scss']
})
export class ExportImportViewComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public uploading = false;
  public downloading = false;
  public allowed = true;
  public environment = environment;

  @ViewChild('information') information: TemplateRef<any>;

  private destroyed = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private utilitiesService: UtilitiesService,
    private notificationService: NotificationService, private dialogService: DialogService) { }

  public ngOnInit(): void {
    this.form = this.formBuilder.group({
      images: true,
      records: true
    });
    this.form.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((data: any) => {
      this.allowed = data.images || data.records;
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  } 

  public showInfo(): void {
    this.dialogService.showModal(InformationDialogComponent, { maxWidth: '800px', data: {
      title: 'Export and Import',
      text: this.information
    }});
  }

  public runDownload(): void {
    this.downloading = true;
    this.utilitiesService.downloadMobileBackendContent(this.form.value as ContentExportImport)
      .pipe(finalize(() => this.downloading = false)).subscribe((status: ExportResult) => {
        this.notificationService.success(status?.message);
      }, (error: HttpErrorResponse) => {
        this.notificationService.error(error.message);
      });
  }

  public runUpload(file: File): void {
    this.uploading = true;
    this.utilitiesService.uploadMobileBackendContent(file, this.form.value as ContentExportImport)
        .pipe(finalize(() => this.uploading = false)).subscribe((result: ExportImportResult) => {
      if (!result || result?.exception) {
        const DEFAULT_MESSAGE = 'Something went wrong. Please try again.';
        this.notificationService.error(result?.message || result?.exception || DEFAULT_MESSAGE);
      } else {
        this.notificationService.success(result.message);
      }
    }, (error: HttpErrorResponse) => {
      this.notificationService.error(error.message);
    });
  }

}
