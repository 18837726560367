<div class="month-select">
  <div class="field" [class.expanded]="expanded" [class.disabled]="disabled"
      (click)="toggle($event)" cdkOverlayOrigin #origin="cdkOverlayOrigin">
    <div class="value" [class.empty]="!text">
      {{text || placeholder || 'Not Selected'}}
    </div>
    <div class="controls">
      <div class="button button-clear" *ngIf="clearable && selectedDate" (click)="clear($event)"></div>
      <div class="calendar-button" [class.expanded]="expanded"></div>
    </div>
  </div>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="origin" [cdkConnectedOverlayOpen]="expanded"
  [cdkConnectedOverlayPositions]="PositionBelow" (overlayOutsideClick)="toggle($event)"> 
  <div class="dropdown-panel items-panel">
    <div class="year-selection-panel align-container small-spacing">
      <button class="button button-prev" [class.disabled]="!prevYearAllowed" (click)="prevYear($event)"></button>
      <div class="year-title label">{{year}}</div>
      <button class="button button-next" [class.disabled]="!nextYearAllowed" (click)="nextYear($event)"></button>
    </div>
    <div class="months-container items-container">
      <div class="month-item selectable-item" *ngFor="let month of months" (click)="selectMonth(month, $event)"
        [class.selected]="month.selected" [class.disabled]="month.disabled">
        {{month.title}}
      </div>
    </div>
  </div>
</ng-template>