<div class="alarms-view sub-view">
  <div class="controls-panel view-header align-container" [class.space-between]="justify">
    <div class="label-base">Period</div>
    <div class="range-select">
      <app-dates-range [(ngModel)]="range" (ngModelChange)="updateData(true)" [typeSelector]="false">
      </app-dates-range>
    </div>
    <div class="query-field">
      <app-search-bar (search)="search($event)" [searching]="loading" placeholder="Search name">
      </app-search-bar>
    </div>
  </div>
  <div class="view-section">
    <div class="contents scroll-container">
      <perfect-scrollbar>
        <table class="results-table stripes-table">
          <thead>
            <tr>
              <th>
                Name
              </th>
              <th>
                Time
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of alarms?.content">
              <td class="break-word">{{row.name || '&mdash;'}}</td>
              <td class="nowrap">{{row.ts ? (row.ts | date:DATE_TIME) : '&mdash;'}}</td>
            </tr>
          </tbody>
        </table>
        <app-no-data *ngIf="!alarms || !alarms?.content.length" [bordered]="true"></app-no-data>
      </perfect-scrollbar>
    </div>
    <div class="pagination-block attached" *ngIf="alarms && alarms.totalPages > 1">
      <div class="text-info">Showing {{alarms.startElement}} to {{alarms.endElement}} of {{alarms.totalElements}} items</div>
      <app-pagination [activePage]="activePage" [totalPages]="alarms.totalPages" (pageChanged)="setActivePage($event)">
      </app-pagination>
    </div>
  </div>
</div>