import { Component, Inject, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http'; 
import { TestingService } from '@services/testing.service';
import { FirmwareService } from '@services/firmware.service';
import { NotificationService } from '@services/notification.service';
import { TestingVirtualDevice, TestingVirtualDeviceStatus } from '@models/testing';
import { TestingFirmware } from '@models/firmware';
import { DropdownItem } from '@models/dropdown';
import { Subject, takeUntil, filter, finalize } from 'rxjs';
import { CONFLICT, PRECONDITION_FAILED, FORBIDDEN } from '@constants/http-params';

@Component({
  selector: 'app-device-upload-firmware-dialog',
  templateUrl: './device-upload-firmware-dialog.component.html',
  styleUrls: ['./device-upload-firmware-dialog.component.scss']
})
export class DeviceUploadFirmwareDialogComponent implements OnInit, OnDestroy {

  public virtualDevice: TestingVirtualDevice;
  public firmware: Array<DropdownItem>;
  public selected: DropdownItem;
  public processing = false;
  public loading = false;
  public deviceBusy = false;

  private destroyed = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {virtualDevice: TestingVirtualDevice},
    private changeDetectorRef: ChangeDetectorRef,
    private dialogRef: MatDialogRef<DeviceUploadFirmwareDialogComponent>,
    private testingService: TestingService,
    private firmwareService: FirmwareService,
    private notificationService: NotificationService
  ) { }

  public ngOnInit(): void {
    this.virtualDevice = this.data.virtualDevice;
    this.loading = true;
    this.firmwareService.getTestingFirmwares(this.virtualDevice.deviceMac)
        .pipe(finalize(() => this.loading = false)).subscribe((firmware: Array<TestingFirmware>) => {
      this.firmware = (firmware || []).map(x => ({value: x.id, title: x.version, original: x}));
    });
  }

  public ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  } 

  public runUploadFirmware(): void {
    this.processing = true;
    this.deviceBusy = false;
    this.testingService.runUploadFirmware(this.virtualDevice.deviceMac, this.selected.original).subscribe((trackId: string) => {
      this.virtualDevice.trackId = trackId;
      this.testingService.appendVirtualSmartDeviceToMonitoring(this.virtualDevice, this.selected.title)
          .pipe(takeUntil(this.destroyed)).subscribe((x: TestingVirtualDeviceStatus) => {
        this.processing = false;
        this.close();
      });
    }, (error: HttpErrorResponse) => {
      this.processing = false;
      if (error.status === CONFLICT) {
        this.deviceBusy = true;
        this.awaitsDeviceReady();
      } else if (error.status === PRECONDITION_FAILED) {
        this.virtualDevice.deviceOnline = false;
        this.awaitsDeviceReady();
      } else if (error.status !== FORBIDDEN) {
        this.notificationService.error(`Failed to Upload Firmware on device ${this.virtualDevice.deviceMac}.`);
      }
    });
  }

  public close(): void {
    this.dialogRef.close();
  }

  public proceed(): void {
    this.dialogRef.close(true);
  }

  private awaitsDeviceReady(): void {
    this.testingService.awaitsDeviceReady(this.virtualDevice.deviceMac, this.destroyed).subscribe(() => {
      this.virtualDevice.deviceOnline = true;
      this.deviceBusy = false;
      this.changeDetectorRef.detectChanges();
    });
  }
}
