import { Component, OnInit, OnDestroy, Input, forwardRef, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { POSITION_BELOW } from '@constants/overlay-position';
import { DropdownItem, LookupProvider } from '@models/dropdown';
import { NotificationService } from '@services/notification.service';
import { BehaviorSubject, debounceTime, skip } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { BLUR_DELAY, LOOKUP_DELAY, DEFAULT_MAX_LOOKUP_ITEMS } from '@constants/config';

@Component({
  selector: 'app-lookup',
  templateUrl: './lookup.component.html',
  styleUrls: ['./lookup.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => LookupComponent),
    multi: true
  }]

})
export class LookupComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input()
  public placeholder = 'Search';

  @Input()
  public provider: LookupProvider;

  @Input()
  public multi = false;

  @Input()
  public clearable = false;

  @Input()
  public unique = false;

  @Input()
  public invalid = false;

  @Input()
  public disabled = false;

  @Input()
  public sort = false;

  @Input()
  public tagsItemsFlag = false;

  @Input()
  public magnifyingGlass = false;

  @Input()
  public allowSearchEmpty = false;

  @Input()
  public maxItemsCount = DEFAULT_MAX_LOOKUP_ITEMS;

  @Input()
  public maxItemsOverflowText: string;

  @Input()
  public allowCustomInput: boolean;

  @Input()
  public allowAddingNonExisting = false;

  @Input()
  public textFieldMask?: string;

  @Input()
  public textFieldPatterns?: { [character: string]: { pattern: RegExp; optional?: boolean; symbol?: string; }};

  @ViewChild('trigger') trigger: ElementRef;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(CdkConnectedOverlay) overlay: CdkConnectedOverlay;

  public query = '';
  public expanded = false;
  public focused = false;
  public selectedItem: DropdownItem;
  public selectedItems: Array<DropdownItem> = []; // the same for .multi
  public items: Array<DropdownItem> = [];
  public sizeBox: ClientRect;
  public PositionBelow = POSITION_BELOW;

  private blurTimeout: number;
  private destroyed = new BehaviorSubject<boolean>(false);
  private scheduledSearch = new BehaviorSubject<string>('');
  private onTouchedCallback: () => void;
  protected commit(value: DropdownItem | Array<DropdownItem> | string) {}

  constructor(private notificationService: NotificationService, private changeDetection: ChangeDetectorRef) { }

  public ngOnInit(): void {
    this.scheduledSearch.pipe(debounceTime(LOOKUP_DELAY), skip(1))
      .subscribe(() => this.search(), (err) => this.failedSearch(err));
  }

  public ngOnDestroy(): void {
    this.destroyed.next(true);
    this.destroyed.complete();
  }

  public toggle(event: MouseEvent): void {
    if (!this.disabled) {
      if (this.expanded) {
        this.expanded = false;
      } else {
        this.search();
      }
      this.returnFocus();
    }
    event.stopPropagation();
  }

  public scheduleSearch(): void {
    this.scheduledSearch.next(this.query);
  }

  public search(): void {
    if (this.provider && (!!this.query.length || this.allowSearchEmpty)) {
      if (!this.multi || !this.maxItemsCount || this.selectedItems?.length < this.maxItemsCount) {
        this.provider.search(this.query).subscribe((items: Array<DropdownItem>) => {
          this.items = (items || []);
          const existing = new Set((this.multi ? this.selectedItems : (this.selectedItem ? [this.selectedItem] : [])).map(x => x.value));
          if (this.unique) {
            this.items = (items || []).filter(x => !existing.has(x.value));
          }
          if (this.sort) {
            this.items.sort((a: DropdownItem, b: DropdownItem) => {
              return (+existing.has(a.value) - +existing.has(b.value)) || (a.title || '').localeCompare(b.title);
            });
          }
          this.expanded = !!this.items.length;
          this.sizeBox = this.trigger.nativeElement.getBoundingClientRect();
        });
      }
    }
  }

  public failedSearch(err: HttpErrorResponse): void {
    this.notificationService.error(err.message);
  }

  public focus(): void {
    this.focused = true;
  }

  public blur(force = false): void {
    this.cancelBlur();
    if (force) {
      this.executeBlur();
    } else {
      this.blurTimeout = setTimeout(() => {
        this.executeBlur();
      }, BLUR_DELAY);
    }
  }

  public toggleItem(item: DropdownItem, event: MouseEvent): void {
    if (!this.disabled) {
      this.selectedItem = this.multi ? null : item;
      this.selectedItems = this.multi ?
        (this.selectedItems.includes(item) ? this.selectedItems.filter(x => x !== item) : this.selectedItems.concat(item)) : [];
      this.changeDetection.detectChanges();
      this.query = this.multi ? '' : this.selectedItem.title || '';
      this.expanded = !!this.multi;
      this.expanded = this.expanded && (!this.multi || !this.maxItemsCount || this.selectedItems.length < this.maxItemsCount);
      if (this.expanded) {
        this.overlay.overlayRef.updatePosition();
      }
      this.commit(this.allowCustomInput ? this.query : (this.multi ? this.selectedItems : this.selectedItem));
      if (this.multi) {
        this.returnFocus();
      }
    }
    event.stopPropagation();
  }

  public isChecked(item: DropdownItem, event?: MouseEvent): boolean {
    event?.stopPropagation();
    return this.selectedItems.includes(item);
  }

  public removeItem(item: DropdownItem, event: MouseEvent): void {
    if (!this.disabled) {
      event && event.stopPropagation();
      this.selectedItems = this.selectedItems.filter(x => x !== item);
      this.commit(this.selectedItems);
    }
  }

  public includeNewItem(event: KeyboardEvent): void {
    if (this.multi && !this.disabled && (!this.unique || !this.selectedItems.map(x => x.title).includes(this.query))) {
      const existing = this.items.find(x => x.title === this.query);
      const only = !this.allowAddingNonExisting && this.query && this.items && this.items.length === 1 ? this.items[0] : null;
      if (existing || only || this.allowAddingNonExisting) {
        this.selectedItems = this.selectedItems.concat(existing || only || {value: null, title: this.query});
        this.commit(this.selectedItems);
      }
      this.query = '';
      this.expanded = false;
    }
  }

  public clear(event: MouseEvent): void {
    if (!this.disabled) {
      this.selectedItem = null;
      this.selectedItems = [];
      this.query = '';
      this.commit(this.multi ? this.selectedItems : this.selectedItem);
    }
    event.stopPropagation();
  }

  public writeValue(value: DropdownItem | Array<DropdownItem> | string) {
    if (this.destroyed.value) {
      return;
    }
    if (this.allowCustomInput) {
      this.query = value as string;
    } else {
      this.selectedItem = this.multi ? null : value as DropdownItem;
      this.selectedItems = this.multi ? (value as Array<DropdownItem> || []) : [];
      this.query = this.multi ? '' : this.selectedItem?.title || '';
    }
    this.expanded = false;
  }

  public registerOnChange(fn: any): void {
    this.commit = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  private returnFocus(): void {
    this.cancelBlur();
    if (this.input?.nativeElement) {
      this.input.nativeElement.focus();
    }
  }

  private cancelBlur(): void {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }
  }

  private executeBlur(): void {
    if (!this.allowCustomInput) {
      this.query = this.multi ? '' : this.selectedItem?.title || '';
    } else {
      this.commit(this.query);
    }
    this.expanded = this.focused = false;
  }

}
